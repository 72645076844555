import styled, { css } from "styled-components";
import { templates } from "../../theme/templates";
import { text } from "../../theme/text";

export const Wrapper = styled.div<{ buttonActive?: boolean; type: string, width: string }>`
  ${(p) => {
    switch (p.type) {
      case "authPage":
        return css`
          ${templates.centerContent};
          width: 100%;
          height: 60px;
          color: ${p.buttonActive ? "black" : text.colors.gray};
          margin-top: 40px;
          background-color: ${p.buttonActive
            ? p.theme.colors.yellowMain
            : p.theme.colors.subBackground};
          border-radius: 16px;
          font-weight: 600;
          font-family: Gilroy-Semibold;
          font-size: 20px;
          line-height: 29px;
          text-align: center;
          letter-spacing: -0.3px;
          cursor: pointer;
          @media (max-width: 900px) {
            height: 56px;
            margin-top: 32px;
            font-size: 16px;
          }
        `;

      case "sideBarWhite":
        return css`
          ${templates.centerContent};
          width: 90px;
          height: 38px;
          color: ${text.colors.black};

          background-color: ${(p) => p.theme.colors.white};
          border-radius: 10px;

          font-weight: 600;
          font-family: Gilroy-Semibold;
          font-size: 18px;
          line-height: 22px;
          text-align: center;
          letter-spacing: -0.3px;

          cursor: pointer;
        `;
      case "sideBarGold":
        return css`
          ${templates.centerContent};
          width: 90px;
          height: 38px;
          color: ${(p) => p.theme.colors.white};

          border-radius: 10px;
          border: 1px solid ${(p) => p.theme.colors.yellowMain};
          font-weight: 600;
          font-family: Gilroy-Semibold;
          font-size: 18px;
          line-height: 22px;
          text-align: center;
          letter-spacing: -0.3px;

          cursor: pointer;
        `;
      case "verification":
        return css`
          ${templates.centerContent};
          width: 100%;
          height: 68px;
          color: ${p.buttonActive ? "black" : text.colors.gray};
          margin-top: 32px;
          
          background-color: ${p.buttonActive
            ? p.theme.colors.yellowMain
            : "#191C1E"};
          border-radius: 16px;
          font-weight: 600;
          font-family: Gilroy-Semibold;
          font-size: 20px;
          line-height: 29px;
          text-align: center;
          cursor: pointer;
          @media (max-width: 850px) {
            height: 56px;
            font-size: 16px;
          }
        `;
      case "support":
        return css`
          ${templates.centerContent};
          width: 100%;
          height: 68px;
          color: ${p.buttonActive ? "black" : text.colors.gray};
          background-color: ${p.buttonActive
            ? p.theme.colors.yellowMain
            : "#191C1E"};
          border-radius: 16px;
          font-weight: 600;
          font-family: Gilroy-Semibold;
          font-size: 20px;
          line-height: 29px;
          text-align: center;
          letter-spacing: -0.3px;
          cursor: pointer;
          @media (max-width: 850px) {
            height: 56px;
            font-size: 16px;
          }
        `;
        case "NFT":
          return css`
            ${templates.centerContent};
            width: ${p.width ? p.width : '100%'};
            height: 68px;
            color: ${p.buttonActive ? "black" : text.colors.gray};
            background-color: ${p.buttonActive
              ? p.theme.colors.white
              : "#191C1E"};
            border-radius: 16px;
            margin-top: 20px;
            font-weight: 600;
            font-family: Gilroy-Semibold;
            font-size: 20px;
            line-height: 29px;
            text-align: center;
            letter-spacing: -0.3px;
            cursor: pointer;
            @media (max-width: 850px) {
              height: 48px;
              font-size: 16px;
            }
          `;

      default:
        return "";
    }
  }}
`;
