import {
  takeLeading,
  put,
  select
} from 'redux-saga/effects';
import { api } from '../../api';
import { sagaFactory, zedApiResponseDataTransformer, zedApiResponseValidator } from '../../helpers/sagaHelpers';
import { EscrowActions } from './EscrowRedux';
import * as T from '../../types/api';
import { userIdSelector } from '../user/UserSelectors';
import { EscrowTxConstructorActions } from '../escrowTxConstructor/EscrowTxConstructorSlice';
import { escrowTxListDataSelector } from './EscrowSelectors';

const changeRequest = sagaFactory<T.EscrowChangeResponse>({
  restActions: EscrowActions.change,
  apiRequest: api.escrowChange,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  toastOptions:{
    showSuccess: true,
    showFailure: true,
    getInfoFn: (data, success) => data?.message ?? (success ? "Transaction updated" : "Transaction update failed")
  },
  successSaga: function* (payload, data): any {
    const userId: ReturnType<typeof userIdSelector> = yield select(userIdSelector);

    if (userId !== undefined)
      yield put(EscrowActions.list.request({
        customer_id: userId
      }));
  }
});

const deleteRequest = sagaFactory<T.EscrowDeleteResponse>({
  restActions: EscrowActions.delete,
  apiRequest: api.escrowDelete,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  toastOptions:{
    showSuccess: true,
    showFailure: true,
    getInfoFn: (data, success) => data?.message ?? (success ? "Transaction deleted" : "Transaction deletion failed")
  },
  successSaga: function* (payload): any {
    const escrowTxList: ReturnType<typeof escrowTxListDataSelector> = yield select(escrowTxListDataSelector);
    const escrowTxListWithoutDeletedTx = escrowTxList.filter((tx) => tx.id !== payload.id);
    yield put(EscrowActions.list.success(escrowTxListWithoutDeletedTx));
  }
});

const editRequest = sagaFactory<T.EscrowEditResponse>({
  restActions: EscrowActions.edit,
  apiRequest: api.escrowEdit,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  toastOptions:{
    showSuccess: true,
    showFailure: true,
    getInfoFn: (data, success) => data?.message ?? (success ? "Transaction updated" : "Transaction update failed")
  },
  successSaga: function* (payload, data): any {
    const userId: ReturnType<typeof userIdSelector> = yield select(userIdSelector);

    if (userId !== undefined)
      yield put(EscrowActions.list.request({
        customer_id: userId
      }));

    if (payload.clearEscrowTxConstructorOnSuccess)
      yield put(EscrowTxConstructorActions.clearAllState());
  }
});

const listRequest = sagaFactory<T.EscrowListResponse>({
  restActions: EscrowActions.list,
  apiRequest: api.escrowList,
  retryOptions: {
    maxTries: 2,
    delayMs: 500
  },
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  // toastOptions:{
  //   // showSuccess: true,
  //   showFailure: true
  // }
});

const newRequest = sagaFactory<T.EscrowNewResponse>({
  restActions: EscrowActions.new,
  apiRequest: api.escrowNew,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  toastOptions:{
    showSuccess: true,
    showFailure: true,
    getInfoFn: (data, success) => data?.message ?? (success ? "Transaction created" : "Transaction creation failed")
  },
  successSaga: function* (payload, data): any {
    const userId: ReturnType<typeof userIdSelector> = yield select(userIdSelector);

    if (userId !== undefined)
      yield put(EscrowActions.list.request({
        customer_id: userId
      }));

    if (payload.clearEscrowTxConstructorOnSuccess)
      yield put(EscrowTxConstructorActions.clearAllState());
  }
});

export function* EscrowSaga() {
  yield* [
    takeLeading(EscrowActions.change.request.type, changeRequest),
    takeLeading(EscrowActions.delete.request.type, deleteRequest),
    takeLeading(EscrowActions.edit.request.type, editRequest),
    takeLeading(EscrowActions.list.request.type, listRequest),
    takeLeading(EscrowActions.new.request.type, newRequest),
  ];
}