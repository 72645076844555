import styled, { keyframes } from "styled-components";
import { templates } from "../../../theme/templates";


export const Wrapper = styled.div`
  position: fixed;
  ${templates.centerContent};
  width: 100%;
  top: 0;
  height: 100vh;
  background-color: #000000c4;
  z-index: 5;
`;

export const AnimationContainer = styled.div`
  width: 690px;
  height: 583px;
`;
export const ButtonsContainer = styled.div`
  width: 100%;
  margin-top: 79px;
  display: flex;
  justify-content: space-between;
`;
export const Button = styled.div<{ border?: boolean; backColor: string }>`
  width: 310px;
  height: 68px;
  ${templates.centerContent};
  background-color: ${(p) => p.backColor};
  border: ${(p) =>
    p.border ? `1px solid ${p.theme.colors.yellowMain}` : "none"};
  border-radius: 16px;
  cursor: pointer;
`;
export const CloseButton = styled.div`
  position: absolute;
  ${templates.centerContent};
  top: 24px;
  right: 24px;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  border: 1px solid ${(p) => p.theme.colors.white};
  cursor: pointer;
`;
