import React, { useState, useCallback } from 'react';
import { useStyles } from './ActivateCardModalStyles';
import { Box, Card } from '@material-ui/core';
import { theme } from '../../theme/default/theme';
import { CustomButton } from '../CustomButton';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { cardsSelector } from '../../logic/cards/CardsSelectors';
import { StringValueItem } from '../StringValueItem';
import { TextInputUnderlined } from '../TextInputUnderlined';
import { validate2FACode } from '../../helpers/validationHelper';
import { useHideErrors } from '../../hooks/useHideErrors';
import { CardsActions } from '../../logic/cards/CardsRedux';
import CloseIcon from "./../../assets/icons/close";
import cn from 'classnames';
import Modal from "react-modal";

Modal.setAppElement("#root");

// Global constants
const MODAL_CUSTOM_STYLE = {
  content: {
    display: "flex",
    border: `1px solid ${theme.colors.borderGrey}`
  },
  overlay: {
    zIndex: 11,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

export type ActivateCardModalProps = {
  visible: boolean;
  onClose: () => void;
};

const ActivateCardModal: React.FC<ActivateCardModalProps> = ({
  visible,
  onClose
}) => {
  const s = useStyles();
  // @ts-ignore
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    cardToActivate: card,
    activate: {
      fetching: fetchingActivateCard
    }
  } = useSelector(cardsSelector);

  const [code2FA, setCode2FA] = useState("");

  const {
    hideErrors,
    setHideErrors,
    onInputErrorAnimationEnd
  } = useHideErrors();

  const handleActivate = useCallback(() => {
    if (!card)
      return;

    if (validate2FACode(code2FA)) {
      dispatch(CardsActions.activate.request({
        id: card.id,
        otp_code: code2FA,
        successCallback: () => {
          onClose();
          dispatch(CardsActions.setCardToActivate(null));
        }
      }));
    } else {
      setHideErrors(false);
    }
  }, [card, code2FA, onClose]);

  return (
    <Modal
      isOpen={visible}
      onRequestClose={onClose}
      contentLabel="Card activation"
      className={cn(s.modal, s["&__content"])}
      style={MODAL_CUSTOM_STYLE as any}
    >
      <Box className={cn(s.topCont)}>
        <Box className={cn(s.endRow)}>
          <Box className={cn(s.closeIcon)}>
            <CustomButton
              bgColorActive="transparent"
              icon={<CloseIcon color={theme.colors.white} />}
              onPress={onClose}
            />
          </Box>
        </Box>
      </Box>

      {card &&
        <Box className={cn(s.contentContainer)}>
          <Box className={cn(s.fullWidthCol)}>
            <p className={cn(s.title)}>
              {t("cards.activateYourCard")}
            </p>

            <StringValueItem 
              label={t("cardNumber")}
              value={card.card_number}
            />

            <TextInputUnderlined
              value={code2FA} 
              placeholder={t("twoFACode") as string}
              onChangeText={(text) => setCode2FA(text)} 
              validationOk={hideErrors || validate2FACode(code2FA)}
              onErrorAnimationEnd={onInputErrorAnimationEnd}
            />
          </Box>

          <CustomButton
            title={t("buttons.activate") as string}
            bgColorActive={theme.colors.yellowMain}
            colorActive={theme.colors.black}
            defaultSizing
            onPress={handleActivate}
            spinner={fetchingActivateCard}
          />
        </Box>
      }
    </Modal>
  );
};

ActivateCardModal.defaultProps={}

export { ActivateCardModal };