import { 
  createSlice, 
  // prepareAutoBatched,
  PayloadAction,
  createAction, 
} from '@reduxjs/toolkit';
import { EscrowPartnerRole, EscrowPaymentType, EscrowTransaction } from '../../types/types';
import { getId } from '../../helpers/random';
import _ from 'lodash';
import { CryptoAccountDropdownItem } from '../crypto/CryptoRedux';

export type EscrowPartnerFormInfo = {
  localId: string;
  email: string;
  role: EscrowPartnerRole | undefined;
  payment: EscrowPaymentType | undefined;
  account: CryptoAccountDropdownItem | null;
  amount: string;
};

const createEscrowPartnerPlaceholder = (): EscrowPartnerFormInfo => ({
  localId: getId(),
  email: "",
  role: undefined,
  payment: undefined,
  account: null,
  amount: ""
});

type SetEmailByPartnerLocalIdPayload = {
  isMe?: boolean;
  partnerLocalId: string;
  email: string;
};

type SetFieldByPartnerLocalIdPayload = {
  isMe?: boolean;
  partnerLocalId: string;
  field: keyof EscrowPartnerFormInfo;
  value: any;
};

type SetFieldOfUserAsPartnerPayload = {
  field: keyof EscrowPartnerFormInfo;
  value: any;
};

type PrepareTxConstructorForEditingPayload = {
  tx: EscrowTransaction;
  successCallback?: () => void;
};

type EscrowTxConstructorState = {
  title: string;
  description: string;
  userAsPartner: EscrowPartnerFormInfo;
  partners: ObjectT<EscrowPartnerFormInfo>;
  preparingTxConstructorForEditing: boolean;
};

const initialEscrowTxConstructorState: EscrowTxConstructorState = {
  title: "",
  description: "",
  userAsPartner: createEscrowPartnerPlaceholder(),
  partners: {},
  preparingTxConstructorForEditing: false
};

const escrowTxConstructorSlice = createSlice({
  name: 'escrowTxConstructor',
  initialState: { ...initialEscrowTxConstructorState },
  reducers: {
    setTitle(state, action: PayloadAction<string>) {
      state.title = action.payload;
    },
    setDescription(state, action: PayloadAction<string>) {
      state.description = action.payload;
    },
    addPartner(state) {
      const newPartner = createEscrowPartnerPlaceholder();
      state.partners[newPartner.localId] = newPartner;
    },
    setEmailByPartnerLocalId(state, action: PayloadAction<SetEmailByPartnerLocalIdPayload>) {
      const { isMe, partnerLocalId, email } = action.payload;

      if (isMe && state.userAsPartner.localId === partnerLocalId)
        state.userAsPartner.email = email;
      else if (state.partners[partnerLocalId])
        state.partners[partnerLocalId].email = email;
    },
    setFieldByPartnerLocalId(state, action: PayloadAction<SetFieldByPartnerLocalIdPayload>) {
      const { isMe, partnerLocalId, field, value } = action.payload;
      
      if (isMe && state.userAsPartner.localId === partnerLocalId) {
        // @ts-ignore
        state.userAsPartner[field] = value;
      } else if (state.partners[partnerLocalId]) {
        // @ts-ignore
        state.partners[partnerLocalId][field] = value;
      }
    },
    deletePartnerByLocalId(state, action: PayloadAction<string>) {
      state.partners = _.omit(state.partners, action.payload);
    },
    setFieldOfUserAsPartner(state, action: PayloadAction<SetFieldOfUserAsPartnerPayload>) {
      const { field, value } = action.payload;
      // @ts-ignore
      state.userAsPartner[field] = value;
    },
    setUserAsPartner(state, action: PayloadAction<EscrowPartnerFormInfo>) {
      state.userAsPartner = action.payload;
    },
    setPartners(state, action: PayloadAction<ObjectT<EscrowPartnerFormInfo>>) {
      state.partners = action.payload;
    },
    setPreparingTxConstructorForEditing(state, action: PayloadAction<boolean>) {
      state.preparingTxConstructorForEditing = action.payload;
    },
    clearAllState() {
      return {
        ...initialEscrowTxConstructorState
      }
    }
  },
});

const escrowTxConstructorReducer = escrowTxConstructorSlice.reducer;
const EscrowTxConstructorActions = {
  ...escrowTxConstructorSlice.actions,
  prepareTxConstructorForEditing: createAction<PrepareTxConstructorForEditingPayload>('escrowTxConstructor/prepareTxConstructorForEditing')
};

export { escrowTxConstructorReducer, EscrowTxConstructorActions };