import styled from "styled-components";
import { templates } from "../../../theme/templates";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 365px;
  border-radius: 12px;
  padding: 14px;
  background-color: ${(p) => p.theme.colors.subBackground};
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;
export const Button = styled.div`
  ${templates.centerContent};
  width: 100%;
  border-radius: 12px;
  padding: 10px;
  cursor: pointer;
`;
