import React, { memo } from 'react';
import { useStyles } from './TimerStyles';
import { Box } from '@material-ui/core';
import { useTimer } from 'use-timer';
import { useDependentValue } from '../../helpers/customHooks';
import { CONFIG } from '../../config';
import { addLeadZeroesTimer } from '../../helpers/strings';
import ClockIcon from './assets/clock';
import moment from 'moment';
import cn from 'classnames';

export type TimerProps = {
  initialTime: number;
};

const Timer: React.FC<TimerProps> = ({
  initialTime
}) => {
  const s = useStyles();

  const { time } = useTimer({
    initialTime,
    timerType: 'DECREMENTAL',
    endTime: 0,
    autostart: true,
    onTimeOver: () => {},
  });

  const timeToShow = useDependentValue(() => {
    const duration = moment.duration(time * 1000, 'milliseconds');

    if (time <= CONFIG.DATE_TIME.SECONDS_IN_A_DAY * 4)
      return `${addLeadZeroesTimer(duration.hours())}:${addLeadZeroesTimer(duration.minutes())}:${addLeadZeroesTimer(duration.seconds())}`;

    return `${Math.floor(duration.asDays())}d ${duration.hours()}h`;
  }, [time]);

  return (
    <Box className={cn(s.container)}>
      <Box>
        <ClockIcon />
      </Box>
      <p className={cn(s.timerText)}>
        {timeToShow}
      </p>
    </Box>
  );
};

Timer.defaultProps={}

const MemorizedComponent = memo(Timer);
export { MemorizedComponent as Timer };