import { FC, useState } from "react";
import { MessageWrapper } from "./ChatStyles";
import { useThemeContext } from "../../../theme";
import { Text } from "../../../components/Text/Text";
import { ContentBox } from "../../../components/ContentBox/ContentBox";
import { CONFIG } from "../../../config";
import styled from "styled-components";
import ViewPicture from "../../../components/Modals/ViewPicture/ViewPicture";
import { FileContainer } from "../../../components/Modals/CreateTicket/CreateTicketStyles";

type Props = {
  message: string;
  direction: string;
  date: string;
  name: string;
  files: string[];
};

const MessageItem: FC<Props> = ({ message, direction, date, name, files }) => {
  const margin = direction === "CompanyToClient";
  const [showImage, setShowImage] = useState(false);
  const [currentImg, setCurrentImg] = useState("");
  const { theme } = useThemeContext();
  return (
    <>
      <MessageWrapper direction={margin}>
        <ContentBox
          backColor="none"
          width="100%"
          justyfiContent="space-between"
        >
          <Text
            color={theme.colors.yellowMain}
            size="16px"
            fontStyle={theme.fonts.f600}
          >
            {name}
          </Text>
          <Text
            color={theme.colors.white}
            size="14px"
            fontStyle={theme.fonts.f600}
          >
            {date}
          </Text>
        </ContentBox>
        <Text
          color={theme.colors.white}
          size="18px"
          fontStyle={theme.fonts.f600}
        >
          {message}
        </Text>

        {files ? (
          <ImagesWrapper>
            {files.map((el, index) => (
              <FileContainer
                key={index}
                style={{
                  minWidth: "80px",
                  maxWidth: "80px",
                  height: "80px",
                }}
              >
                <ImageItem
                  src={CONFIG.ZED_BASE_URL + el}
                  alt="asd"
                  onClick={() => {
                    setShowImage(true);
                    setCurrentImg(CONFIG.ZED_BASE_URL + el);
                  }}
                />
              </FileContainer>
            ))}
          </ImagesWrapper>
        ) : (
          <></>
        )}
      </MessageWrapper>
      {showImage && (
        <ViewPicture img={currentImg} setShowImage={setShowImage} />
      )}
    </>
  );
};
export default MessageItem;

const ImageItem = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
  cursor: pointer;
  box-sizing: border-box;
`;
const ImagesWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
`;
