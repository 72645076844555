import { Action } from '@reduxjs/toolkit';
import { ApiResponse } from 'apisauce';
import _ from 'lodash';
import { call, put, select, takeLatest, takeEvery, take, all, takeLeading, delay } from 'redux-saga/effects';
import { api, RequestErrorFromResponse } from './../../api';
import { NftActions } from './NftRedux';
import { classicApiResponseValidator, retryApiCall, sagaFactory, zedApiResponseDataTransformer, zedApiResponseValidator } from './../../helpers/sagaHelpers';
import { ChangePlanResponse, CreateNftCollectionResponse, CreateNftProfileResponse, CreateNftResponse, DeleteNftProfileResponse, EditNftCollectionResponse, EditNftProfileResponse, EditNftResponse, FinancialReportResponse, GetMyZedAuthTokenResponse, GetPlansResponse, ListCategoriesResponse, ListCollectionsResponse, ListContractsResponse, ListNftUsersResponse, ListNftsResponse } from './../../types/api';
import { getNftCategoriesFromLocalStorage, getNftContractsFromLocalStorage, getNftPlansFromLocalStorage, storeNftCategoriesToLocalStorage, storeNftContractsToLocalStorage, storeNftPlansToLocalStorage } from './../../helpers/localStorage';
import { showToast } from './../../helpers/alertService';
import i18next from 'i18next';
import { userIdSelector, userTokenSelector } from '../user/UserSelectors';

const getPlansRequest = sagaFactory<GetPlansResponse>({
  restActions: NftActions.getPlans,
  apiRequest: api.getPlansGet,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  retryOptions: {
    delayMs: 2e3
  },
  cachingOptions: {
    getFromLocalStorageFn: getNftPlansFromLocalStorage,
    storeToLocalStorageFn: storeNftPlansToLocalStorage,
    successFn: NftActions.getPlans.success,
    dataKey: "plans",
  }
});

const getContractCostRequest = sagaFactory({
  restActions: NftActions.getContractCost,
  apiRequest: api.getContractCostPost,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  retryOptions: {
    delayMs: 2e3
  },
});

const financialReportRequest = sagaFactory<FinancialReportResponse>({
  restActions: NftActions.financialReport,
  apiRequest: api.financialReportGet,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
});


function* createNftCollectionRequest(action: Action) {
  if (NftActions.createNftCollection.request.match(action)) {
    try {
      const token: string = yield select(userTokenSelector);

      let logoLink = "";
      let coverLink = "";
      let bannerLink = "";
      
      const uploadFileResponse: ApiResponse<any> = yield call(
        api.uploadFile,
        action.payload.fData,
        token
      );

      if (zedApiResponseValidator(uploadFileResponse)) {
        logoLink = uploadFileResponse.data.value[0].Value;
        coverLink = uploadFileResponse.data.value[1].Value;
        bannerLink = uploadFileResponse.data.value[2].Value;
      } else {
        throw new RequestErrorFromResponse(uploadFileResponse);
      }

      delete action.payload.fData;

      const createNftCollectionResponse: ApiResponse<CreateNftCollectionResponse> = yield call(
        api.createNftCollectionPost,
        {
          ...action.payload,
          logo: logoLink,
          cover: coverLink,
          banner: bannerLink
        }
      );
      if (zedApiResponseValidator(createNftCollectionResponse)) {
        const responseData = createNftCollectionResponse.data!;

        // Refetch user collections
        const userId: number = yield select(userIdSelector);
        yield put(NftActions.listCollections.request({
          customer_id: userId
        }));

        yield call(showToast, {
          title: i18next.t('success'),
          info: responseData.message,
          type: "success",
        });

        
        if(action.payload.successCallback){
          yield call(action.payload.successCallback)
        }
        yield put(NftActions.createNftCollection.success());
      } else {
        throw new RequestErrorFromResponse(createNftCollectionResponse);
      }
    } catch (error) {
      yield call(showToast, {
        title: i18next.t('oops') as string,
        // @ts-ignore
        info: error?.description ?? "Error",
        type: "error",
      });

      yield put(NftActions.createNftCollection.failure(error));
    }
  }
}

function* createNftProfileRequest(action: Action) {
  if (NftActions.createNftProfile.request.match(action)) {
    try {
      const getMyZedAuthTokenResponse: ApiResponse<GetMyZedAuthTokenResponse> = yield call(
        api.getMyZedAuthTokenGet
      );

      if (!classicApiResponseValidator(getMyZedAuthTokenResponse)) {
        throw new RequestErrorFromResponse(getMyZedAuthTokenResponse);
      }
      
      const createNftProfileResponse: ApiResponse<CreateNftProfileResponse> = yield call(
        api.createNftProfilePost,
        {
          ...action.payload, 
          token: getMyZedAuthTokenResponse.data!.access_token,
          metamask: {
            "blockchain": 0,
            "address": null
          },
          walletconnect: {
            "blockchain": 0,
            "address": null
          },
          email: "",
          verification_code: "",
          password: ""
        }
      );
      if (zedApiResponseValidator(createNftProfileResponse)) {
        // Get created profile
        const userId: number = yield select(userIdSelector);
        yield put(NftActions.listNftUsers.request({
          customer_id: userId,
          fetchAdditionalInfoOnSuccess: true
        }));
        yield delay(100);
        
        if (action.payload.successCallback) {
          yield call(action.payload.successCallback);
        }

        yield put(NftActions.createNftProfile.success());
      } else {
        if (action.payload.failureCallback) {
          yield call(action.payload.failureCallback);
        }

        yield call(showToast, {
          title: i18next.t('oops') as string,
          info: createNftProfileResponse?.data?.message ?? "Error",
          type: "error",
        });

        throw new RequestErrorFromResponse(createNftProfileResponse);
      }
    } catch (error) {
      yield put(NftActions.createNftProfile.failure(error));
    }
  }
}

function* deleteNftProfileRequest(action: Action) {
  if (NftActions.deleteNftProfile.request.match(action)) {
    try {
      const deleteNftProfileResponse: ApiResponse<DeleteNftProfileResponse> = yield call(
        api.deleteNftProfilePost,
        action.payload
      );
      if (zedApiResponseValidator(deleteNftProfileResponse)) {
        const responseData = deleteNftProfileResponse.data!;
        yield call(showToast, {
          title: i18next.t('success'),
          info: responseData.message,
          type: "success",
        });

        yield put(NftActions.deleteNftProfile.success());
      } else {
        yield call(showToast, {
          title: i18next.t('oops') as string,
          info: deleteNftProfileResponse?.data?.message ?? "Error",
          type: "error",
        });
        
        throw new RequestErrorFromResponse(deleteNftProfileResponse);
      }
    } catch (error) {
      yield put(NftActions.deleteNftProfile.failure(error));
    }
  }
}

function* editNftProfileRequest(action: Action) {
  if (NftActions.editNftProfile.request.match(action)) {
    try {
      const token: string = yield select(userTokenSelector);

      let logoLink = "";
      let bannerLink = "";
      
      if (action.payload.logo || action.payload.banner) {
        const uploadFileResponse: ApiResponse<any> = yield call(
          api.uploadFile,
          action.payload.fData,
          token
        );

        if (zedApiResponseValidator(uploadFileResponse)) {
          if (action.payload.logo) {
            logoLink = uploadFileResponse.data.value[0].Value;
            if (action.payload.banner)
              bannerLink = uploadFileResponse.data.value[1].Value;
          } else {
            bannerLink = uploadFileResponse.data.value[0].Value;
          }
        } else {
          throw new RequestErrorFromResponse(uploadFileResponse);
        }

        delete action.payload.fData;
      }

      const editNftProfileResponse: ApiResponse<EditNftProfileResponse> = yield call(
        api.editNftProfilePost,
        {
          id: action.payload.id,
          username: action.payload.username,
          Bio: action.payload.Bio,
          logo: action.payload.logo ? logoLink : "",
          banner: action.payload.banner ? bannerLink : "",
          links: action.payload.links,
          status: action.payload.status
        }
      );
      if (zedApiResponseValidator(editNftProfileResponse)) {
        const responseData = editNftProfileResponse.data!;

        const userId: number = yield select(userIdSelector);
        // refetch user nft profile
        yield put(NftActions.listNftUsers.request({
          customer_id: userId
        }));

        yield call(showToast, {
          title: i18next.t('success'),
          info: responseData.message,
          type: "success",
        });

        yield put(NftActions.editNftProfile.success());
      } else {
        yield call(showToast, {
          title: i18next.t('oops') as string,
          info: editNftProfileResponse?.data?.message ?? "Error",
          type: "error",
        });

        throw new RequestErrorFromResponse(editNftProfileResponse);
      }
    } catch (error) {
      yield put(NftActions.editNftProfile.failure(error));
    }
  }
}

function* listNftUsersRequest(action: Action) {
  if (NftActions.listNftUsers.request.match(action)) {
    try {
      if (action.payload.customer_id) {
        yield put(NftActions.setFetchingNftProfile(true));
      }

      const listNftUsersResponse: ApiResponse<ListNftUsersResponse> = yield call(
        api.listNftUsersGet,
        action.payload
      );
      if (zedApiResponseValidator(listNftUsersResponse)) {
        const responseData = listNftUsersResponse.data!;
        
        const userId: number = yield select(userIdSelector);
        if (action.payload.customer_id && action.payload.customer_id === userId) {
          const myNftProfile = responseData.value.find((p) => p.customer.id === userId);

          if (myNftProfile) {
            yield put(NftActions.setNftProfile(myNftProfile));
          }

          if (action.payload.fetchAdditionalInfoOnSuccess) {
            yield put(NftActions.listCollections.request({
              customer_id: userId
            }));
            yield put(NftActions.listContracts.request({}));
            yield put(NftActions.getPlans.request());
          }
        }

        if (action.payload.customer_id) {
          yield put(NftActions.setFetchingNftProfile(false));
        }
        yield put(NftActions.listNftUsers.success(responseData.value));
      } else {
        throw new RequestErrorFromResponse(listNftUsersResponse);
      }
    } catch (error) {
      if (action.payload.customer_id) {
        yield put(NftActions.setFetchingNftProfile(false));
      }
      yield put(NftActions.listNftUsers.failure(error));
    }
  }
}
function* createNftRequest(action: Action) {
  if (NftActions.createNft.request.match(action)) {
    try {
      const token: string = yield select(userTokenSelector);

      let nftFileLink = "";
      let posterLink = "";

      const uploadFileResponse: ApiResponse<any> = yield call(
        api.uploadFile,
        action.payload.fData,
        token
      );

      if (zedApiResponseValidator(uploadFileResponse)) {
        nftFileLink = uploadFileResponse.data.value[0].Value;
        if (uploadFileResponse.data.value[1])
          posterLink = uploadFileResponse.data.value[1].Value;
      } else {
        throw new RequestErrorFromResponse(uploadFileResponse);
      }

      delete action.payload.fData;

      const createNftResponse: ApiResponse<CreateNftResponse> = yield call(
        api.createNftPost,
        {
          ...action.payload,
          nft_file: nftFileLink,
          nft_poster: posterLink
        }
      );
      if (zedApiResponseValidator(createNftResponse)) {
        const responseData = createNftResponse.data!;

        // User created new contract
        if (action.payload.contract_id === 0) {
          yield put(NftActions.listContracts.request({
            ignoreCache: true
          }));
        }

        yield call(showToast, {
          title: i18next.t('success'),
          info: responseData.message,
          type: "success",
        });

        // yield call(pop);

        yield put(NftActions.createNft.success());
      } else {
        throw new RequestErrorFromResponse(createNftResponse);
      }
    } catch (error) {
      yield call(showToast, {
        title: i18next.t('oops') as string,
        // @ts-ignore
        info: error?.description ?? "Error",
        type: "error",
      });
      
      yield put(NftActions.createNft.failure(error));
    }
  }
}

const listCategoriesRequest = sagaFactory<ListCategoriesResponse>({
  restActions: NftActions.listCategories,
  apiRequest: api.listCategoriesGet,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  cachingOptions: {
    getFromLocalStorageFn: getNftCategoriesFromLocalStorage,
    storeToLocalStorageFn: storeNftCategoriesToLocalStorage,
    successFn: NftActions.listCategories.success,
    dataKey: "categories",
    cacheLifetime: 3
  },
  retryOptions: {
    delayMs: 200
  }
});

function* listCollectionsRequest(action: Action) {
  if (NftActions.listCollections.request.match(action)) {
    try {
      const userId: number = yield select(userIdSelector);
      if (action.payload.customer_id && action.payload.customer_id === userId) {
        yield put(NftActions.setFetchingMyNftCollections(true));
      }

      const listCollectionsResponse: ApiResponse<ListCollectionsResponse> = yield call(retryApiCall, {
        apiRequest: api.listCollectionsGet,
        args: [action.payload],
        delayMs: 1500,
      });
      if (zedApiResponseValidator(listCollectionsResponse)) {
        const responseData = listCollectionsResponse.data!;

        if (action.payload.customer_id !== undefined) {
          yield put(NftActions.updateCollectionsByUserTable({
            userId: action.payload.customer_id,
            collections: responseData.value
          }));

          if (action.payload.customer_id === userId) {
            yield put(NftActions.setMyNftCollections(responseData.value));
            yield put(NftActions.setFetchingMyNftCollections(false));
          }
        }

        yield put(NftActions.listCollections.success(responseData.value));
      } else {
        throw new RequestErrorFromResponse(listCollectionsResponse);
      }
    } catch (error) {
      if (action.payload.customer_id) {
        yield put(NftActions.setFetchingMyNftCollections(false));
      }
      yield put(NftActions.listCollections.failure(error));
    }
  }
}

function* changePlanRequest(action: Action) {
  if (NftActions.changePlan.request.match(action)) {
    try {
      const changePlanResponse: ApiResponse<ChangePlanResponse> = yield call(
        api.changePlanPost,
        action.payload
      );
      if (zedApiResponseValidator(changePlanResponse)) {
        const responseData = changePlanResponse.data!;
        yield put(NftActions.changePlan.success({
          
        }));
      } else {
        throw new RequestErrorFromResponse(changePlanResponse);
      }
    } catch (error) {
      yield put(NftActions.changePlan.failure(error));
    }
  }
}

const changeLikeStatusRequest = sagaFactory({
  restActions: NftActions.changeLikeStatus,
  apiRequest: api.changeLikeStatusPost,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  retryOptions: {
    delayMs: 500
  }
});


function* listNftsRequest(action: Action) {
  if (NftActions.listNfts.request.match(action)) {
    try {
      const listNftsResponse: ApiResponse<ListNftsResponse> = yield call(
        api.listNftsGet,
        action.payload
      );
      if (zedApiResponseValidator(listNftsResponse)) {
        const responseData = listNftsResponse.data!;

        if (action.payload.collection_id !== undefined) {
          yield put(NftActions.updateNftsByCollectionTable({
            collectionId: action.payload.collection_id,
            nfts: responseData.value
          }));
        }

        yield put(NftActions.listNfts.success(responseData.value));
      } else {
        throw new RequestErrorFromResponse(listNftsResponse);
      }
    } catch (error) {
      yield put(NftActions.listNfts.failure(error));
    }
  }
}

const changeFollowStatusRequest = sagaFactory({
  restActions: NftActions.changeFollowStatus,
  apiRequest: api.changeFollowStatusPost,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  retryOptions: {
    delayMs: 500
  },
  toastOptions: {
    showSuccess: true,
    showFailure: true
  }
});

const listContractsRequest = sagaFactory<ListContractsResponse>({
  restActions: NftActions.listContracts,
  apiRequest: api.listContractsGet,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  cachingOptions: {
    getFromLocalStorageFn: getNftContractsFromLocalStorage,
    storeToLocalStorageFn: storeNftContractsToLocalStorage,
    successFn: NftActions.listContracts.success,
    dataKey: "contracts",
    cacheLifetime: 6
  },
  retryOptions: {
    delayMs: 750,
    maxTries: 3
  }
});

function* editNftRequest(action: Action) {
  if (NftActions.editNft.request.match(action)) {
    try {
      let posterLink = action.payload.posterLink ?? "";

      if (!posterLink && action.payload.fData) {
        const token: string = yield select(userTokenSelector);

        const uploadFileResponse: ApiResponse<any> = yield call(
          api.uploadFile,
          action.payload.fData,
          token
        );

        if (zedApiResponseValidator(uploadFileResponse)) {
          posterLink = uploadFileResponse.data.value[0].Value;
        } else {
          throw new RequestErrorFromResponse(uploadFileResponse);
        }

        delete action.payload.fData;
      }

      const editNftResponse: ApiResponse<EditNftResponse> = yield call(
        api.editNftPost,
        {
          ...action.payload,
          nft_poster: posterLink
        }
      );
      if (zedApiResponseValidator(editNftResponse)) {
        const responseData = editNftResponse.data!;

        // Refetch user NFTs
        const userId: number = yield select(userIdSelector);
        yield put(NftActions.listNfts.request({
          customer_id: userId
        }));

        yield call(showToast, {
          title: i18next.t('success'),
          info: responseData.message,
          type: "success",
        });

        // yield call(showScreenAsDefault, 'TabNavigator');

        yield put(NftActions.editNft.success());
      } else {
        throw new RequestErrorFromResponse(editNftResponse);
      }
    } catch (error) {
      yield call(showToast, {
        title: i18next.t('oops') as string,
        // @ts-ignore
        info: error?.description ?? error?.message ?? "Error",
        type: "error",
      });
      
      yield put(NftActions.editNft.failure(error));
    }
  }
}

function* editNftCollectionRequest(action: Action) {
  if (NftActions.editNftCollection.request.match(action)) {
    try {
      let logoLink = action.payload.logoLink ?? "";
      let coverLink = action.payload.coverLink ?? "";
      let bannerLink = action.payload.bannerLink ?? "";
      
      if ((!logoLink || !coverLink || !bannerLink) && action.payload.fData) {
        const token: string = yield select(userTokenSelector);

        const uploadFileResponse: ApiResponse<any> = yield call(
          api.uploadFile,
          action.payload.fData,
          token
        );

        if (zedApiResponseValidator(uploadFileResponse)) {
          let i = 0;
          if (!logoLink)
            logoLink = uploadFileResponse.data.value[i++].Value;
          if (!coverLink)
            coverLink = uploadFileResponse.data.value[i++].Value;
          if (!bannerLink)
            bannerLink = uploadFileResponse.data.value[i++].Value;
        } else {
          throw new RequestErrorFromResponse(uploadFileResponse);
        }

        delete action.payload.fData;
      }

      const editNftCollectionResponse: ApiResponse<EditNftCollectionResponse> = yield call(
        api.editNftCollectionPost,
        {
          ...action.payload,
          logo: logoLink,
          cover: coverLink,
          banner: bannerLink
        }
      );
      if (zedApiResponseValidator(editNftCollectionResponse)) {
        const responseData = editNftCollectionResponse.data!;

        // Refetch user collections
        const userId: number = yield select(userIdSelector);
        yield put(NftActions.listCollections.request({
          customer_id: userId
        }));

        yield call(showToast, {
          title: i18next.t('success'),
          info: responseData.message,
          type: "success",
        });

        // yield call(showScreenAsDefault, 'TabNavigator');

        yield put(NftActions.editNftCollection.success());
      } else {
        throw new RequestErrorFromResponse(editNftCollectionResponse);
      }
    } catch (error) {
      yield call(showToast, {
        title: i18next.t('oops') as string,
        // @ts-ignore
        info: error?.description ?? error?.message ?? "Error",
        type: "error",
      });
      
      yield put(NftActions.editNftCollection.failure(error));
    }
  }
}


export function* NftSaga() {
  yield* [
    takeLeading(NftActions.getPlans.request.type, getPlansRequest),
    takeLeading(NftActions.getContractCost.request.type, getContractCostRequest),
    takeLatest(NftActions.financialReport.request.type, financialReportRequest),
    takeLatest(NftActions.createNftProfile.request.type, createNftProfileRequest),
    takeLatest(NftActions.deleteNftProfile.request.type, deleteNftProfileRequest),
    takeLatest(NftActions.editNftProfile.request.type, editNftProfileRequest),
    takeLeading(NftActions.listNftUsers.request.type, listNftUsersRequest),
    takeLeading(NftActions.listCategories.request.type, listCategoriesRequest),
    takeLatest(NftActions.listCollections.request.type, listCollectionsRequest),
    takeLatest(NftActions.createNftCollection.request.type, createNftCollectionRequest),
    takeLatest(NftActions.changePlan.request.type, changePlanRequest),
    takeLatest(NftActions.changeLikeStatus.request.type, changeLikeStatusRequest),
    takeLatest(NftActions.editNftCollection.request.type, editNftCollectionRequest),
    takeLatest(NftActions.editNft.request.type, editNftRequest),
    takeLatest(NftActions.listNfts.request.type, listNftsRequest),
    takeLatest(NftActions.changeFollowStatus.request.type, changeFollowStatusRequest),
    takeLeading(NftActions.listContracts.request.type, listContractsRequest),
    takeLatest(NftActions.createNft.request.type, createNftRequest),
  ];
}