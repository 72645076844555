import { Instruction, InstructionGroup } from "@appklaar/codebud";
import { EscrowActions } from "./../logic/escrow/EscrowRedux";

export const getEscrowInstructions = (store: any): (Instruction | InstructionGroup)[] => ([
  {
    groupId: "Escrow",
    groupDescription: "Escrow entity instructions",
    groupColor: "#000000",
    groupInstructions: [
      {
        id: "createEscrowTx",
        description: "Создать Escrow-транзакцию",
        // parametersDescription: {

        // },
        handler: () => {
          store.dispatch(EscrowActions.new.request({
            title: "My 5th Escrow Transaction",
            description: "Escrow Transaction Description",
            partners: [
              {
                customer_id: 26816,
                // customer_email: "customer@gmail.com",
                // customer_name: "Aa",
                account_id: 23749,
                role: "Buyer",
                will_pay: 500,
                will_receive: 0,
                // commission: 0.08
              } as any,
              {
                customer_id: 26819,
                // customer_email: "customer@gmail.com",
                // customer_name: "Aa",
                account_id: 23766,
                role: "Seller",
                will_pay: 0,
                will_receive: 500,
                // commission: 0
              } as any,
            ]
          }));
        }
      },
      {
        id: "Get escrow transactions list",
        description: "Получить список Escrow-транзакций",
        handler: () => {
          store.dispatch(EscrowActions.list.request({
            customer_id: 26816
          }));
        }
      },
      {
        id: "deleteEscrowTx",
        description: "Удаление Escrow-транзакции. Только admin может удалить escrow транзакцию любого статуса. Юзер же ограничен в правах.",
        parametersDescription: {
          id: "number"
        },
        handler: (data) => {
          store.dispatch(EscrowActions.delete.request(data));
        }
      },
      {
        id: "changeEscrowTx",
        description: "Обновление статуса Escrow-транзакции",
        parametersDescription: {
          id: "number",
          status: "string"
        },
        handler: (data) => {
          store.dispatch(EscrowActions.change.request({
            id: data.id,
            type: "Status",
            new_status: {
              status: data.status
            },
            // "new_partner_status": {
            //   "customer_id": 144,
            //   "status": "Agreed"
            // },
            // "new_file": {
            //   "title": "Contract",
            //   "file": "/files/upload/contract.jpg"
            // },
            // "new_transaction": {
            //   "account_id": 87,
            //   "debit": 0,
            //   "credit": 100.0
            // }
            // new_partner_status: null as any,
            // new_file: null as any,
            // new_transaction: null as any
          }))
        }
      },
      {
        id: "editEscrowTx",
        description: "Редактирование Escrow-транзакции",
        parametersDescription: {
          id: "number"
        },
        handler: (data) => {
          store.dispatch(EscrowActions.edit.request({
            id: data.id,
            title: "My 5th Escrow Transaction",
            description: "Escrow Transaction Description",
            // @ts-ignore
            status: "Cancelled",
            new_status: {
              status: "Cancelled",
            },
            partners: [
              {
                customer_id: 26816,
                // customer_email: "customer@gmail.com",
                // customer_name: "Aa",
                account_id: 23749,
                role: "Buyer",
                will_pay: 500,
                will_receive: 0,
                // commission: 0.08
              } as any,
              {
                customer_id: 26819,
                // customer_email: "customer@gmail.com",
                // customer_name: "Aa",
                account_id: 23766,
                role: "Seller",
                will_pay: 0,
                will_receive: 500,
                // commission: 0
              } as any,
            ]
          }));
        }
      }
    ]
  },
]);