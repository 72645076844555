import React, { useState, useCallback } from "react"

export const useHideErrors = () => {
  const [hideErrors, setHideErrors] = useState(true);

  const onInputErrorAnimationEnd = useCallback(() => {
    setHideErrors(true);
  }, []);

  return {
    hideErrors,
    setHideErrors,
    onInputErrorAnimationEnd
  };
}