import React, { memo, useState, useEffect } from "react";
import { useStyles } from "./TextInputStyles";
import { Box } from "@material-ui/core";
import CloseIcon from "./assets/close";
import classNames from "classnames";
import "animate.css";
import { theme } from "../../theme/default/theme";
import { Eye } from "../Input/InputStyles";

export type TextInputProps = {
  value: string;
  validationOk?: undefined | boolean;
  onErrorAnimationEnd?: () => void;
  placeholder?: string;
  onChangeText: (t: string) => void;
  bgColor?: string;
  color?: string;
  paddingLeft?: number;
  paddingRight?: number;
  borderFocused?: string;
  borderUnfocused?: string;
  borderError?: string;
  textAlign?: string;
  fontSize?: number;
  height?: number;
  maxLength?: number;
  editable?: boolean;
  label?: string;
  labelColor?: string;
  secure?: boolean;
  inputType?: string;
  marginTop?: number;
  onClear?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

const TextInput: React.FC<TextInputProps> = ({
  value,
  validationOk,
  onErrorAnimationEnd,
  placeholder,
  onChangeText,
  bgColor,
  color,
  paddingLeft,
  paddingRight,
  borderFocused,
  borderError,
  borderUnfocused,
  textAlign,
  fontSize,
  height,
  maxLength,
  editable,
  label,
  labelColor,
  secure,
  marginTop,
  inputType,
  onClear,
  onBlur,
  onFocus,
}) => {
  const s = useStyles();

  const [focused, setFocused] = useState(false);
  const [secureEntry, setSecureEntry] = useState(true);

  useEffect(() => {
    if (validationOk === false && onErrorAnimationEnd)
      setTimeout(() => {
        onErrorAnimationEnd();
      }, 1000);
  }, [validationOk]);

  return (
    <Box className={classNames(s.globalContainer)} style={{ marginTop }}>
      {label && (
        <p
          style={{
            margin: 0,
            color: labelColor,
            fontWeight: "bolder",
            marginBottom: theme.metrics.x2,
          }}
        >
          {label}
        </p>
      )}
      {secure && (
        <Box
          className={classNames(s.rightIconContainer)}
          onClick={() => setSecureEntry((v) => !v)}
        >
          <img src="/images/eye.png" />
        </Box>
      )}
      <Box
        className={classNames(s.container, "animate__animated", {
          animate__pulse: validationOk === false,
        })}
        style={{
          height,
          backgroundColor: bgColor,
          paddingLeft,
          paddingRight: secure === false ? paddingRight : theme.metrics.x7 * 2,
          border:
            validationOk === false
              ? borderError
              : focused
              ? borderFocused
              : borderUnfocused,
        }}
      >
        <input
          onClick={(e) => e.stopPropagation()}
          spellCheck={false}
          // @ts-ignore
          style={{ color, textAlign, fontSize }}
          className={classNames(s.input)}
          type={inputType ?? (secure && secureEntry ? "password" : "text")}
          value={value}
          placeholder={placeholder}
          onChange={(event) => onChangeText(event.target.value)}
          onFocus={() => {
            setFocused(true);
            onFocus && onFocus();
          }}
          onBlur={() => {
            setFocused(false);
            onBlur && onBlur();
          }}
          maxLength={maxLength}
          disabled={!editable}
        />
        {onClear && (
          <Box className={classNames(s.clearBtn)} onClick={onClear}>
            <CloseIcon fill={color} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

TextInput.defaultProps = {
  validationOk: undefined,
  bgColor: theme.colors.subBackground,
  color: "white",
  paddingLeft: 16,
  paddingRight: 16,
  placeholder: "",
  borderFocused: "1px solid #323232",
  borderUnfocused: "1px solid #323232",
  borderError: "solid 1px #f00",
  textAlign: "left",
  fontSize: 16,
  height: 44,
  maxLength: undefined,
  editable: true,
  labelColor: "white",
  secure: false,
  marginTop: theme.metrics.x3,
};

const MemorizedComponent = memo(TextInput);
export { MemorizedComponent as TextInput };
