import React, { memo, useState, forwardRef, useImperativeHandle } from 'react';
import { useStyles } from './ShowDetailsStyles';
import { Box } from '@material-ui/core';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { theme } from '../../theme/default/theme';

export type ShowDetailsProps = {
  ref?: any;
  children: React.ReactNode;
  marginTop?: number;
  marginBottom?: number;
};

export type ShowDetailsMethods = {
  open: () => void;
  close: () => void;
};

const ShowDetails: React.FC<ShowDetailsProps> = forwardRef(({
  children,
  marginTop,
  marginBottom
}, ref) => {
  const s = useStyles();
  // @ts-ignore
  const { t } = useTranslation();

  const [showChildren, setShowChildren] = useState(false);

  useImperativeHandle(ref, (): ShowDetailsMethods => ({
    open: () => setShowChildren(true),
    close: () => setShowChildren(false)
  }), []);

  return (
    <Box className={cn(s.container)} style={{marginTop, marginBottom}}>
      <p 
        className={cn(s.textBtn)} 
        onClick={() => setShowChildren((v) => !v)}
      >
        {showChildren ? t("hideDetails") : t("showDetails")}
      </p>

      {showChildren &&
        <Box className={cn(s.childrenCont)}>
          {children}
        </Box>
      }
    </Box>
  );
});

ShowDetails.defaultProps={
  marginTop: theme.metrics.x4,
  marginBottom: theme.metrics.x4
}

const MemorizedComponent = memo(ShowDetails);
export { MemorizedComponent as ShowDetails };