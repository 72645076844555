import { DefaultLocalStorageType } from "../localStorage";
import { call, put } from 'redux-saga/effects';
import { CONFIG } from "../../config";
import moment from 'moment';

export type CacheHelperOptions = {
  getFromLocalStorageFn: () => DefaultLocalStorageType;
  successFn: (payload: any) => any;
}

// redux-saga that returns true if cache contains fresh data and successFn is put
export function* cacheHelper(options: CacheHelperOptions, cacheLifetime: number = CONFIG.API_DATA_CACHE_LIFETIME_HRS) {
  try {
    const storedData: DefaultLocalStorageType = yield call(options.getFromLocalStorageFn);

    if (storedData) {
      const parsedData = JSON.parse(storedData);

      if (moment().diff(moment(parsedData.timestamp), 'hours') < cacheLifetime) {
        yield put(options.successFn(parsedData));
        return true;
      }
    }

    return false;
  } catch (error) {
    return false;
  }
}