import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    height: theme.metrics.x10,
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderRadius: theme.metrics.x3,
    marginBottom: theme.metrics.x4,
  },
  subtitle: {
    fontFamily: theme.fonts.f400.fontFamily,
    letterSpacing: -0.3,
    fontSize: theme.metrics.x3,
    color: theme.colors.greySubText,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    marginTop: theme.metrics.x4,
    alignSelf: 'flex-start',
  },
  text: {
    fontFamily: theme.fonts.f600.fontFamily,
    width: '85%',
    letterSpacing: -0.3,
    fontSize: theme.metrics.x4,
    color: theme.colors.white,
    margin: 0
  },
  copyButton: {
    display: 'flex',
    width: 28,
    height: 28,
    flexDirection: 'column',
    padding: theme.metrics.x,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.metrics.x2,
    borderWidth: 1,
    borderColor: theme.colors.yellowMain,
    cursor: 'pointer'
  },
});