import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "flex-end"
  },
  textBtn: {
    margin: 0,
    fontFamily: theme.fonts.f600.fontFamily,
    fontSize: 20,
    color: theme.colors.greySubText,
    cursor: "pointer",
    userSelect: "none"
  },
  childrenCont: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingTop: theme.metrics.x2
  }
});