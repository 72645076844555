import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.metrics.x4,
    gap: theme.metrics.x4,
    border: `1px solid ${theme.colors.borderGrey}`,
    padding: theme.metrics.x4,
    width: 400
  },
  rowBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  rowCentered: {
    display: 'flex',
    alignItems: 'center',
  },
  nameText: {
    fontFamily: theme.fonts.f500.fontFamily,
    fontSize: 18,
    color: theme.colors.text,
  },
  badgeIconCont: {
    display: 'flex',
    marginLeft: theme.metrics.x3
  },
  roleText: {
    fontFamily: theme.fonts.f500.fontFamily,
    fontSize: 16,
    color: theme.colors.textDescription,
  },
  secondaryText: {
    fontFamily: theme.fonts.f400.fontFamily,
    fontSize: 16,
    color: theme.colors.textDescription,
  },
  decorLine: {
    display: 'flex',
    height: 1,
    width: '100%',
    backgroundColor: theme.colors.borderGrey,
  }
});