import styled, { css } from "styled-components";

export const Wrapper = styled.div`
padding: 24px;
@media(max-width: 700px){
    padding: 24px 16px;
}
`
export const TopContainer = styled.div`
display: flex;
gap: 24px;
flex-direction: column-reverse;
@media(max-width: 700px){
    gap: 16px;
}

`;
export const TitleContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 40px;
margin-bottom: 32px;
@media(max-width: 700px){
    margin-bottom: 24px;
}
`;
export const BlocksContainer = styled.div`
display: flex;
gap: 24px;
width: 100%;
@media(max-width: 700px){
    gap: 16px;
}
@media(max-width: 500px){
    flex-direction: column;
}
`;