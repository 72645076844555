import { useSelector } from "react-redux"
import { useDependentState, useDependentValue } from "../helpers/customHooks";
import { nftSelector } from "../logic/nft/NftSelectors";

export const useSmartContractsDropdownData = () => {
  const {
    listContracts: {
      data: allContracts
    }
  } = useSelector(nftSelector);

  const contractsDropdownData = useDependentValue(() => {
    if (!allContracts)
      return [];

    return allContracts.contracts.map((contract) => ({
      title: contract.name, 
      value: contract.id,
      id: contract.id,
    }));
  }, [allContracts]);

  const [contract, setContract] = useDependentState(() => {
    return contractsDropdownData[0] ?? {title: "ZEDPAY", value: 1};
  }, [contractsDropdownData]);

  return {
    contractsDropdownData,
    contract,
    setContract
  };
}