import React, { memo } from 'react';
import { useStyles } from './SupportButtonStyles';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

export type SupportButtonProps = {};

const SupportButton: React.FC<SupportButtonProps> = ({}) => {
  const s = useStyles();
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <Box 
      onClick={() => {
        // @ts-ignore
        window.Intercom('show')
      }}
      className={cn(s.container)}
    >
      <p className={cn(s.whiteText)}>
        {t("buttons.support")}
      </p>
    </Box>
  );
};

SupportButton.defaultProps={}

const MemorizedComponent = memo(SupportButton);
export { MemorizedComponent as SupportButton };