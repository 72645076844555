import {
  call,
  put,
  race,
  select,
  take,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import { SocialActions } from "./SocialRedux";
import { ApiResponse } from "apisauce";
import { Action } from "@reduxjs/toolkit";
import {
  RequestError,
  RequestErrorFromResponse,
  api
} from "../../api";
import _ from "lodash";
import {
  AdResponse,
  TicketOpenResponse,
  TicketReplyResponse,
  TicketsListResponse,
  TicketsTypesResponse,
} from "../../types/api";
import { GeneralActions, UploadResponseItem } from "../general/GeneralRedux";
import { generalSelector } from "../general/GeneralSelectors";
import { showToast } from "../../helpers/alertService";
import i18next from "i18next";
import { sagaFactory, zedApiResponseValidator } from "../../helpers/sagaHelpers";

const getNewsRequest = sagaFactory({
  restActions: SocialActions.news,
  apiRequest: api.newsGet,
  retryOptions: {
    delayMs: 2e3
  }
});

const getBlogRequest = sagaFactory({
  restActions: SocialActions.blog,
  apiRequest: api.blogGet,
  apiResponseValidator: zedApiResponseValidator,
  retryOptions: {
    delayMs: 2e3
  }
});

const getAdRequest = sagaFactory<AdResponse>({
  restActions: SocialActions.ad,
  apiRequest: api.adGet,
  retryOptions: {
    delayMs: 2e3
  }
});

function* ticketsListRequest(action: Action) {
  if (SocialActions.ticketsList.request.match(action)) {
    try {
      const ticketsListResponse: ApiResponse<TicketsListResponse> = yield call(
        api.ticketsList,
        action.payload
      );
      if (ticketsListResponse.ok && ticketsListResponse.data) {
        const responseData = ticketsListResponse.data;
        yield put(SocialActions.ticketsList.success(responseData.value));
      } else {
        throw new RequestErrorFromResponse(ticketsListResponse);
      }
    } catch (error) {
      yield put(SocialActions.ticketsList.failure(error));
    }
  }
}

function* ticketsTypesRequest(action: Action) {
  if (SocialActions.ticketsTypes.request.match(action)) {
    try {
      const ticketsTypesResponse: ApiResponse<TicketsTypesResponse> =
        yield call(api.ticketsTypes);
      if (ticketsTypesResponse.ok && ticketsTypesResponse.data) {
        const responseData = ticketsTypesResponse.data;
        yield put(
          SocialActions.ticketsTypes.success(responseData.value)
        );
      } else {
        throw new RequestErrorFromResponse(ticketsTypesResponse);
      }
    } catch (error) {
      yield put(SocialActions.ticketsTypes.failure(error));
    }
  }
}

function* ticketOpenRequest(action: Action) {
  if (SocialActions.ticketOpen.request.match(action)) {
    try {
      var filesArray: string[] = [];
      if (action.payload.messages[0].files instanceof FormData) {
        yield put(
          GeneralActions.uploadFile.request(action.payload.messages[0].files)
        );
        const { success } = yield race({
          success: take(GeneralActions.uploadFile.success),
          failure: take(GeneralActions.uploadFile.failure),
        });
        if (success) {
          const {
            uploadFile: { data: uploadData },
          } = yield select(generalSelector);
          filesArray = uploadData.value.map((item: UploadResponseItem) => {
            return item.Value;
          });
        } else throw new Error("File was not uploaded");
      }

      var formatedMessages = [];
      if (filesArray.length > 0) {
        formatedMessages = [
          { message: action.payload.messages[0].message, files: filesArray },
        ];
      } else {
        formatedMessages = [{ message: action.payload.messages[0].message }];
      }

      const ticketOpenResponse: ApiResponse<TicketOpenResponse> = yield call(
        api.ticketOpen,
        { ...action.payload, messages: formatedMessages }
      );
      if (ticketOpenResponse.ok && ticketOpenResponse.data?.success) {
        const responseData = ticketOpenResponse.data;
        yield put(SocialActions.ticketOpen.success(responseData));
      } else {
        yield call(showToast, {
          title: i18next.t("oops") as string,
          info:
            ticketOpenResponse.data?.message ??
            (i18next.t("serverError") as string),
          type: "error",
        });
        throw new RequestErrorFromResponse(ticketOpenResponse);
      }
    } catch (error) {
      yield put(SocialActions.ticketOpen.failure(error));
    }
  }
}

function* ticketReplyRequest(action: Action) {
  if (SocialActions.ticketReply.request.match(action)) {
    try {
      var filesArray: string[] = [];
      if (action.payload.messages[0].files instanceof FormData) {
        yield put(
          GeneralActions.uploadFile.request(action.payload.messages[0].files)
        );
        const { success } = yield race({
          success: take(GeneralActions.uploadFile.success),
          failure: take(GeneralActions.uploadFile.failure),
        });
        if (success) {
          const {
            uploadFile: { data: uploadData },
          } = yield select(generalSelector);
          filesArray = uploadData.value.map((item: UploadResponseItem) => {
            return item.Value;
          });
        } else throw new Error("File was not uploaded");
      }

      var formatedMessages = [];
      if (filesArray.length > 0) {
        formatedMessages = [
          { message: action.payload.messages[0].message, files: filesArray },
        ];
      } else {
        formatedMessages = [{ message: action.payload.messages[0].message }];
      }

      const ticketReplyResponse: ApiResponse<TicketReplyResponse> = yield call(
        api.ticketReply,
        { id: action.payload.id, messages: formatedMessages as any }
      );
      if (ticketReplyResponse.ok && ticketReplyResponse.data) {
        const responseData = ticketReplyResponse.data;
        yield put(SocialActions.ticketReply.success(responseData));
      } else {
        throw new RequestErrorFromResponse(ticketReplyResponse);
      }
    } catch (error) {
      yield put(SocialActions.ticketReply.failure(error));
    }
  }
}

function* ticketChangeRequest(action: Action) {
  if (SocialActions.ticketChange.request.match(action)) {
    try {
      const ticketChangeResponse: ApiResponse<any> = yield call(
        api.ticketChange,
        action.payload
      );
      if (ticketChangeResponse.ok && ticketChangeResponse.data) {
        const responseData = ticketChangeResponse.data;
        yield put(SocialActions.ticketChange.success(responseData));
      } else {
        throw new RequestErrorFromResponse(ticketChangeResponse);
      }
    } catch (error) {
      yield put(SocialActions.ticketChange.failure(error));
    }
  }
}

export function* SocialSaga() {
  yield* [
    takeLatest(SocialActions.news.request.type, getNewsRequest),
    takeLeading(SocialActions.blog.request.type, getBlogRequest),
    takeLeading(SocialActions.ad.request.type, getAdRequest),
    takeLeading(SocialActions.ticketsList.request.type, ticketsListRequest),
    takeLeading(SocialActions.ticketsTypes.request.type, ticketsTypesRequest),
    takeLatest(SocialActions.ticketOpen.request.type, ticketOpenRequest),
    takeLeading(SocialActions.ticketReply.request.type, ticketReplyRequest),
    takeLeading(SocialActions.ticketChange.request.type, ticketChangeRequest),
  ];
}
