import { useState } from "react";
import { validateLink } from "../helpers/validationHelper";
import { NftSocialMedia, NftSocialMediaLink } from "../types/types";

const DEFAULT_LINKS_STATE: {[media in NftSocialMedia]: string} = {
  "Facebook": "",
  "Instagram": "",
  "Pinterest": "",
  "TikTok": "",
  "Twitter": "",
  "Website": "", 
  "YouTube": ""
};

export const useLinksState = () => {
  const [linksInner, setLinksInner] = useState(DEFAULT_LINKS_STATE);

  const setLink = (media: NftSocialMedia, value: string) => {
    setLinksInner((v) => ({...v, [media]: value.toLowerCase()}));
  }

  const setFilledLinks = (links: NftSocialMediaLink[]) => {
    for (const l of links)
      setLinksInner((v) => ({...v, [l.platform]: l.url}));
  }

  const validateLinks = () => {
    for (const media of Object.keys(linksInner) as Array<NftSocialMedia>)
      if (linksInner[media].length > 0 && !validateLink(linksInner[media]))
        return false;

    return true;
  }

  const getLinksArray = (): NftSocialMediaLink[] => {
    return (Object.keys(linksInner) as Array<NftSocialMedia>)
      .map((media) => ({platform: media, url: linksInner[media]}))
      .filter((link) => link.url.length > 0)
  }

  return {
    links: linksInner,
    setLink,
    setFilledLinks,
    validateLinks,
    getLinksArray
  };
}