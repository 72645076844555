import React, { FC, useEffect, useState } from "react";
import { Text } from "../../../../components/Text/Text";
import LoaderRing from "../../../../helpers/Loader/LoaderRing";
import { useThemeContext } from "../../../../theme";
import { useNftPage } from "../hooks/useNftPage";
import { Button, Picture, Wrapper } from "./EmptyPageStyles";
import i18next from "i18next";

type Props = {
  fetchingProfile: boolean;
  fetchingCreate: boolean;
};

const EmptyPage: FC<Props> = ({ fetchingProfile, fetchingCreate }) => {
  const { theme } = useThemeContext();
  const { t } = i18next;
  const { createNftProfile } = useNftPage();
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Wrapper>
      {fetchingProfile ? (
        <LoaderRing size={"60px"} color={theme.colors.yellowMain} />
      ) : showContent ? (
        <>
          <Picture src="/images/NFT_Page/block1.png" />
          <Text
            color={theme.colors.white}
            size="36px"
            fontStyle={theme.fonts.f600}
          >
            {t("nftHomeScreen.welcomeToNft")}
          </Text>
          <Text
            color={theme.colors.greySubText}
            size="24px"
            fontStyle={theme.fonts.f600}
            textAlign="center"
          >
            {t("nftHomeScreen.nftProfileRequired")}
          </Text>
          <Button onClick={() => createNftProfile()}>
            {fetchingCreate ? (
              <LoaderRing size="24px" color={theme.colors.black} />
            ) : (
              <Text
                color={theme.colors.black}
                size="30px"
                fontStyle={theme.fonts.f500}
              >
                {t("nftHomeScreen.createNftProfile")}
              </Text>
            )}
          </Button>
        </>
      ) : null}
    </Wrapper>
  );
};
export default EmptyPage;
