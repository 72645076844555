import { FC } from "react";
import styled from "styled-components";
import { templates } from "../../../../theme/templates";
import i18next from "i18next";

type Props = {
  setImage?: any;
  image: any;
  id: any;
  setModal: any;
};
const CheckQuality: FC<Props> = ({ setImage, image, id, setModal }) => {
  const { t } = i18next;
  return (
    <>
      <Picture
        style={{ width: "100%" }}
        src={URL.createObjectURL(image)}
        alt="document"
      />

      <Buttons>
        <Button
          black
          onClick={() => setImage((prev: any) => ({ ...prev, [id]: null }))}
        >
          {t("buttons.tryAgain")}
        </Button>
        <Button onClick={() => setModal(false)}>{t("buttons.continue")}</Button>
      </Buttons>
    </>
  );
};
export default CheckQuality;
const Buttons = styled.div`
  display: flex;
  margin-top: 10px;
  width: 90%;
  column-gap: 20px;
`;
const Button = styled.div<{ black?: boolean }>`
  ${templates.centerContent};
  width: 100%;
  border-radius: 12px;
  cursor: pointer;
  font-family: Gilroy-Semibold;
  font-weight: 600;
  cursor: pointer;
  border: ${(p) =>
    p.black ? `1px solid ${p.theme.colors.yellowMain}` : "none"};
  height: 50px;
  color: ${(p) => (p.black ? p.theme.colors.yellowMain : p.theme.colors.black)};
  background-color: ${(p) =>
    p.black ? p.theme.colors.black : p.theme.colors.yellowMain};
`;
const Picture = styled.img`
  border-radius: 8px;
  width: 100%;
  max-height: 700px;
`;
