import { makeStyles } from '@material-ui/core/styles';
import { theme } from "../../theme/default/theme";

export const useStyles = makeStyles({
  detailsCont: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: theme.metrics.x2,
  },
  detailRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.metrics.x,
    paddingBottom: theme.metrics.x
  },
  detailLabel: {
    fontFamily: theme.fonts.f400.fontFamily,
    fontSize: 14,
    color: theme.colors.textDescription,
  },
  detailValue: {
    fontFamily: theme.fonts.f600.fontFamily,
    fontSize: 14,
    color: theme.colors.text,
  }
});