function SvgComponent(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.333 16.666h3.334l8.75-8.75a2.356 2.356 0 00-1.667-4.023 2.357 2.357 0 00-1.667.69l-8.75 8.75v3.333zM11.25 5.417l3.333 3.333"
        stroke="#fff"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgComponent
