import i18next from "i18next";
import { useThemeContext } from "../../theme";
import MarketItemBlock from "./components/MarketItemBlock";
import { ContainerSB, MarketItemsBlock, Wrapper } from "./MarketsStyle";
import { blocksData, tabsData } from "./mock";
import { Text } from "../../components/Text/Text";
import InputSearch from "./components/InputSearch";
import Tabs from "./components/MarketsTabs";
import MarketSelector from "./components/MarketSelector";
import MarketItemsTable from "./components/MarketItemsTable";
import MarketSort from "./components/MarketSort";

const MarketPage = () => {
  const { theme } = useThemeContext();
  const { t } = i18next;
  return (
    <Wrapper>
      <MarketItemsBlock>
        {blocksData.map((el, index) => (
          <MarketItemBlock
            key={index}
            icon={el.icon}
            title={el.title}
            percents={el.percents}
            value={el.value}
            secondValue={el.secondValue}
            hoursVolume={el.hoursVolume}
          />
        ))}
      </MarketItemsBlock>

      <Text
        color={theme.colors.white}
        fontStyle={theme.fonts.f600}
        size="50px"
        margin="0 0 32px 0"
      >
        {t("marketsPage.markets")}
      </Text>

      <ContainerSB>
        <Tabs tabsData={tabsData} />
        <InputSearch placeholder={t("marketsPage.searchCoinName")} />
      </ContainerSB>

      <MarketSelector />
      <MarketSort />
      {Array.from({ length: 12 }, (_, index) => (
        <MarketItemsTable key={index} />
      ))}
    </Wrapper>
  );
};
export default MarketPage;
