import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { Text } from "../../../components/Text/Text";
import { useThemeContext } from "../../../theme";
import {
  BackArrow,
  CloseTicket,
  HeadWrapper,
  IconAdd,
  Input,
  InputWrapper,
  MessagesArea,
  Wrapper,
} from "./ChatStyles";

import { Ticket } from "../Ticket";
import { MessageData } from "../data";
import MessageItem from "./MessageItem";
import { useSupportChat } from "../hooks/useSupportChat";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import LoaderRing from "../../../helpers/Loader/LoaderRing";
import useWindowWidth from "../../../hooks/useWindowWidth";
import {
  FileContainer,
  Icon,
} from "../../../components/Modals/CreateTicket/CreateTicketStyles";
import AreYouSure from "../../../components/Modals/AreYouSure/AreYouSure";
import i18next from "i18next";
import useEnterKeyPress from "../../../hooks/useEnterKeyPress";

type Props = {
  data: MessageData[];
  id: number;
  setPageStep: any;
};

const Chat: FC<Props> = ({ data, id, setPageStep }) => {
  const { theme } = useThemeContext();
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const [showError, setShowError] = useState(false);
  const { t } = i18next;
  const {
    sendingMessage,
    onSend,
    setMessage,
    message,
    setSelectedFiles,
    selectedFiles,
    onClose,
    closeFetch,
    ticketSuccess,
  } = useSupportChat(id);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files).slice(0, 6 - selectedFiles.length); // Ограничение в 3 изображения
      setSelectedFiles([...selectedFiles, ...newFiles]);
    }
  };
  const handleRemoveFile = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const filtredData = data.filter((el) => el.id === id);

  const inputHandler = (e: { target: { value: string } }) => {
    setMessage((prev) => e.target.value);
    if (showError === true) {
      setShowError(false);
    }
  };

  const sendClick = () => {
    if (message.length) {
      onSend();
      setShowError(false);
    } else {
      setShowError(true);
    }
  };
  const messagesAreaRef = useRef<HTMLDivElement>(null);
  // Прокрутка скролла в самый низ при обновлении данных или отправке сообщения
  useEffect(() => {
    if (messagesAreaRef.current) {
      messagesAreaRef.current.scrollTop = messagesAreaRef.current.scrollHeight;
    }
  }, [data]);
  const windowWidth = useWindowWidth();
  useEnterKeyPress(true, sendClick);
  return (
    <>
      <Wrapper>
        <HeadWrapper>
          {windowWidth < 1030 && (
            <BackArrow
              src="/images/support/back_arrow.svg"
              onClick={() => setPageStep("tickets")}
            />
          )}

          <Text
            color={theme.colors.white}
            size="30px"
            fontStyle={theme.fonts.f600}
            margin="1px auto"
          >
            {t("supportPage.chatWithSupport")}
          </Text>
          <CloseTicket onClick={() => setConfirmIsOpen(true)}>
            {filtredData.length && filtredData[0].status !== "Closed" && (
              <Text
                color={theme.colors.red}
                size="21px"
                fontStyle={theme.fonts.f600}
              >
                {t("supportPage.close")}
              </Text>
            )}
          </CloseTicket>
        </HeadWrapper>
        {filtredData.length ? (
          <>
            <div style={{ margin: "3px 0 auto 0" }}>
              <Ticket data={filtredData[0]} />
            </div>

            <MessagesArea ref={messagesAreaRef} id="area">
              {filtredData.map((el) =>
                el.messages.map((el, index) => (
                  <MessageItem
                    key={index}
                    message={el.message}
                    direction={el.direction}
                    name={el.name}
                    date={el.date_time}
                    files={el.files}
                  />
                ))
              )}
            </MessagesArea>
          </>
        ) : (
          <Text
            color={theme.colors.white}
            size="30px"
            fontStyle={theme.fonts.f700}
            margin="0 auto 90px"
          >
            {t("supportPage.selectTicket")}
          </Text>
        )}
        {filtredData.length && (
          <InputWrapper>
            <input
              ref={fileInputRef}
              type="file"
              accept=".jpg, .jpeg, .png"
              multiple
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            {selectedFiles.length ? (
              <ImagesContainer>
                {selectedFiles.map((file, index) => (
                  <FileContainer
                    key={index}
                    onClick={() => handleRemoveFile(index)}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    style={{
                      minWidth: "100px",
                      maxWidth: "100px",
                      height: "100px",
                    }}
                  >
                    {hoveredIndex === index && (
                      <Icon src="/images/support/delete.svg" />
                    )}
                    <img
                      src={URL.createObjectURL(file)}
                      alt="Selected file"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "12px",
                      }}
                    />
                  </FileContainer>
                ))}
              </ImagesContainer>
            ) : null}

            <IconAdd
              src="/images/support/add.svg"
              onClick={() => {
                handleButtonClick();
              }}
            />

            <Input
              error={showError}
              value={message}
              placeholder="Send message"
              onChange={(e) => inputHandler(e)}
            />
            {sendingMessage ? (
              <div style={{ width: "40px", height: "40px" }}>
                <LoaderRing size="38px" color={theme.colors.yellowMain} />
              </div>
            ) : (
              <IconAdd
                src="/images/support/send.svg"
                onClick={() => {
                  sendClick();
                }}
              />
            )}
          </InputWrapper>
        )}
      </Wrapper>
      {confirmIsOpen && (
        <AreYouSure
          func={onClose}
          fetch={closeFetch}
          setModal={setConfirmIsOpen}
          success={ticketSuccess}
          setPage={setPageStep}
          yes={{ text: t("supportPage.close"), color: theme.colors.red }}
          no={{ text: t("cancel"), color: theme.colors.green }}
        />
      )}
    </>
  );
};
export default Chat;

const ImagesContainer = styled.div`
  position: absolute;
  display: flex;
  column-gap: 8px;
  padding: 10px;
  width: 100%;
  min-height: 120px;
  border-radius: 12px 12px 0 0;
  bottom: 60px;
  background-color: ${(p) => p.theme.colors.subBackground};
  overflow: auto;
  /* Стили скролла */
  scrollbar-width: thin;
  scrollbar-color: ${(p) => p.theme.colors.scrollbar};

  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${(p) => p.theme.colors.greySubText};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.colors.white};
    border-radius: 3px;
  }
`;
