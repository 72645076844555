import { ChangeEvent, useEffect, useState } from "react";
import { useThemeContext } from "../../../theme";
import { Text } from "../../Text/Text";
import {
  AnimationContainer,
  Button,
  FileContainer,
  Icon,
  Input,
  Tab,
  TabsContainer,
  TextArea,
} from "./CreateTicketStyles";
import { useCreateTicket } from "../../../Pages/Support/hooks/useCreateTicket";
import LoaderRing from "../../../helpers/Loader/LoaderRing";
import BlurWrapper from "../../BlurWrapper/BlurWrapper";
import i18next from "i18next";

const TicketModal = ({ setModal }: any) => {
  const { theme } = useThemeContext();
  const { t } = i18next;
  const [activeTab, setActiveTab] = useState(69);

  const {
    createTicket,
    setMessage,
    message,
    subject,
    setPickedType,
    setSubject,
    setSelectedFiles,
    selectedFiles,
    fetch,
    success,
    ticketsTypes,
  } = useCreateTicket();
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files).slice(0, 6 - selectedFiles.length); // Ограничение в 6 изображения
      setSelectedFiles([...selectedFiles, ...newFiles]);
    }
  };

  const handleClick = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };
  const handleRemoveFile = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  useEffect(() => {
    if (success) setModal(false);
  }, [success]);
  return (
    <BlurWrapper setModal={setModal}>
      <AnimationContainer onClick={(e) => e.stopPropagation()}>
        <Input
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <TextArea
          placeholder="Message"
          style={{ height: "100px" }}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Text
          color={theme.colors.white}
          size="20px"
          fontStyle={theme.fonts.f500}
          margin="0 0 0 10px"
        >
          {t("supportPage.chooseDepartment")} :
        </Text>
        <TabsContainer>
          {(ticketsTypes ?? []).map((el: any) => (
            <Tab
              key={el.order}
              active={activeTab === el.order}
              onClick={() => {
                setActiveTab(el.order);
                setPickedType(el.title);
              }}
            >
              <Text
                color={
                  activeTab === el.order
                    ? theme.colors.black
                    : theme.colors.yellowMain
                }
                size="16px"
                fontStyle={theme.fonts.f500}
              >
                {el.title}
              </Text>
            </Tab>
          ))}
        </TabsContainer>
        <div style={{ display: "flex" }}>
          <Text
            color={theme.colors.white}
            size="20px"
            fontStyle={theme.fonts.f500}
            margin="5px 0 0 10px"
          >
            {t("supportPage.chooseFile")}
          </Text>
          <Text
            color={theme.colors.yellowMain}
            size="20px"
            fontStyle={theme.fonts.f500}
            margin=" 4px 0 0 5px"
          >
            ({t("supportPage.optional")})
          </Text>
        </div>

        <TabsContainer>
          <input
            id="fileInput"
            type="file"
            style={{ display: "none" }}
            accept=".jpg, .jpeg, .png"
            multiple
            max="6"
            onChange={handleFileChange}
          />
          {selectedFiles.map((file, index) => (
            <FileContainer
              key={index}
              onClick={() => handleRemoveFile(index)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              redBorder={true}
            >
              {hoveredIndex === index && (
                <Icon src="/images/support/delete.svg" />
              )}

              <img
                src={URL.createObjectURL(file)}
                alt="Selected file"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "12px",
                }}
              />
            </FileContainer>
          ))}
          {selectedFiles.length < 6 && (
            <FileContainer onClick={handleClick}>
              <input
                id="fileInput"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <Icon src="/images/support/add.svg" />
            </FileContainer>
          )}
        </TabsContainer>
        <Button onClick={() => createTicket()}>
          {!fetch ? (
            <Text
              color={theme.colors.black}
              size="20px"
              fontStyle={theme.fonts.f600}
            >
              {t("supportPage.create")}
            </Text>
          ) : (
            <LoaderRing size="45px" color={theme.colors.black} />
          )}
        </Button>
      </AnimationContainer>
    </BlurWrapper>
  );
};
export default TicketModal;
