import { makeStyles } from '@material-ui/core/styles';
import { theme } from "../../theme/default/theme";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    // width: "100%",
    // height: "100%",
    // backgroundColor: 'yellow'
  },
  top: {
    display: "flex",
    width: "100%",
    justifyContent: 'space-between',
    // height: 200,
    // backgroundColor: 'red',
    marginBottom: theme.metrics.x8
  },
  rowCentered: {
    display: "flex",
    alignItems: "center",
  },
  mainText: {
    fontFamily: theme.fonts.f500.fontFamily,
    fontSize: 20,
    color: theme.colors.text,
  },
  secondaryText: {
    fontFamily: theme.fonts.f400.fontFamily,
    fontSize: 16,
    color: theme.colors.textDescription,
  },
  escrowPartnersCont: {
    display: "flex",
    gap: theme.metrics.x4,
    flexWrap: "wrap",
  }
});