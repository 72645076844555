import { Country } from "../types/types";
import { flags } from '../assets/flags';
import { theme } from "../theme/default/theme";

export const countriesToDataPicker = (countries: Country[]) => {
  return countries.map((c) => {
    // @ts-ignore
    const flag = flags[c.alpha_2]?.emoji ?? "";
    const label = `${flag} ${c.name}`;
    const value = c.id;

    return {label, value, color: theme.colors.white};
  });
};

export const stringOrFallback = (s: string | null | undefined, fallback: string | undefined = "-") => {
  if (s)
    return s;

  return fallback;
}

export const clearObjectFromEmptyStrings = (obj: any) => {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
      delete obj[propName];
    }
  }
  return obj
}

export const prepareImageName = (uri: string) => {
  let data = uri.split('/');
  return data[data.length - 1];
};

export const prettyStringToNumber = (s: string) => {
  const clearStr = s.replace(/,/g, "");
  return Number(clearStr);
}

export const prettyCardExpiryMonth = (s: string) => {
  if (s === "--")
    return s;

  if (s.length === 1)
    return `0${s}`;

  return s;
}

export const prettyCardExpiryYear = (s: string) => {
  if (s === "--")
    return s;

  if (s.length === 4)
    return s.slice(2, 4);

  return s;
}

export const formatCardNumberWithSpaces = (s: string) => {
  const m = s.match(/.{1,4}/g);
  if (!m)
    return s;

  return m.join(' ');
}
export const prettyPageUrl = (s: string) => {

  if(s){
    const inputString = s;
    const regex = /^(?:https?:\/\/)?[^/]+(.*)/;

  return inputString.replace(regex, "$1");
  }
}