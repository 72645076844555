import React, { useCallback, useState } from 'react';
import { validateFloat } from '../helpers/validationHelper';

export const useAmountInput = () => {
  const [amount, setAmount] = useState("");

  // Подменить запятые на точки, провести валидацию
  const handleAmountInput = useCallback((text: string, setFunc: (t: string) => void) => {
    text = text.replace(",", ".");

    if (validateFloat(text)) 
      setFunc(text);
  }, []);

  return {
    amount,
    setAmount,
    handleAmountInput
  }
}