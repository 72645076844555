import React, { ReactNode } from "react";
import { useStyles } from "./QRCodeItemStyles";
import { Box, CircularProgress } from "@material-ui/core";
import { copyToClipboard } from "../../helpers/copyToClipboard";
import { ManualItem } from "../ManualItem";
import { DataPickerItemType } from "../../types/types";
import { SettingsItem } from "../SettingsItem";
import { useThemeContext } from "../../theme";
import classNames from "classnames";

export type QRCodeItemProps = {
  title?: string | null;
  loading?: boolean;
  qr?: string;
  manualValue?: string;
  manualTitle?: string;
  optionsData?: DataPickerItemType[];
  optionValue?: string | number;
  optionsLabel?: string;
  optionsRightLabel?: string;
  onOptionValueChange?: (v: string | number | undefined) => void;
  topContent?: ReactNode;
};

const QRCodeItem: React.FC<QRCodeItemProps> = ({
  title,
  loading,
  qr,
  manualTitle,
  manualValue,
  optionsData,
  optionValue,
  optionsLabel,
  optionsRightLabel,
  onOptionValueChange,
  topContent
}) => {
  const s = useStyles();
  const { theme } = useThemeContext();

  return (
    <Box className={classNames(s.container)}>
      {title &&
        <p className={classNames(s.titleText)}>{title}</p>
      }

      {Boolean(topContent) &&
        <Box className={classNames(s.topContentContainer)}>
          {topContent}
        </Box>
      }

      {(onOptionValueChange && optionsData && optionsData.length > 0) &&
        <Box className={classNames(s.settingsItemContainer)}>
          <SettingsItem
            label={optionsLabel as string}
            labelColor={theme.colors.greySubText}
            rightLabel={optionsRightLabel}
            onValueChange={(v) => onOptionValueChange(v)}
            picker={true}
            items={optionsData}
            value={optionValue}
            disabled={optionsData.length <= 1}
          />
        </Box>
      }

      <Box className={classNames(s.qrCodeImgCont)}>
        {loading || !qr ? (
          <CircularProgress size={30} />
        ) : (
          <img className={classNames(s.qrImg)} src={qr} />
        )}
      </Box>
      {manualTitle && manualValue && (
        <ManualItem
          subtitle={manualTitle}
          manualKey={manualValue}
          onCopyPress={() => copyToClipboard(manualValue)}
        />
      )}
    </Box>
  );
};

QRCodeItem.defaultProps = {
  loading: false,
  optionsLabel: "Option"
};

export { QRCodeItem };
