import { NftItem, NftFileType } from "../types/types";
import moment from 'moment';

export const isNftImage = (nft: NftItem) => {
  const imageLikeFileTypes = new Set<NftFileType>(["PNG", "JPG", "GIF"]);

  return imageLikeFileTypes.has(nft.file_type as any);
}

export const getNftImage = (nft: NftItem) => {
  return isNftImage(nft) ? nft.nft_file : nft.nft_poster;
}

export const getBidTimerSeconds = (nft: NftItem): number | undefined => {
  if (!nft.allow_bid)
    return undefined;

  return Math.floor(moment(nft.bid_expiry).diff(moment()).valueOf() / 1e3);
}