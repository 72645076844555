import styled, { css, keyframes } from "styled-components";
import { templates } from "../../../theme/templates";
import { darkScrollbar } from "@mui/material";

const slideInAnimation = keyframes`
  from {
    transform: scale(0.2);
  }

  to {
    transform: scale(1);
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  ${templates.centerContent};
  top: 0;
  height: 100vh;
  background-color: ${(p) => p.theme.colors.blurBg};
  z-index: 5;
  width: stretch;
`;
export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 690px;

  row-gap: 20px;
  background-color: ${(p) => p.theme.colors.black};
  border-radius: 16px;
  border: 1px solid ${p => p.theme.colors.yellowMain};
  padding: 16px 12px;
  @media (max-width: 700px) {
    width: 100%;
    padding: 10px 5px;
  }
`;
export const Input = styled.input`
  all: unset;
  height: 40px;
  background-color: ${(p) => p.theme.colors.subBackground};

  padding: 0 10px;
  width: 100%;
  color: ${(p) => p.theme.colors.white};
  box-sizing: border-box;
`;
export const TextArea = styled.textarea`
  all: unset;
  height: 100px;
  background-color: ${(p) => p.theme.colors.subBackground};
  overflow-x: hidden;
  resize: none;
  padding: 10px;
  width: 100%;
  color: ${(p) => p.theme.colors.white};
  box-sizing: border-box;
  word-break: break-all;
  scrollbar-width: thin;
  scrollbar-color: ${(p) => p.theme.colors.scrollbar};

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${(p) => p.theme.colors.greySubText};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.colors.white};
    border-radius: 3px;
  }
`;
export const TabsContainer = styled.div`
  display: flex;
  background-color: ${(p) => p.theme.colors.subBackground};
  column-gap: 20px;
  padding: 10px 20px;
  overflow: auto;
  /* Стили скролла */
  scrollbar-width: thin;
  scrollbar-color: ${(p) => p.theme.colors.scrollbar};

  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${(p) => p.theme.colors.greySubText};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.colors.white};
    border-radius: 3px;
  }
`;
export const Tab = styled.div<{ active: boolean }>`
  ${templates.centerContent};
  border: 1px solid ${(p) => p.theme.colors.yellowMain};
  padding: 8px 10px;
  background-color: ${(p) => (p.active ? p.theme.colors.yellowMain : "")};
  border-radius: 12px;
  cursor: pointer;
`;
export const Button = styled.div`
  ${templates.centerContent};
  margin-top: 20px;
  min-height: 60px;
  background-color: ${(p) => p.theme.colors.yellowMain};
  border-radius: 12px;
  cursor: pointer;
  padding: 10px 0;
`;
export const FileContainer = styled.div<{ redBorder?: boolean }>`
  position: relative;
  min-width: 150px;
  height: 150px;
  ${templates.centerContent};
  cursor: pointer;
  border-radius: 12px;
  border: 2px solid ${(p) => p.theme.colors.borderGrey};
  transition: 0.3s;
  @media (max-width: 700px) {
    min-width: 100px;
    height: 100px;
  }
  &:hover {
    border: 2px solid
      ${(p) => (p.redBorder ? p.theme.colors.red : p.theme.colors.yellowMain)};
  }
`;
export const Icon = styled.img`
  position: absolute;
  width: 40px;
  height: 40px;
  cursor: pointer;
`;
