import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: "center"
  },
  label: {
    fontFamily: theme.fonts.f400.fontFamily,
    fontSize: 17,
    margin: 0
  },
  value: {
    fontFamily: theme.fonts.f400.fontFamily,
    fontSize: 17,
    maxWidth: "75%",
    margin: 0
  },
});