import { makeStyles } from '@material-ui/core/styles';
import { theme } from "../../theme/default/theme";

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 23px',
    backgroundColor: theme.colors.black,
    // backgroundColor: 'yellow',
    minHeight: '107vh',
    "@media (max-width: 900px)": {
      padding: '15px 16px',
      minHeight: 'calc(107vh + 30px)'
    }
  },
  top: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    // height: 200,
    // backgroundColor: 'red',
    marginBottom: theme.metrics.x8
  },
  addPartnerBtn: {
    display: "flex",
    width: 135
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.metrics.x6,
    backgroundColor: theme.colors.codGray,
    borderRadius: theme.metrics.x4,
    border: `1px solid ${theme.colors.borderGrey}`,
  },
  rowBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  label: {
    fontFamily: theme.fonts.f700.fontFamily,
    fontSize: 24,
    color: theme.colors.text,
    margin: 0,
    pointerEvents: 'none'
  },
  formCont: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 500
  },
  inputCont: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.metrics.x4
  },
  formFooter: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.metrics.x6
  },
  createTxBtn: {
    display: 'flex',
    width: 240
  }
});