import styled, { css, keyframes } from "styled-components";
const shakeAnimation = keyframes`
  0% { transform: translateX(0); }
  10%, 90% { transform: translateX(-5px); }
  20%, 80% { transform: translateX(5px); }
  30%, 50%, 70% { transform: translateX(-5px); }
  40%, 60% { transform: translateX(5px); }
  100% { transform: translateX(0); }
`;

export const Wrapper = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  @media (max-width: 700px) {
    width: 80%;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const Input = styled.input<{error?: boolean}>`
  all: unset;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-size: 21px;
  font-family: Gilroy-Medium;
  font-weight: 500;
  color: ${(p) => p.theme.colors.white};
  border-bottom: 1px solid ${(p) => p.theme.colors.yellowMain};
  @media (max-width: 700px) {
    font-size: 16px;
  }
  ${(p) =>
    (!p.error)&&
    css`
      border-bottom-color: red;
      animation: ${shakeAnimation} 0.4s linear;
    `}
`;
export const Select = styled.div`
  display: flex;
  margin: 10px 0;
  align-items: center;
  position: relative;
  width: 100%;
  height: 50px;
  border-radius: 12px;
  background-color: ${(p) => p.theme.colors.blackOpacityBackground};
`;
