import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// Redux & Redux-Saga setup
import "./store";
import { Provider } from "react-redux";
import { store } from "./store/store";
//
import { HelmetProvider } from "react-helmet-async";
import { IntercomProvider } from 'react-use-intercom';
import { CONFIG } from "./config";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <IntercomProvider 
        appId={CONFIG.INTERCOM.APP_ID} 
        // autoBoot={true}
      >
        <App />
      </IntercomProvider>
    </HelmetProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();