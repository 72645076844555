import styled from "styled-components";

import { templates } from "../../theme/templates";

export const Wrapper = styled.div<{
  width?: string;
  padding?: string;
  direction?: string;
  color?: string;
  backColor?: string;
  margin?: string;
  justyfiContent?: "space-between" | "flex-start" | "flex-end";
  alignItems?: "center" | "flex-start" | "flex-end";
  border?: string;
  columnGap?: string;
  rowGap?: string;
}>`
  ${templates.centerContent};
  width: ${(p) => p.width || "auto"};
  flex-direction: ${(p) => p.direction || "row"};
  justify-content: ${(p) => p.justyfiContent || "none"};
  align-items: ${(p) => p.alignItems || "none"};
  padding: ${(p) => p.padding || "0"};
  background-color: ${(p) => p.backColor || p.theme.colors.subBackground};
  color: ${(p) => p.color || "#6D6D7B"};
  border: ${(p) => p.border || "none"};
  border-radius: 16px;
  margin: ${(p) => p.margin || ""};
  row-gap: ${(p) => p.rowGap || ""};
  column-gap: ${(p) => p.columnGap || ""};
  position: relative;
`;
