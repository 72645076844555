import React from 'react';
import { useDependentValue } from './../helpers/customHooks';
import { UserInfo } from './../logic/user/UserRedux';
import { flags } from './../assets/flags';

export const useCountryText = (profile: UserInfo | undefined) => {
  const countryText = useDependentValue<string>(() => {
    if (!profile?.country)
      return "-";

    const key: string = profile.country.alpha_2;
    // @ts-expect-error
    const flag = flags[key]?.emoji ?? "";

    return `${flag} ${profile.country.name}`;
  }, [profile]);

  return countryText;
}