import React, { memo, useState } from "react";
import { useStyles } from "./DepositOfflineStyles";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { WalletItem } from "../../../../../components/WalletItem";
import { CONFIG } from "../../../../../config";
import { useDispatch, useSelector } from "react-redux";
import { cryptoSelector } from "../../../../../logic/crypto/CryptoSelectors";
import { useThemeContext } from "../../../../../theme";
import { prettyStringToNumber } from "../../../../../helpers/formatHelpers";
import { TextInput } from "../../../../../components/TextInput";
import { useAmountInput } from "../../../../../hooks/useAmountInput";
import { generalSelector } from "../../../../../logic/general/GeneralSelectors";
import { useHideErrors } from "../../../../../hooks/useHideErrors";
import { validateFloat } from "../../../../../helpers/validationHelper";
import { CustomButton } from "../../../../../components/CustomButton";
import { BankInfoModal } from "../../../../../components/BankInfoModal";
import { PickedMedia } from "../../../../../components/PickedMedia";
import { CryptoActions } from "../../../../../logic/crypto/CryptoRedux";
import { ShowDetails } from "../../../../../components/ShowDetails";
import { StringValueItem } from "../../../../../components/StringValueItem";

export type DepositOfflineProps = {};

const DepositOffline: React.FC<DepositOfflineProps> = ({}) => {
  const s = useStyles();
  // @ts-ignore
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const dispatch = useDispatch();

  const {
    paymentsPageSelectedAccount: accountData,
    deposit: { fetching: fetchingDeposit },
  } = useSelector(cryptoSelector);

  const {
    getBankAccounts: { data: bankAccounts },
  } = useSelector(generalSelector);

  const [active, setActive] = useState(false);
  const [receiptPhoto, setReceiptPhoto] = useState<any>(null);

  const { hideErrors, setHideErrors, onInputErrorAnimationEnd } =
    useHideErrors();

  const { amount, setAmount, handleAmountInput } = useAmountInput();

  const onPressSend = () => {
    if (accountData && validateFloat(amount) && receiptPhoto) {
      const fData = new FormData();
      fData.append("receipt", receiptPhoto);

      dispatch(
        CryptoActions.deposit.request({
          to_account_id: accountData.id,
          amount: Number(amount),
          type: "Deposit_Requested",
          receipt: "",
          fData,
        })
      );
    } else {
      setHideErrors(false);
    }
  };

  if (!accountData) return null;

  return (
    <Box className={cn(s.globalContainer)}>
      <Box className={cn(s.container)}>
        <Box className={cn(s.spaceBetween)}>
          <Box className={cn(s.topBlock)}>
            <p className={cn(s.whiteTextSemiBold)}>{t("to")}</p>
            <Box className={cn(s.block)}>
              <WalletItem
                image={`${CONFIG.ZED_BASE_URL}/${accountData.currency_flag}`}
                userHasThisWallet={true}
                // userCurrencySymbol={getCurrencySymbol(userCurrency)}
                userCurrencySymbol="$"
                balance={
                  accountData.balance *
                  prettyStringToNumber(accountData.currency_price)
                }
                cryptoBalance={accountData.balance}
                name={accountData.currency_code}
                walletName={accountData.name}
                marginVertical={theme.metrics.x3}
              />
            </Box>

            <Box onClick={() => setActive(true)} style={{ cursor: "pointer" }}>
              <p className={cn(s.showBankDetails)}>
                {t("depositOfflinePage.showBankInfo")}
              </p>
            </Box>

            <Box className={cn(s.inputsContainer)}>
              <TextInput
                value={amount}
                placeholder={t("enterAmount") as string}
                onChangeText={(text) => handleAmountInput(text, setAmount)}
                validationOk={hideErrors || validateFloat(amount)}
                onErrorAnimationEnd={onInputErrorAnimationEnd}
              />
            </Box>

            <p className={cn(s.whiteText)}>
              {t("depositOfflinePage.uploadReceipt")}
            </p>
            <Box className={cn(s.imgPickerCont)}>
              <PickedMedia
                onFileChanged={(data: any) => setReceiptPhoto(data)}
                source={receiptPhoto}
                empty={!receiptPhoto}
              />
            </Box>

            <ShowDetails>
              <StringValueItem 
                label={t("payments.operationTime")}
                value={t("defaultConfirmOperationTime")}
              />
            </ShowDetails>
          </Box>

          <CustomButton
            title={t("buttons.send") as string}
            bgColorActive={theme.colors.yellowMain}
            bgColorUnactive={theme.colors.shark}
            colorActive={theme.colors.black}
            colorUnactive={theme.colors.greySubTextDesc}
            defaultSizing={true}
            onPress={onPressSend}
            disabled={!receiptPhoto}
            spinner={fetchingDeposit}
          />
        </Box>
      </Box>

      <BankInfoModal
        data={bankAccounts?.accounts[0]}
        visible={active}
        onClose={() => setActive(false)}
      />
    </Box>
  );
};

DepositOffline.defaultProps = {};

const MemorizedComponent = memo(DepositOffline);
export { MemorizedComponent as DepositOffline };
