import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  storieModal: {
    display: 'flex',
    flexDirection: 'column',
    width: 476,
    maxWidth: '100vw',
    height: '100vh',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colors.subBackground,
    overflow: 'hidden',
    paddingTop: theme.metrics.x5,
  },
  "&__content": {outline: "none"},
  topCont: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '12%'
  },
  endRow: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    // backgroundColor: 'red',
    justifyContent: 'flex-end',
    paddingTop: theme.metrics.x2,
    paddingBottom: theme.metrics.x2,
    paddingRight: theme.metrics.x2,
    paddingLeft: theme.metrics.x2,
  },
  closeIcon: {
    display: 'flex',
    // backgroundColor: 'blue',
    width: 40,
    height: 40,
    // marginRight: 
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '88%',
    // backgroundColor: 'red',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  prevBtn: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    height: '100%',
    width: '25%',
    left: 0,
    top: 0,
    zIndex: 3,
    // backgroundColor: 'red'
  },
  nextBtn: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    height: '100%',
    width: '75%',
    right: 0,
    top: 0,
    zIndex: 3,
    // backgroundColor: 'blue'
  },
  fullSpace: {
    display: 'flex',
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  }
});