import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../theme/default/theme';

export const useStyles = makeStyles({
  rowFullWidth: {
    display: "flex",
    flexDirection: "row",
    width: '100%',
    // backgroundColor: 'blue',
    marginBottom: 24,
    height: 190
  },
  cardInfoCol: {
    width: 290,
    display: "flex",
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginRight: theme.metrics.x4
    // backgroundColor: 'blue'
  },
  fullWidth: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  fullSpaceCentered: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  greySubTextSemiBold: {
    fontFamily: theme.fonts.f600.fontFamily,
    fontSize: 20,
    color: theme.colors.greySubText,
    margin: 0
  },
  historyBlock: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxHeight: 200,
    maxWidth: 450,
    overflowY: 'scroll',
    // backgroundColor: 'blue'
  },
  cardStatusDescBox: {
    height: "30vh",
    display: 'flex',
    flexDirection: 'column',
    padding: theme.metrics.x4,
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardStatusSpecialBtn: {
    display: 'flex',
    width: 200
  }
});