import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    paddingRight: 8,
    paddingLeft: 8,
    fontWeight: 'bold'
  },
  titleText: {
    pointerEvents: 'none',
  }
});