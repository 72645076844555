import React, { memo } from "react";
import { useStyles } from "./ManualItemStyles";
import { Box } from "@material-ui/core";
import { useThemeContext } from "../../theme";
import cn from "classnames";
import CopyIcon from "./assets/copy";

export type ManualItemProps = {
  subtitle: string;
  manualKey: string;
  onCopyPress: () => void;
};

const ManualItem: React.FC<ManualItemProps> = ({
  subtitle,
  manualKey,
  onCopyPress,
}) => {
  const s = useStyles();
  const { theme } = useThemeContext();

  return (
    <Box>
      <p className={cn(s.subtitle)}>{subtitle}:</p>
      <Box className={cn(s.container)}>
        <p className={cn(s.text)}>{manualKey}</p>
        <Box className={cn(s.copyButton)} onClick={onCopyPress}>
          <CopyIcon color={theme.colors.yellowMain} />
        </Box>
      </Box>
    </Box>
  );
};

ManualItem.defaultProps = {};

const MemorizedComponent = memo(ManualItem);
export { MemorizedComponent as ManualItem };
