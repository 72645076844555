import { call, spawn, take, put } from "redux-saga/effects";
import { UserSaga } from "../logic/user/UserSagas";
import { SocialSaga } from "../logic/social/SocialSagas";
import { AppSettingsSaga } from "../logic/appSettings/AppSettingsSagas";
import { GeneralSaga } from "../logic/general/GeneralSagas";
import { CryptoSaga } from "../logic/crypto/CryptoSagas";
import { StorieSaga } from "../logic/storie/StorieSagas";
import { CardsSaga } from "../logic/cards/CardsSagas";
import { NftSaga } from "../logic/nft/NftSagas";
import { UserActions } from "../logic/user/UserRedux";
import { AppSettingsActions } from "../logic/appSettings/AppSettingsRedux";
import { EsimSaga } from '../logic/esim/EsimSagas';
import { EscrowSaga } from "../logic/escrow/EscrowSagas";
import { EscrowtxConstructorSaga } from "../logic/escrowTxConstructor/EscrowTxConstructorSagas";

function* applicationStart() {
  console.log("Running applicationStart saga...");

  yield put(AppSettingsActions.getAppSettingsFromAsyncStorage());
  yield take(AppSettingsActions.appStartSettingsSetupDone);

  yield put(UserActions.runInitialSaga());
}

function* rootSaga() {
  const sagas = [
    applicationStart,
    UserSaga,
    AppSettingsSaga,
    GeneralSaga,
    CryptoSaga,
    StorieSaga,
    SocialSaga,
    CardsSaga,
    NftSaga,
    EsimSaga,
    EscrowSaga,
    EscrowtxConstructorSaga
  ];

  yield* sagas.map((saga) =>
    spawn(function* sagaGuard() {
      while (true) {
        try {
          yield call(saga);
          break;
        } catch (e) {
          console.log("rootSaga error:", e);
        }
      }
    })
  );
}

export { rootSaga };
