import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme/default/theme";

export const useStyles = makeStyles({
  globalContainer: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: 'yellow'
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 400,
    height: "100%",
    padding: 24,
    // backgroundColor: 'blue'
  },
  spaceBetween: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexGrow: 1,
    // backgroundColor: 'red',
    justifyContent: "space-between",
  },
  rowBetween: {
    display: "flex",
    flexDirection: 'row',
    width: "100%",
    justifyContent: 'space-between'
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  validationsContainer: {
    display: "flex",
    width: "100%",
    marginBottom: theme.metrics.x3,
  },
  dataPicker: {
    display: "flex",
    width: "100%",
    marginTop: theme.metrics.x3,
    marginBottom: theme.metrics.x3,
  },
});
