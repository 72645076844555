import React, { useState } from "react";
import { useStyles } from "./RegisterPageStyles";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  passwordValidations,
  validateName,
} from "../../helpers/validationHelper";
import { CONFIG } from "../../config";
import { ValidationInfo } from "../../components/ValidationInfo";
import { TextInput } from "../../components/TextInput";
import { useHideErrors } from "../../hooks/useHideErrors";
import { CustomButton } from "../../components/CustomButton";
import { FormTitle, SmallText } from "../Login/LoginPageStyles";
import { theme } from "../../theme/default/theme";
import { useDependentValue } from "../../helpers/customHooks";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../logic/user/UserSelectors";
import { generalSelector } from "../../logic/general/GeneralSelectors";
import { SettingsItem } from "../../components/SettingsItem";
import { UserActions } from "../../logic/user/UserRedux";
import { useNavigate } from "react-router-dom";
import { SupportButton } from "../../components/SupportButton";
import classNames from "classnames";

export type RegisterPageProps = {};

const RegisterPage: React.FC<RegisterPageProps> = ({}) => {
  const dispatch = useDispatch();
  const s = useStyles();
  const navigate = useNavigate();
  // @ts-ignore
  const { t } = useTranslation();

  const {
    userEmail,
    register: { fetching: fetchingRegister },
  } = useSelector(userSelector);

  const {
    listCountries: { data: countriesData },
  } = useSelector(generalSelector);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [countryId, setCountryId] = useState<string | number | undefined>(
    undefined
  );
  const [refferalCode, setRefferalCode] = useState<string>(CONFIG.DEFAULT_REFERRAL_CODE);

  const { hideErrors, setHideErrors, onInputErrorAnimationEnd } =
    useHideErrors();

  const nextBtnEnabled = useDependentValue(() => {
    return !!(
      firstName &&
      lastName &&
      password &&
      confirmPassword &&
      countryId
    );
  }, [firstName, lastName, password, confirmPassword, countryId]);

  const onPressNext = () => {
    if (
      validateName(firstName) &&
      validateName(lastName) &&
      passwordValidations.validatePassword(password) &&
      passwordValidations.validatePasswordsMatch(password, confirmPassword)
    ) {
      dispatch(
        UserActions.register.request({
          first_name: firstName,
          last_name: lastName,
          customer_type: 1,
          email: userEmail as string,
          password,
          country_id: countryId as number,
          referral_code: refferalCode,
          successCallback: () => navigate("/", { replace: true }),
        })
      );
    } else {
      setHideErrors(false);
    }
  };

  return (
    <Box className={classNames(s.globalContainer)}>
      <Box className={classNames(s.container)}>
        <FormTitle>{t("registerScreen.personalInformation")}</FormTitle>
        <Box className={classNames(s.rowBetween)}>
          <SmallText>{t("registerScreen.pleaseFill")}</SmallText>
          <SupportButton />
        </Box>

        <Box className={classNames(s.spaceBetween)}>
          <Box className={classNames(s.inputsContainer)}>
            <Box className={classNames(s.validationsContainer)}>
              <ValidationInfo
                validations={[
                  {
                    id: "v0",
                    text: t("passwordsMustMatch"),
                    ok: passwordValidations.validatePasswordsMatch(
                      password,
                      confirmPassword
                    ),
                  },
                  {
                    id: "v1",
                    text: t("passwordOneNumber"),
                    ok: passwordValidations.validateOneNumber(password),
                  },
                  {
                    id: "v2",
                    text: t("passwordOneUppercase"),
                    ok: passwordValidations.validateOneUppercase(password),
                  },
                  {
                    id: "v3",
                    text: t("passwordLengthMin"),
                    ok: passwordValidations.validatePasswordMinLength(password),
                  },
                ]}
              />
            </Box>

            <TextInput
              value={password}
              placeholder={t("password") as string}
              onChangeText={(text) => setPassword(text)}
              secure={true}
              validationOk={
                hideErrors || passwordValidations.validatePassword(password)
              }
              onErrorAnimationEnd={onInputErrorAnimationEnd}
            />
            <TextInput
              value={confirmPassword}
              placeholder={t("confirmPassword") as string}
              onChangeText={(text) => setConfirmPassword(text)}
              secure={true}
              validationOk={
                hideErrors ||
                passwordValidations.validatePasswordsMatch(
                  password,
                  confirmPassword
                )
              }
              onErrorAnimationEnd={onInputErrorAnimationEnd}
            />

            <TextInput
              value={firstName}
              placeholder={t("firstName") as string}
              onChangeText={(text) => setFirstName(text)}
              validationOk={hideErrors || validateName(firstName)}
              onErrorAnimationEnd={onInputErrorAnimationEnd}
            />
            <TextInput
              value={lastName}
              placeholder={t("lastName") as string}
              onChangeText={(text) => setLastName(text)}
              validationOk={hideErrors || validateName(lastName)}
              onErrorAnimationEnd={onInputErrorAnimationEnd}
            />

            <Box className={classNames(s.dataPicker)}>
              {countriesData?.countries && (
                <SettingsItem
                  label={t("country")}
                  labelColor={theme.colors.greySubText}
                  rightLabel={countriesData.countriesTable[countryId as any]}
                  onValueChange={(v) => setCountryId(v)}
                  picker={true}
                  items={countriesData.countries}
                  value={countryId}
                />
              )}
            </Box>

            {/* <TextInput
              value={refferalCode}
              label={t("optional") as string}
              placeholder={t("refferalCode") as string}
              onChangeText={(text) => setRefferalCode(text)}
              validationOk={hideErrors}
              onErrorAnimationEnd={onInputErrorAnimationEnd}
            /> */}
          </Box>

          <CustomButton
            title={t("buttons.next") as string}
            bgColorActive={theme.colors.yellowMain}
            bgColorUnactive={theme.colors.shark}
            defaultSizing
            onPress={() => onPressNext()}
            disabled={!nextBtnEnabled}
            spinner={fetchingRegister}
          />
        </Box>
      </Box>
    </Box>
  );
};

RegisterPage.defaultProps = {};

export { RegisterPage };
