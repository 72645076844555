import React from 'react';
import { useStyles } from './StringValueTableStyles';
import { Box } from '@material-ui/core';
import { useThemeContext } from '../../theme';

export type LabelValueItem = {
  id: string;
  label: string | number;
  value: string | number;
  valueColor?: string;
};

export type StringValueTableProps = {
  items: LabelValueItem[];
  border?: string;
  withRowBottomBorder?: boolean;
  maxWidth?: number | string;
  padding?: number;
};

const StringValueTable: React.FC<StringValueTableProps> = ({
  items,
  border,
  withRowBottomBorder = true,
  maxWidth,
  padding = 12
}) => {
  const { theme } = useThemeContext();
  const s = useStyles();

  return (
    <Box 
      className={s.detailsCont} 
      style={{
        maxWidth,
        border: border ?? `1px solid ${theme.colors.borderGrey}`,
        padding
      }}
    >
      {items.map((item, index) => (
        <Box 
          className={s.detailRow} 
          key={item.id}
          style={{borderBottom: withRowBottomBorder && index !== items.length - 1 ? `1px solid ${theme.colors.borderGrey}` : "none"}}
        >
          <span className={s.detailLabel}>
            {item.label}
          </span>
          <span 
            className={s.detailValue}
            style={{color: item.valueColor}}
          >
            {item.value}
          </span>
        </Box>
      ))}
    </Box>
  );
};

export { StringValueTable };