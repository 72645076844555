import React, { FC } from "react";
import { Button, Picture, Wrapper } from "./ItemStyled";
import { Text } from "../../../components/Text/Text";
import { text } from "../../../theme/text";

import { useThemeContext } from "../../../theme";

import i18next from "i18next";

type Props = {
  img: string;
  title: string;
  time: string;
  description: string;
  completed?: boolean;
  disabled?: boolean;
  amount: string;
  onPress?: () => void;
  onReview?: boolean;
};

const VerificationItem: FC<Props> = ({
  img,
  title,
  time,
  description,
  completed,
  disabled,
  amount,
  onReview,
  onPress,
}) => {
  const { theme } = useThemeContext();
  const { t } = i18next;

  const renderButton = () => {
    if (completed) {
      return (
        <Button bgColor={theme.colors.green}>
          <Text
            color={theme.colors.white}
            size="21px"
            fontStyle={theme.fonts.f600}
          >
            {t("buttons.completed")}
          </Text>
        </Button>
      );
    }
    if (disabled) {
      return (
        <Button bgColor={theme.colors.black}>
          <Text
            color={theme.colors.white}
            size="16px"
            fontStyle={theme.fonts.f600}
          >
            {t("verificationLevels.disabled")}
          </Text>
        </Button>
      );
    }
    if (onReview) {
      return (
        <Button bgColor={theme.colors.blackOpacityBackground}>
          <Text
            color={theme.colors.white}
            size="21px"
            fontStyle={theme.fonts.f600}
          >
            {t("buttons.inReview")}
          </Text>
        </Button>
      );
    }
    return (
      <>
        <Button bgColor={theme.colors.yellowMain} onClick={onPress}>
          <Text
            color={theme.colors.black}
            size="21px"
            fontStyle={theme.fonts.f600}
          >
            {t("buttons.increaseLimits")}
          </Text>
        </Button>
        <Text
          color={text.colors.gray}
          size="16px"
          fontStyle={theme.fonts.f400}
          margin="6px 0 0 0"
        >
          {t("verificationLevels.itWillTake", { time })}
        </Text>
      </>
    );
  };

  return (
    <Wrapper>
      <Picture src={img} alt="asd" />
      <Text
        color={theme.colors.white}
        size="21px"
        fontStyle={theme.fonts.f700}
        textAlign="center"
        margin="-25px 0 0 0"
      >
        {title}
      </Text>
      <Text
        color={theme.colors.greySubTextDesc}
        size="16px"
        fontStyle={theme.fonts.f400}
        margin="8px 0 0 0"
        textAlign="center"
      >
        {description}
      </Text>
      <Text
        color={text.colors.gray}
        size="21px"
        fontStyle={theme.fonts.f300}
        margin="18px 0 0 0"
      >
        {t("verificationLevels.limitDesc")}
      </Text>
      <Text color={theme.colors.white} size="30px" fontStyle={theme.fonts.f700}>
        {amount}
      </Text>
      {renderButton()}
    </Wrapper>
  );
};
export default VerificationItem;
