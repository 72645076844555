import React, { memo, useState, useMemo } from 'react';
import { useStyles } from './EscrowViewTxPageStyles';
import { Box } from '@material-ui/core';
import { useThemeContext } from "../../theme";
import {
  Wrapper,
} from "./../Payments/PaymentsPage/PaymentsStyle";
import { GoBackButton } from './../../components/GoBackButton';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { ExpandableBlock } from './../../components/ExpandableBlock';
import { StringValueTable, LabelValueItem } from './../../components/StringValueTable';
import { NoData } from '../../components/NoData';
import { EscrowPartnerItem } from './../../components/EscrowPartnerItem';
import { useDispatch, useSelector } from 'react-redux';
import { escrowSelector } from '../../logic/escrow/EscrowSelectors';
import { ESCROW_STATUS_COLOR_TABLE } from '../../components/EscrowTxItem';
import { CustomButton } from './../../components/CustomButton';
import AreYouSure from "../../components/Modals/AreYouSure/AreYouSure";
import EditIcon from './../../assets/icons/edit';
import DeleteIcon from './../../assets/icons/delete';
import { EscrowTxConstructorActions } from '../../logic/escrowTxConstructor/EscrowTxConstructorSlice';
import { escrowTxConstructorSelector } from '../../logic/escrowTxConstructor/EscrowTxConstructorSelectors';
import { EscrowActions } from '../../logic/escrow/EscrowRedux';

export type EscrowViewTxPageProps = {};

const EscrowViewTxPage: React.FC<EscrowViewTxPageProps> = ({}) => {
  const { theme } = useThemeContext();
  const s = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    viewingTx,
    change: {
      fetching: fetchingChangeEscrowTx
    }
  } = useSelector(escrowSelector);

  const {
    preparingTxConstructorForEditing
  } = useSelector(escrowTxConstructorSelector);

  const [confirmCancelModalOpened, setConfirmCancelModalOpened] = useState(false);

  const logs = useMemo<LabelValueItem[]>(() => (viewingTx?.logs ?? []).map((log, index) => ({
    id: `${log} ${index}`,
    label: log.date_time,
    value: log.text
  })), [viewingTx?.logs]);

  const onClickCancelTx = () => {
    if (!viewingTx)
      return;

    setConfirmCancelModalOpened(true);
  }

  const handleCancelTx = () => {
    setConfirmCancelModalOpened(false);

    if (!viewingTx)
      return;

    dispatch(EscrowActions.change.request({
      id: viewingTx.id,
      type: "Status",
      new_status: {
        status: "Cancelled"
      },
      successCallback: () => navigate("/main/escrow")
    }));
  }

  const onClickEditTx = () => {
    if (!viewingTx)
      return;
    
    dispatch(EscrowTxConstructorActions.prepareTxConstructorForEditing({
      tx: viewingTx,
      successCallback: () => navigate(`/main/escrow-create-tx/${viewingTx.id}`)
    }));
  }

  return (
    <Wrapper>
      <Box className={cn(s.container)}>
        <Box className={s.top}>
          <GoBackButton onPress={() => navigate("/main/escrow")}/>

          {(!!viewingTx && viewingTx.status === "Pending") &&
            <Box className={s.rowCentered}>
              <Box>
                <CustomButton 
                  alt='Cancel transaction'
                  onPress={onClickCancelTx}
                  icon={<DeleteIcon />}
                  bgColorActive={theme.colors.transparent}
                  spinner={fetchingChangeEscrowTx}
                />
              </Box>
              <Box>
                <CustomButton 
                  alt='Edit transaction'
                  onPress={onClickEditTx}
                  icon={<EditIcon />}
                  bgColorActive={theme.colors.transparent}
                  spinner={preparingTxConstructorForEditing}
                />
              </Box>
            </Box>
          }
        </Box>

        {viewingTx ?
          <>
            <ExpandableBlock 
              label={"Summary"}
            >
              <span className={s.mainText}>
                {viewingTx.title}
              </span>
              <span 
                className={s.secondaryText} 
                style={{marginTop: theme.metrics.x3, marginBottom: theme.metrics.x3}}
              >
                {viewingTx.description}
              </span>

              <StringValueTable 
                items={[
                  {
                    id: "txStatus",
                    label: "Status",
                    value: viewingTx.status,
                    valueColor: ESCROW_STATUS_COLOR_TABLE[viewingTx.status]
                  },
                  {
                    id: "transferId",
                    label: "Transfer ID",
                    value: viewingTx.transfer_id
                  }
                ]}
                maxWidth={550}
              />
            </ExpandableBlock>

            {/* <ExpandableBlock 
              label={"Files"}
              marginTop={theme.metrics.x4}
            >
              <NoData 
                text={"Nothing found"}
              />
            </ExpandableBlock> */}

            <ExpandableBlock 
              label={"Partners"}
              marginTop={theme.metrics.x4}
            >
              <Box className={s.escrowPartnersCont}>
                {viewingTx.partners.map((partner) => (
                  <EscrowPartnerItem 
                    key={`partner_${partner.customer_id}`}
                    escrowPartner={partner}
                  />
                ))}
              </Box>
            </ExpandableBlock>

            <ExpandableBlock 
              label={"Logs"}
              marginTop={theme.metrics.x4}
            >
              <StringValueTable 
                items={logs}
              />
            </ExpandableBlock>
          </>
        :
          <NoData />
        }
      </Box>

      {confirmCancelModalOpened && (
        <AreYouSure
          func={handleCancelTx}
          //fetch={closeFetch}
          setModal={setConfirmCancelModalOpened}
          // success={ticketSuccess}
          description={`Are you sure you want to cancel transaction${viewingTx?.title ? ` "${viewingTx.title}"` : ""}?`}
          yes={{ text: "Yes", color: theme.colors.red }}
          no={{ text: "No", color: theme.colors.green }}
        />
      )}
    </Wrapper>
  );
};


const MemorizedComponent = memo(EscrowViewTxPage);
export { MemorizedComponent as EscrowViewTxPage };