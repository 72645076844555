import i18next from "i18next";
import { ContentBox } from "../../../../components/ContentBox/ContentBox";
import { Text } from "../../../../components/Text/Text";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import { useThemeContext } from "../../../../theme";
import { SelectButton } from "../../../Payments/MethodOptional/MethodOptianalStyles";
import { Soon, ToggleButton, ToggleCircle } from "../../ProfileStyles";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appSettingsSelector } from "../../../../logic/appSettings/AppSettingsSelectors";
import {
  AppSettingsActions,
  CurrentLangT,
} from "../../../../logic/appSettings/AppSettingsRedux";
import i18n from "../../../../i18n/i18n";
import { CurrencyT } from "../../../../types/types";
import styled from "styled-components";
import { LANGUAGES_DATA, LANGUAGE_LABEL } from "../../../../data/languages";
import { CURRENCY_DATA, CURRENCY_LABEL } from "../../../../data/currency";
import { useDependentState } from "../../../../helpers/customHooks";
import { userSelector } from "../../../../logic/user/UserSelectors";
import { UserActions } from "../../../../logic/user/UserRedux";

type ToggleState = {
  email: boolean;
};
type OpenSelect = {
  currency: boolean;
  lang: boolean;
};

const Settings = () => {
  const { theme } = useThemeContext();
  const windowWidth = useWindowWidth();
  const { t } = i18next;
  const dispatch = useDispatch();

  const { currentLang, currency: currentCurrency } = useSelector(appSettingsSelector);

  const {
    whoAmI: {
      data: profile
    }
  } = useSelector(userSelector);

  const [openSelect, setOpenSelect] = useState<OpenSelect>({
    currency: false,
    lang: false,
  });
  const [toggle, setToggle] = useState<ToggleState>({
    email: false
  });

  const [notificationsEnabled, setNotificationsEnabled] = useDependentState(() => {
    return profile?.notification?.app ?? false;
  }, [profile]);

  const handleNotificationToogle = async () => {
    if (!profile)
      return;

    dispatch(UserActions.setNotifications.request({
      id: profile.user_id,
      notification: {...profile.notification, app: !notificationsEnabled},
      manual: true
    }));
    setNotificationsEnabled(!notificationsEnabled);
  };

  const onLanguageSelect = (language: CurrentLangT) => {
    if (language) {
      i18n.changeLanguage(language);
      dispatch(AppSettingsActions.currentLang(language));
      setOpenSelect((prevState) => ({
        ...prevState,
        lang: false,
      }));
    }
  };

  const onCurrencySelect = (currency: CurrencyT) => {
    if (currency) {
      dispatch(AppSettingsActions.setCurrency(currency));
      setOpenSelect((prevState) => ({
        ...prevState,
        currency: false,
      }));
    }
  };
  return (
    <>
      <ContentBox
        width="100%"
        columnGap="24px"
        backColor="none"
        alignItems="flex-start"
        direction={windowWidth > 650 ? "row" : "column"}
      >
        {/*левая часть настроек */}
        <ContentBox direction="column" backColor="none" width="100%">
          {/* первый селект */}
          <Text
            color={theme.colors.white}
            size="44px"
            fontStyle={theme.fonts.f600}
            margin=" 60px auto 40px 0"
          >
            {t("profile.settings")}
          </Text>
          <ContentBox
            padding="16px 12px"
            backColor={theme.colors.subBackground}
            justyfiContent="space-between"
            width="100%"
          >
            {openSelect.lang && (
              <SelectArea>
                {LANGUAGES_DATA.map((el, index) => (
                  <SelectItem
                    onClick={() => onLanguageSelect(el.value as CurrentLangT)}
                  >
                    {el.label}
                  </SelectItem>
                ))}
              </SelectArea>
            )}
            <Text
              color={theme.colors.greySubText}
              size="16px"
              fontStyle={theme.fonts.f400}
            >
              {t("profile.language")}
            </Text>
            <ContentBox>
              <Text
                color={theme.colors.white}
                size="21px"
                fontStyle={theme.fonts.f400}
                margin="0 16px 0 auto"
              >
                {LANGUAGE_LABEL[currentLang]}
              </Text>
              <SelectButton
                openSelect={openSelect.lang}
                onClick={() =>
                  setOpenSelect((prevState) => ({
                    ...prevState,
                    lang: !prevState.lang,
                  }))
                }
              >
                <img src="/images/Select_arrow_Down.png" alt="asd" />
              </SelectButton>
            </ContentBox>
          </ContentBox>
          {/* второй селект */}

          <ContentBox
            padding="16px 12px"
            backColor={theme.colors.subBackground}
            justyfiContent="space-between"
            width="100%"
            margin="16px 0 0 0"
          >
            {openSelect.currency && (
              <SelectArea>
                {CURRENCY_DATA.map((el, index) => (
                  <SelectItem
                    onClick={() => onCurrencySelect(el.value as CurrencyT)}
                  >
                    {el.label}
                  </SelectItem>
                ))}
              </SelectArea>
            )}
            <Text
              color={theme.colors.greySubText}
              size="16px"
              fontStyle={theme.fonts.f400}
            >
              {t("profile.primaryCurrency")}
            </Text>
            <ContentBox>
              <img
                src="/images/$.png"
                alt="asd"
                style={{ marginRight: "10px" }}
              />
              <Text
                color={theme.colors.white}
                size="21px"
                fontStyle={theme.fonts.f400}
                margin="0 16px 0 auto"
              >
                {CURRENCY_LABEL[currentCurrency]}
              </Text>
              <SelectButton
                openSelect={openSelect.currency}
                onClick={() =>
                  setOpenSelect((prevState) => ({
                    ...prevState,
                    currency: !prevState.currency,
                  }))
                }
              >
                <img src="/images/Select_arrow_Down.png" alt="asd" />
              </SelectButton>
            </ContentBox>
          </ContentBox>
          <ContentBox
            backColor="none"
            justyfiContent="space-between"
            width="100%"
            margin="20px 0 0 0"
          >
            <ContentBox direction="column" backColor="none">
              <Text
                color={theme.colors.white}
                size="21px"
                fontStyle={theme.fonts.f600}
                margin="0 auto 0 0"
              >
                {t("profile.notifiacations")}
              </Text>
            </ContentBox>
            <ToggleButton
              toggle={notificationsEnabled}
              onClick={handleNotificationToogle}
            >
              <ToggleCircle toggle={notificationsEnabled} />
            </ToggleButton>
          </ContentBox>
        </ContentBox>
        {/*правая часть настроек */}
        <ContentBox direction="column" width="100%" backColor="none">
          <Text
            color={theme.colors.white}
            size="38px"
            fontStyle={theme.fonts.f600}
            margin=" 60px auto 40px 0"
          >
            {t("profile.twoStepAuth")} (2FA)
          </Text>
          <ContentBox
            direction="column"
            backColor="none"
            width="100%"
            justyfiContent="flex-start"
            rowGap="16px"
          >
            <ContentBox
              backColor="none"
              justyfiContent="space-between"
              width="100%"
            >
              <ContentBox direction="column" backColor="none">
                <Text
                  color={theme.colors.white}
                  size="21px"
                  fontStyle={theme.fonts.f600}
                  margin="0 auto 0 0"
                >
                  {t("profile.email")}
                </Text>
                <Text
                  color={theme.colors.greySubText}
                  size="18px"
                  fontStyle={theme.fonts.f400}
                >
                  {t("profile.reciveEmail")}
                </Text>
              </ContentBox>
              <ToggleButton
                toggle={toggle.email}
                onClick={() =>
                  setToggle((prevState) => ({
                    ...prevState,
                    email: !prevState.email,
                  }))
                }
              >
                <ToggleCircle toggle={toggle.email} />
              </ToggleButton>
            </ContentBox>
            <ContentBox
              backColor="none"
              justyfiContent="space-between"
              width="100%"
            >
              <ContentBox direction="column" backColor="none" padding="30px 0">
                <Text
                  color={theme.colors.white}
                  size="21px"
                  fontStyle={theme.fonts.f600}
                  margin="0 auto 0 0"
                >
                  {t("profile.googleAuth")}
                </Text>
              </ContentBox>
              <Soon>
                <Text color="#444A4D" size="21px" fontStyle={theme.fonts.f600}>
                  {t("profile.soon")}
                </Text>
              </Soon>
            </ContentBox>
          </ContentBox>
        </ContentBox>
      </ContentBox>
    </>
  );
};
export default Settings;

const SelectArea = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px 0;
  width: 100%;
  row-gap: 10px;
  height: 100px;
  border-top: 1px solid ${(p) => p.theme.colors.borderGrey};
  background-color: ${(p) => p.theme.colors.subBackground};
  left: 0;
  top: 65px;
  z-index: 1;
  overflow-x: hidden;
  /* Стили скролла */
  scrollbar-width: thin;
  scrollbar-color: ${(p) => p.theme.colors.scrollbar};

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${(p) => p.theme.colors.greySubText};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.colors.white};
    border-radius: 3px;
  }
`;
const SelectItem = styled.div`
  font-size: 21px;
  cursor: pointer;
  color: ${(p) => p.theme.colors.white};
  @media (max-width: 700px) {
    font-size: 16px;
  }

  &:hover {
    color: ${(p) => p.theme.colors.yellowMain};
  }
`;
