import i18next from "i18next";
import { SliderItem } from "../LoginPage";
const { t } = i18next;

export const sliderData: SliderItem[] = [
  {
    title: "loginPage.slider.title1",
    text: "loginPage.slider.text1",
    img: "/images/slider/0.svg",
  },
  {
    title: "loginPage.slider.title2",
    text: "loginPage.slider.text2",
    img: "/images/slider/1.svg",
  },
  {
    title: "loginPage.slider.title3",
    text: "loginPage.slider.text3",
    img: "/images/slider/2.svg",
  },
  {
    title: "loginPage.slider.title4",
    text: "loginPage.slider.text4",
    img: "/images/slider/3.svg",
  },
];

