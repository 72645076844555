import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    height: theme.metrics.x12 * 2,
    width: theme.metrics.x12 * 2,
    backgroundColor: theme.colors.black,
    borderRadius: theme.metrics.x3,
    border: `1px solid ${theme.colors.yellowMain}`,
    marginHorizontal: theme.metrics.x,
    cursor: 'pointer'
  },
  imageCont: {
    display: 'flex',
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  image: {
    display: 'flex',
    flexDirection: 'column',
    height: theme.metrics.x12 * 2 - 1,
    width: theme.metrics.x12 * 2 - 1,
    borderRadius: theme.metrics.x3,
    objectFit: 'cover'
  },
  emptyCont: {
    display: 'flex',
    width: 28,
    height: 28,
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  deleteBtn: {
    display: 'flex',
    width: 24,
    height: 24,
    cursor: 'pointer',
    padding: 4,
    borderRadius: 12,
    border: `1px solid ${theme.colors.red}`,
    zIndex: 2,
    position: 'absolute',
    backgroundColor: theme.colors.black,
    right: -12,
    top: -12
  },
  input: {
    display: "flex",
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0,
    // backgroundColor: 'red',
    cursor: "pointer"
  }
});