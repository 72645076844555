import { makeStyles } from '@material-ui/core/styles';
import { theme } from "../../theme/default/theme";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column"
    // width: "100%",
    // height: "100%",
    // backgroundColor: 'yellow'
  },
  top: {
    display: "flex",
    width: "100%",
    // height: 200,
    // backgroundColor: 'red',
    marginBottom: theme.metrics.x8
  },
  newTxBtnCont: {
    display: "flex",
    width: 200,
  },
  transactionsCont: {
    display: "flex",
    // flexDirection: "column",
    // flex: 1,
    // width: "100%",
    // height: "100%",
    // backgroundColor: "blue",
    flexWrap: "wrap",
    // justifyContent: 'space-between',
    gap: theme.metrics.x4,
    overflowY: "scroll",
    overflowX: "hidden",
    // margin-top: 12px;
    // border-radius: 8px;
  }
});
