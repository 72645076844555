import React, { memo } from 'react';
import { useStyles } from './WalletInfoStyles';
import { Box } from '@material-ui/core';
import { QRCodeItem } from "../../../../../components/QRCodeItem";
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useCryptoAccountTopUp } from '../../../../../hooks/useCryptoAccountTopUp';
import { useSelector } from 'react-redux';
import { cryptoSelector } from '../../../../../logic/crypto/CryptoSelectors';
import { WalletItem } from '../../../../../components/WalletItem';
import { CONFIG } from '../../../../../config';
import { prettyStringToNumber } from '../../../../../helpers/formatHelpers';

export type WalletInfoProps = {};

const WalletInfo: React.FC<WalletInfoProps> = ({}) => {
  const s = useStyles();
  // @ts-ignore
  const { t } = useTranslation();

  const { paymentsPageSelectedAccount } = useSelector(cryptoSelector);

  const isCurrencyTypeCrypto = paymentsPageSelectedAccount?.currency_type === "Crypto";

  const { 
    QRCodesTable, 
    generatingCode, 
    accountsOptions,
    optionValueToLabelTable,
    accountAddress,
    setAccountAddress
  } = useCryptoAccountTopUp(paymentsPageSelectedAccount, isCurrencyTypeCrypto);

  if (!accountAddress)
    return null;

  return (
    <Box className={cn(s.globalContainer)}>
      <Box className={cn(s.container)}>
        <QRCodeItem
          loading={!QRCodesTable[accountAddress] && generatingCode}
          qr={QRCodesTable[accountAddress] ?? ""}
          manualTitle={t(isCurrencyTypeCrypto ? "blockchainAddress" : "address") as string}
          manualValue={accountAddress}
          optionsData={accountsOptions}
          onOptionValueChange={(v) => v && setAccountAddress(v as string)}
          optionsLabel={t("blockchain") as string}
          optionsRightLabel={optionValueToLabelTable[accountAddress]}
          topContent={
            paymentsPageSelectedAccount ?
            <WalletItem
              image={`${CONFIG.ZED_BASE_URL}/${paymentsPageSelectedAccount.currency_flag}`}
              userHasThisWallet={true}
              // userCurrencySymbol={getCurrencySymbol(userCurrency)}
              userCurrencySymbol="$"
              balance={paymentsPageSelectedAccount.balance * prettyStringToNumber(paymentsPageSelectedAccount.currency_price)}
              cryptoBalance={paymentsPageSelectedAccount.balance}
              name={paymentsPageSelectedAccount.currency_code}
              walletName={paymentsPageSelectedAccount.name}
            /> : undefined
          }
        />
      </Box>
    </Box>
  );
};

WalletInfo.defaultProps={}

const MemorizedComponent = memo(WalletInfo);
export { MemorizedComponent as WalletInfo };