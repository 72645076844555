import { FC } from "react";
import BlurWrapper from "../../BlurWrapper/BlurWrapper";
import styled from "styled-components";
import { templates } from "../../../theme/templates";

type Props = {
  img: string;
  setShowImage: any;
};

const ViewPicture: FC<Props> = ({ img, setShowImage }) => {
  return (
    <BlurWrapper setModal={setShowImage}>
      <PictureWrapper>
        <Picture src={img} onClick={(e) => e.stopPropagation()} />
        <CloseButton>X</CloseButton>
      </PictureWrapper>
    </BlurWrapper>
  );
};

export default ViewPicture;

const PictureWrapper = styled.div`
  position: relative;
  max-width: 90%;
  max-height: 90vh;
`;

const Picture = styled.img`
  width: 100%;
  max-height: 90vh;
  margin: 0 auto;
  object-fit: contain;
`;

const CloseButton = styled.div`
  ${templates.centerContent};
  position: absolute;
  top: -15px;
  right: -15px;
  background-color: ${(p) => p.theme.colors.subBackground};
  border: 1px solid ${(p) => p.theme.colors.yellowMain};
  color: ${(p) => p.theme.colors.yellowMain};
  width: 30px;
  height: 30px;
  border-radius: 8px;
  cursor: pointer;
  z-index: 1;
`;
