import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  spaceContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 3,
    cursor: 'pointer',
    // backgroundColor: 'green'
    // evelation: 3 // android
  },
  periodLabel: {
    textTransform: 'capitalize',
    color: theme.colors.black,
    fontFamily: theme.fonts.f600.fontFamily,
    fontSize: 15,
    margin: 0
  },
  block: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  confirmedText: {
    color: theme.colors.red,
    fontFamily: theme.fonts.f600.fontFamily,
    fontSize: theme.metrics.x3,
    padding: 2,
    margin: 0
  },
});