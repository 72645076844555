export type ObjectT<T> = {[key: string]: T};

export type CurrencyT = `usd` | `eur`;

export type PlatformLowercase = "web" | "ios" | "android";

export type CoinPriceTimestamp = {
  price: string;
  date: string;
};

export type NumberPair = [number, number];

export type WithTimestamp<T> = T & {
  timestamp: number;
};

export type BlockchainType =
  | "none"
  | "bitcoin"
  | "ethereum"
  | "binance_smart_chain"
  | "tron";

export type Country = {
  id: number;
  name: string;
  flag: string;
  phone_prefix: string;
  alpha_2: string;
  alpha_3: string;
};

export type CountryCardAvailability = {
  name: string;
  alpha_2: string;
  alpha_3: string;
  country_code: string;
  iso_3166_2: string;
  region: string;
  sub_region: string;
  intermediate_region: string;
  region_code: string;
  sub_region_code: string;
  intermediate_region_code: string;
};

export enum DocumentStatus {
  Requested = 1,
  Pending,
  Accepted,
  Rejected,
  Review
};

export type DocumentStatusString = keyof typeof DocumentStatus;

export type UserDocument = {
  date_submitted: string;
  title: string;
  file: string;
  status: DocumentStatusString;
  comment: string;
};

export type BusinessInfo = {
  entity_name: string;
  entity_type: string;
  registration_number: string;
  date_of_registration: string;
  source_of_funds: string;
  nature_of_business: string;
  application_reason: string;
  website: string;
  file_certificate_of_incorporation: string;
  file_articles_of_association: string;
  file_register_of_directors: string;
  file_register_of_member: string;
  file_sanctions_questionnaire: string;
  file_letter_of_authorization: string;
  file_current_operating_status: string;
  file_Intermediaries: string;
  behalf: string;
  file_proof_of_address: string;
  file_Supplementary: string;
  admin_comment: string;
  status: number;
};

export type ZedApiKey = {
  active: boolean;
  api_key: string;
  secret_key: string;
};

export type ZedApiNotificationInfo = {
  telegram: boolean;
  telegram_id?: string;
  whatsapp: boolean;
  app: boolean;
  sms: boolean;
  email: boolean;
};

export type TransactionType =
  | "Deposit_Requested"
  | "Withdraw_Requested"
  | "Deposit_Online"
  | "Deposit_Offline"
  | "Withdraw_Online"
  | "Withdraw_Offline"
  | "Transfer_In"
  | "Transfer_Out"
  | "Convert_In"
  | "Convert_Out"
  | "Commission"
  | "Escrow"
  | "NFT"
  | "Referral_Bonus"
  | "Local_Bank";

export type CustomerType = "Individual" | "Organization";

export type GenderBasic = "Male" | "Female";

export type Gender =
  | "Not_specified"
  | GenderBasic
  | "Non_binary"
  | "Transgender"
  | "Intersex";

export const AvailableGenders = [
  "Not_specified",
  "Male",
  "Female",
  "Non_binary",
  "Transgender",
  "Intersex",
];
export const AvailableGendersSet = new Set(AvailableGenders);

export type BankTransferLogItem = {
  date_time: string;
  title: string;
  message: string;
  file: string;
};

export type BankTransfer = {
  country: string;
  currency: string;
  payment_type: string;
  receiver_type: string;
  full_name: string;
  mobile: string;
  email: string;
  reg_number: string;
  phone: string;
  bank_name: string;
  branch_name: string;
  BIC: string;
  IBAN: string;
  message: string;
  proforma: string;
};

export type GetTransactionsItem = {
  id: number;
  date_time: string;
  transfer_id: number;
  currency_code: string;
  debit: number;
  credit: number;
  balance: number;
  transaction_type: string;
  system_remark: string;
  customer_remark: string;
  status: string;
  hash: string | null;
  details: {
    bank_transfer: BankTransfer | null;
    bank_transfer_log: BankTransferLogItem[];
  };
  details_text: string;
};

export type DropdownItemConstructor<T> = {
  title: string;
  value: string | number;
} & T;

export type ZedpayCardType = "Virtual" | "Physical";

export type ZedpayCardNetwork = "Visa" | "Master";

export type ZedpayCard = {
  id: number;
  date_time: string;
  currency_code: string;
  type: ZedpayCardNetwork;
  card_plan: string;
  rechargeable: string;
  name: string;
  balance: number;
  expires_in: string;
  details: any;
  status: "Pending" | string;
};

export type ZedpayCardFullInfo = {
  id: number;
  date_time: string;
  customer_id: number;
  account_id: number;
  first_name: string;
  last_name: string;
  initial_balance: number;
  balance: number;
  balance_update: string;
  currency: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  country: number;
  zip: string;
  phone: string;
  email: string;
  card_type: ZedpayCardType;
  network: ZedpayCardNetwork;
  card_number: string;
  card_exp_mth: string;
  card_exp_year: string;
  card_cvv: string;
  pin: string;
  status: string;
  physical: null;
};

export type KeyValueObj = {
  Key: string;
  Value: string;
};

export type ZedpayCardTransaction = {
  date_time: string;
  description: string;
  amount: string;
  auth_code: string;
};

export type CardHolderInfo = {
  customer_id: number;
  surname: string;
  amount: number;
  name: string;
  address_line1: string;
  city: string;
  state: string;
  country: number;
  zip: string;
  phone: string;
  email: string;
  network: ZedpayCardNetwork;
  referral_code: string;
};

export type FcfIdType = "Passport" | "Driver_License" | "National_ID";

export type FcfIdLanguage =
  | "English"
  | "Spanish"
  | "French"
  | "Arabic"
  | "Chinese"
  | "Japanese"
  | "Russian"
  | "Portuguese"
  | "German"
  | "Italian"
  | "Hindi"
  | "Other";

export type FcfAddressType =
  | "Credit_Card_Statement"
  | "Utility_Bill"
  | "Bank_Statement"
  | "Bank_Letter";

export type KycInfo = {
  id: {
    id: number;
    idNumber: string;
    idExpDate: string;
    idIssueDate: string;
    idType: FcfIdType;
    idDocLang: FcfIdLanguage;
    front_side: string;
    back_side: string;
  };
  address: {
    id: number;
    address_TypeId: FcfAddressType;
    address_IssuedDate: string;
    address_LangId: FcfIdLanguage;
    front_side: string;
    back_side: string;
  };
};

export type DataPickerItemType = {
  label: string;
  value: string | number | null | undefined;
};

export type PlatformBackendT = "iOS" | "android" | "web";

export type ToastMessageProps = {
  title: string;
  info: string;
  type: "success" | "error" | "warn";
};

export interface TabData {
  id: number;
  title: string;
}

export interface ButtonData {
  label: string;
  iconSrc: string;
}

export interface BoxData {
  id: number;
  title: string;
  iconSrc?: string;
  description: string;
  buttons: ButtonData[];
  autoSelect?: boolean;
}


export const FCF_ID_TABLE: {[key: string]: number} = {
  Passport: 0, 
  Driver_License: 1, 
  National_ID: 2
}
export const FCF_ID_TYPES_ARRAY = ["Passport", "Driver_License", "National_ID"];

export const FCF_ID_LABELS_ARRAY = [
  "Passport",
  "Driver License",
  "National ID",
];
export const FCF_ADDRESS_TYPE_TABLE: {[key: string]: number} = {
  Credit_Card_Statement: 0, 
  Utility_Bill: 1, 
  Bank_Statement: 2, 
  Bank_Letter: 3
}

export const FCF_ADDRESS_TYPES_ARRAY = [
  "Credit_Card_Statement",
  "Utility_Bill",
  "Bank_Statement",
  "Bank_Letter",
];

export const FCF_ADDRESS_LABELS_ARRAY = [
  "Credit Card Statement",
  "Utility Bill",
  "Bank Statement",
  "Bank Letter",
];
export const FCF_ID_LANGUAGES_ARRAY = [
  "English",
  "Spanish",
  "French",
  "Arabic",
  "Chinese",
  "Japanese",
  "Russian",
  "Portuguese",
  "German",
  "Italian",
  "Hindi",
  "Other",
];

export interface BoxData {
  id: number;
  title: string;
  iconSrc?: string;
  description: string;
  buttons: ButtonData[];
}

export type ShortCurrencyInfo = {
  id: number;
  currency_code: string;
  balance: number;
  swap_rate: number;
};

export type ShortCurrencyInfoBackRate = ShortCurrencyInfo & {
  backRate: number;
};

export type SwapReceiveTable = {
  // Key - pay_account_id
  [key: string]: ShortCurrencyInfoBackRate[];
};

export type Bank = {
  id: number;
  bank: string;
  city: string;
  branch: any;
  swift_code: string;
  TimeZone: number;
  TimeZoneName: string;
};

export type LocalBank = {
  id: number;
  country_id: number;
  country_name: string;
  currency_code: string;
  bank_name: string;
};

export type SwiftCountry = {
  id: number;
  Country: string;
  Country_Code: string;
  Details: any;
  Status: string;
};

export type CommissionType = "Fixed" | "Percentage";

export type CardCommissions = {
  virtual_issue: number;
  virtual_load: number;
  virtual_unload: number;
  virtual_maintenance: number;
  physical_issue: number;
  physical_load: number;
  physical_unload: number;
  physical_maintenance: number;
};

export type Commission = {
  transaction_type: TransactionType;
  commission_type: CommissionType;
  amount: number;
}

export type CommissionsTable = {[k in TransactionType]?: Commission};

export type NftUserWalletType = "ZedPay" | "MetaMask" | "WalletConnect";

export type NftSocialMedia = "Twitter" | "Facebook" | "Pinterest" | "YouTube" | "Instagram" | "Website" | "TikTok";

export type NftHistoryAction = "Deployed" | "Minted" | "Transferred" | "Burned" | "Minter_Added";

export type NftBidStatus = "Active" | "Accepted" | "Rejected" | "Expired" | "Cancelled" | "Archived";

export type NftTransferStatus = "Pending" | "Completed" | "Rejected" | "Cancelled";

export type NftItemFilterType = "On_Sale" | "Owned" | "Created" | "Liked" | "Sold";

export type NftPlanStatus = "Active" | "Expired" | "Disabled" | "Cancelled";

export type NftPlanLimitType = "Maximum_NFT_Items" | "Max_Collections" | "Tag_Limits" | "Royalty_Limit" | "Auction_Length";

export type NftContractStatus = "Pending" | "Ready" | "Disabled";

export type NftItemStatus = "Pending" | "Minted" | "Invisible" | "Burned" | "Archived";

export type NftBidViewTypes = "Actives" | "Archived " | "Not_Archived" | "Not_Cancell_or_Archived";

export type NftFileType = "JPG" | "PNG" | "GIF" | "SVG" | "Webp" | "MP4" | "WEBM" | "MP3" | "WAV" | "OGG";

export type NftPlan = {
  order: number;
  title: string;
  max_nft_items: number;
  max_collections: number;
  tag_limit: number;
  royalty_limit: string;
  auction_length: number;
  transfer_commission: string;
  package_duration: number;
  start_date: string;
  end_date: string;
  price: number;
  currency_id: number;
  currency_code: string;
  status: NftPlanStatus;
}

export type BlockchainAddress = {
  blockchain: number;
  address: null | string;
}

export type GeneralStatus = "Enabled" | "Disabled";

export type NftSocialMediaLink = {
  platform: NftSocialMedia;
  url: string;
};

export type NftUserInfo = {
  id: number;
  reg_date: string;
  primary_wallet_type: NftUserWalletType;
  customer: {
    id: number;
    first_name: string;
    last_name: string;
  },
  metamask: BlockchainAddress;
  walletconnect: BlockchainAddress;
  username: string;
  Bio: string;
  logo: string;
  banner: string;
  links: any[];
  followers: number;
  plans: NftPlan[];
  trend: boolean;
  badge: boolean;
  status: GeneralStatus;
  followed_by_me: boolean;
};

export type NftCategory = {
  order: number;
  title: string;
};

export type NftCollection = {
  id: number;
  created_at: string;
  nft_user: NftUserInfo;
  category: string;
  title: string;
  description: string;
  logo: string;
  cover: string;
  banner: string;
  links: any[];
  status: GeneralStatus;
  items: number;
};

export type SmartContract = {
  id: number;
  created_at: string;
  nft_user: NftUserInfo | null;
  account_id: number;
  blockchain: string;
  address_index: number;
  name: string;
  symbol: string;
  contract_address: string;
  transaction_url: string;
  price: number;
  currency_code: string;
  token_ids: any;
  status: GeneralStatus;
};

export type MetadataItem = {
  type: string;
  description: string;
};

export type TitleValuePair = {
  title: string;
  value: string;
}

export type NftOwner = {
  nft_user_id: number;
  since: string;
};

export type NftSupplyData = {
  items: number;
  creator_user_id: number;
  created_at: string;
  owners: NftOwner[];
};

export type NftTag = {
  tag: string;
};

export type NftBid = {
  date_time: string;
  nft_user_id: number;
  nft_username: string;
  nft_user_logo: string;
  nft_user_link: string;
  offer: string;
  currency_code: string;
  status: NftBidStatus;
};

export type NftHistoryItem = {
  date_time: string;
  action: NftHistoryAction;
  nft_username: string;
  nft_user_logo: string;
  nft_user_link: string;
  description: string;
};

export type NftItem =  {
  id: number;
  created_at: string;
  nft_user: NftUserInfo;
  collection: NftCollection;
  smart_contract: SmartContract;
  token_id: string;
  metadata_json: {
    "title": string;
    "type": string;
    "properties": {
      "name": MetadataItem;
      "description": MetadataItem;
      "image": MetadataItem;
    }
  },
  metadata_url: string;
  title: string;
  description: string;
  category: string;
  link: string;
  file_type: string;
  nft_file: string;
  nft_poster: string;
  supply: NftSupplyData;
  properties: TitleValuePair[];
  tags: NftTag[];
  blockchain: string;
  account_id: number;
  currency_id: number;
  currency_code: string;
  price: number;
  instant_sale: boolean;
  allow_bid: boolean;
  bid_expiry: string;
  unlock_on_purchase: boolean;
  royalties: number;
  likes: number;
  gallery: any[];
  bids: NftBid[];
  history: NftHistoryItem[];
  status: NftItemStatus;
  liked_by_me: boolean;
};

export type NftProperty = {[key: string]: string};

export type Log = {
  date_time: string;
  text: string;
};

export type EscrowPartnerRole = 'Seller' | 'Buyer' | 'Broker';

export type EscrowPaymentType = 'Will Pay' | 'Will Receive';

export type EscrowPartnerBasicInfo = {
  customer_id: number;
  account_id: number;
  role: EscrowPartnerRole;
  will_pay: number;
  will_receive: number;
  commission?: number;
}

export type EscrowPartner = EscrowPartnerBasicInfo & {
  customer_email: string;
  customer_name: string;
  currency_code: string;
  total_paid: number;
  total_received: number;
  status: 'AGREED' | 'PENDING' | string;
};

export type EscrowTransactionStatus = "Pending" | "Completed" | "Cancelled";

export type EscrowTransaction = {
  id: number;
  date_time: string;
  transfer_id: number;
  title: string;
  description: string;
  partners: EscrowPartner[];
  files: KeyValueObj[];
  logs: Log[];
  status: EscrowTransactionStatus;
};