import styled from "styled-components";
import { theme } from "../../../../theme/default/theme"; 


export const Wrapper = styled.div`
display: flex;
column-gap: 24px;
@media(max-width: 900px){
    flex-direction: column;
}
`;

export const PictureWrapper = styled.div`
padding: 20px;
background-color: ${theme.colors.subBackground};
border-radius: 24px;
width: 50%;
height: fit-content;
@media(max-width: 900px){
    width: 100%;
}
`;
export const Picture = styled.img`
width: 100%;
`;

export const Right = styled.div`
display: flex;
flex-direction: column;
width: 50%;
@media(max-width: 900px){
    width: 100%;
}

`;

export const SemiText = styled.span