import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'red'
  },
  topContentContainer: {
    display: 'flex',
    width: '100%',
    maxWidth: 400,
    // backgroundColor: 'red',
    paddingLeft: theme.metrics.x8,
    paddingRight: theme.metrics.x8,
    marginBottom: theme.metrics.x4
  },
  settingsItemContainer: {
    display: 'flex',
    width: '100%',
    maxWidth: 400,
    paddingLeft: theme.metrics.x8,
    paddingRight: theme.metrics.x8,
  },
  titleText: {
    fontFamily: theme.fonts.f600.fontFamily,
    letterSpacing: -0.3,
    textAlign: 'center',
    fontSize: theme.metrics.x4_5,
    color: theme.colors.white,
  },
  qrCodeImgCont: {
    display: 'flex',
    width: '100%',
    maxWidth: 400,
    aspectRatio: 1,
    padding: theme.metrics.x8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  qrImg: {
    width: '100%',
    height: '100%',
    borderRadius: theme.metrics.x3,
    objectFit: "cover"
  }
});