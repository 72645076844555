import { useDispatch, useSelector } from "react-redux";
import {
  Banner,
  CollectionImage,
  InfoWrapper,
  Wrapper,
} from "./NFTCollectionItemPageStyles";
import { nftSelector } from "../../../logic/nft/NftSelectors";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CONFIG } from "../../../config";
import { getBidTimerSeconds, getNftImage } from "../../../helpers/nftHelpers";
import { Text } from "../../../components/Text/Text";
import { useThemeContext } from "../../../theme";
import { NftCollection, NftItem } from "../../../types/types";
import Person from "../NFTItemPage/InfoNFT/components/Person";
import { CardsWrapper } from "../NFTItemPage/NFTItemPageStyle";
import CardNFT from "../../../components/CardNFT/CardNFT";
import { NftActions } from "../../../logic/nft/NftRedux";
import i18next from "i18next";
import { BLOCKCHAIN_SHORT_NAME_TABLE } from "../../../data/zedpay";

const NFTCollectionItemPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = i18next;
  const [collectionItem, setCollectionItem] = useState<NftCollection | null>(
    null
  );
  const [nftListFiltred, setNftListFiltred] = useState<NftItem[] | null>(null);
  const { theme } = useThemeContext();

  const {
    listCollections: { data: collectionList },
    listNfts: { data: nftList },
  } = useSelector(nftSelector);

  const editCollection = () => {
    navigate("/nft/create-collection", { state: collectionItem });
  };

  useEffect(() => {
    if (collectionList) {
      const idNumber = id ? parseInt(id, 10) : undefined;

      const filteredNft = collectionList.filter(
        (el) => String(el.id) === String(idNumber)
      );

      if (filteredNft.length > 0) {
        setCollectionItem(filteredNft[0]);
      }
    }
  }, [id, collectionList, nftList]);
  useEffect(() => {
    if (collectionItem && nftList) {
      const filteredNft = nftList.filter(
        (el) => String(el.collection?.title) === String(collectionItem.title)
      );

      setNftListFiltred(filteredNft);
    }
  }, [collectionItem, nftList]);
  useEffect(() => {
    dispatch(NftActions.listNfts.request({}));
  }, []);
  return (
    <Wrapper>
      <Banner bg={`${CONFIG.ZED_BASE_URL}${collectionItem?.banner}`}>
        <CollectionImage
          image={`${CONFIG.ZED_BASE_URL}${collectionItem?.logo}`}
        ></CollectionImage>
      </Banner>
      <InfoWrapper>
        <Text color={theme.colors.white} size="32px" margin="10px 0 0 0">
          {collectionItem?.title}
        </Text>
        <Text color={theme.colors.greySubText} size="26px" margin="10px 0 0 0">
          {collectionItem?.description}
        </Text>
        <Person
          img={`${CONFIG.ZED_BASE_URL}${collectionItem?.nft_user.logo}`}
          name={collectionItem?.nft_user.username}
          title="Owner"
        />
        <Text color={theme.colors.white} size="26px" margin="24px 0 0 0">
          {t("createNftCollectionScreen.category")}: {collectionItem?.category}
        </Text>
        <Text color={theme.colors.white} size="26px" margin="40px 0 60px 0">
          {t("nftPlansScreen.nfts")}: {collectionItem?.items}{" "}
          {t("createNftCollectionScreen.items")}
        </Text>
      </InfoWrapper>
      {collectionItem?.items !== undefined && collectionItem?.items > 0 && (
        <CardsWrapper style={{ padding: "0 24px" }}>
          {nftListFiltred?.map((el, index) => (
            <CardNFT
              currency_code={el.currency_code}
              currency_id={el.currency_id}
              likedByMe={el.liked_by_me}
              key={index}
              href={`/nft/item/${el.id}`}
              img={`${CONFIG.ZED_BASE_URL}${getNftImage(el)}`}
              name={el.nft_user.username}
              title={el.title}
              verif={false}
              avatar={`${CONFIG.ZED_BASE_URL}${el.nft_user.logo}`}
              bottomLeftImage={`${CONFIG.ZED_BASE_URL}${CONFIG.ZED_ASSETS_PATH.NFT_FILES}${BLOCKCHAIN_SHORT_NAME_TABLE[el.blockchain.toUpperCase()]}.png`}
              timer={getBidTimerSeconds(el)}
              price={el.price}
            />
          ))}
        </CardsWrapper>
      )}
      <Text
        color={theme.colors.yellowMain}
        size="32px"
        fontStyle={theme.fonts.f600}
        margin="24px auto 50px auto"
        func={() => editCollection()}
      >
        {t("buttons.edit")}
      </Text>
    </Wrapper>
  );
};
export default NFTCollectionItemPage;
