import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../../../theme/default/theme';

export const useStyles = makeStyles({
  inputsRow: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    // backgroundColor: 'green'
  },
  inputCont: {
    display: 'flex',
    flexDirection: 'column',
    width: '43%',
    alignItems: 'center'
  },
  swapBtn: {
    display: 'flex',
    width: 40,
    height: 40,
    // backgroundColor: 'blue'
  },
  topLabel: {
    fontFamily: theme.fonts.f400.fontFamily,
    color: theme.colors.greySubText,
    margin: 0
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.metrics.x2
  }
});