import React, { memo } from 'react';
import { Box, CircularProgress } from '@material-ui/core';

export type LoaderCenteredProps = {
  size?: number;
  bgColor?: string;
};

const LoaderCentered: React.FC<LoaderCenteredProps> = ({
  size,
  bgColor
}) => {

  return (
    <Box style={{display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: bgColor}}>
      <CircularProgress size={size}/>
    </Box>
  );
};

LoaderCentered.defaultProps={
  size: 20
}

const MemorizedComponent = memo(LoaderCentered);
export { MemorizedComponent as LoaderCentered };