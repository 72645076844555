import { useDispatch, useSelector } from "react-redux";
import { nftSelector } from "../../../logic/nft/NftSelectors";
import EmptyPage from "./components/EmtyPage";
import CreateNFTCollectionPage from "../CreateNFTCollection/CreateNFTCollection";
import { Text } from "../../../components/Text/Text";
import { useThemeContext } from "../../../theme";
import CardNFT from "../../../components/CardNFT/CardNFT";
import { CONFIG } from "../../../config";
import styled from "styled-components";
import { Wrapper } from "../NFTItemPage/NFTItemPageStyle";
import { LoaderContainer } from "../NFTCatalogPage/NFTCatalogPageStyles";
import LoaderRing from "../../../helpers/Loader/LoaderRing";
import { useEffect } from "react";
import { NftActions } from "../../../logic/nft/NftRedux";
import { userIdSelector } from "../../../logic/user/UserSelectors";
import { TopContainer } from "../NFTHomePage/NFTHomePageStyle";
import { Btn } from "../../../components/Button/Btn";
import { templates } from "../../../theme/templates";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";

const NFTMyCollectionPage = () => {
  const { theme } = useThemeContext();
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);
  const navigate = useNavigate();
  const { t } = i18next;

  const { myNftCollections, fetchingMyNftCollections } =
    useSelector(nftSelector);

  useEffect(() => {
    dispatch(
      NftActions.listCollections.request({
        customer_id: userId,
      })
    );
  }, []);

  return (
    <Wrapper>
      {fetchingMyNftCollections ? (
        <LoaderContainer>
          <LoaderRing color={theme.colors.yellowMain} size="46px" />
        </LoaderContainer>
      ) : myNftCollections ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "24px",
            }}
          >
            <Text
              color={theme.colors.white}
              size="48px"
              fontStyle={theme.fonts.f500}
            >
              {t("myNftCollectionsScreen.headerTitle")}
            </Text>
            <Button onClick={() => navigate("/nft/create-collection")}>
              <Text
                color={theme.colors.black}
                size="24px"
                fontStyle={theme.fonts.f500}
                textAlign="center"
              >
                {t("myNftCollectionsScreen.createCollection")}
              </Text>
            </Button>
          </div>

          <GridContainer>
            {/* @ts-ignore */}
            {myNftCollections?.map((item, index) => (
              <CardNFT
                likedByMe={false}
                key={index}
                href={`/nft/item-collection/${item.id}`}
                isCollection
                img={`${CONFIG.ZED_BASE_URL}${item?.logo}`}
                name={item.nft_user.username}
                title={item.title}
                verif={item.nft_user.badge}
                avatar={`${CONFIG.ZED_BASE_URL}${item.nft_user?.logo}`}
                counterCollection={item.items}
              />
            ))}
          </GridContainer>
        </>
      ) : (
        <EmptyPage fetchingProfile={false} fetchingCreate={false} />
      )}
    </Wrapper>
  );
};
export default NFTMyCollectionPage;
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  width: 100%;
  overflow-x: hidden;
  @media (max-width: 656px) {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }
  @media (max-width: 400px) {
    grid-template-columns: repeat(1, minmax(150px, 1fr));
  }

  & > div {
    width: 100%;
  }
`;

const Button = styled.div`
  width: 40%;
  border-radius: 12px;
  background-color: ${(p) => p.theme.colors.yellowMain};
  height: 48px;
  ${templates.centerContent};
  cursor: pointer;
`;
