import styled from "styled-components";
import { templates } from "../../../theme/templates";
import { Text } from "../../../components/Text/Text";
import { selectData } from "../mock";
import { theme } from "../../../theme/default/theme";
import { useState } from "react";

const MarketSelector = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <Wrapper>
      {selectData.map((el, index) => (
        <Item key={index} onClick={() => setActiveIndex(index)}>
          <Text
            color={
              activeIndex === index
                ? theme.colors.white
                : theme.colors.greySubText
            }
            fontStyle={
              activeIndex === index ? theme.fonts.f600 : theme.fonts.f400
            }
            size="16px"
          >
            {el}
          </Text>
        </Item>
      ))}
    </Wrapper>
  );
};
export default MarketSelector;
const Wrapper = styled.div`
  display: flex;
  column-gap: 8px;
  margin-top: 24px;
`;
const Item = styled.div`
  padding: 10px;
  ${templates.centerContent};
  cursor: pointer;
`;
