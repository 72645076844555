import styled, { css } from "styled-components";

export const Wrapper = styled.div<{active: boolean}>`
border-radius: 16px;
background-color: ${p => p.theme.colors.subBackground};
display: flex;
flex-direction: column;
align-items: center;
row-gap: 12px;
padding: 12px;
${p => p.active && css`
border: 2px solid ${p =>p.theme.colors.yellowMain};
`}
`;
export const Picture = styled.img`
width: 70%;

`;