import { useEffect, useState } from "react";
import { validateLink, validateNftBio, validateNftUsername } from "../../../../helpers/validationHelper";
import { NftSocialMedia, NftSocialMediaLink } from "../../../../types/types";
import { useDispatch, useSelector } from "react-redux";
import { nftSelector } from "../../../../logic/nft/NftSelectors";
import { prepareImageName } from "../../../../helpers/formatHelpers";
import { NftActions } from "../../../../logic/nft/NftRedux";
import { userIdSelector } from "../../../../logic/user/UserSelectors";
import { useHideErrors } from "../../../../hooks/useHideErrors";

const DEFAULT_LINKS_STATE: {[media in NftSocialMedia]: string} = {
    "Facebook": "",
    "Instagram": "",
    "Pinterest": "",
    "TikTok": "",
    "Twitter": "",
    "Website": "", 
    "YouTube": ""
  };

export const useNFTProfile = () => {
    const [variant, setVariant] = useState<"edit" | "view">("view");
    const [username, setUsername] = useState("");
    const [bio, setBio] = useState("");
    const [linksInner, setLinksInner] = useState(DEFAULT_LINKS_STATE);
    const [images, setImages] = useState<{
        logo: File | null;
        banner: File | null
    }>({
        logo: null,
        banner: null
    })
    const [profileVisible, setProfileVisible] = useState(true)
    const dispatch = useDispatch()
    const userId = useSelector(userIdSelector)
    
    const {
        nftProfile,
        viewingNftProfile,
        fetchingNftProfile,
        editNftProfile: {fetching: fetchingEdit}
      } = useSelector(nftSelector);

    const { setHideErrors } = useHideErrors()

    const setLink = (media: NftSocialMedia, value: string) => {
        setLinksInner((v) => ({...v, [media]: value.toLowerCase()}));
      }
    const setFilledLinks = (links: NftSocialMediaLink[]) => {
      for (const l of links)
        setLinksInner((v) => ({...v, [l.platform]: l.url}));
    }

    const getLinksArray = (): NftSocialMediaLink[] => {
      return (Object.keys(linksInner) as Array<NftSocialMedia>)
        .map((media) => ({platform: media, url: linksInner[media]}))
        .filter((link) => link.url.length > 0)
    }

    const validateLinks = () => {
        for (const media of Object.keys(linksInner) as Array<NftSocialMedia>)
          if (linksInner[media].length > 0 && !validateLink(linksInner[media]))
            return false;
    
        return true;
      }

      useEffect(() => {
        dispatch(NftActions.listNftUsers.request({
          customer_id: userId,
          fetchAdditionalInfoOnSuccess: true
          
        }));
        dispatch(NftActions.listCategories.request());
     
      }, []);

      useEffect(() => {
        if (nftProfile) {
          
          setUsername(nftProfile.username);
          setBio(nftProfile.Bio);
          setFilledLinks(nftProfile.links ?? []);
          setProfileVisible(nftProfile.status === "Enabled");
        }
      }, [nftProfile]);




    return {
        links: linksInner,
        variant,
        setVariant,
        setLink,
        profileVisible,
        setProfileVisible,
        images,
        setImages,
        setUsername,
        username,
        setBio,
        bio,
        validateLinks,
        getLinksArray,
        // onPressSave,
        fetchingEdit,
        nftProfile,
        userId,
        viewingNftProfile
    }
}
