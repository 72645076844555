import { CoinPriceTimestamp, NumberPair } from "./../types/types";
import { scaleLinear } from "d3-scale";
import * as shape from "d3-shape";

// This func returns path of the line and some other useful data for coinTimeStamps
export const buildLineChart = (data: CoinPriceTimestamp[], width: number, height: number) => {
  if (!data)
    return null;

  const formattedValues = data.map((cp: CoinPriceTimestamp) => [parseFloat(cp.price), parseInt(cp.date) / 1e3] as NumberPair);
  const lastIdx = formattedValues.length - 1;
  const prices = formattedValues.map((v: NumberPair) => v[0]);
  const dates = formattedValues.map((v: NumberPair) => v[1]);
  const dateFrom = dates[lastIdx];
  const dateTo = dates[0];
  const scaleX = scaleLinear().domain([dateFrom, dateTo]).range([width, 0]);
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);
  const scaleY = scaleLinear().domain([minPrice, maxPrice]).range([height, 0]);
  const startPriceOfPeriod = prices[0];
  const finalPriceOfPeriod = prices[lastIdx];

  return {
    minPrice,
    maxPrice,
    pricesNumbers: prices,
    startPriceOfPeriod,
    finalPriceOfPeriod,
    dateFrom,
    dateTo,
    path: shape
      .line()
      .x(([, x]) => scaleX(x) as number)
      .y(([y]) => scaleY(y) as number)
      .curve(shape.curveBasis)(formattedValues) as string
  };
};

export const buildLineChartNumberPair = (data: NumberPair[], width: number, height: number) => {
  if (!data)
    return null;

  const formattedValues = data.map((np: NumberPair) => [np[1] / 1e3, np[0]] as NumberPair);
  const lastIdx = formattedValues.length - 1;
  const prices = formattedValues.map((v: NumberPair) => v[0]);
  const dates = formattedValues.map((v: NumberPair) => v[1]);
  const dateFrom = dates[lastIdx];
  const dateTo = dates[0];
  const scaleX = scaleLinear().domain([dateFrom, dateTo]).range([width, 0]);
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);
  const scaleY = scaleLinear().domain([minPrice, maxPrice]).range([height, 0]);
  const startPriceOfPeriod = prices[0];
  const finalPriceOfPeriod = prices[lastIdx];

  return {
    minPrice,
    maxPrice,
    pricesNumbers: prices,
    startPriceOfPeriod,
    finalPriceOfPeriod,
    dateFrom,
    dateTo,
    path: shape
      .line()
      .x(([, x]) => scaleX(x) as number)
      .y(([y]) => scaleY(y) as number)
      .curve(shape.curveBasis)(formattedValues) as string
  };
};