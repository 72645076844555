import styled from "styled-components";
import { theme } from "../../../theme/default/theme";
import { Text } from "../../../components/Text/Text";
import { FC } from "react";
import { BlockData } from "../mock";

const MarketItemBlock: FC<BlockData> = ({
  hoursVolume,
  icon,
  percents,
  secondValue,
  title,
  value,
}) => {
  return (
    <Wrapper>
      <Container>
        <Coin src={icon} />
        <Text
          color={theme.colors.white}
          fontStyle={theme.fonts.f600}
          size="18px"
          margin="0 auto 0 0"
        >
          {title}
        </Text>
        <Text
          color={theme.colors.green}
          fontStyle={theme.fonts.f600}
          size="14px"
          margin="3px 0 0 0"
        >
          {percents}
        </Text>
      </Container>

      <Container>
        <Text
          color={theme.colors.white}
          fontStyle={theme.fonts.f600}
          size="32px"
        >
          {value}
        </Text>
        <Text
          color={theme.colors.greySubText}
          fontStyle={theme.fonts.f400}
          size="14px"
          margin="10px 0 0 0"
        >
          {secondValue}
        </Text>
      </Container>

      <Container>
        <Text
          color={theme.colors.greySubText}
          fontStyle={theme.fonts.f400}
          size="14px"
        >
          {hoursVolume}
        </Text>
        <Button src="/images/markets/arrow.svg" alt="image" />
      </Container>
    </Wrapper>
  );
};
export default MarketItemBlock;

const Wrapper = styled.div`
  border-radius: 16px;
  background-color: ${theme.colors.subBackground};
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 318px;
  box-sizing: border-box;
`;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  white-space: nowrap;
`;
const Button = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
const Coin = styled.img`
  width: 16px;
  height: 16px;
  margin-bottom: 2px;
`;
