import React, { memo } from 'react';
import { useStyles } from './StringValueItemStyles';
import { Box } from '@material-ui/core';
import cn from 'classnames';
import { theme } from '../../theme/default/theme';

export type StringValueItemProps = {
  value: string;
  label: string;
  valueColor?: string;
  labelColor?: string;
  customHeight?: number;
  borderColor?: string;
};

const StringValueItem: React.FC<StringValueItemProps> = ({
  label,
  value,
  customHeight,
  borderColor,
  labelColor,
  valueColor
}) => {
  const s = useStyles();

  return (
    <Box className={cn(s.container)} style={{height: customHeight, borderBottom: `1px solid ${borderColor}`}}>
      <p className={cn(s.label)} style={{color: labelColor}}>
        {label}
      </p>

      <p className={cn(s.value)} style={{color: valueColor}}>
        {value}
      </p>
    </Box>
  );
};

StringValueItem.defaultProps={
  customHeight: 50,
  borderColor: theme.colors.borderGrey,
  labelColor: theme.colors.greySubText,
  valueColor: theme.colors.white
}

const MemorizedComponent = memo(StringValueItem);
export { MemorizedComponent as StringValueItem };