import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container: {
    display: "flex",
    height: 32,
    maxWidth: '55%',
    backgroundColor: theme.colors.yellowMain,
    borderRadius: theme.metrics.x4,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.metrics.x,
    paddingBottom: theme.metrics.x,
    paddingLeft: theme.metrics.x2,
    paddingRight: theme.metrics.x2,
  },
  timerText: {
    display: "flex",
    color: theme.colors.black,
    fontFamily: theme.fonts.f400.fontFamily,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingTop: theme.metrics.x025,
    margin: 0
  }
});