import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDependentValue, useDependentState } from './../helpers/customHooks';
import { CryptoAccount } from './../logic/crypto/CryptoRedux';
import { GeneralActions } from './../logic/general/GeneralRedux';
import { generalSelector } from './../logic/general/GeneralSelectors';
import { BLOCKCHAIN_NAME_TABLE } from '../data/zedpay';

export const useCryptoAccountTopUp = (accountData: CryptoAccount | undefined | null, withNetworkOptions: boolean = false) => {
  const dispatch = useDispatch();
  // const navigation = useNavigation<any>();

  const {
    QRCodesTable,
    generateQRCode: {
      fetching: generatingCode
    }
  } = useSelector(generalSelector);

  const [qrModalVisible, setQrModalVisible] = useState(false);

  const accountsOptions = useDependentValue(() => {
    if (withNetworkOptions && accountData?.address)
      return accountData.address.map((address) => ({...address, label: BLOCKCHAIN_NAME_TABLE[address.blockchain], value: address.address}));

    return [];
  }, [accountData, withNetworkOptions]);

  const optionValueToLabelTable = useDependentValue(() => {
    if (withNetworkOptions && accountData?.address) {
      const table: {[key: string]: string} = {};
      for (const address of accountData.address)
        table[address.address] = BLOCKCHAIN_NAME_TABLE[address.blockchain];

      return table;
    }

    return {};
  }, [accountData, withNetworkOptions]);

  const [accountAddress, setAccountAddress] = useDependentState<string | undefined>(() => {
    if (accountData?.address[0])
      return accountData.address[0].address;

    return undefined;
  }, [accountData]);

  const requestGenerateQR = useCallback((address: string) => {
    if (!QRCodesTable[address])
      dispatch(GeneralActions.generateQRCode.request({text: address}));
  }, [QRCodesTable]);

  const handleCryptoAccountTopUpPress = useCallback(() => {
    if (accountAddress) 
      requestGenerateQR(accountAddress);

    setQrModalVisible(true);
  }, [accountAddress, QRCodesTable]);

  const handleFiatAccountTopUpPress = useCallback(() => {
    // navigation.navigate("DepositOfflineScreen", {accountData});
  }, [accountData]);
  
  const handleAccountTopUpPress = useCallback(() => {
    if (accountData) {
      if (accountData.currency_type === "Crypto") {
        handleCryptoAccountTopUpPress();
      } else {
        handleFiatAccountTopUpPress();
      }
    }
  }, [accountData, handleCryptoAccountTopUpPress, handleFiatAccountTopUpPress]);

  useEffect(() => {
    if (withNetworkOptions && accountAddress)
      requestGenerateQR(accountAddress);
  }, [withNetworkOptions, accountAddress]);

  return { 
    QRCodesTable,
    generatingCode,
    qrModalVisible,
    setQrModalVisible,
    accountsOptions,
    optionValueToLabelTable,
    accountAddress,
    setAccountAddress,
    handleCryptoAccountTopUpPress,
    handleFiatAccountTopUpPress,
    handleAccountTopUpPress 
  };
}