import { FC, useState } from "react";
import { Text } from "../../../../components/Text/Text";
import { useThemeContext } from "../../../../theme";
import {
  ButtonOpen,
  Icon,
  Input,
  ItemContainer,
  MaskInput,
  Select,
  SelectArea,
  SelectItem,
} from "./ItemStyles";

type Props = {
  variant: "viewing" | "edit";
  label: string;
  value?: any;
  id: string;
  inputHandler?: any;
  select?: boolean;
  items?: any;
  setValue?: any;
  rightLabel?: string;
  error?: boolean;
};

const Item: FC<Props> = ({
  variant,
  label,
  value,
  id,
  inputHandler,
  select,
  items,
  setValue,
  rightLabel,
  error,
}) => {
  const { theme } = useThemeContext();
  const [viewSelectArea, setViewSelectArea] = useState(false);
  if (id === "country") {
    value = `${rightLabel}`;
  }
  return (
    <>
      {variant === "viewing" && (
        <ItemContainer>
          <Text
            color={theme.colors.greySubText}
            size="21px"
            fontStyle={theme.fonts.f500}
          >
            {label}
          </Text>
          <Text
            color={theme.colors.white}
            size="21px"
            fontStyle={theme.fonts.f500}
            textAlign="right"
          >
            {value || "-"}
          </Text>
        </ItemContainer>
      )}
      {variant === "edit" && (
        <ItemContainer>
          <Text
            color={theme.colors.greySubText}
            size="21px"
            fontStyle={theme.fonts.f500}
          >
            {label}
          </Text>
          {select ? (
            <Select>
              <Text
                color={theme.colors.white}
                size="21px"
                fontStyle={theme.fonts.f500}
                textAlign="center"
              >
                {value}
              </Text>
              <ButtonOpen onClick={() => setViewSelectArea(!viewSelectArea)}>
                <Icon
                  deg={viewSelectArea}
                  src="/images/Select_arrow_Down.png"
                />
              </ButtonOpen>
              {viewSelectArea && (
                <SelectArea>
                  {items.map((el: { label: string; value: string }) => (
                    <SelectItem
                      key={el.value}
                      onClick={() => {
                        setValue((prev: any) => ({ ...prev, [id]: el.value }));
                        setViewSelectArea(false);
                      }}
                    >
                      {el.label}
                    </SelectItem>
                  ))}
                </SelectArea>
              )}
            </Select>
          ) : id === "bDate" ? (
            <MaskInput
              mask={[
                /[1-2]/,
                /[0-9]/,
                /[0-9]/,
                /\d/,
                "-",
                /[0-1]/,
                /\d/,
                "-",
                /[0-3]/,
                /[0-9]/,
              ]}
              placeholder="YYYY-MM-DD"
              value={value}
              name={id}
              onChange={(e) => inputHandler(e)}
            ></MaskInput>
          ) : (
            <Input
              error={!error!}
              name={id}
              value={value}
              onChange={(e) => inputHandler(e)}
            />
          )}
        </ItemContainer>
      )}
    </>
  );
};
export default Item;
