import { makeStyles } from '@material-ui/core/styles';
import { theme } from "../../theme/default/theme";

export const useStyles =  makeStyles({
  container: {
    display: "flex",
    alignItems: 'center',
    cursor: 'pointer',
  },
  iconCont: {
    display: "flex",
    paddingBottom: theme.metrics.x025
  },
  text: {
    fontFamily: theme.fonts.f500.fontFamily,
    fontSize: 18,
    color: theme.colors.textDescription,
    userSelect: 'none',
    pointerEvents: 'none'
  }
});