import styled, { keyframes } from "styled-components";
import { templates } from "../../../theme/templates";

export const Wrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  overflow-y: auto;
  left: 0;
  z-index: 10;
  cursor: default;
  background-color: ${(p) => p.theme.colors.blurBg};
`;

export const ContentWrapper = styled.div`
  position: absolute;
  top: 85px;
  right: 0;
  width: 510px;
  overflow-y: auto;
  background-color: ${(p) => p.theme.colors.black};
  border-radius: 12px;
  padding-bottom: 10px;
  border: 1px solid #323232;
  z-index: 4;
 
  @media (max-width: 800px) {
    top: 70px;
  }
  @media (max-width: 520px) {
   width: 100%;
   top: 0;
   border-radius: 0 0 12px 12px;
  }
`;
export const MenuItem = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 20px;
  padding: 10px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: ${(p) => p.theme.colors.subBackground};
    color: ${p => p.theme.colors.yellowMain}
  }
`;
export const MenuIcon = styled.img`
  width: 30px;
  height: 30px;
  @media (max-width: 700px) {
    width: 18px;
    height: 18px;
  }
`;
export const IconWrapper = styled.div`
  ${templates.centerContent}
  padding: 5px;
  border: 1px solid ${(p) => p.theme.colors.borderMdm};
  border-radius: 12px;
  
`;
export const UserArea = styled.div`
  background-color: ${(p) => p.theme.colors.woodsmoke};
  flex-direction: column;
  ${templates.centerContent};
  padding: 10px 0;
`;
export const UserIcon = styled.img`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  object-fit: cover;
  cursor: pointer;
  border-radius: 50%;
`;
export const IconBack = styled.img`
width: 30px;
height: 30px;
top: 10px;
left: 10px;
position: absolute;
fill: ${p => p.theme.colors.yellowMain};
`;