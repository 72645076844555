import React, { useMemo } from "react";
import { useStyles } from "./ToastMessageStyles";
import { Box } from "@material-ui/core";
import { ToastMessageProps } from "../../types/types";
import { useThemeContext } from "../../theme";
import AlertToast from "./assets/alertToast";
import SuccessToast from "./assets/successToast";
import WarnToast from "./assets/warnToast";
import classNames from "classnames";

const ToastMessage: React.FC<ToastMessageProps> = ({ title, info, type }) => {
  const s = useStyles();
  const { theme } = useThemeContext();

  const typeParams = useMemo(() => {
    switch (type) {
      case "success":
        return {
          icon: <SuccessToast fill={"green"} />,
          background: theme.colors.green100,
        };
      case "error":
        return {
          icon: <AlertToast />,
          background: theme.colors.red100,
        };
      case "warn":
        return {
          icon: <WarnToast />,
          background: theme.colors.yellow100,
        };
      default:
        return {
          icon: <WarnToast />,
          background: theme.colors.red100,
        };
    }
  }, [type]);

  return (
    <Box className={classNames(s.container)}>
      <Box className={classNames(s.iconCont)}>{typeParams.icon}</Box>
      <Box className={classNames(s.textCol)}>
        <p className={classNames(s.titleText)}>{title}</p>
        {info && <p className={classNames(s.infoText)}>{info}</p>}
      </Box>
    </Box>
  );
};

ToastMessage.defaultProps = {};

export { ToastMessage };
