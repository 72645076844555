import React, { useMemo, useState } from 'react';
import { useStyles, RefferalIdCont, Copy } from './RefferalIdStyles';
// import { Box } from '@material-ui/core';
import { getRefferalId } from "./../../helpers/strings";
// import cn from 'classnames';
import { userIdSelector } from '../../logic/user/UserSelectors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Text } from "../../components/Text/Text";
import { useThemeContext } from '../../theme';
import { showToast } from '../../helpers/alertService';

export type RefferalIdProps = {};

const RefferalId: React.FC<RefferalIdProps> = ({}) => {
  const s = useStyles();
  // @ts-ignore
  const { t } = useTranslation();
  const { theme } = useThemeContext();

  const userId = useSelector(userIdSelector);

  const [copyIcon, setCopyIcon] = useState(false);

  const refferalID = useMemo(() => {
    return getRefferalId(userId ?? 0);
  }, [userId]);

  const copy = () => {
    navigator.clipboard.writeText(refferalID);
    setCopyIcon(true);
    showToast({
      title: t("Success"),
      info: t("CopiedToClipboard"),
      type: "success",
    });
  };

  return (
    <>
      <Text
        color={theme.colors.greySubText}
        size="18px"
        margin="20px auto 0 auto"
        fontStyle={theme.fonts.f500}
      >
        {t("profile.refferalId")}:
      </Text>
      <RefferalIdCont>
        <Text
          color={theme.colors.yellowMain}
          size="16px"
          fontStyle={theme.fonts.f500}
        >
          {refferalID}
        </Text>
        <Copy
          src={
            copyIcon
              ? "/images/profile/copy-done.svg"
              : "/images/profile/copy.svg"
          }
          onClick={() => copy()}
        />
      </RefferalIdCont>
    </>
  );
};

RefferalId.defaultProps={}

export { RefferalId };