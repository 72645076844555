import { Instruction, InstructionGroup } from "@appklaar/codebud";
import { StorieActions } from "./../logic/storie/StorieRedux";

export const getStorieInstructions = (store: any): (Instruction | InstructionGroup)[] => ([
  {
    groupId: "Storie",
    groupDescription: "Storie entity instructions",
    groupColor: "#77a3b7",
    groupInstructions: [
      {
        id: "getStorieList",
        description: "Get stories list, pagination by index.",
        parametersDescription: {
          index: "number",
        },
        handler: (data) => {
          store.dispatch(StorieActions.getStorieList.request(data));
        },
      },
      {
        id: "getStorieById",
        description: "Get storie by id.",
        parametersDescription: {
          storieId: "string",
        },
        handler: (data) => {
          store.dispatch(StorieActions.getStorieById.request(data));
        },
      },
    ]
  },
]);