import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  globalContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'yellow'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    paddingLeft: 24,
    paddingRight: 24,
    // backgroundColor: 'blue'
  },
});