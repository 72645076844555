import { ApiResponse, PROBLEM_CODE } from "apisauce";

class RequestError extends Error {
  description: string;
  code?: number;
  invalidParameters?: string[];
  fields?: any;

  constructor({
    description,
    code,
    invalidParameters,
    fields,
  }: {
    description: string;
    code?: number;
    invalidParameters?: string[];
    fields?: any;
  }) {
    super(description);
    this.description = description;
    this.code = code;
    this.invalidParameters = invalidParameters;
    this.fields = fields;
  }
}

class RequestErrorFromResponse extends Error {
  description: string;
  problem: PROBLEM_CODE | null;
  originalError: any;
  code?: number;
  invalidParameters?: string[];
  duration?: number;

  constructor(response: ApiResponse<any>) {
    const _description = response.data?.message ?? "Error (no description provided)";

    super(_description);
    this.problem = response.problem;
    this.originalError = response.originalError;
    this.description = _description;
    this.code = response.status ?? -3;
    this.invalidParameters = response.data?.invalidParameters;
    this.duration = response.duration;
  }
}

export { RequestError, RequestErrorFromResponse};
