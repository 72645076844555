import React, { memo, useEffect } from 'react';
import { useStyles } from './EscrowPageStyles';
import { Box } from '@material-ui/core';
import { useThemeContext } from "../../theme";
import {
  Wrapper,
} from "./../Payments/PaymentsPage/PaymentsStyle";
import { CustomButton } from "./../../components/CustomButton";
import { EscrowTxItem } from "./../../components/EscrowTxItem";
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { escrowSelector } from '../../logic/escrow/EscrowSelectors';
import { EscrowActions } from '../../logic/escrow/EscrowRedux';
import { userIdSelector } from '../../logic/user/UserSelectors';
import { NoData } from './../../components/NoData';
import { EscrowTransaction } from '../../types/types';

export type EscrowPageProps = {};

const EscrowPage: React.FC<EscrowPageProps> = ({}) => {
  const { theme } = useThemeContext();
  const s = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userId = useSelector(userIdSelector);
  
  const {
    list: {
      data: escrowTransactionsData,
      fetching: fetchingEscrowTransactions
    }
  } = useSelector(escrowSelector);

  const requestEscrowTransactions = () => {
    if (userId === undefined)
      return;

    dispatch(EscrowActions.list.request({
      customer_id: userId
    }));
  }

  const onClickOpenTxDetails = (tx: EscrowTransaction) => {
    dispatch(EscrowActions.setViewingTx(tx));
    navigate("/main/escrow-view-tx");
  }

  useEffect(() => {
    requestEscrowTransactions();
  }, []);

  return (
    <Wrapper>
      <Box className={cn(s.container)}>
        <Box className={s.top}>
          <Box className={s.newTxBtnCont}>
            <CustomButton 
              onPress={() => navigate("/main/escrow-create-tx")}
              title={"New Transaction"}
              bgColorActive={theme.colors.yellowMain}
              borderRadius={8}
            />
          </Box>
        </Box>

        <Box className={s.transactionsCont}>
          {!fetchingEscrowTransactions && escrowTransactionsData?.length ?
            escrowTransactionsData.map((tx) => (
              <EscrowTxItem 
                key={`et_l_${tx.id}`}
                data={tx}
                onClickOpen={() => onClickOpenTxDetails(tx)}
              />
            ))
          :
            <NoData loading={fetchingEscrowTransactions} />
          }
        </Box>
      </Box>
    </Wrapper>
  );
};

const MemorizedComponent = memo(EscrowPage);
export { MemorizedComponent as EscrowPage };