import { useSelector } from "react-redux";
import { useDependentState, useDependentValue } from "../helpers/customHooks";
import { nftSelector } from "../logic/nft/NftSelectors";

export const useMyNftCollectionsDropdownData = () => {
  const {
    myNftCollections
  } = useSelector(nftSelector);

  const collectionsDropdownData = useDependentValue(() => {
    return myNftCollections.map((collection) => ({
      ...collection,
      value: collection.id,
      id: collection.id
    }));
  }, [myNftCollections]);

  const [collection, setCollection] = useDependentState(() => {
    return collectionsDropdownData[0] ?? {title: "", value: 0};
  }, [collectionsDropdownData]);


  return {
    collectionsDropdownData,
    collection,
    setCollection
  };
}