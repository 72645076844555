import { CardsWrapper, Wrapper } from "./NFTItemPageStyle";
import InfoNFT from "./InfoNFT/InfoNFT";
import { Text } from "../../../components/Text/Text";
import { useThemeContext } from "../../../theme";
import CardNFT from "../../../components/CardNFT/CardNFT";
import { cards } from "./mock";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { nftSelector } from "../../../logic/nft/NftSelectors";
import { useEffect, useState } from "react";
import { NftItem } from "../../../types/types";
import { NftActions } from "../../../logic/nft/NftRedux";
import { CONFIG } from "../../../config";
import { getBidTimerSeconds, getNftImage } from "../../../helpers/nftHelpers";
import i18next from "i18next";
import { BLOCKCHAIN_SHORT_NAME_TABLE } from "../../../data/zedpay";

const NFTItemPage = () => {
  const { theme } = useThemeContext();
  const windowWidth = useWindowWidth();
  const dispatch = useDispatch();
  const { t } = i18next;
  const { id } = useParams();
  const navigate = useNavigate();
  const sliceNumber = windowWidth > 1729 ? 5 : 4;

  const [nftItem, setNftItem] = useState({});

  const {
    listNfts: { data: nftList },
  } = useSelector(nftSelector);

  const editNft = () => {
    navigate("/nft/create-nft", { state: nftItem });
  };

  useEffect(() => {
    if (nftList) {
      const idNumber = id ? parseInt(id, 10) : undefined;

      const foundNft = nftList.find((el) => String(el.id) === String(idNumber));

      if (foundNft) {
        setNftItem(foundNft);
      }
    }
  }, [id, nftList]);
  useEffect(() => {
    dispatch(NftActions.listNfts.request({}));
  }, []);
  return (
    <Wrapper>
      <InfoNFT data={nftItem as NftItem} />

      <Text
        color={theme.colors.yellowMain}
        size="32px"
        fontStyle={theme.fonts.f600}
        margin="24px auto"
        textAlign="center"
        func={() => editNft()}
      >
        {t("buttons.edit")}
      </Text>
      <Text
        color={theme.colors.white}
        size="50px"
        fontStyle={theme.fonts.f600}
        margin="50px 0 26px 0"
      >
        {t("nftDetailsScreen.recentViews")}
      </Text>
      <CardsWrapper>
        {nftList?.slice(0, sliceNumber).map((el, index) => (
          <CardNFT
            currency_code={el.currency_code}
            currency_id={el.currency_id}
            likedByMe={el.liked_by_me}
            key={index}
            href={`/nft/item/${el.id}`}
            img={`${CONFIG.ZED_BASE_URL}${getNftImage(el)}`}
            name={el.nft_user.username}
            title={el.title}
            verif={false}
            avatar={`${CONFIG.ZED_BASE_URL}${el.nft_user.logo}`}
            bottomLeftImage={`${CONFIG.ZED_BASE_URL}${CONFIG.ZED_ASSETS_PATH.NFT_FILES}${BLOCKCHAIN_SHORT_NAME_TABLE[el.blockchain.toUpperCase()]}.png`}
            timer={getBidTimerSeconds(el)}
            price={el.price}
          />
        ))}
      </CardsWrapper>
    </Wrapper>
  );
};
export default NFTItemPage;
