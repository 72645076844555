import styled, { keyframes } from "styled-components";

import { templates } from "../../../theme/templates";

const expandAnimation = keyframes`
  0% {
    transform: scaleY(0);
    transform-origin: top;
  }
  100% {
    transform: scaleY(1);
    transform-origin: top;
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;

  left: 0;
  z-index: 10;
  cursor: default;
`;

export const ContentWrapper = styled.div`
  position: absolute;
  top: 100px;
  right: 120px;
  width: 310px;
  height: 325px;
  border-radius: 24px;
  border: 1px solid #323232;
  z-index: 4;
  overflow: hidden;
  animation: ${expandAnimation} 0.4s ease-in-out;
`;

export const LangItem = styled.div<{ isLast?: boolean }>`
  ${templates.centerContent};
  justify-content: flex-start;
  padding: 24px;
  width: 100%;
  background-color: ${(p) => p.theme.colors.subBackground};
  height: 81px;
  color: ${(p) => p.theme.colors.white};
  font-size: 18px;
  transition: 0.3s;
  cursor: pointer;
  border-bottom: ${(props) => (props.isLast ? "none" : "1px solid #323232")};
  &:hover {
    background-color: #292e32;
  }
`;
export const LangImg = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 15px;
`;
