import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container: {
    cursor: 'pointer'
  },
  whiteText: {
    fontFamily: theme.fonts.f600.fontFamily,
    color: theme.colors.white,
    fontSize: 16,
    margin: 0
  },
});