import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
  >
    <path
      fill="#BEBEBE"
      fillRule="evenodd"
      d="M6.912 10.588a.833.833 0 0 1 0-1.178l4.714-4.714a.835.835 0 1 1 1.179 1.178L8.678 10l4.126 4.125a.833.833 0 0 1-1.179 1.178l-4.714-4.714Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
