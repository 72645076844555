import React, { memo } from 'react';
import { useStyles } from './BankCardStyles';
import { Box, CircularProgress } from '@material-ui/core';
import { ZedpayCardNetwork, ZedpayCardType } from '../../types/types';
import { useThemeContext } from '../../theme';
import { useTranslation } from 'react-i18next';
import { copyToClipboard } from '../../helpers/copyToClipboard';
import { seperateCardNumber } from '../../helpers/numbers';
import cn from 'classnames';

const VISA_IMAGE = require("./../../assets/images/visa.png");
const MASTERCARD_IMAGE = require("./../../assets/images/mastercard.png");

export type BankCardProps = {
  type: ZedpayCardType;
  network: ZedpayCardNetwork;
  backgroundColor?: string;
  cardHolder: string;
  cardNumber?: string;
  expiration: string;
  cvv: string;
  fetchingCVV?: boolean;
  label?: string;
  showDetails: boolean;
  marginRight?: number;
};

const BankCard: React.FC<BankCardProps> = ({
  type,
  network,
  backgroundColor,
  cardHolder,
  cardNumber,
  expiration,
  cvv,
  fetchingCVV,
  label,
  showDetails,
  marginRight
}) => {
  const s = useStyles();
  const { theme } = useThemeContext();
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <Box className={cn(s.container)} style={{backgroundColor, marginRight}}>
      <Box className={cn(s.rowBetween)}>
        <p className={cn(s.cardType)}>
          {label ?? (type === "Virtual" ? t('bankCard.VirtualCard') : t('bankCard.PhysicalCard'))}
        </p>
        <Box className={cn(s.networkImgCont)}>
          <img src={network === "Visa" ? VISA_IMAGE : MASTERCARD_IMAGE} className={cn(s.networkImg)}/>
        </Box>
      </Box>
      <Box
        onClick={() => showDetails && cardNumber && copyToClipboard(cardNumber)}
        className={cn(s.cardNumberContainer, {[s.cursor]: showDetails})}
      >
        {seperateCardNumber(cardNumber, !showDetails).map(
          (item: any, index: number) => (
            <p key={index} className={cn(s.cardNumber)}>
              {item}
            </p>
          )
        )}
      </Box>
      <Box className={cn(s.bottomContainer)}>
        <Box
          style={{maxWidth: '55%'}}
          onClick={() => showDetails && copyToClipboard(cardHolder)}
          className={cn({[s.cursor]: showDetails})}
        >
          <p className={cn(s.title)}>{t('bankCard.CardHolder')}</p>
          <p className={cn(s.subTitle)}>
            {cardHolder}
          </p>
        </Box>
        <Box className={cn(s.rowCentered)}>
          <Box
            onClick={() => showDetails && copyToClipboard(expiration.toString())}
            style={{marginRight: theme.metrics.x7}}
            className={cn({[s.cursor]: showDetails})}
          >
            <p className={cn(s.title)}>{t('bankCard.Expiration')}</p>
            <p className={cn(s.subTitle)}>{!showDetails ? '**/**' : expiration}</p>
          </Box>
          <Box
            onClick={() => showDetails && copyToClipboard(cvv.toString())}
            className={cn({[s.cursor]: showDetails})}
          >
            <p className={cn(s.title)}>{t('bankCard.CVV')}</p>
            <Box>
              {(showDetails && fetchingCVV)?
                <CircularProgress size={12} style={{marginLeft: 4}}/>
              :
                <p className={cn(s.subTitle)}>{showDetails ? cvv : "***"}</p>
              }
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

BankCard.defaultProps={
  backgroundColor: '#95BECE',
  cardNumber: "****************",
  fetchingCVV: false,
  showDetails: false,
  marginRight: 0
}

const MemorizedComponent = memo(BankCard);
export { MemorizedComponent as BankCard };