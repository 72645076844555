import React, { memo } from "react";
import { useStyles } from "./BankInfoModalStyles";
import { Box } from "@material-ui/core";
import cn from "classnames";
import Modal from "react-modal";
import { CustomButton } from "../CustomButton";
import CloseIcon from "./../../assets/icons/close";
import { useThemeContext } from "../../theme";
import { BankAccountInfo } from "../../logic/general/GeneralRedux";
import { copyToClipboard } from "../../helpers/copyToClipboard";
import { BankInfoItem } from "../BankInfoItem";
import { theme } from "../../theme/default/theme";

Modal.setAppElement("#root");

// Global constants
const MODAL_CUSTOM_STYLE = {
  content: {
    display: "flex",
    border: `1px solid ${theme.colors.borderGrey}`
  },
  overlay: {
    zIndex: 11,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

export type BankInfoModalProps = {
  visible: boolean;
  onClose: () => void;
  data: BankAccountInfo | undefined;
};

const BankInfoModal: React.FC<BankInfoModalProps> = ({
  visible,
  onClose,
  data,
}) => {
  const s = useStyles();
  const { theme } = useThemeContext();

  return (
    <Modal
      isOpen={visible}
      onRequestClose={onClose}
      contentLabel="Storie modal"
      className={cn(s.bankInfoModal, s["&__content"])}
      style={MODAL_CUSTOM_STYLE as any}
    >
      <Box className={cn(s.topCont)}>
        <Box className={cn(s.endRow)}>
          <Box className={cn(s.closeIcon)}>
            <CustomButton
              bgColorActive="transparent"
              icon={<CloseIcon color={theme.colors.white} />}
              onPress={onClose}
            />
          </Box>
        </Box>
      </Box>

      <Box className={cn(s.contentContainer)}>
        {data &&
          Object.keys(data).map((item: string, index: number) => {
            return (
              <BankInfoItem
                key={index}
                // @ts-ignore
                onPress={() => copyToClipboard(data[item])}
                title={item}
                // @ts-ignore
                value={data[item]}
              />
            );
          })}
      </Box>
    </Modal>
  );
};

BankInfoModal.defaultProps = {};

const MemorizedComponent = memo(BankInfoModal);
export { MemorizedComponent as BankInfoModal };
