import React, { FC, useMemo } from "react";
import { QRCodeItem } from "../../../components/QRCodeItem";
import { useTranslation } from "react-i18next";
import { useCryptoAccountTopUp } from "../../../hooks/useCryptoAccountTopUp";
import { useSelector } from "react-redux";
import { cryptoSelector } from "../../../logic/crypto/CryptoSelectors";
import {
  ExchangeBoxes,
  PaymentsTabs,
  TopUpBoxes,
  WalletInfoBoxes,
  WithdrawBoxes,
} from "../PaymentsPage/paymentsPageConstants";
import { DepositOffline } from "./PageBlocks/DepositOffline";
import { Exchange } from "./PageBlocks/Exchange";
import { BlockchainWithdraw } from "./PageBlocks/BlockchainWithdraw";
import { OfflineWithdraw } from "./PageBlocks/OfflineWithdraw";
import {
  CardsOperationsTabs,
  LoadBoxes,
  SendBoxes,
  UnloadBoxes,
} from "../CardsOperations/cardsOperationsPageConstants";
import { LoadCard } from "./PageBlocks/LoadCard";
import { CardToCard } from "./PageBlocks/CardToCard";
import { UnloadCard } from "./PageBlocks/UnloadCard";
import { InternalFiatTransfer } from "./PageBlocks/InternalFiatTransfer";
import { WalletInfo } from "./PageBlocks/WalletInfo";
import { WalletItem } from "../../../components/WalletItem";
import { CONFIG } from "../../../config";
import { prettyStringToNumber } from "../../../helpers/formatHelpers";

const PAYMENTS_PAGE = "payments";
const CARDS_OPERATIONS_PAGE = "cardsOperations";

type FormProps = {
  activePage: typeof PAYMENTS_PAGE | typeof CARDS_OPERATIONS_PAGE;
  activeTab: number;
  activeBox: number;
};

const MethodOptional: FC<FormProps> = ({
  activePage,
  activeBox,
  activeTab,
}) => {
  // @ts-ignore
  const { t } = useTranslation();

  const { paymentsPageSelectedAccount } = useSelector(cryptoSelector);

  const { 
    QRCodesTable, 
    generatingCode, 
    accountsOptions,
    optionValueToLabelTable,
    accountAddress,
    setAccountAddress
  } = useCryptoAccountTopUp(paymentsPageSelectedAccount, true);

  const content = useMemo(() => {
    const combination = `${activePage}${activeTab}${activeBox}`;

    switch (combination) {
      case `${PAYMENTS_PAGE}${PaymentsTabs.TopUp}${TopUpBoxes.CryptoTopUp}`:
        return accountAddress ? (
          <QRCodeItem
            title={t("scanQRBelow")}
            loading={!QRCodesTable[accountAddress] && generatingCode}
            qr={QRCodesTable[accountAddress] ?? ""}
            manualTitle={t("blockchainAddress") as string}
            manualValue={accountAddress}
            optionsData={accountsOptions}
            onOptionValueChange={(v) => v && setAccountAddress(v as string)}
            optionsLabel={t("blockchain") as string}
            optionsRightLabel={optionValueToLabelTable[accountAddress]}
            topContent={
              paymentsPageSelectedAccount ?
              <WalletItem
                image={`${CONFIG.ZED_BASE_URL}/${paymentsPageSelectedAccount.currency_flag}`}
                userHasThisWallet={true}
                // userCurrencySymbol={getCurrencySymbol(userCurrency)}
                userCurrencySymbol="$"
                balance={paymentsPageSelectedAccount.balance * prettyStringToNumber(paymentsPageSelectedAccount.currency_price)}
                cryptoBalance={paymentsPageSelectedAccount.balance}
                name={paymentsPageSelectedAccount.currency_code}
                walletName={paymentsPageSelectedAccount.name}
              /> : undefined
            }
          />
        ) : null;
      case `${PAYMENTS_PAGE}${PaymentsTabs.TopUp}${TopUpBoxes.FiatOfflineTopUp}`:
        return <DepositOffline />;
      case `${PAYMENTS_PAGE}${PaymentsTabs.Withdraw}${WithdrawBoxes.CryptoToBlockchain}`:
        return <BlockchainWithdraw />;
      case `${PAYMENTS_PAGE}${PaymentsTabs.Withdraw}${WithdrawBoxes.FiatToBank}`:
        return <OfflineWithdraw />;
      case `${PAYMENTS_PAGE}${PaymentsTabs.Withdraw}${WithdrawBoxes.InternalFiatTransfer}`:
        return <InternalFiatTransfer />
      case `${PAYMENTS_PAGE}${PaymentsTabs.Exchange}${ExchangeBoxes.Exchange}`:
        return <Exchange />;
      case `${PAYMENTS_PAGE}${PaymentsTabs.WalletInfo}${WalletInfoBoxes.MainInfo}`:
        return <WalletInfo />;
      case `${CARDS_OPERATIONS_PAGE}${CardsOperationsTabs.Load}${LoadBoxes.LoadUSDZ}`:
        return <LoadCard />;
      case `${CARDS_OPERATIONS_PAGE}${CardsOperationsTabs.Send}${SendBoxes.CardToCard}`:
        return <CardToCard />;
      case `${CARDS_OPERATIONS_PAGE}${CardsOperationsTabs.Unload}${UnloadBoxes.UnloadUSDZ}`:
        return <UnloadCard />;

      default:
        return null;
    }
  }, [activePage, activeTab, activeBox, paymentsPageSelectedAccount, QRCodesTable, accountAddress, generatingCode, t]);

  return content;
};

export default MethodOptional;