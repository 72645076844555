import styled from "styled-components";
import { useThemeContext } from "../../../../../theme";
import { FC } from "react";
import { Text } from "../../../../../components/Text/Text";
import { Line } from "../../NFTItemPageStyle";
import Person from "./Person";
import { CONFIG } from "../../../../../config";
import { generalSelector } from "../../../../../logic/general/GeneralSelectors";
import { useSelector } from "react-redux";
import i18next from "i18next";

type Props = {
  price: number;
  currency: string;
  abt: string;
  descriptionText: string;
  avatar: string;
  collection: any;
  royalties?: number;
  category: string;
  userName: string;
  currency_id: any;
};

const DescriptionNFT: FC<Props> = ({
  price,
  abt,
  currency,
  descriptionText,
  avatar,
  collection,
  royalties,
  category,
  userName,
  currency_id,
}) => {
  const { theme } = useThemeContext();
  const { t } = i18next;

  const {
    currencyList: { data: currencyList },
  } = useSelector(generalSelector);
  return (
    <Wrapper>
      <Price>
        <Pic
          src={`${CONFIG.ZED_BASE_URL}${
            currencyList ? currencyList?.currencies[currency_id]?.flag : "/"
          }`}
        />
        <Text
          color={theme.colors.yellowMain}
          fontStyle={theme.fonts.f600}
          size="24px"
        >
          {price}
        </Text>
        <Text
          color={theme.colors.greySubTextDesc}
          fontStyle={theme.fonts.f600}
          size="24px"
        >
          {currency}
        </Text>
        <Text
          color={theme.colors.yellowMain}
          fontStyle={theme.fonts.f600}
          size="24px"
        >
          {abt}
        </Text>
      </Price>
      <Text color={theme.colors.white} fontStyle={theme.fonts.f400} size="24px">
        {descriptionText}
      </Text>
      <Line />
      <Container>
        <Person
          title={(category ?? "") + " " + royalties + "%"}
          subTitle={t("nftDetailsScreen.royalties") as string}
          img={avatar}
          name={`@${userName}`}
        />
        {collection && (
          <Person
            title={t("nftDetailsScreen.collection") as string}
            img={`${CONFIG.ZED_BASE_URL}${collection.logo}`}
            name={collection.title}
          />
        )}
      </Container>
      {/* <Btn
        label={t("nftDetailsScreen.unblockableContent")}
        type={"NFT"}
        buttonActive={true}
        width={windowWidth > 900 ? "75%" : "100%"}
      /> */}
      <Line style={{ marginBottom: "20px" }} />
    </Wrapper>
  );
};
export default DescriptionNFT;

const Wrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  @media (max-width: 700px) {
    margin-top: 21px;
  }
`;
const Price = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;
const Pic = styled.img``;
const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  column-gap: 40px;
`;
