import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    height: 190,
    width: 342,
    borderRadius: theme.metrics.x4,
    justifyContent: 'space-between',
    paddingTop: theme.metrics.x6,
    paddingBottom: theme.metrics.x6,
    paddingLeft: theme.metrics.x4,
    paddingRight: theme.metrics.x4
  },
  cardNumberContainer: {
    display: "flex",
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontFamily: theme.fonts.f500.fontFamily,
    fontSize: theme.metrics.x2_5,
    letterSpacing: -0.3,
    color: theme.colors.disabledBackground,
    marginLeft: 0,
    marginTop: 0,
    marginRight: 0,
    marginBottom: theme.metrics.x,
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  subTitle: {
    fontFamily: theme.fonts.f700.fontFamily,
    fontSize: theme.metrics.x4,
    letterSpacing: 2.7,
    color: theme.colors.disabledBackground,
    margin: 0
  },
  rowCentered: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardType: {
    fontFamily: theme.fonts.f600.fontFamily,
    fontSize: theme.metrics.x4,
    letterSpacing: 1.5,
    // color: theme.colors.textSub,
    color: theme.colors.disabledBackground,
    margin: 0
  },
  cardNumber: {
    fontFamily: theme.fonts.f500.fontFamily,
    fontSize: theme.metrics.x5_5,
    letterSpacing: 2.7,
    textAlign: 'center',
    color: theme.colors.disabledBackground,
    margin: 0
  },
  rowBetween: {
    display: "flex",
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  networkImgCont: {
    display: "flex",
    flexDirection: "column",
    height: 32,
    width: 64,
    marginTop: -theme.metrics.x2
  },
  networkImg: {
    height: "100%",
    width: "100%",
    objectFit: 'contain',
  },
  cursor: {
    cursor: "pointer"
  }
});