import styled from "styled-components";
import { Text } from "../../../../../components/Text/Text";
import { useThemeContext } from "../../../../../theme";
import { FC } from "react";
import { templates } from "../../../../../theme/templates";
import { NftItem } from "../../../../../types/types";
import { useDependentState } from "../../../../../helpers/customHooks";
import { useDispatch } from "react-redux";
import { NftActions } from "../../../../../logic/nft/NftRedux";
import HeartBlackIcon from "./../../../../../assets/icons/Heart_black.svg";
import HeartIcon from "./../../../../../assets/icons/Heart.svg";

type Props = {
  data: NftItem;
};

const HeaderNFT: FC<Props> = ({ data }) => {
  const { theme } = useThemeContext();
  const dispatch = useDispatch();
  const [likedByMe, setLikedByMe] = useDependentState<boolean>(() => {
    return data.liked_by_me;
  }, [data]);
  const [visualLike, setVisualLike] = useDependentState(() => {
    return data.likes;
  }, [data]);
  const handlePressLike = () => {
    const liked = likedByMe;

    dispatch(
      NftActions.changeLikeStatus.request({
        id: data.id,
        liked_by_me: !liked,
        successCallback: () => {
          setLikedByMe(!liked);
          setVisualLike(visualLike + (!liked ? 1 : -1));
        },
        failureCallback: () => {
          setLikedByMe(liked);
        },
      })
    );
  };

  return (
    <Wrapper>
      <Text
        color={theme.colors.white}
        fontStyle={theme.fonts.f600}
        size="36px"
        margin="0 auto 0 0"
      >
        {data.title}
      </Text>
      <Likes onClick={() => handlePressLike()} likedByMe={likedByMe}>
        <Icon
          src={likedByMe ? HeartBlackIcon : HeartIcon}
        />
        <Text
          color={likedByMe ? theme.colors.black : theme.colors.white}
          fontStyle={theme.fonts.f400}
          size="21px"
          margin="0 0 0 10px"
        >
          {visualLike}
        </Text>
      </Likes>
      {/* <Likes likedByMe={false}>
        <Icon src="/images/NFT_ItemPage/Dots.svg" />
      </Likes> */}
    </Wrapper>
  );
};
export default HeaderNFT;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 900px) {
    margin-top: 16px;
  }
`;
const Likes = styled.div<{ likedByMe: boolean }>`
  cursor: pointer;
  padding: 10px;
  background-color: ${(p) =>
    p.likedByMe ? p.theme.colors.yellowMain : p.theme.colors.black};
  ${templates.centerContent};
  border: 2px solid ${(p) => p.theme.colors.borderGrey};
  border-radius: 14px;
  margin-left: 16px;
  @media (max-width: 900px) {
    padding: 7px 9px;
  }
`;
const Icon = styled.img`
  @media (max-width: 700px) {
    width: 18px;
    height: 18px;
  }
`;
