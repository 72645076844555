import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    fontFamily: theme.fonts.f600.fontFamily,
    padding: 0,
    margin: 0,
    textAlign: 'center',
    whiteSpace: 'pre-line'
  }
});