import { useSelector } from "react-redux";
import { useDependentState, useDependentValue } from "../helpers/customHooks";
import { nftSelector } from "../logic/nft/NftSelectors";

export const useNftCategoryDropdownData = () => {
  const {
    listCategories: {
      data: nftCategories
    },
  } = useSelector(nftSelector);
  
  const categoryDropdownData = useDependentValue(() => {
    if (!nftCategories)
      return [];

    return nftCategories.categories.map((category) => ({
      ...category, 
      value: category.title,
      id: category.order
    }));
  }, [nftCategories]);

  const [category, setCategory] = useDependentState(() => {
    return {title: "Art", value: "Art"};
  }, [categoryDropdownData]);
  
  const setCategoryByTitle = (title: string) => {
    const categoryDropdownItem = categoryDropdownData.find((c: { value: string; }) => c.value === title);

    if (categoryDropdownItem)
      setTimeout(() => {
        setCategory(categoryDropdownItem);
      }, 100);
  }


  return {
    categoryDropdownData,
    category, 
    setCategory,
    setCategoryByTitle
  };
};