import { useState } from "react";
import styled from "styled-components";
import { theme } from "../../../theme/default/theme";
import { Text } from "../../../components/Text/Text";

const MarketItemsTable = () => {
  const [like, setLike] = useState(false);
  return (
    <Wrapper>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Star
          src={
            like ? "/images/markets/Star.svg" : "/images/markets/Star-empty.svg"
          }
          onClick={() => setLike(!like)}
        />
        <Text
          color={theme.colors.white}
          size="14px"
          fontStyle={theme.fonts.f400}
          margin="2px 0 0 4px"
        >
          ZEDXION/ZEDXIONC
        </Text>
      </div>

      <LastPrice>
        <Text
          color={theme.colors.white}
          size="14px"
          fontStyle={theme.fonts.f400}
        >
          1.000000
        </Text>
        <Text
          color={theme.colors.greySubText}
          size="14px"
          fontStyle={theme.fonts.f400}
        >
          /$0.100707484
        </Text>
      </LastPrice>
      <Text color={theme.colors.white} size="14px" fontStyle={theme.fonts.f400}>
        0.00%
      </Text>
      <Text color={theme.colors.white} size="14px" fontStyle={theme.fonts.f400}>
        1.000000
      </Text>
      <Text color={theme.colors.white} size="14px" fontStyle={theme.fonts.f400}>
        0.234001
      </Text>
      <Text color={theme.colors.white} size="14px" fontStyle={theme.fonts.f400}>
        21.000007
      </Text>
    </Wrapper>
  );
};
export default MarketItemsTable;
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  padding: 22px 0;
  border-bottom: 1px solid ${theme.colors.greySubTextDesc};
  align-items: center;
`;
const Star = styled.img`
  cursor: pointer;
`;
const LastPrice = styled.div`
  display: flex;
`;
