import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  globalContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative',
    "--animate-duration": "1000ms"
  },
  input: {
    height: '100%',
    backgroundColor: 'transparent',
    border: '0',
    outline: 0,
    fontSize: 18,
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px #000000 inset",
      WebkitTextFillColor: "#ffffff",
    },
    "&:-webkit-autofill::first-line": {
      fontSize: 18,
    }
  },
  clearBtn: {
    position: 'absolute',
    right: 4,
    cursor: 'pointer'
  },
  rightIconContainer: {
    position: 'absolute',
    right: theme.metrics.x4,
    zIndex: 3,
    paddingTop: theme.metrics.x
  },
  rightLabel: {
    color: theme.colors.greySubText,
    margin: 0
  },
  pickerContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    minHeight: 80,
    maxHeight: 200,
    width: "30ch",
    backgroundColor: theme.colors.white,
    zIndex: 5,
    borderRadius: theme.metrics.x,
    overflow: 'scroll',
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  pickerItem: {
    display: 'flex',
    width: '100%',
    minHeight: 40,
    height: 40,
    paddingLeft: theme.metrics.x4,
    paddingRight: theme.metrics.x4,
    alignItems: 'center',
    cursor: 'pointer'
  },
  pickerItemSelected: {
    backgroundColor: theme.colors.gallery
  },
  pickerItemText: {
    color: theme.colors.black,
    fontFamily: theme.fonts.f400.fontFamily,
    margin: 0
  }
});