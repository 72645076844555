import React from "react";
import ContentLoader from "react-content-loader";
import { theme } from "../../theme/default/theme";
import LoaderRing from "../../helpers/Loader/LoaderRing";
import styled from "styled-components";

export const SkeletonCard = (props: any) => (
  <ContentLoader
    speed={2}
    width={props.width}
    height={props.heigth}
    viewBox={`0 0 ${props.width} ${props.height}`}
    backgroundColor="#8c8c8c"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect
      x="0"
      y="0"
      rx="18"
      ry="18"
      width={props.width}
      height={props.height}
    />
  </ContentLoader>
);
export const SkeletonGridItem = (props: any) => {
  if (props.desktop) {
    return (
      <ContentLoader
        speed={2}
        width={476}
        height={86}
        viewBox="0 0 476 86"
        backgroundColor="#8c8c8c"
        foregroundColor="#ecebeb"
        {...props}
      >
        <circle cx="27" cy="46" r="25" />
        <rect x="65" y="17" rx="0" ry="0" width="174" height="20" />
        <rect x="65" y="50" rx="0" ry="0" width="99" height="14" />
      </ContentLoader>
    );
  } else {
    return (
      <GridItemWrapper>
        <ContentLoader
          speed={2}
          width={212}
          height={36}
          viewBox="0 0 212 36"
          backgroundColor="#8c8c8c"
          foregroundColor="#ecebeb"
          {...props}
        >
          <circle cx="19" cy="20" r="15" />
          <rect x="49" y="4" rx="0" ry="0" width="77" height="13" />
          <rect x="49" y="23" rx="0" ry="0" width="66" height="9" />
        </ContentLoader>
      </GridItemWrapper>
    );
  }
};

export const SkeletonGridImage = (props: any) => (
  <ContentLoader
    speed={2}
    width={30}
    height={30}
    viewBox="0 0 30 30"
    backgroundColor="#FED337"
    foregroundColor="#ffe78f"
    {...props}
  >
    <circle cx="15" cy="15" r="15" />
  </ContentLoader>
);

const GridItemWrapper = styled.div`
  width: 100%;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: start;
`;
