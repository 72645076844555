import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: "column",
    width: '100%',
    padding: theme.metrics.x6,
    backgroundColor: theme.colors.codGray,
    borderRadius: theme.metrics.x4,
    border: `1px solid ${theme.colors.borderGrey}`,
  },
  previewCont: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  label: {
    fontFamily: theme.fonts.f700.fontFamily,
    fontSize: 24,
    color: theme.colors.text,
    margin: 0,
    pointerEvents: 'none'
  },
  chevronCont: {
    display: 'flex',
    transitionDuration: '0.5s',
  },
  chevronRotated: {
    transform: "rotateZ(-180deg)"
  },
  childrenCont: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginTop: theme.metrics.x5
  }
});