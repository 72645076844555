import React, { useCallback, useState } from "react";
import { useStyles } from "./ResetPasswordPageStyles";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormTitle, SmallLink, SmallText } from "../Login/LoginPageStyles";
import { TextInput } from "../../components/TextInput";
import { useHideErrors } from "../../hooks/useHideErrors";
import { CustomButton } from "../../components/CustomButton";
import { validateEmail } from "../../helpers/validationHelper";
import { UserActions } from "../../logic/user/UserRedux";
import { useThemeContext } from "../../theme";
import { userSelector } from "../../logic/user/UserSelectors";
import { useTranslation } from "react-i18next";
import { SupportButton } from "../../components/SupportButton";
import classNames from "classnames";

export type ResetPasswordPageProps = {};

const ResetPasswordPage: React.FC<ResetPasswordPageProps> = ({}) => {
  const dispatch = useDispatch();
  const s = useStyles();
  const navigate = useNavigate();
  // @ts-ignore
  const { t } = useTranslation();
  const { theme } = useThemeContext();

  const {
    requestResetPassword: { fetching: fetchingRequest },
  } = useSelector(userSelector);

  const [email, setEmail] = useState("");

  const { hideErrors, setHideErrors, onInputErrorAnimationEnd } =
    useHideErrors();

  const onPressRemembered = useCallback(() => {
    navigate("/", { replace: true });
  }, []);

  const onPressSend = () => {
    if (validateEmail(email)) {
      dispatch(
        UserActions.requestResetPassword.request({
          email,
          successCallback: () => navigate("/", { replace: true }),
        })
      );
    } else {
      setHideErrors(false);
    }
  };

  return (
    <Box className={classNames(s.globalContainer)}>
      <Box className={classNames(s.container)}>
        <FormTitle>{t("resetPasswordPage.requestCode")}</FormTitle>
        <Box className={classNames(s.rowBetween)}>
          <SmallText>{t("registerScreen.pleaseFill")}</SmallText>
          <SupportButton />
        </Box>
        <Box className={classNames(s.remembered)}>
          <SmallLink onClick={onPressRemembered}>
            {t("resetPasswordPage.rememberedPassword")}
          </SmallLink>
        </Box>

        <Box className={classNames(s.spaceBetween)}>
          <Box className={classNames(s.inputsContainer)}>
            {/* <Box className={classNames(s.validationsContainer)}>
              <ValidationInfo 
                validations={
                  [
                    {id: 'v0', text: t("passwordsMustMatch"), ok: passwordValidations.validatePasswordsMatch(password, confirmPassword)},
                    {id: 'v1', text: t("passwordOneNumber"), ok: passwordValidations.validateOneNumber(password)},
                    {id: 'v2', text: t("passwordOneUppercase"), ok: passwordValidations.validateOneUppercase(password)},
                    {id: 'v3', text: t("passwordLengthMin"), ok: passwordValidations.validatePasswordMinLength(password)}
                  ]
                }
              />
            </Box> */}

            <TextInput
              value={email}
              placeholder={t("email") as string}
              onChangeText={(text) => setEmail(text)}
              validationOk={hideErrors || validateEmail(email)}
              onErrorAnimationEnd={onInputErrorAnimationEnd}
            />
            {/* <TextInput
              value={confirmPassword} 
              placeholder={t("confirmPassword") as string}
              onChangeText={(text) => setConfirmPassword(text)} 
              secure={true}
              validationOk={hideErrors || passwordValidations.validatePasswordsMatch(password, confirmPassword)}
              onErrorAnimationEnd={onInputErrorAnimationEnd}
            />

            <TextInput
              value={firstName} 
              placeholder={t("firstName") as string}
              onChangeText={(text) => setFirstName(text)} 
              validationOk={hideErrors || validateName(firstName)}
              onErrorAnimationEnd={onInputErrorAnimationEnd}
            />
            <TextInput
              value={lastName} 
              placeholder={t("lastName") as string}
              onChangeText={(text) => setLastName(text)} 
              validationOk={hideErrors || validateName(lastName)}
              onErrorAnimationEnd={onInputErrorAnimationEnd}
            /> */}
          </Box>

          <CustomButton
            title={t("buttons.send") as string}
            bgColorActive={theme.colors.yellowMain}
            bgColorUnactive={theme.colors.shark}
            defaultSizing
            onPress={onPressSend}
            spinner={fetchingRequest}
            marginBottom={theme.metrics.x12}
          />
        </Box>
      </Box>
    </Box>
  );
};

ResetPasswordPage.defaultProps = {};

export { ResetPasswordPage };
