import styled from "styled-components";
import { templates } from "../../theme/templates";

export const Wrapper = styled.div`
  display: flex;
  height: 100vh
`;

export const LeftWrapper = styled.div<{ skip: boolean }>`
  ${templates.centerContent};
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.subBackground};
  width: 45%;
  @media (max-width: 1378px) {
    width: 55%;
  }

  @media (max-width: 890px) {
    display: none;
  }
`;
export const LeftWrapperRes = styled.div<{ skip: boolean }>`
  ${templates.centerContent};
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.subBackground};
  width: 100%;
  display: none;

  @media (max-width: 890px) {
    display: ${(p) => (p.skip ? "none" : "flex")};
    padding: 0 16px;
  }
`;
export const Skip = styled.div`
  position: absolute;
  top: 47px;
  right: 28px;
  font-size: 24px;
  color: ${(p) => p.theme.colors.white};
`;
export const RightWrapper = styled.div<{ skip: boolean }>`
  padding-top: 130px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.black};
  width: 55%;
  height: 100vh;
  @media (max-width: 1378px) {
    width: 45%;
  }

  @media (max-width: 890px) {
    display: ${(p) => (p.skip ? "flex" : "none")};
    width: 100%;
    padding-top: 30px;
  }
`;
export const FormContainer = styled.div`
  width: 100%;
  padding: 0 138px;
  @media (max-width: 1378px) {
    padding: 0 30px;
  }
`;
export const Slider = styled.div`
  width: 100%;
  padding: 0 40px;
  justify-content: space-between;
  display: flex;
  align-items: flex-end;
`;
export const Picture = styled.img`
  width: 366px;

  user-select: none;
  @media (max-width: 1378px) {
    width: 290px;
    height: 290px;
  }
  @media (max-width: 890px) {
    width: 100%;
    height: auto;
    max-height: 600px;
    max-width: 700px;
    padding: 0 51px;
  }
`;
export const ImageArrow = styled.img`
  width: 14px;
  height: 22px;

  @media (max-width: 700px) {
    width: 7px;
    height: 12px;
  }
`;
export const BoxWBorder = styled.div<{ left?: boolean }>`
  cursor: pointer;
  ${templates.centerContent};
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  border-radius: 16px;
  border: 2px solid ${(p) => p.theme.colors.white};
  transform: rotate(${(p) => (p.left ? "180deg" : "0deg")});
  user-select: none;
  @media (max-width: 700px) {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 10px;
  }
`;
export const FormTitle = styled.div`
  font-weight: 600;
  font-family: Gilroy-Semibold;
  font-size: 50px;
  line-height: 61px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: ${(p) => p.theme.colors.white};
  margin-top: 60px;
  @media (max-width: 700px) {
    font-size: 24px;
    margin-top: 10px;
  }
`;
export const Title = styled.div`
  margin-top: 60px;
  font-weight: 700;
  font-family: Gilroy-Bold;
  font-size: 42px;
  line-height: 62px;
  text-align: center;
  letter-spacing: -0.3px;
  color: ${(p) => p.theme.colors.white};
  @media (max-width: 700px) {
    font-size: 40px;
  }
  @media (max-width: 575px) {
    font-size: 26px;
    margin-top: 10px;
  }
  @media (max-width: 450px) {
    font-size: 20px;
  }
`;
export const Text = styled.div`
  margin-top: 30px;
  font-weight: 400;
  font-family: Gilroy-Regular;
  padding: 0 67px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.3px;
  color: ${(p) => p.theme.colors.textDescription};
  @media (max-width: 700px) {
    padding: 0 21px;
    font-size: 14px;
    line-height: 16px;
  }
  @media (max-width: 700px) {
    font-size: 14px;
    margin-top: 0;
  }
`;
export const SmallText = styled.div`
  color: ${(p) => p.theme.colors.greySubText};
  font-weight: 500;
  font-family: Gilroy-Medium;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  @media (max-width: 700px) {
    font-size: 14px;
  }
`;
export const SmallLink = styled.span`
  font-weight: 700;
  font-family: Gilroy-Bold;
  margin-left: 5px;
  color: ${(p) => p.theme.colors.yellowMain};
  cursor: pointer;
  user-select: none;
`;
export const ErrorText = styled.div`
  color: ${(p) => p.theme.colors.greenError};
  font-size: 16px;
  line-height: 19px;
  margin: 10px 0;
`;
export const AgreeTerms = styled.div`
  display: flex;
`;
export const OtpInputsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  column-gap: 8px;
`;
export const OtpInput = styled.input`
all: unset;
  ${templates.centerContent};
  text-align: center;
  border: 2px solid ${p => p.theme.colors.yellowMain};
  border-radius: 50%;
  background-color: ${p => p.theme.colors.subBackground};
  color: ${p => p.theme.colors.white};
  font-size: 22px;
  font-family:Gilroy-Medium;
    font-weight:500;
  width: 50px;
  height: 50px;
  &:focus{
    
    border: 2px solid ${p => p.theme.colors.white};
  }
`;
