import i18next from "i18next";
import { Text } from "../../../components/Text/Text";
import { useThemeContext } from "../../../theme";
import { ButtonSend, GridContainer } from "./DownloadDocumentsStyle";
import DownloadDocument from "./DownloadDocument/DownloadDocument";
import { generalSelector } from "../../../logic/general/GeneralSelectors";
import { useSelector } from "react-redux";
import { useVerification } from "../../../components/Modals/Verification/useVerification";
import { ChangeEvent, useEffect } from "react";
import { showToast } from "../../../helpers/alertService";
import { userSelector } from "../../../logic/user/UserSelectors";
import { useNavigate } from "react-router-dom";
import LoaderRing from "../../../helpers/Loader/LoaderRing";

type VerificationButtonType = string;

const DownloadDocuments = ({ setDownloadDocuments }: any) => {
  const { theme } = useThemeContext();
  const { t } = i18next;
  const {
    listDocuments: { data: listData, fetching: fetshingList },
    uploadFile: { data: uploadData, fetching: fetchingUpload },
  } = useSelector(generalSelector);
  const {
    sendDocuments: { data: sendSuccess, fetching: fetchingSend },
    userId,
  } = useSelector(userSelector);

  const {
    modalStep,
    setModalStep,
    selectedImage,
    setSelectedImage,
    documentSelect,
    sendImages,
  } = useVerification();
  const documentImages = {
    main: "/images/verification_passport.png",
    small1: "/images/passport_dark.png",
    small2: "/images/passport_small.png",
    small3: "/images/passport_blur.png",
  };
  const navigation = useNavigate();

  const validImages = () => {
    const mondatory = listData
      ?.filter((el) => el.mandatory === true)
      .map((el) => el.title.replace(/\s/g, "_"));
    const areAllKeysIncluded = mondatory?.every((key) => {
      //@ts-ignore
      return Object.keys(selectedImage).includes(key);
    });

    if (areAllKeysIncluded) {
      sendImages();
    } else {
      showToast({
        type: "error",
        title: "Error",
        info: "All required documents must be uploaded",
      });
    }
  };
  useEffect(() => {
    if (sendSuccess) {
      showToast({
        type: "success",
        title: "Success",
        info: "Documents sent",
      });

      setDownloadDocuments(false);
    }
  }, [sendSuccess]);

  const handleFileChange = (
    event: ChangeEvent<HTMLInputElement>,
    type: VerificationButtonType
  ) => {
    const file = event.target.files?.[0];
    if (file && file.type.includes("image/")) {
      setSelectedImage((prevImages) => ({
        ...prevImages,
        [type]: file,
      }));
    }
  };

  const openFilePicker = (type: VerificationButtonType) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.addEventListener(
      "change",
      (event) =>
        handleFileChange(
          event as unknown as ChangeEvent<HTMLInputElement>,
          type
        ) as void
    );
    fileInput.click();
  };

  return (
    <>
      <>
        <Text
          color={theme.colors.white}
          size="50px"
          fontStyle={theme.fonts.f600}
        >
          Download Documents
        </Text>
        <GridContainer>
          {listData?.map((el, index) => (
            <DownloadDocument
              key={index}
              mandatory={el.mandatory}
              title={el.title}
              images={documentImages}
              openFilePicker={openFilePicker}
              checkImage={selectedImage}
              setSelectedImage={setSelectedImage}
            />
          ))}
        </GridContainer>
        <ButtonSend onClick={() => validImages()}>
          <Text
            color={theme.colors.black}
            fontStyle={theme.fonts.f600}
            size="21px"
          >
            {" "}
            {!fetchingSend && !fetchingUpload ? (
              "Submit"
            ) : (
              <LoaderRing color={theme.colors.black} size="24px" />
            )}
          </Text>
        </ButtonSend>
      </>
    </>
  );
};
export default DownloadDocuments;
