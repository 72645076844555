import { Action } from "@reduxjs/toolkit";
import { ApiResponse } from "apisauce";
import _ from "lodash";
import {
  call,
  put,
  select,
  takeLatest,
  takeEvery,
  take,
  all,
} from "redux-saga/effects";
import { api, RequestErrorFromResponse } from "../../api";
import { takeLeading } from "../../store/sagaHelper";
import { CryptoAccountDropdownItem, CryptoActions, MarketInfo } from "./CryptoRedux";
import {
  BuyCryptoElementsResponse,
  ChangeAccountStatusResponse,
  CoingeckoMarketChartsResponse,
  ConvertCurrencyResponse,
  DeleteAccountResponse,
  DepositResponse,
  FindAccountsResponse,
  GetAccountsListResponse,
  OpenAccountResponse,
  RemitResponse,
  SwapReceiveResponse,
  TransferResponse,
} from "../../types/api";
import { userIdSelector, userTokenSelector } from "../user/UserSelectors";
import { showToast } from "../../helpers/alertService";
import { CONFIG } from "../../config";
import { GeneralActions } from "../general/GeneralRedux";
import { exchangeFromCoinSelector, exchangeToCoinSelector, getAccountsListSelector } from "./CryptoSelectors";
import { currencyListToOpenSelector } from "../general/GeneralSelectors";
import { calculateChange } from "../../helpers/price";
// import { pop } from '../../helpers/navigationHelpers';
import i18next from "i18next";
import { ERROR_MESSAGE_2FA_REQUIRED, UserActions } from "../user/UserRedux";
import { retryApiCall, zedApiResponseValidator } from "../../helpers/sagaHelpers";

function* prepareRequiredCryptoThings(action: Action) {
  if (CryptoActions.prepareRequiredCryptoThings.match(action)) {
    try {
      yield all([
        take(CryptoActions.getAccountsList.success),
        take(GeneralActions.currencyListToOpen.success),
      ]);

      const getAccountsList: ReturnType<typeof getAccountsListSelector> =
        yield select(getAccountsListSelector);
      const userAccounts = getAccountsList.data;

      // USDZ acc is required. If user already has USDZ account then end saga
      if (
        userAccounts &&
        userAccounts.value.find((account) => account.currency_code === "USDZ")
      )
        return;

      const userId: number = yield select(userIdSelector);

      const currencyListToOpen: ReturnType<typeof currencyListToOpenSelector> =
        yield select(currencyListToOpenSelector);
      const availableCurrencies = currencyListToOpen.data;

      let usdzCurrencyId: number = CONFIG.USDZ_CURRENCY_ID;

      if (availableCurrencies) {
        const foundCurrency = availableCurrencies.currencies.find(
          (currency) => currency.code === "USDZ"
        );
        if (foundCurrency) usdzCurrencyId = foundCurrency.id;
      }

      // Create required USDZ acc
      yield put(
        CryptoActions.openAccount.request({
          customer_id: userId as number,
          currency_id: usdzCurrencyId,
          hideToasts: true,
        })
      );
    } catch (error) {
      console.log("prepareRequiredCryptoThings error:", error);
    }
  }
}

function* getAccountsListRequest(action: Action) {
  if (CryptoActions.getAccountsList.request.match(action)) {
    try {
      const getAccountsListResponse: ApiResponse<GetAccountsListResponse> = yield call(retryApiCall, {
        apiRequest: api.getAccountsListGet,
        args: [action.payload]
      });
      
      if (getAccountsListResponse.ok && getAccountsListResponse.data?.success) {
        const responseData = getAccountsListResponse.data;

        const cryptoAccountsDropdownItems = responseData.value.map((a) => ({
          ...a,
          title: a.name,
          value: a.id,
        }));

        if (cryptoAccountsDropdownItems.length > 0) {
          let newExchangeFromCoin: CryptoAccountDropdownItem = cryptoAccountsDropdownItems[0];
          const exchangeFromCoin: CryptoAccountDropdownItem | null = yield select(exchangeFromCoinSelector);

          if (exchangeFromCoin) {
            const updatedExchangeFromCoin = cryptoAccountsDropdownItems.find((account) => account.id === exchangeFromCoin.id);
            if (updatedExchangeFromCoin)
              newExchangeFromCoin = updatedExchangeFromCoin;
          }
          
          yield put(CryptoActions.setExchangeFromCoin(newExchangeFromCoin));
        }

        if (cryptoAccountsDropdownItems.length > 1) {
          let newExchangeToCoin: CryptoAccountDropdownItem = cryptoAccountsDropdownItems[1];
          const exchangeToCoin: CryptoAccountDropdownItem | null = yield select(exchangeToCoinSelector);

          if (exchangeToCoin) {
            const updatedExchangeToCoin = cryptoAccountsDropdownItems.find((account) => account.id === exchangeToCoin.id);
            if (updatedExchangeToCoin)
              newExchangeToCoin = updatedExchangeToCoin;
          }

          yield put(CryptoActions.setExchangeToCoin(newExchangeToCoin));
        }

        yield put(CryptoActions.setExchangeScreenAccounts(cryptoAccountsDropdownItems));
        yield put(CryptoActions.getAccountsList.success(responseData));
      } else {
        throw new RequestErrorFromResponse(getAccountsListResponse);
      }
    } catch (error) {
      yield put(CryptoActions.getAccountsList.failure(error));
    }
  }
}

function* openAccountRequest(action: Action) {
  if (CryptoActions.openAccount.request.match(action)) {
    try {
      const openAccountResponse: ApiResponse<OpenAccountResponse> = yield call(
        api.openAccountPost,
        action.payload
      );
      if (openAccountResponse.ok && openAccountResponse.data?.success) {
        const responseData = openAccountResponse.data;

        if (!action.payload.hideToasts) {
          yield call(showToast, {
            title: i18next.t("success"),
            info: responseData.message,
            type: "success",
          });
        }

        // Fetch user accounts (wallets) again
        yield put(
          CryptoActions.getAccountsList.request({
            total_currency: CONFIG.ZED_API_TOTAL_CURRENCY,
          })
        );

        yield put(CryptoActions.setShowAvailableAccountsStillNotOpened(false));

        yield put(CryptoActions.openAccount.success(responseData));
      } else {
        throw new RequestErrorFromResponse(openAccountResponse);
      }
    } catch (error) {
      yield put(CryptoActions.openAccount.failure(error));
    }
  }
}

function* depositRequest(action: Action) {
  if (CryptoActions.deposit.request.match(action)) {
    try {
      const token: string = yield select(userTokenSelector);

      if (action.payload.type === "Deposit_Requested" && action.payload.fData) {
        const uploadFileResponse: ApiResponse<any> = yield call(
          api.uploadFile,
          action.payload.fData,
          token
        );

        if (uploadFileResponse.ok && uploadFileResponse?.data?.success) {
          action.payload.receipt = uploadFileResponse.data.value[0].Value;
        } else {
          yield call(showToast, {
            title: i18next.t("oops") as string,
            info: uploadFileResponse?.data?.message ?? "Image was not uploaded",
            type: "error",
          });

          throw new RequestErrorFromResponse(uploadFileResponse);
        }
      }

      delete action.payload.fData;

      const depositResponse: ApiResponse<DepositResponse> = yield call(
        api.depositPost,
        action.payload
      );
      if (depositResponse.ok && depositResponse.data?.success) {
        const responseData = depositResponse.data;

        yield call(showToast, {
          title: i18next.t("success"),
          info: responseData.message,
          type: "success",
        });

        // yield call(pop);

        yield put(CryptoActions.deposit.success(responseData.value));
      } else {
        yield call(showToast, {
          title: i18next.t("oops") as string,
          info: depositResponse?.data?.message ?? "Error",
          type: "error",
        });

        throw new RequestErrorFromResponse(depositResponse);
      }
    } catch (error) {
      yield put(CryptoActions.deposit.failure(error));
    }
  }
}

function* buyCryptoElementsRequest(action: Action) {
  if (CryptoActions.buyCryptoElements.request.match(action)) {
    try {
      const buyCryptoElementsResponse: ApiResponse<BuyCryptoElementsResponse> =
        yield call(api.buyCryptoElementsGet, action.payload);
      if (
        buyCryptoElementsResponse.ok &&
        buyCryptoElementsResponse.data?.success
      ) {
        const responseData = buyCryptoElementsResponse.data;
        yield put(CryptoActions.buyCryptoElements.success(responseData.value));
      } else {
        throw new RequestErrorFromResponse(buyCryptoElementsResponse);
      }
    } catch (error) {
      yield put(CryptoActions.buyCryptoElements.failure(error));
    }
  }
}

function* deleteAccountRequest(action: Action) {
  if (CryptoActions.deleteAccount.request.match(action)) {
    try {
      const deleteAccountResponse: ApiResponse<DeleteAccountResponse> =
        yield call(api.deleteAccountPost, action.payload);
      if (deleteAccountResponse.ok && deleteAccountResponse.data?.success) {
        const responseData = deleteAccountResponse.data;

        yield call(showToast, {
          title: i18next.t("success"),
          info: responseData.message,
          type: "success",
        });

        yield put(CryptoActions.deleteAccount.success(responseData));
      } else {
        throw new RequestErrorFromResponse(deleteAccountResponse);
      }
    } catch (error) {
      yield put(CryptoActions.deleteAccount.failure(error));
    }
  }
}

function* changeAccountStatusRequest(action: Action) {
  if (CryptoActions.changeAccountStatus.request.match(action)) {
    try {
      const changeAccountStatusResponse: ApiResponse<ChangeAccountStatusResponse> =
        yield call(api.changeAccountStatusPost, action.payload);
      if (
        changeAccountStatusResponse.ok &&
        changeAccountStatusResponse.data?.success
      ) {
        const responseData = changeAccountStatusResponse.data;

        yield call(showToast, {
          title: i18next.t("success"),
          info: responseData.message,
          type: "success",
        });

        yield put(CryptoActions.changeAccountStatus.success(responseData));
      } else {
        throw new RequestErrorFromResponse(changeAccountStatusResponse);
      }
    } catch (error) {
      yield put(CryptoActions.changeAccountStatus.failure(error));
    }
  }
}

function* transferRequest(action: Action) {
  if (CryptoActions.transfer.request.match(action)) {
    try {
      if (action.payload.check_only)
        yield put(CryptoActions.setTransferCheckOnlyFetching(true));

      const transferResponse: ApiResponse<TransferResponse> = yield call(
        api.transferPost,
        action.payload
      );
      if (transferResponse.ok && transferResponse.data?.success) {
        const responseData = transferResponse.data;

        if (action.payload.check_only) {
          const backRate = (1 / Number(responseData.value.rate)).toString();
          yield put(
            CryptoActions.setTransferCheckOnlyResult({
              ...responseData.value,
              backRate,
            })
          );
          
          if (action.payload.successCallback) {
            yield call(action.payload.successCallback);
          }

          yield put(CryptoActions.transfer.finish());
          return;
        }

        yield put(
          CryptoActions.getAccountsList.request({
            total_currency: CONFIG.ZED_API_TOTAL_CURRENCY,
          })
        );

        if (action.payload.successCallback) {
          yield call(action.payload.successCallback);
        }

        yield call(showToast, {
          title: i18next.t("success"),
          info: responseData.message,
          type: "success",
        });

        // if (action.payload.to_blockchain === "none")
        //   yield call(pop);
        // else
        //   yield call(pop, 2);

        yield put(CryptoActions.transfer.success(responseData));
      } else {
        if (
          !action.payload.otp_code &&
          transferResponse.data?.message === ERROR_MESSAGE_2FA_REQUIRED
        ) {
          yield put(UserActions.setEnabled2FA(true));

          yield call(showToast, {
            title: i18next.t("securityCheck"),
            info: i18next.t("pleaseEnter2FA"),
            type: "success",
          });
        } else if (action.payload.check_only === false || action.payload.showErrorToastOnCheck) {
          yield call(showToast, {
            title: i18next.t("oops") as string,
            // @ts-ignore
            info: transferResponse?.data?.message ?? "Error",
            type: "error",
          });
        }

        throw new RequestErrorFromResponse(transferResponse);
      }
    } catch (error) {
      yield put(CryptoActions.setTransferCheckOnlyFetching(false));
      yield put(CryptoActions.transfer.failure(error));
    }
  }
}

function* coingeckoMarketChartsRequest(action: Action) {
  if (CryptoActions.coingeckoMarketCharts.request.match(action)) {
    try {
      const coingeckoMarketChartsResponse: ApiResponse<CoingeckoMarketChartsResponse> = yield call(retryApiCall, {
        apiRequest: api.coingeckoMarketChartsGet
      });
      if (
        coingeckoMarketChartsResponse.ok &&
        coingeckoMarketChartsResponse.data
      ) {
        const responseData = coingeckoMarketChartsResponse.data;

        try {
          let marketCapOldValue = 0;
          let marketCapNewValue = 0;
          let marketVolumeOldValue = 0;
          let marketVolumeNewValue = 0;
          const marketCap: MarketInfo = {
            value: 0,
            change: 0,
          };
          const marketVolume: MarketInfo = {
            value: 0,
            change: 0,
          };

          Object.keys(responseData.table).forEach((key) => {
            const marketCaps = responseData.table[key].market_caps;
            const marketVolumes = responseData.table[key].total_volumes;
            const prices = responseData.table[key].prices;
            marketCapOldValue += marketCaps[0][1];
            marketCapNewValue += marketCaps[marketCaps.length - 1][1];
            marketVolumeOldValue += marketVolumes[0][1];
            marketVolumeNewValue += marketVolumes[marketVolumes.length - 1][1];

            const priceOld = prices[0][1];
            const priceNew = prices[prices.length - 1][1];
            const coinPriceChange = calculateChange(priceOld, priceNew) * 100;
            responseData.table[key].priceChange = coinPriceChange;
          });

          marketCap.value = marketCapNewValue;
          marketCap.change =
            calculateChange(marketCapOldValue, marketCapNewValue) * 100; // * 100%

          marketVolume.value = marketVolumeNewValue;
          marketVolume.change =
            calculateChange(marketVolumeOldValue, marketVolumeNewValue) * 100; // * 100%

          yield put(CryptoActions.setMarketCap(marketCap));
          yield put(CryptoActions.setMarketVolume(marketVolume));
        } catch (e) {
          console.log(
            "coingeckoMarketChartsRequest marketInfo calculation error",
            e
          );
        }

        yield put(
          CryptoActions.coingeckoMarketCharts.success(responseData.table)
        );
      } else {
        throw new RequestErrorFromResponse(coingeckoMarketChartsResponse);
      }
    } catch (error) {
      yield put(
        CryptoActions.coingeckoMarketCharts.failure(error)
      );
    }
  }
}

function* convertCurrencyRequest(action: Action) {
  if (CryptoActions.convertCurrency.request.match(action)) {
    try {
      const convertCurrencyResponse: ApiResponse<ConvertCurrencyResponse> =
        yield call(api.convertCurrencyPost, action.payload);
      if (convertCurrencyResponse.ok && convertCurrencyResponse.data?.success) {
        const responseData = convertCurrencyResponse.data;

        const convertData = {
          ...responseData.value,
          backRate: 1 / responseData.value.rate,
        };

        yield put(CryptoActions.convertCurrency.success(convertData));
      } else {
        throw new RequestErrorFromResponse(convertCurrencyResponse);
      }
    } catch (error) {
      yield put(CryptoActions.convertCurrency.failure(error));
    }
  }
}

function* remitRequest(action: Action) {
  if (CryptoActions.remit.request.match(action)) {
    try {
      const remitResponse: ApiResponse<RemitResponse> = yield call(
        api.remitPost,
        action.payload
      );
      if (remitResponse.ok && remitResponse.data?.success) {
        const responseData = remitResponse.data;

        yield call(showToast, {
          title: i18next.t("success"),
          info: responseData.message,
          type: "success",
        });

        // yield call(pop, 2);

        yield put(CryptoActions.remit.success(responseData.value));
      } else {
        yield call(showToast, {
          title: i18next.t("oops") as string,
          info: remitResponse?.data?.message ?? "Error",
          type: "error",
        });

        throw new RequestErrorFromResponse(remitResponse);
      }
    } catch (error) {
      yield put(CryptoActions.remit.failure(error));
    }
  }
}

function* swapReceiveRequest(action: Action) {
  if (CryptoActions.swapReceive.request.match(action)) {
    try {      
      const swapReceiveResponse: ApiResponse<SwapReceiveResponse> = yield call(
        api.swapReceiveGet,
        action.payload
      );
      if (swapReceiveResponse.ok && swapReceiveResponse.data?.success) {
        const responseData = swapReceiveResponse.data;

        const valueWithBackRate = responseData.value.map((v) => ({...v, backRate: 1 / v.swap_rate}));

        if (action.payload.successCallback) {
          yield call(action.payload.successCallback, valueWithBackRate);
        }

        if (action.payload.pay_account_id) {
          yield put(CryptoActions.updateSwapReceiveTable({pay_account_id: action.payload.pay_account_id, value: valueWithBackRate}));
        }

        yield put(CryptoActions.swapReceive.success(valueWithBackRate));
      } else {
        throw new RequestErrorFromResponse(swapReceiveResponse);
      }
    } catch (error) {
      yield put(CryptoActions.swapReceive.failure(error));
    }
  }
}

function* findAccountsRequest(action: Action): any {
  if (CryptoActions.findAccounts.request.match(action)) {
    try {
      const findAccountsResponse: ApiResponse<FindAccountsResponse> = yield call(
        api.findAccountsGet,
        action.payload
      );
      if (zedApiResponseValidator(findAccountsResponse)) {
        const responseData = findAccountsResponse.data!;
        const cryptoAccountsDropdownItems = responseData.value.map((a) => ({
          ...a,
          title: a.name,
          value: a.id,
        }));

        yield put(CryptoActions.updateAccountsByUserEmailTable({
          email: action.payload.email,
          accounts: cryptoAccountsDropdownItems
        }));
        yield put(CryptoActions.findAccounts.success());
      } else {
        yield call(showToast, {
          title: i18next.t("oops") as string,
          info: findAccountsResponse?.data?.message ?? "Error",
          type: "error",
        });

        throw new RequestErrorFromResponse(findAccountsResponse);
      }
    } catch (error) {
      yield put(CryptoActions.findAccounts.failure(error));
    } 
  }
}

export function* CryptoSaga() {
  yield* [
    takeLatest(
      CryptoActions.getAccountsList.request.type,
      getAccountsListRequest
    ),
    takeLatest(CryptoActions.openAccount.request.type, openAccountRequest),
    takeLatest(CryptoActions.deposit.request.type, depositRequest),
    takeLatest(
      CryptoActions.buyCryptoElements.request.type,
      buyCryptoElementsRequest
    ),
    takeLatest(CryptoActions.deleteAccount.request.type, deleteAccountRequest),
    takeLatest(
      CryptoActions.changeAccountStatus.request.type,
      changeAccountStatusRequest
    ),
    takeLeading(
      CryptoActions.prepareRequiredCryptoThings.type,
      prepareRequiredCryptoThings
    ),
    takeLatest(CryptoActions.transfer.request.type, transferRequest),
    takeLeading(
      CryptoActions.coingeckoMarketCharts.request.type,
      coingeckoMarketChartsRequest
    ),
    takeLatest(
      CryptoActions.convertCurrency.request.type,
      convertCurrencyRequest
    ),
    takeLatest(CryptoActions.remit.request.type, remitRequest),
    takeLatest(CryptoActions.swapReceive.request.type, swapReceiveRequest),
    takeLatest(CryptoActions.findAccounts.request.type, findAccountsRequest),
  ];
}
