import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SocialActions } from "../../../logic/social/SocialRedux";
import { socialSelector } from "../../../logic/social/SocialSelectors";
import { userIdSelector } from "../../../logic/user/UserSelectors";

export const useTicketsList = () => {
  const dispatch = useDispatch();
  const [pickedPeriod, setPickedPeriod] = useState(0);
  const {
    ticketsList: { fetching: loading, data },
  } = useSelector(socialSelector);
  const user_id = useSelector(userIdSelector);

  const getList = () => {
    dispatch(
      SocialActions.ticketsList.request({
        items: 20,
        user_id: user_id as number,
        id: user_id as number,
      })
    );
  };

  useEffect(() => {
    getList();
  }, []);

  return {
    pickedPeriod,
    loading,
    data,
    setPickedPeriod,
    getList,
  };
};
