import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

const logoSize = 36;

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    height: logoSize,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  pointer: {
    cursor: 'pointer'
  },
  containerHovered: {
    backgroundColor: 'red'
  },
  left: {
    display: 'flex',
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    // backgroundColor: 'blue'
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '50%',
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  logoCont: {
    display: 'flex',
    flexDirection: 'column',
    width: logoSize,
    height: logoSize,
    minWidth: logoSize,
    minHeight: logoSize,
    borderRadius: 8,
    overflow: 'hidden',
    // backgroundColor: 'red',
    marginRight: theme.metrics.x2
  },
  whiteBold: {
    color: theme.colors.white,
    fontFamily: theme.fonts.f800.fontFamily,
    fontSize: 17,
    marginLeft: 0,
    marginTop: 0,
    marginRight: 0,
    marginBottom: theme.metrics.x
  },
  greyText: {
    color: theme.colors.greySubText,
    fontFamily: theme.fonts.f600.fontFamily,
    fontSize: 14,
    margin: 0,
    whiteSpace: 'nowrap',
    // backgroundColor: 'red'
  },
  changeText: {
    fontFamily: theme.fonts.f600.fontFamily,
    fontSize: 15,
    margin: 0,
  },
  actionButton: {
    display: 'flex',
    flexDirection: 'column',
    height: 32,
    width: 60
  },
  fullSpace: {
    width: '100%',
    height: '100%'
  },
  rightLabel: {
    fontFamily: theme.fonts.f600.fontFamily,
    fontSize: 16,
    margin: 0,
  }
});