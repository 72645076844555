import styled from "styled-components";

export const Wrapper = styled.div`
padding: 32px 24px;
`;
export const MarketItemsBlock = styled.div`
  display: flex;
  column-gap: 24px;
  overflow: hidden; /* Скрываем скролл по умолчанию */
  padding-bottom: 10px;
  margin-bottom: 80px;
  transition: overflow 0.3s ease;

  /* Стили скролла при наведении на блок */
  &:hover {
    overflow: auto;
    margin-bottom: 75px;
  }

  /* Стили для скролла */
  scrollbar-width: thin;
  scrollbar-color: ${(p) => p.theme.colors.scrollbar};

  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${(p) => p.theme.colors.greySubText};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.colors.white};
    border-radius: 3px;
  }
`;
export const ContainerSB = styled.div`
display: flex;
justify-content: space-between;
`;



