import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useStyles } from "./ProgressBarSeparatedStyles";
import { Box } from "@material-ui/core";
import { useDependentValue } from "../../helpers/customHooks";
import { theme } from "../../theme/default/theme";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
  runOnJS,
  Easing,
  cancelAnimation,
} from "react-native-reanimated";
import cn from "classnames";
import moment from "moment";

export type ProgressBarSeparatedProps = {
  numOfBlocks: number;
  currentIndex: number;
  onBlockAnimationEnd?: (blockIndex: number) => void;
  currentDuration?: number;
  height?: number;
  paddingHorizontal?: number;
  ref?: any;
};

export type ProgressBarSeparatedMethods = {
  pause: () => void;
  play: () => void;
};

const ProgressBarSeparated: React.FC<ProgressBarSeparatedProps> = forwardRef(
  (
    {
      numOfBlocks,
      currentIndex,
      onBlockAnimationEnd,
      currentDuration,
      height,
      paddingHorizontal,
    },
    ref
  ) => {
    const s = useStyles();

    const [lastAnimationStartTimestamp, setLastAnimationStartTimestamp] =
      useState(0);
    // If user paused, this value will be needed
    const [animationLasted, setAnimationLasted] = useState(0);

    const blocks = useDependentValue(() => {
      return new Array(numOfBlocks).fill(0).map((_, i) => i);
    }, [numOfBlocks]);

    const width = useSharedValue(0);

    const animatedWidthStyle = useAnimatedStyle(
      () => ({
        display: "flex",
        flexDirection: "column",
        width: width.value + "%",
        height: "100%",
        backgroundColor: theme.colors.white,
      }),
      [width]
    );

    const startWidthAnimation = (duration: number) => {
      if (duration < 0) duration = 0;

      setLastAnimationStartTimestamp(moment().valueOf());

      width.value = withTiming(
        100,
        { duration, easing: Easing.linear },
        (finished) =>
          finished &&
          onBlockAnimationEnd &&
          runOnJS(onBlockAnimationEnd)(currentIndex)
      );
    };

    useEffect(() => {
      width.value = 0;
      setAnimationLasted(0);
      startWidthAnimation(currentDuration as number);
    }, [currentIndex, currentDuration]);

    useImperativeHandle(
      ref,
      (): ProgressBarSeparatedMethods => ({
        pause: () => {
          cancelAnimation(width);
          const animationLasted =
            moment().valueOf() - lastAnimationStartTimestamp;
          setAnimationLasted((prev) => prev + animationLasted);
        },
        play: () => {
          // console.log('animationLasted', animationLasted)
          // console.log('remainingtime', currentDuration as number - animationLasted)
          startWidthAnimation((currentDuration as number) - animationLasted);
        },
      }),
      [lastAnimationStartTimestamp, currentDuration, animationLasted]
    );

    return (
      <Box
        className={cn(s.container)}
        style={{
          height,
          paddingRight: paddingHorizontal,
          paddingLeft: paddingHorizontal,
        }}
      >
        {blocks.map((b: number, index: number) => (
          <Box
            key={b}
            className={cn(s.block)}
            style={{
              backgroundColor:
                index < currentIndex
                  ? theme.colors.white
                  : theme.colors.greySubText,
              marginRight: index === numOfBlocks - 1 ? 0 : theme.metrics.x,
            }}
          >
            {index === currentIndex && (
              <Animated.View style={animatedWidthStyle} />
            )}
          </Box>
        ))}
      </Box>
    );
  }
);

ProgressBarSeparated.defaultProps = {
  height: 2,
  paddingHorizontal: theme.metrics.x,
  currentDuration: 5e3,
};

export { ProgressBarSeparated };
