import { useSelector } from "react-redux";
import { useDependentState, useDependentValue } from "../helpers/customHooks";
import { cryptoSelector } from "../logic/crypto/CryptoSelectors";

export const useAccountDropdownData = (allowedCurrencies?: string[]) => {
  const {
    exchangeScreenAccounts: accounts,
  } = useSelector(cryptoSelector);

  const accountDropdownData = useDependentValue(() => {
    if (!accounts)
      return [];

    return Object.values(accounts).map((accounts) => ({
      ...accounts,
      title: accounts.title, 
      value: accounts.id,
      id: accounts.id
    })).filter((accounts) => {
      if (allowedCurrencies === undefined)
        return true;

      return allowedCurrencies.includes(accounts.title);
    });
  }, [accounts]);
   
  const accountsDropdownDataFiltered = useDependentValue(() => {
    return accountDropdownData.filter((a: any) => a.currency_type === "Crypto");
  }, [accountDropdownData]);

  const [account, setAccount] = useDependentState(() => {
    return accountsDropdownDataFiltered[0] ?? {title: "Wallet: ", value: 0};
  }, [accountsDropdownDataFiltered]);

  return {
    accountDropdownData: accountsDropdownDataFiltered,
    account,
    setAccount
  };
};