import { FC, useState } from "react";
import { Text } from "../../../../components/Text/Text";
import { useThemeContext } from "../../../../theme";
import {
  Button,
  Cross,
  InputContainer,
  InputField,
  MainWrapperInput,
  OpenBtn,
  SelectArea,
  TagItem,
  TagsWrapper,
} from "../CreateNFTStyles";
import i18next from "i18next";

type Props = {
  title: string;
  type: "select" | "input" | "select file";
  handleInput?: any;
  name?: string;
  value?: string | number;
  handleEnterKey?: any;
  tags?: string[];
  deleteTag?: any;
  selectedImage?: any;
  setSelectedImage?: any;
  items?: any;
  file?: boolean;
  error?: boolean;
  propIndex?: number;
};

const Input: FC<Props> = ({
  title,
  type,
  handleInput,
  name,
  value,
  handleEnterKey,
  tags,
  deleteTag,
  selectedImage,
  setSelectedImage,
  items,
  file,
  error,
  propIndex,
}) => {
  const { theme } = useThemeContext();
  const { t } = i18next;
  const [selectIsOpen, setSelectIsOpen] = useState(false);

  const handleImageUpload = async () => {
    try {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = "image/*";
      fileInput.addEventListener("change", async (event) => {
        const inputElement = event.target as HTMLInputElement;
        const selectedFile = inputElement.files?.[0];

        if (selectedFile && name) {
          setSelectedImage((prev: any) => ({ ...prev, [name]: selectedFile }));
        }
      });

      fileInput.click();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <MainWrapperInput>
        {name === "tags" || type === "select file" || (
          <Text
            color="white"
            size="18px"
            fontStyle={theme.fonts.f600}
            margin="0 0 10px 10px"
          >
            {title + " :"}
          </Text>
        )}

        <InputContainer file={file} error={!error}>
          {type !== "input" && (
            <Text
              color={theme.colors.white}
              size="18px"
              fontStyle={theme.fonts.f400}
              whiteSpace={true}
            >
              {value}
            </Text>
          )}
          {type === "input" && (name === "type" || name === "value") && (
            <InputField
              name={name}
              onChange={(e) => handleInput(e, propIndex, name)}
              placeholder={""}
              value={value}
            />
          )}

          {type === "input" && name !== "type" && name !== "value" && (
            <InputField
              name={name}
              onChange={(e) => handleInput(e)}
              placeholder={""}
              value={value}
              onKeyDown={handleEnterKey && handleEnterKey}
            />
          )}

          {type === "select file" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                rowGap: "20px",
              }}
            >
              <Button onClick={handleImageUpload}>
                {name === "poster" && t("createNftScreen.selectPoster")}
                {name === "file" && t("createNftScreen.selectFile")}
              </Button>
              {selectedImage ? (
                <div>
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    alt="Selected Image"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  />
                </div>
              ) : null}
            </div>
          )}

          {type === "select" && (
            <OpenBtn
              open={selectIsOpen}
              onClick={() => setSelectIsOpen(!selectIsOpen)}
            >
              <img src="/images/Select_arrow_Down.png" />
            </OpenBtn>
          )}
          {selectIsOpen && (
            <SelectArea>
              {items.map((el: any, index: any) => (
                <div
                  key={index}
                  onClick={() => {
                    handleInput(name, el);
                    setSelectIsOpen(false);
                  }}
                >
                  <Text
                    key={index}
                    color={theme.colors.white}
                    fontStyle={theme.fonts.f600}
                    size="18px"
                    hoverColor={theme.colors.yellowMain}
                  >
                    {el.title}
                  </Text>
                </div>
              ))}
            </SelectArea>
          )}
        </InputContainer>
        {type === "input" && tags ? (
          <TagsWrapper>
            {tags.map((tag, index) => (
              <TagItem key={index}>
                <Text
                  color={theme.colors.white}
                  size="16px"
                  fontStyle={theme.fonts.f600}
                >
                  {tag}
                </Text>
                <Cross
                  src="/images/NFT_Page/cross.svg"
                  onClick={() => deleteTag(index)}
                />
              </TagItem>
            ))}
          </TagsWrapper>
        ) : null}
      </MainWrapperInput>
    </>
  );
};
export default Input;
