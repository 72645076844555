import { restActionCreatorHelper } from "./../../helpers/restActionCreatorHelper";

const cardsRestAction = restActionCreatorHelper(`cards`);

export const GetMyCardsRestActions = cardsRestAction("getMyCards");
export const CreateVirtualCardRestActions =
  cardsRestAction("createVirtualCard");
export const CreateVisaCardRestActions = cardsRestAction("createVisaCard");
export const GetTransactionsRestActions = cardsRestAction("getTransactions");
export const AllowedCountriesRestActions = cardsRestAction("allowedCountries");
export const CardFeaturesRestActions = cardsRestAction("cardFeatures");
export const IssueVirtualCardRestActions = cardsRestAction("issueVirtualCard");
export const GetCVVRestActions = cardsRestAction("getCVV");
export const LoadVirtualCardRestActions = cardsRestAction("loadVirtualCard");
export const LoadPhysicalCardRestActions = cardsRestAction("loadPhysicalCard");
export const CardTransactionsRestActions = cardsRestAction("cardTransactions");
export const CardToCardRestActions = cardsRestAction("cardToCard");
export const NewHolderRestActions = cardsRestAction("newHolder");
export const SubmitKycRestActions = cardsRestAction("submitKyc");
export const ActivateRestActions = cardsRestAction("activate");
export const UnloadVirtualCardRestActions = cardsRestAction("unloadVirtualCard");
export const UnloadPhysicalCardRestActions = cardsRestAction("unloadPhysicalCard");
export const CardCommissionsRestActions = cardsRestAction("cardCommissions");