import React, { useEffect } from "react";
import Header from "./Header";
import SideBar from "./SideBar";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../../logic/user/UserRedux";
import { userSelector } from "../../logic/user/UserSelectors";
import { ActionModal } from "../../components/ActionModal";

const Layout = () => {
  const dispatch = useDispatch();

  const { actionModalState } = useSelector(userSelector);

  const scroll = (element: any) => {
    const point = document.getElementById(element);
    if (point) {
      point.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  // ComponentDidMount
  useEffect(() => {
    dispatch(
      UserActions.whoAmI.request({ fetchAdditionalInfoOnSuccess: true })
    );
  }, []);

  return (
    <>
      <SideBar />
      <Header scroll={scroll} />

      <ActionModal
        visible={actionModalState?.visible ?? false}
        onClose={() => dispatch(UserActions.setActionModalState(null))}
        onPressYes={actionModalState?.onPressYes ?? (() => {})}
        onPressNo={actionModalState?.onPressNo}
        title={actionModalState?.title}
        text={actionModalState?.text}
      />
    </>
  );
};
export default Layout;
