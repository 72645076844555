import styled from "styled-components";
import { templates } from "../../theme/templates";
import { makeStyles } from '@material-ui/core/styles';
import { theme } from "../../theme/default/theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 23px;
  background-color: ${(p) => p.theme.colors.black};
  min-height: 100vh;
  @media (max-width: 570px) {
    padding: 24px 8px;
  }
`;
export const Line = styled.div`
  margin-top: 36px;
  margin-bottom: 36px;
  height: 1px;
  background-color: ${(p) => p.theme.colors.subBackground};
`;
export const SmallBtn = styled.div`
  ${templates.centerContent};
  padding: 9px;
  background-color: ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.black};
  font-size: 14px;
  font-weight: 700;
  font-family: Gilroy-Bold;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
  @media (max-width: 570px) {
    height: 24px;
    width: auto;
    font-size: 12px;
  }
`;
export const ExchangeItemsBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 24px;
  justify-content: center;
  margin-top: 40px;
  column-gap: 24px;
`;
export const Image = styled.img`
  @media (max-width: 570px) {
    width: 151px;
    height: 151px;
  }
`;

export const useStyles = makeStyles({
  horizontalScrollCont: {
    display: 'flex', 
    flexDirection: 'row', 
    marginRight: theme.metrics.x2, 
    overflowX: "scroll", 
    paddingTop: theme.metrics.x6,
    paddingBottom: theme.metrics.x5
  }
});