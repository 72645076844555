import React from "react";
import styled from "styled-components";
import { theme } from "../../../theme/default/theme";

const StyledText = styled.span<{ isNumber: boolean }>`
  color: ${(props) => (props.isNumber ? theme.colors.green : "inherit")};
`;

export const highlightNumbers = (str: string): JSX.Element => {
  const numbersRegex = /(\$|\d+\.\d+|\d+|\+|%)/g;
  let match;

  const highlightedText: JSX.Element[] = [];

  while ((match = numbersRegex.exec(str)) !== null) {
    const { index } = match;
    const nonNumberPart = str.slice(
      highlightedText.reduce(
        (acc, el) => acc + el.props.children.toString().length,
        0
      ),
      index
    );
    highlightedText.push(
      <React.Fragment key={`nonNumber_${index}`}>
        {nonNumberPart}
      </React.Fragment>
    );

    const numberPart = match[0];
    if (numberPart === "6") {
      highlightedText.push(
        <React.Fragment key={`number_${index}`}>{numberPart}</React.Fragment>
      );
    } else {
      highlightedText.push(
        <StyledText isNumber key={`number_${index}`}>
          {numberPart}
        </StyledText>
      );
    }
  }

  if (highlightedText.length === 0) {
    highlightedText.push(
      <React.Fragment key={`nonNumber_0`}>{str}</React.Fragment>
    );
  } else {
    const lastIndex = highlightedText.reduce(
      (acc, el) => acc + el.props.children.toString().length,
      0
    );
    highlightedText.push(
      <React.Fragment key={`nonNumber_${lastIndex}`}>
        {str.slice(lastIndex)}
      </React.Fragment>
    );
  }

  return <span>{highlightedText}</span>;
};
