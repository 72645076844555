import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { Picture } from "../LoginPageStyles";
import { SliderItem } from "../LoginPage";
import { FC, useRef, useState } from "react";

import SwiperCore, { Navigation } from "swiper";

SwiperCore.use([Navigation]);

type Props = {
  data: SliderItem[];
  activeSlideIndex: number;
  handleSlideChange: (swiper: any) => void;
  swiperRef: React.MutableRefObject<any>;
};

const SliderLogin: FC<Props> = ({
  data,
  activeSlideIndex,
  handleSlideChange,
  swiperRef,
}) => {
  return (
    <Swiper
      spaceBetween={30}
      slidesPerView={1}
      onSlideChange={handleSlideChange}
      onSwiper={(swiper) => {
        swiperRef.current = swiper;
      }}
      initialSlide={activeSlideIndex}
    >
      {data.map((el, index) => (
        <SwiperSlide key={index}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Picture src={el.img} />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
export default SliderLogin;
