import React, { useState } from "react";
import Avatar from "react-avatar-edit";
import styled from "styled-components";
import { templates } from "../../theme/templates";
import BlurWrapper from "../../components/BlurWrapper/BlurWrapper";
import "./custom-avatar.css";
import { theme } from "../../theme/default/theme";
import useWindowWidth from "../../hooks/useWindowWidth";

type Props = {
  setAvatarUploaderOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedFile: React.Dispatch<React.SetStateAction<File | null>>;
};

const CustomAvatar: React.FC<Props> = ({
  setAvatarUploaderOpen,
  setSelectedFile,
}) => {
  const [src, setSrc] = useState<string | undefined>(undefined);

  const onClose = () => {
    setSrc(undefined);
    setAvatarUploaderOpen(false);
  };

  const onCrop = (preview: string) => {
    // Handle the preview image if needed
    setSrc(preview);
  };

  const onSave = () => {
    // Convert the base64 image to a File object
    if (src) {
      const byteString = atob(src.split(",")[1]);
      const mimeString = src.split(",")[0].split(":")[1].split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: mimeString });
      const file = new File([blob], "avatar.jpeg", { type: mimeString });

      setSelectedFile(file);
    }

    setAvatarUploaderOpen(false);
  };

  const windowWidth = useWindowWidth();

  const width = windowWidth > 700 ? 600 : 375;
  const height = windowWidth > 700 ? 500 : 300;
  return (
    <>
      <Wrapper>
        <BlurWrapper setModal={setAvatarUploaderOpen}>
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              display: "flex",
              backgroundColor: theme.colors.subBackground,
              flexDirection: "column",
            }}
          >
            <Avatar
              width={width}
              height={height}
              onCrop={onCrop}
              onClose={onClose}
              cropRadius={50}
              label={"Choose file"}
              borderStyle={{
                border: "1px dashed yellow",
                maxHeight: "90%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              labelStyle={{
                fontSize: "24px",
                color: theme.colors.yellowMain,
                cursor: "pointer",
              }}
            />

            <Button onClick={onSave}>Save Image</Button>
          </div>
        </BlurWrapper>
      </Wrapper>
    </>
  );
};

export default CustomAvatar;
const Wrapper = styled.div`
  position: absolute;
  z-index: 10;
`;
const Button = styled.div`
  width: 100px;
  height: 40px;
  color: black;
  ${templates.centerContent};
  border-radius: 12px;
  cursor: pointer;
  background-color: ${theme.colors.yellowMain};
  margin: 10px auto;
`;
