import { restActionCreatorHelper } from './../../helpers/restActionCreatorHelper';

const nftRestAction = restActionCreatorHelper(`nft`);

export const GetPlansRestActions = nftRestAction('getPlans');

export const GetContractCostRestActions = nftRestAction('getContractCost');

export const FinancialReportRestActions = nftRestAction('financialReport');

export const CreateNftProfileRestActions = nftRestAction('createNftProfile');

export const DeleteNftProfileRestActions = nftRestAction('deleteNftProfile');

export const EditNftProfileRestActions = nftRestAction('editNftProfile');

export const ListNftUsersRestActions = nftRestAction('listNftUsers');

export const ListCategoriesRestActions = nftRestAction('listCategories');

export const ListCollectionsRestActions = nftRestAction('listCollections');

export const ChangePlanRestActions = nftRestAction('changePlan');

export const ChangeLikeStatusRestActions = nftRestAction('changeLikeStatus');

export const ListNftsRestActions = nftRestAction('listNfts');

export const ChangeFollowStatusRestActions = nftRestAction('changeFollowStatus');

export const CreateNftCollectionRestActions = nftRestAction('createNftCollection');

export const CreateNftRestActions = nftRestAction('createNft');

export const ListContractsRestActions = nftRestAction('listContracts');

export const ViewingNft = nftRestAction('viewvingNft')

export const EditNftRestActions = nftRestAction('editNft');

export const ViewingNftProfile = nftRestAction('ViewingNftProfile')

export const EditNftCollectionRestActions = nftRestAction('editNftCollection');