import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: "100%",
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.metrics.x2
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flex: 1,
  },
});