import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative'
  },
  iconCont: {
    width: 30,
    height: 30,
    marginRight: theme.metrics.x3
  },
  textCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  titleText: {
    fontWeight: 'bold',
    color: theme.colors.black,
    margin: 0
  },
  infoText: {
    color: theme.colors.black,
    margin: 0
  }
});