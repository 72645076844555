import { FC } from "react";
import { Text } from "../../../../components/Text/Text";
import { useThemeContext } from "../../../../theme";
import { TitleContainer } from "../NFTHomePageStyle";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";

type Props = {
  title: string;
  href: string;
};

const ListTitle: FC<Props> = ({ title, href }) => {
  const { theme } = useThemeContext();
  const navigate = useNavigate();
  const { t } = i18next;

  return (
    <TitleContainer>
      <Text
        color={theme.colors.white}
        size="50.1px"
        fontStyle={theme.fonts.f600}
      >
        {title}
      </Text>
      <Text
        color={theme.colors.white}
        size="24px"
        fontStyle={theme.fonts.f600}
        whiteSpace={true}
        hoverColor={theme.colors.yellowMain}
        func={() => navigate(href)}
      >
        {t("nftHomeScreen.viewAll")}
      </Text>
    </TitleContainer>
  );
};
export default ListTitle;
