import styled from "styled-components";
import { templates } from "../../../theme/templates";

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
row-gap: 24px;
position: relative;
padding-bottom: 24px;
`;
export const Background = styled.div<{bg: string}>`
background-image: url(${p => p.bg});
width: 100%;
height: 300px;
${templates.centerContent};
flex-direction: column;
background-size: cover;
    background-position: center;
    margin-top: -24px;
`;
export const UserIcon = styled.img`
border-radius: 50%;
width: 120px;
height: 120px;
border: 2px solid ${p => p.theme.colors.yellowMain};
cursor: pointer;
`;
export const InputsWrapper = styled.div`
display: flex;
flex-direction: column;
row-gap: 20px;
width: 50%;
@media(max-width: 700px){
    width: 60%;
}
@media(max-width: 420px){
    width: 90%;
}
`;
export const EditUserBanner = styled.div`
width: 70%;
height: 160px;
border: 2px solid ${p => p.theme.colors.yellowMain};
border-radius: 12px;
@media(max-width: 700px){
    width: 80%;
    height: 130px;
}
@media(max-width: 420px){
    height: 110px;
    width: 90%;
}
`;
export const EditUserIcon = styled.div`
width: 160px;
height: 160px;
border: 2px solid ${p => p.theme.colors.yellowMain};
border-radius: 50%;
@media(max-width: 700px){
   
    height: 130px;
    width: 130px;
}
@media(max-width: 420px){
    height: 110px;
    width: 110px;
   
}
`;
export const ToggleWrapper = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`;

export const Back = styled.img`
position: absolute;
padding-left: 24px;
cursor: pointer;
width: 70px;
top: 0;
left: 0;
@media(max-width: 800px){
    top: 16px;
}
@media(max-width: 700px){
    width: 50px;
}
`;
export const CardsContainer = styled.div`
  display: grid;
  padding: 0 24px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 24px;
  width: 100%;
  @media (max-width: 656px) {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }
      @media (max-width: 400px) {
    grid-template-columns: repeat(1, minmax(150px, 1fr));
  }
  
  & > div {
    width: 100%;
   
   
  }
`;