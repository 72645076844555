import React, { memo, useMemo, useState } from 'react';
import { useStyles } from './EscrowTxItemStyles';
import { Box } from '@material-ui/core';
import { useThemeContext } from "../../theme";
import cn from 'classnames';
import { StringValueTable, LabelValueItem } from './../StringValueTable';
import ArrowUpRightIcon from './../../assets/icons/arrowUpRight';
import { EscrowTransaction } from '../../types/types';

export const ESCROW_STATUS_COLOR_TABLE: ObjectT<string> = {
  "Cancelled": "red",
  "Completed": "green",
  "Pending": "yellow"
};

export type EscrowTxItemProps = {
  data: EscrowTransaction;
  onClickOpen: () => void;
};

const EscrowTxItem: React.FC<EscrowTxItemProps> = ({
  data,
  onClickOpen
}) => {
  const { theme } = useThemeContext();
  const s = useStyles();

  const [hovered, setHovered] = useState(false);

  const tableData = useMemo<LabelValueItem[]>(() => {
    return [
      {
        id: "txStatus",
        label: "Status",
        value: data.status,
        valueColor: ESCROW_STATUS_COLOR_TABLE[data.status]
      },
      {
        id: "transferId",
        label: "Transfer ID",
        value: data.transfer_id
      },
    ].concat(data.partners.map((partner) => ({
        id: `partner ${partner.customer_id}`,
        label: partner.role,
        value: partner.customer_email
      } as any))
      // .sort((a, b) => a.role < b.role ? -1 : 1)
    );
  }, [data]);

  return (
    <Box 
      className={cn(s.container)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClickOpen}
    >
      <Box className={s.topCont}>
        <Box className={s.col}>
          <span className={s.dateText}>
            {data.date_time}
          </span>
          <span className={s.titleText}>
            {data.title}
          </span>
        </Box>

        <ArrowUpRightIcon color={hovered ? theme.colors.white : theme.colors.scorpion} />
      </Box>

      <StringValueTable 
        items={tableData}
      />
    </Box>
  );
};

const MemorizedComponent = memo(EscrowTxItem);
export { MemorizedComponent as EscrowTxItem };