import styled from "styled-components";
import { ContentBox } from "../../components/ContentBox/ContentBox";
import { Text } from "../../components/Text/Text";
import { useThemeContext } from "../../theme";
import { FC } from "react";
import { MessageData } from "./data";
import i18next from "i18next";

type Props = {
  data: MessageData;
  setId?: any;
  setPageStep?: any;
};

export const Ticket: FC<Props> = ({ data, setId, setPageStep }) => {
  const { theme } = useThemeContext();
  const { t } = i18next;

  return (
    <TicketWrapper
      onClick={() => {
        setId && setId(data.id);
        setPageStep && setPageStep("chat");
      }}
    >
      {data ? (
        <>
          {" "}
          <ContentBox
            backColor="none"
            width="100%"
            justyfiContent="space-between"
            margin="0 0 10px 0"
          >
            <Text
              color={theme.colors.white}
              fontStyle={theme.fonts.f600}
              size="17px"
            >
              {data.last_update}
            </Text>
            <Text
              color={theme.colors.yellowMain}
              size="16px"
              fontStyle={theme.fonts.f600}
            >
              {data.status}
            </Text>
          </ContentBox>
          <div style={{ display: "flex" }}>
            <Text
              color={theme.colors.yellowMain}
              size="20px"
              fontStyle={theme.fonts.f600}
            >
              Q:
            </Text>
            <Text
              color={theme.colors.white}
              size="19px"
              fontStyle={theme.fonts.f500}
              margin="0 0 0 5px"
            >
              {data.subject}
            </Text>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Text
              color={theme.colors.yellowMain}
              size="16px"
              fontStyle={theme.fonts.f600}
            >
              {t("supportPage.department")}:
            </Text>
            <Text
              color={theme.colors.white}
              size="14px"
              fontStyle={theme.fonts.f500}
              margin="0 0 0 5px"
            >
              {data.department}
            </Text>
          </div>
        </>
      ) : (
        <></>
      )}
    </TicketWrapper>
  );
};
export const TicketWrapper = styled.div`
  padding: 10px;
  background-color: ${(p) => p.theme.colors.subBackground};
  width: 100%;
  border-radius: 12px;
  cursor: pointer;
`;
