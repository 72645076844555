import styled from "styled-components";
import { templates } from "../../theme/templates";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 24px;
  background-color: ${(p) => p.theme.colors.black};
  @media (max-width: 900px) {
    padding: 24px 16px;
  }
`;
export const Button = styled.div`
  background-color: ${(p) => p.theme.colors.yellowMain};
  ${templates.centerContent};
  min-width: 261px;
  height: 68px;
  border-radius: 16px;
  cursor: pointer;
  @media (max-width: 1045px) {
    margin-top: 16px;
  }
  @media (max-width: 700px) {
    height: 48px;
  }
`;
export const InputButton = styled.div`
  width: 40px;
  height: 40px;
  transform: rotate(-90deg);
  border-radius: 11px;
  border: 1px solid ${(p) => p.theme.colors.greySubText};
  ${templates.centerContent};
  cursor: pointer;
  @media (max-width: 700px) {
    width: 24px;
    height: 24px;
    border-radius: 8px;
  }
`;
export const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(p) => p.theme.colors.subBackground};
  margin-top: 60px;
  @media (max-width: 900px) {
    margin-top: 16px;
  }
  
`;
export const ToggleButton = styled.div<{ toggle: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 4px;
  min-width: 90px;
  height: 40px;
  border-radius: 60px;
  background-color: ${(p) =>
    p.toggle ? p.theme.colors.yellowMain : "#1e2224"};
  margin-left: 100px;
  cursor: pointer;
`;
export const ToggleCircle = styled.div<{ toggle: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: ${(p) => (p.toggle ? "0 0 0 auto" : "0 auto 0 0")};
  background-color: ${(p) => (p.toggle ? p.theme.colors.black : " #444a4d")};
`;
export const Soon = styled.div`
  width: 90px;
  height: 45px;
  ${templates.centerContent};
  background-color: #1e2224;
  border-radius: 8px;
`;

export const GridContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 30px;
  column-gap: 24px;
  @media (max-width: 550px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
    margin-top: 16px;
  }
`;
export const ButtonChange = styled.div`
  ${templates.centerContent};
  width: 230px;
  height: 50px;
  margin: 10px auto 20px auto;
  border-radius: 12px;
  background-color: ${(p) => p.theme.colors.yellowMain};
  cursor: pointer;
`;
export const DeleteAccount = styled.div`
  cursor: pointer;
  margin: 0 auto;
`;
