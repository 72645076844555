import styled from "styled-components";
import { templates } from "../../../theme/templates";

export const FromButton = styled.div`
  height: 68px;
  border-radius: 16px;
  background-color: ${(p) => p.theme.colors.yellowMain};
  font-size: 24px;
  font-weight: 600;
  font-family: Gilroy-Semibold;
  color: black;
  width: 100%;
  ${templates.centerContent};
  cursor: pointer;
  @media (max-width: 900px) {
    font-size: 16px;
    height: 56px;
  }
`;
export const SelectButton = styled.div<{ openSelect: boolean }>`
  width: 40px;
  height: 40px;
  transform: rotate(${(p) => (p.openSelect ? "180deg" : "0")});
  border-radius: 11px;
  border: 1px solid ${(p) => p.theme.colors.borderSml};
  ${templates.centerContent};
  cursor: pointer;
`;
export const Options = styled.div`
  position: absolute;
  top: 69px;
  padding-top: 10px;
  width: calc(100% + 1px);
  height: 324px;
  overflow-y: scroll;
  border-radius: 0 0 24px 24px;
  border: 1px solid #323232;
  border-top: none;

  background-color: ${(p) => p.theme.colors.subBackground || "none"};
  z-index: 6;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 700px) {
    top: 60px;
  }
`;
export const OptionItem = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  width: 100%;
  height: 81px;
  border-top: 1px solid #323232;
  cursor: pointer;
  &:hover {
    background-color: #292e32;
  }
`;
export const InputWrapper = styled.input`
  all: unset;
  width: 100%;
  color: ${(p) => p.theme.colors.white};
  padding: 22px 0;
  font-size: 20px;
  font-family: Gilroy-Semibold;
  @media (max-width: 700px) {
    padding: 10px 0;
  }
`;
export const InputButton = styled.div`
  width: 40px;
  height: 40px;
  transform: rotate(-90deg);
  border-radius: 11px;
  border: 1px solid ${(p) => p.theme.colors.greySubText};
  ${templates.centerContent};
  cursor: pointer;
`;
export const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: #323232;
`;
