import styled from "styled-components";
import { tabsData } from "../mock";
import { Text } from "../../../components/Text/Text";
import { theme } from "../../../theme/default/theme";
import { FC, useState } from "react";
import { templates } from "../../../theme/templates";

type Props = {
  tabsData: string[];
};

const Tabs: FC<Props> = ({ tabsData }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <Wrapper>
      {tabsData.map((el, index) => (
        <TabWrapper
          onClick={() => setActiveIndex(index)}
          key={index}
          bg={activeIndex === index}
        >
          <Text
            color={
              activeIndex !== index
                ? theme.colors.greySubTextDesc
                : theme.colors.black
            }
            fontStyle={theme.fonts.f700}
            size="21px"
          >
            {el}
          </Text>
        </TabWrapper>
      ))}
    </Wrapper>
  );
};
export default Tabs;
const Wrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;
const TabWrapper = styled.div<{ bg: boolean }>`
  cursor: pointer;
  ${templates.centerContent};
  min-width: 130px;
  padding: 13px 23px;
  height: 48px;
  transition: 0.3s;
  white-space: nowrap;
  background-color: ${(p) =>
    !p.bg ? theme.colors.black : theme.colors.yellowMain};
  border-radius: 29px;
  @media (max-width: 700px) {
    padding: 10px 20px;
    min-width: auto;
  }
`;
