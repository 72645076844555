import { useEffect, useState } from 'react'

export const useDebounce = <T>(value: T, getDelay: (v: T) => number) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const handleTimeout = setTimeout(() => {
      setDebouncedValue(value)
    }, getDelay(value))

    return () => {
      clearTimeout(handleTimeout);
    }
  }, [value])

  return debouncedValue;
}