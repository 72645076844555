import styled from "styled-components";

export const SemiString = styled.span`
color: ${p => p.theme.colors.white};
font-weight: 600;
`;
export const ItemsContainer = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-between;
gap: 24px;
margin-top: 40px;
width: 100%;
`;