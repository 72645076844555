export const DEFAULT_ACTIVE_BOX = 0;

export enum CardsOperationsTabs {
  Load = 0,
  Send,
  Unload
};

export enum LoadBoxes {
  LoadUSDZ = 0
};

export enum SendBoxes {
  CardToCard = 0
};

export enum UnloadBoxes {
  UnloadUSDZ = 0
};