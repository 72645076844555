import styled from "styled-components";
import { theme } from "../../theme/default/theme";

export const Wrapper = styled.div`
padding: 20px 20px 26px 20px;
background-color: ${p => p.theme.colors.subBackground};
border-radius: 24px;
border: 1px solid ${(p) => p.theme.colors.greySubText};
height: fit-content;
cursor: pointer;
width: 100%;
min-width: 250px;
&:hover{
    border-color: ${p => p.theme.colors.yellowMain};
}
@media(max-width: 700px){
    min-width: 150px;
    padding: 8px 8px 15px 8px;
    border-radius: 10px;
}

`;

export const PictureWrapper = styled.div<{bg: string}>`
background-image: url(${p => p.bg});
width: 100%;
aspect-ratio: 278 / 274;

background-size: 100% auto;
background-size: cover;
padding: 10px;
position: relative;

@media(max-width: 700px){
    padding: 4px;
}
`;
export const ImageHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ContainerWBG = styled.div<{likedByMe: boolean}>`
display: flex;
align-items: center;

background-color:${p => p.likedByMe ? p.theme.colors.yellowMain : p.theme.colors.black } ;
border-radius: 40px;
padding: 13px 14px;
@media(max-width: 700px){
    
    padding: 8px;
}
`;

export const Icon = styled.img`
@media(max-width: 700px){
    width: 16px;
    height: 16px;
}
`;

export const ContainerSB = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`;

export const Avatar = styled.img`
position: absolute;
bottom: -15px;
right: 12px;
width: 50px;
height: 50px;
border-radius: 50%;
`;

export const BottomLeftImg = styled.img`
display: flex;
position: absolute;
bottom: -10px;
left: 12px;
width: 40px;
height: 40px;
border-radius: 50%;
background-color: ${() => theme.colors.yellow100}
`;