import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { socialSelector } from "../../../logic/social/SocialSelectors";

import _ from "lodash";
import { userIdSelector } from "../../../logic/user/UserSelectors";
import { SocialActions } from "../../../logic/social/SocialRedux";
import { TicketOpenRequest } from "../../../types/api";
import { prepareImageName } from "../../../helpers/formatHelpers";
//import { showToast } from '../../../helpers/alertService';
import i18next from "i18next";
import { showToast } from "../../../helpers/alertService";

const options = {
  mediaType: "photo",
  quality: 0.3,
};

export const useCreateTicket = () => {
  const dispatch = useDispatch();
  const [pickedType, setPickedType] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [keyboardOpened, setKeyboardOpened] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [hideErrors, setHideErrors] = useState(true);
  const {
    ticketsTypes: { data: ticketsTypes },
    ticketOpen: { data: success, fetching: fetch },
  } = useSelector(socialSelector);
  const customer_id = useSelector(userIdSelector);

  const handleAnimationEnd = () => {
    setHideErrors(true);
  };

  useEffect(() => {
    if (success) {
      dispatch(SocialActions.ticketOpen.success(undefined as any));
      dispatch(
        SocialActions.ticketsList.request({
          items: 20,
          user_id: customer_id as number,
          id: customer_id as number,
        })
      );
      showToast({
        title: i18next.t("supportPage.success"),
        info: i18next.t("supportPage.ticketCreated"),
        type: "success",
      });
    }
  }, [success]);

  const deleteImage = (index: number) => {
    let newArr = selectedFiles;
    _.pullAt(newArr, [index]);
    setSelectedFiles([...newArr]);
  };

  const selectionLimit = useMemo(() => {
    return 6 - selectedFiles.length;
  }, [selectedFiles]);

  {
    /*const chooseImage = async () => {
    let result = await launchImageLibrary({...options, selectionLimit} as any);
    if (result.assets) {
      if (selectedFiles.length > 0) {
        setSelectedFiles(selectedFiles.concat(result.assets));
      } else {
        setSelectedFiles(result.assets);
      }
    }
  };*/
  }

  {
    /*useEffect(() => {
    if(Platform.OS === 'android'){
      const showSubscription = Keyboard.addListener('keyboardDidShow', () => {
        setKeyboardOpened(true);
      });
      const hideSubscription = Keyboard.addListener('keyboardDidHide', () => {
        setKeyboardOpened(false);
      });
  
      return () => {
        showSubscription.remove();
        hideSubscription.remove();
      };
    }
  }, []);*/
  }

  const createTicket = () => {
    if (valid) {
      let sendData: TicketOpenRequest = {
        customer_id: customer_id as number,
        department: pickedType,
        subject,
        messages: [{ message: message }],
      };
      if (selectedFiles.length > 0) {
        let fdata = new FormData();
        selectedFiles.forEach((item: any, index: number) => {
          fdata.append(subject + index, item, prepareImageName(item.name));
        });
        sendData.messages[0].files = fdata;
      }
      dispatch(SocialActions.ticketOpen.request(sendData));
    }
  };

  const valid = useMemo(() => {
    if (
      subject !== "" &&
      subject.length > 2 &&
      message !== "" &&
      message.length > 2 &&
      pickedType !== ""
    ) {
      return true;
    }
    return false;
  }, [subject, message, pickedType]);

  return {
    pickedType,
    subject,
    message,
    keyboardOpened,
    ticketsTypes,
    hideErrors,
    valid,
    selectedFiles,
    selectionLimit,
    fetch,
    setPickedType,
    setSubject,
    setMessage,
    success,
    handleAnimationEnd,
    setSelectedFiles,
    //  chooseImage,
    deleteImage,
    setKeyboardOpened,
    createTicket,
  };
};
