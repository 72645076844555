import React, { FC } from "react";
import { ContentWrapper, LangImg, LangItem, Wrapper } from "./SelectLangStyles";
import { useTranslation } from "react-i18next";

type Props = {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setLang: React.Dispatch<React.SetStateAction<string>>;
};

const languages = [
  { flag: "/images/flags/de.png", name: "Deutsch", lang: "de" },
  { flag: "/images/flags/gb.svg", name: "English", lang: "en" },
  { flag: "/images/flags/pt.png", name: "Portuguese", lang: "pt" },
  { flag: "/images/flags/es.png", name: "Español", lang: "es" },
];

const SelectLang: FC<Props> = ({ setVisible, setLang }) => {
  // @ts-ignore
  const { i18n } = useTranslation();

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  return (
    <Wrapper onClick={() => setVisible(false)}>
      <ContentWrapper onClick={(e) => e.stopPropagation()}>
        {languages.map((lang, index) => (
          <LangItem
            key={index}
            isLast={index === languages.length - 1}
            onClick={() => {
              setLang(lang.flag);
              setVisible(false);
              changeLanguage(lang.lang);
            }}
          >
            <LangImg src={lang.flag} alt="Language flag" /> {lang.name}
          </LangItem>
        ))}
      </ContentWrapper>
    </Wrapper>
  );
};
export default SelectLang;
