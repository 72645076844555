import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
  >
    <rect
      width={23}
      height={23}
      x={0.5}
      y={0.5}
      stroke="#fff"
      opacity={0.3}
      rx={7.5}
    />
    <path
      stroke="#FED337"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M15.52 10.967 18 8.487l-2.48-2.48M6 8.487h12M8.48 13.033 6 15.513l2.48 2.48M18 15.513H6"
    />
  </svg>
)
export default SvgComponent