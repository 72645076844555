import { ApiResponse } from "apisauce";
import { call, delay } from "redux-saga/effects";
import { ApiResponseValidator, classicApiResponseValidator } from "./apiResponseValidators";

export type RetryApiCallParams = {
  maxTries?: number;
  delayMs?: number;
  apiRequest: (...args: any[]) => Promise<ApiResponse<any>>;
  args?: any[];
  apiResponseValidator?: ApiResponseValidator;
};

function* retryApiCall(
  {
    maxTries = 2, 
    delayMs = 1e3, 
    apiRequest, 
    args = [],
    apiResponseValidator = classicApiResponseValidator
  }: RetryApiCallParams
) {
  for (let i = 0; i < maxTries; i++) {
    const apiResponse: ApiResponse<any> = yield call(apiRequest, ...args);
    if (apiResponseValidator(apiResponse)) {
      return apiResponse;
    } else {
      if (i < (maxTries - 1)) {
        yield delay(delayMs);
      } else {
        // attempts failed after maxTries attempts
        return apiResponse;
      }
    }
  }
}

export { retryApiCall };