import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  padding-top: 10px;
  background-color: ${(p) => p.theme.colors.black};
  height: calc(100vh - 100px);
  @media (max-width: 1030px) {
    min-height: calc(100vh - 106px);
  }
  @media (max-width: 800px) {
    min-height: calc(100vh - 76px);
  }
`;
export const LoaderPosition = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
