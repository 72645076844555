import React, { memo } from 'react';
import { useStyles } from './NoDataStyles';
import { Box, CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import { t } from 'i18next';
import { theme } from '../../theme/default/theme';

export type NoDataProps = {
  bgColor?: string;
  text?: string;
  textColor?: string;
  fontSize?: number;
  loading?: boolean;
  loaderSize?: number;
};

const NoData: React.FC<NoDataProps> = ({
  bgColor,
  text,
  textColor,
  fontSize,
  loading,
  loaderSize = 16
}) => {
  const s = useStyles();

  return (
    <Box className={classNames(s.container)} style={{backgroundColor: bgColor}}>
      {loading ?
        <CircularProgress size={loaderSize} />
      :
        <p className={classNames(s.text)} style={{color: textColor, fontSize}}>
          {text}
        </p>
      }
    </Box>
  );
};

NoData.defaultProps={
  text: t("noData") as string,
  textColor: theme.colors.white,
  fontSize: 28,
  loading: false
}

const MemorizedComponent = memo(NoData);
export { MemorizedComponent as NoData };