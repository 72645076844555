import { ChangeEvent, FC } from "react";
// import { FileContainer } from "../../../../components/Modals/CreateTicket/CreateTicketStyles";
import { IconAdd } from "../../../Support/Chat/ChatStyles";
import styled from "styled-components";

type Props = {
  image: any;
  setImage: any;
  id: any;
  name: any;
  circle?: any;
};

const ImagePicker: FC<Props> = ({ image, setImage, id, name, circle }) => {
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const inputName = event.target.name;
    if (file) {
      setImage((prev: any) => ({ ...prev, [inputName]: file }));
    }
  };

  const handleClick = (fileName: string) => {
    const fileInput = document.getElementById(id) as HTMLInputElement;

    if (fileInput) {
      fileInput.click();
    }
  };
  return (
    <>
      <FileContainer onClick={() => handleClick(id)}>
        {image ? (
          <Picture
            circle={circle}
            src={typeof image === "string" ? image : URL.createObjectURL(image)}
            alt="Selected file"
          />
        ) : (
          <IconAdd src="/images/support/add.svg" />
        )}

        <input
          id={id}
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
          name={name}
        />
      </FileContainer>
    </>
  );
};
export default ImagePicker;

const FileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 158px;
  @media (max-width: 700px) {
    min-height: 128px;
  }
  @media (max-width: 420px) {
    min-height: 108px;
  }
`;
const Picture = styled.img<{ circle: boolean }>`
  width: 100%;
  height: 100%;
  max-height: 158px;
  object-fit: cover;
  border-radius: ${(p) => (p.circle ? "50%" : "12px")};
  @media (max-width: 700px) {
    max-height: 128px;
  }
  @media (max-width: 420px) {
    max-height: 108px;
  }
`;
