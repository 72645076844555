import React, { memo, useRef, useState } from "react";
import { useStyles } from "./StoriesModalStyles";
import { Box } from "@material-ui/core";
import { StorieItem } from "../../logic/storie/StorieRedux";
import {
  ProgressBarSeparated,
  ProgressBarSeparatedMethods,
} from "../../components/ProgressBarSeparated";
import { theme } from "../../theme/default/theme";
import { CustomButton } from "../CustomButton";
import { isMobile } from "react-device-detect";
import moment from "moment";
import Modal from "react-modal";
import cn from "classnames";
import CloseIcon from "./../../assets/icons/close";

Modal.setAppElement("#root");

// Global constants
const MODAL_CUSTOM_STYLE = {
  content: {
    display: "flex",
  },
  overlay: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 11,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 1)",
  },
};

const DEFAULT_STORY_DURATION = 5e3;

export type StoriesModalProps = {
  storiesData: StorieItem[];
  visible: boolean;
  pauseTapMinDuration?: number;
  onClose: (shouldShowNextStories: boolean) => void;
};

const StoriesModal: React.FC<StoriesModalProps> = ({
  storiesData,
  visible,
  pauseTapMinDuration,
  onClose,
}) => {
  const s = useStyles();

  const progressBarRef = useRef<ProgressBarSeparatedMethods>();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [currentDuration, setCurrentDuration] = useState(
    DEFAULT_STORY_DURATION
  );
  const [pauseTimestamp, setPauseTimestamp] = useState(0);
  const [videoPaused, setVideoPaused] = useState(false);

  const onCloseInner = (
    shouldShowNextStories: boolean,
    resetCurrentStoryIndex: boolean = false
  ) => {
    if (resetCurrentStoryIndex) setCurrentStoryIndex(0);

    setCurrentDuration(DEFAULT_STORY_DURATION);
    onClose(shouldShowNextStories);
  };

  // Переключение на story с заданным индексом (без валидации индекса)
  const toogleToStoryIndex = (index: number) => {
    setCurrentStoryIndex(index);

    const duration =
      (storiesData[index].duration ?? DEFAULT_STORY_DURATION) +
      (storiesData[index].video ? 1e3 : 0);
    setCurrentDuration(duration);
  };

  // Returns true is was paused
  const determineIfWasPause = () => {
    return (
      moment().valueOf() - pauseTimestamp > (pauseTapMinDuration as number)
    );
  };

  const handlePressPrev = () => {
    setVideoPaused(false);
    videoRef?.current?.pause();
    if (determineIfWasPause()) {
      progressBarRef?.current?.play();
    } else {
      if (currentStoryIndex > 0) {
        toogleToStoryIndex(currentStoryIndex - 1);
      }
    }
  };

  const handlePressNext = () => {
    setVideoPaused(false);
    videoRef?.current?.pause();
    if (determineIfWasPause()) {
      // Should unpause
      progressBarRef?.current?.play();
    } else {
      // Tap was quick, show next storie
      if (currentStoryIndex < storiesData.length - 1) {
        toogleToStoryIndex(currentStoryIndex + 1);
      } else {
        onCloseInner(true, true);
      }
    }
  };

  const handlePressPause = () => {
    setVideoPaused(true);
    videoRef?.current?.play();
    setPauseTimestamp(moment().valueOf());
    progressBarRef?.current?.pause();
  };

  const handleStoryViewed = (index: number) => {
    if (index < storiesData.length - 1) {
      toogleToStoryIndex(currentStoryIndex + 1);
    } else {
      // onCloseInner(true);
    }
  };

  return (
    <Modal
      isOpen={visible}
      onRequestClose={() => onClose(false)}
      contentLabel="Storie modal"
      className={cn(s.storieModal, s["&__content"])}
      style={MODAL_CUSTOM_STYLE as any}
    >
      <Box className={cn(s.topCont)}>
        <ProgressBarSeparated
          ref={progressBarRef}
          numOfBlocks={storiesData.length}
          currentIndex={currentStoryIndex}
          currentDuration={currentDuration}
          onBlockAnimationEnd={(index) => handleStoryViewed(index)}
        />

        <Box className={cn(s.endRow)}>
          <Box className={cn(s.closeIcon)}>
            <CustomButton
              bgColorActive="transparent"
              icon={<CloseIcon color={theme.colors.white} />}
              onPress={() =>
                onCloseInner(
                  false,
                  currentStoryIndex === storiesData.length - 1
                )
              }
            />
          </Box>
        </Box>
      </Box>

      <Box className={cn(s.contentContainer)}>
        <Box
          onMouseDown={isMobile ? undefined : handlePressPause}
          onTouchStart={isMobile ? handlePressPause : undefined}
          onClick={handlePressPrev}
        >
          <Box className={cn(s.prevBtn)} />
        </Box>
        <Box
          onMouseDown={isMobile ? undefined : handlePressPause}
          onTouchStart={isMobile ? handlePressPause : undefined}
          onClick={handlePressNext}
        >
          <Box className={cn(s.nextBtn)} />
        </Box>

        {storiesData[currentStoryIndex].image ? (
          <img
            src={storiesData[currentStoryIndex].image}
            className={cn(s.fullSpace)}
          />
        ) : (
          <video
            ref={videoRef}
            src={storiesData[currentStoryIndex].video}
            // src='http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
            className={cn(s.fullSpace)}
            autoPlay={true}
            // paused={videoPaused}
          />
        )}
      </Box>
    </Modal>
  );
};

StoriesModal.defaultProps = {
  pauseTapMinDuration: 200,
};

const MemorizedComponent = memo(StoriesModal);
export { MemorizedComponent as StoriesModal };
