import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    padding: theme.metrics.x6,
    backgroundColor: theme.colors.batBankRed,
    borderRadius: theme.metrics.x4,
    // border: `1px solid ${theme.colors.borderGrey}`,
  },
  iconCont: {
    display: 'flex',
    marginRight: theme.metrics.x5,
  },
  text: {
    fontFamily: theme.fonts.f500.fontFamily,
    fontSize: 28,
    color: theme.colors.white,
    margin: 0
  }
});