import styled from "styled-components";
import { templates } from "../../../theme/templates";
import { text } from "../../../theme/text";
import { makeStyles } from '@material-ui/core/styles';
import { isDesktop } from "react-device-detect";
import { SIDEBAR_WIDTH } from "../layoutConstants";

export const Wrapper = styled.div`
  position: fixed;
  ${templates.centerContent};
  justify-content: space-between;
  column-gap: 50px;
  padding-left: ${isDesktop ? SIDEBAR_WIDTH : 0}px;
  @media (max-width: 1300px) {
    padding-left: 0px;
  }
  padding-right: 24px;
  width: 100%;
  height: 85px;
  background-color: ${(p) => p.theme.colors.subBackground};
  z-index: 4;
  @media (max-width: 1490px) {
    column-gap: 20px;
  }
  @media (max-width: 1300px) {
    justify-content: space-between;
  }
  @media (max-width: 800px) {
    padding-right: 12px;
    height: 70px;
    column-gap: 0;
  }
`;
export const BoxWBorder = styled.div`
  cursor: pointer;
  ${templates.centerContent};
  min-width: 50px;
  height: 50px;
  border-radius: 16px;
  border: 2px solid ${(p) => p.theme.colors.white};
  margin-left: 16px;
`;
export const Logo = styled.img`
  display: none;
  margin-left: 8px;
  width: 60px;
  height: 60px;
  border-radius: 8px;
  cursor: pointer;
  @media (max-width: 1300px) {
    display: block;
  }
`;
export const Tabs = styled.div`
  ${templates.centerContent};
  column-gap: 10px;
  overflow: auto;
  justify-content: flex-start;
  scrollbar-width: thin;
  scrollbar-color: ${(p) => p.theme.colors.scrollbar};
  white-space: nowrap;

  @media(max-width: 800px){
    column-gap: 0;
  }

  &::-webkit-scrollbar {
    height: 1px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${(p) => p.theme.colors.greySubText};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.colors.white};
    border-radius: 3px;
  }
  
`;
export const Tab = styled.div<{ active?: boolean }>`
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  font-family: Gilroy-Semibold;
  color: ${(p) => (p.active ? text.colors.yellow : "white")};
  cursor: pointer;
  background-color: ${(p) => (p.active ? "#0E1011" : "")};
  padding: 12px 8px;
  border-radius: 16px;
  transition: 0.3s;

  &:hover{
    color: ${p => p.theme.colors.yellowMain};
  }
  @media (max-width: 700px) {
    font-size: 16px;
  }
`;

export const useStyles = makeStyles({
  rightContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflowX: 'auto'
  },
});