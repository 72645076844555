import { useEffect, useState } from "react";
import { Text } from "../../../components/Text/Text";
import { useThemeContext } from "../../../theme";
import {
  Back,
  Background,
  CardsContainer,
  EditUserBanner,
  EditUserIcon,
  InputsWrapper,
  ToggleWrapper,
  UserIcon,
  Wrapper,
} from "./NftProfileStyles";
import InputItem from "./components/InputItem";
import { useNFTProfile } from "./hooks/useNftProfile";
import { ToggleButton, ToggleCircle } from "../../Profile/ProfileStyles";
import { Btn } from "../../../components/Button/Btn";
import ImagePicker from "./components/ImagePicker";
import { NftSocialMedia } from "../../../types/types";
import { CONFIG } from "../../../config";
import { useHideErrors } from "../../../hooks/useHideErrors";
import {
  validateLink,
  validateNftBio,
  validateNftUsername,
} from "../../../helpers/validationHelper";
import { prepareImageName } from "../../../helpers/formatHelpers";
import { NftActions } from "../../../logic/nft/NftRedux";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import useWindowWidth from "../../../hooks/useWindowWidth";
import i18next from "i18next";
import { useDependentValue } from "../../../helpers/customHooks";
import { nftSelector } from "../../../logic/nft/NftSelectors";
import CardNFT from "../../../components/CardNFT/CardNFT";

const NFTProfile = () => {
  const location = useLocation();
  const { theme } = useThemeContext();
  const { t } = i18next;
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();

  const { myNftCollections, fetchingMyNftCollections } =
    useSelector(nftSelector);

  useEffect(() => {
    dispatch(
      NftActions.listCollections.request({
        customer_id: userId,
      })
    );
  }, []);

  const {
    setVariant,
    variant,
    links,
    profileVisible,
    setProfileVisible,
    setLink,
    images,
    setImages,
    bio,
    setBio,
    username,
    setUsername,
    // onPressSave,
    fetchingEdit,
    nftProfile,
    getLinksArray,
    validateLinks,
    userId,
    viewingNftProfile,
  } = useNFTProfile();

  useEffect(() => {
    if (location.state?.variant) {
      setVariant(location.state?.variant);
    }
  }, [location.state?.variant]);

  const isMyAccount = useDependentValue(() => {
    return nftProfile?.customer.id === userId;
  }, [nftProfile, userId]);

  const { hideErrors, setHideErrors } = useHideErrors();

  const onPressSave = () => {
    if (!nftProfile) return;

    if (
      validateNftUsername(username) &&
      validateNftBio(bio) &&
      validateLinks()
    ) {
      const fData = new FormData();

      if (images.logo) {
        const logoBlob = new Blob([images.logo], {
          type: "image/jpeg",
        });
        fData.append(
          "logo",
          logoBlob,
          prepareImageName(images.logo?.name as string)
        );
      }
      if (images.banner) {
        const bannerBlob = new Blob([images.banner], {
          type: "image/jpeg",
        });
        fData.append(
          "banner",
          bannerBlob,
          prepareImageName(images.banner?.name as string)
        );
      }

      dispatch(
        NftActions.editNftProfile.request({
          id: 16,
          logo: !!images.logo,
          banner: !!images.banner,
          fData: images.logo || images.banner ? fData : undefined,
          username,
          Bio: bio,
          links: getLinksArray(),
          status: profileVisible ? "Enabled" : "Disabled",
        })
      );
      setTimeout(() => {
        setVariant("view");
      }, 2000);
    } else {
      setHideErrors(false);
    }
  };

  ////////
  return (
    <Wrapper>
      {variant === "view" && (
        <>
          <Background bg={CONFIG.ZED_BASE_URL_TEST + nftProfile?.banner}>
            <UserIcon src={CONFIG.ZED_BASE_URL_TEST + nftProfile?.logo} />
            <Text
              color={theme.colors.white}
              size="44px"
              fontStyle={theme.fonts.f600}
            >
              {nftProfile?.username}
            </Text>
          </Background>

          <Text
            color={theme.colors.white}
            size="34px"
            fontStyle={theme.fonts.f500}
          >
            {nftProfile?.Bio}
          </Text>
          <InputsWrapper>
            <InputItem
              label={t("nftProfileScreen.followers")}
              value={nftProfile?.followers}
              variant={"info"}
            />
            <Link to="/nft/plans">
              <InputItem
                label={t("nftProfileScreen.currentPlan")}
                value={nftProfile?.plans[0].title}
                variant={"info"}
              />
            </Link>
            {nftProfile?.links.map((el, index) => (
              <InputItem
                key={index}
                label={el.platform}
                value={el.url}
                variant={"link"}
              />
            ))}
          </InputsWrapper>
          {isMyAccount && (
            <Text
              color={theme.colors.yellowMain}
              size="28px"
              fontStyle={theme.fonts.f500}
              func={() => setVariant("edit")}
            >
              {t("nftProfileScreen.editNftProfile")}
            </Text>
          )}
          <Text
            color={theme.colors.white}
            size="44px"
            fontStyle={theme.fonts.f600}
          >
            Collections :
          </Text>
          <CardsContainer>
            {myNftCollections?.map((item, index) => (
              <CardNFT
                likedByMe={false}
                key={index}
                href={`/nft/item-collection/${item.id}`}
                isCollection
                img={`${CONFIG.ZED_BASE_URL}${item.logo}`}
                name={item.nft_user.username}
                title={item.title}
                verif={item.nft_user.badge}
                avatar={`${CONFIG.ZED_BASE_URL}${item.nft_user.logo}`}
                counterCollection={item.items}
              />
            ))}
          </CardsContainer>
        </>
      )}
      {variant === "edit" && (
        <>
          <Back
            src="/images/support/back_arrow.svg"
            onClick={() => setVariant("view")}
          />
          <Text
            color={theme.colors.white}
            size="32px"
            fontStyle={theme.fonts.f600}
            margin={windowWidth > 800 ? "0" : "16px 0 0 0"}
          >
            {t("editNftProfileScreen.logo")}:
          </Text>

          <EditUserIcon>
            <ImagePicker
              image={images.logo}
              setImage={setImages}
              id={"fileLogo"}
              name={"logo"}
              circle
            />
          </EditUserIcon>
          <Text
            color={theme.colors.white}
            size="32px"
            fontStyle={theme.fonts.f600}
          >
            {t("editNftProfileScreen.banner")}:
          </Text>

          <EditUserBanner>
            <ImagePicker
              image={images.banner}
              setImage={setImages}
              id={"fileBanner"}
              name={"banner"}
            />
          </EditUserBanner>
          <InputsWrapper>
            <InputItem
              hideError={hideErrors || validateNftUsername(username)}
              label={t("editNftProfileScreen.username")}
              value={username}
              variant={"edit"}
              inputHandler={(e: any) => setUsername(e.target.value)}
            />
            <InputItem
              hideError={hideErrors || validateNftBio(bio)}
              label={t("editNftProfileScreen.bio")}
              value={bio}
              variant={"edit"}
              inputHandler={(e: any) => setBio(e.target.value)}
            />
            {(Object.keys(links) as Array<NftSocialMedia>).map((media) => (
              <InputItem
                key={`${media}_link`}
                label={`${media} ${t("editNftProfileScreen.link")}`}
                value={links[media]}
                hideError={
                  hideErrors ||
                  links[media].length === 0 ||
                  validateLink(links[media])
                }
                variant={"edit"}
                inputHandler={(e: any) => setLink(media, e.target.value)}
              />
            ))}
            <ToggleWrapper>
              <Text
                color={theme.colors.white}
                size="24px"
                fontStyle={theme.fonts.f600}
                whiteSpace
              >
                {t("editNftProfileScreen.profileVisible")}
              </Text>
              <ToggleButton
                style={{ marginLeft: "0" }}
                toggle={profileVisible}
                onClick={() => setProfileVisible(!profileVisible)}
              >
                <ToggleCircle toggle={profileVisible} />
              </ToggleButton>
            </ToggleWrapper>
            <Btn
              label={t("editNftProfileScreen.save")}
              type={"support"}
              buttonActive
              func={() => onPressSave()}
              spinner={fetchingEdit}
            />
          </InputsWrapper>
        </>
      )}
    </Wrapper>
  );
};
export default NFTProfile;
