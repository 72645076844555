import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    height: 50,
    flexDirection: 'row'
  },
  containerHovered: {
    backgroundColor: theme.colors.subBackground,
    borderRadius: theme.metrics.x4
  },
  contentContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'row'
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.colors.borderGrey}`
  },
  borderTop: {
    borderTopWidth: 1,
    borderColor: theme.colors.subBackground
  },
  rowInfoContainer: {
    display: 'flex',
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1
  },
  labelStyle: {
    fontFamily: theme.fonts.f400.fontFamily,
    fontSize: 18,
    margin: 0
  },
  secondLabelStyle: {
    color: theme.colors.greySubText,
    fontFamily: theme.fonts.f400.fontFamily,
    fontSize: 13,
    margin: 0
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  rLabel: {
    fontSize: 17,
    fontFamily: theme.fonts.f600.fontFamily,
    margin: 0
  },
});