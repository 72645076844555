import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
  ContentWrapper,
  Line,
  RightWrapper,
  Tab,
  TabsBar,
  Wrapper,
} from "./PaymentsStyle";
import SelectMethod from "../SelectMethod";
import MethodOptional from "../MethodOptional";
import { BoxData, TabData } from "../../../types/types";
import { useCryptoAccountTopUp } from "../../../hooks/useCryptoAccountTopUp";
import { useSelector } from "react-redux";
import { cryptoSelector } from "../../../logic/crypto/CryptoSelectors";
import {
  DEFAULT_ACTIVE_BOX,
  ExchangeBoxes,
  PaymentsTabs,
  TopUpBoxes,
  WalletInfoBoxes,
  WithdrawBoxes,
} from "./paymentsPageConstants";
import { useTranslation } from "react-i18next";
import { NoData } from "../../../components/NoData";

const Payments: React.FC = () => {
  // @ts-ignore
  const { t } = useTranslation();

  const { paymentsPageSelectedAccount } = useSelector(cryptoSelector);

  const tabsData: TabData[] = useMemo(
    () => [
      { id: PaymentsTabs.TopUp, title: t("payments.tab1") },
      { id: PaymentsTabs.Withdraw, title: t("payments.tab2") },
      { id: PaymentsTabs.Exchange, title: t("payments.tab3") },
      { id: PaymentsTabs.WalletInfo, title: t("payments.tab4") }
    ],
    [t]
  );

  const boxesData: BoxData[][] = useMemo(() => {
    const topUpBoxes: BoxData[] = [];
    if (paymentsPageSelectedAccount?.currency_type === "Crypto")
      topUpBoxes.push({
        id: TopUpBoxes.CryptoTopUp,
        iconSrc: "/images/binance_small.png",
        title: t("payments.box3title"),
        description: t("payments.box3desc"),
        buttons: [{ label: t("payments.blockchain"), iconSrc: "" }],
        autoSelect: true
      });
    else if (paymentsPageSelectedAccount?.currency_type === "Fiat")
      topUpBoxes.push({
        id: TopUpBoxes.FiatOfflineTopUp,
        iconSrc: "/images/cards_small.png",
        title: t("payments.box1title"),
        description: t("payments.box1desc"),
        buttons: [
          { label: "Up to 5 days", iconSrc: "" },
          { label: "EUR", iconSrc: "" },
          { label: "IBAN", iconSrc: "" },
        ],
        autoSelect: true
      });

    const withdrawBoxes: BoxData[] = [];
    if (paymentsPageSelectedAccount?.currency_type === "Crypto")
      withdrawBoxes.push({
        id: WithdrawBoxes.CryptoToBlockchain,
        iconSrc: "/images/by_wallet.png",
        title: t("payments.box6title"),
        description: t("payments.box6desc"),
        buttons: [
          { label: t("payments.blockchain"), iconSrc: "" },
          { label: t("payments.fast"), iconSrc: "" },
          { label: t("payments.crypto"), iconSrc: "" },
        ],
        autoSelect: true
      });
    else if (paymentsPageSelectedAccount?.currency_type === "Fiat") {
      withdrawBoxes.push({
        id: WithdrawBoxes.FiatToBank,
        iconSrc: "/images/bank.png",
        title: t("payments.box4title"),
        description: t("payments.box4desc"),
        buttons: [
          { label: "Up to 5 day", iconSrc: "" },
          { label: "EUR", iconSrc: "" },
          { label: "IBAN", iconSrc: "" },
        ],
        autoSelect: true
      });
      withdrawBoxes.push({
        id: WithdrawBoxes.InternalFiatTransfer,
        iconSrc: "/images/zedxion.png",
        title: t("payments.sendInternalFiatTransfer"),
        description: t("payments.sendingFiatMoney"),
        buttons: [
          { label: t("payments.fast"), iconSrc: "" },
          { label: t("payments.internal"), iconSrc: "" },
        ],
        autoSelect: false
      });
    }

    const exchangeBoxes: BoxData[] = [
      {
        id: ExchangeBoxes.Exchange,
        iconSrc: "/images/exchange.png",
        title: t("payments.box7title"),
        description: t("payments.box7desc"),
        buttons: [
          { label: t("payments.bestRates"), iconSrc: "" },
          { label: t("payments.instantly"), iconSrc: "" },
        ],
        autoSelect: true
      },
    ];

    const walletInfoBoxes: BoxData[] = [
      {
        id: WalletInfoBoxes.MainInfo,
        iconSrc: "/images/zedxion.png",
        title: t("payments.walletInformation"),
        description: t("payments.shareYourWalletAddress"),
        buttons: [
          { label: t("payments.safe"), iconSrc: "" },
        ],
        autoSelect: true
      },
    ];

    return [topUpBoxes, withdrawBoxes, exchangeBoxes, walletInfoBoxes];
  }, [t, paymentsPageSelectedAccount]);

  const [activeTab, setActiveTab] = useState(PaymentsTabs.TopUp);
  const [activeBox, setActiveBox] = useState(DEFAULT_ACTIVE_BOX);

  const { handleCryptoAccountTopUpPress } = useCryptoAccountTopUp(
    paymentsPageSelectedAccount
  );

  useEffect(() => {
    if (activeTab === PaymentsTabs.TopUp && activeBox === TopUpBoxes.CryptoTopUp)
      handleCryptoAccountTopUpPress();
  }, [activeTab, activeBox, handleCryptoAccountTopUpPress]);

  useEffect(() => {
    const defaultBoxForSelectedTab = boxesData[activeTab].find((value) => value.autoSelect === true);
    setActiveBox(defaultBoxForSelectedTab?.id ?? DEFAULT_ACTIVE_BOX);
  }, [activeTab, paymentsPageSelectedAccount, boxesData]);

  const onClickTab = useCallback((tab: TabData) => {
    setActiveTab(tab.id);
  }, []);

  return (
    <Wrapper>
      {/* табы */}
      <TabsBar>
        {tabsData.map((tab) => (
          <Tab
            key={tab.id}
            active={activeTab === tab.id}
            onClick={() => onClickTab(tab)}
          >
            {tab.title}
          </Tab>
        ))}
      </TabsBar>

      <Line />

      {paymentsPageSelectedAccount ?
        <ContentWrapper>
          {/* левая часть */}
          <SelectMethod
            {...{
              boxesData,
              setActiveBox,
              activeTab,
              activeBox,
            }}
          />
          {/* правая часть */}
          <RightWrapper>
            <MethodOptional
              activePage={"payments"}
              activeTab={activeTab}
              activeBox={activeBox}
            />
          </RightWrapper>
        </ContentWrapper>
      :
        <NoData text={t("payments.noWalletSelected") as string}/>
      }
    </Wrapper>
  );
};

export default Payments;
