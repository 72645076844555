export const handleInputWithDateMask = (e: any, setValue: any) => {
    let value = e.target.value;

    // Удаление всех символов, кроме цифр
    value = value.replace(/[^\d]/g, "");
  
    // Вставка символа "/" после года и месяца
    if (value.length > 4) {
      value = value.slice(0, 4) + "/" + value.slice(4);
    }
    if (value.length > 7) {
      value = value.slice(0, 7) + "/" + value.slice(7);
    }
  
    // Ограничение длины значения
    value = value.slice(0, 10);
  
    // Обновление значения в состоянии
    setValue((prev: any)=> ({...prev, [e.target.name]:value}));
}