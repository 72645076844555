import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { prepareImageName } from "../../../helpers/formatHelpers";
import { GeneralActions } from "../../../logic/general/GeneralRedux";
import { generalSelector } from "../../../logic/general/GeneralSelectors";
import { UserActions } from "../../../logic/user/UserRedux";
import { userSelector } from "../../../logic/user/UserSelectors";
import { useNavigate } from "react-router-dom";

type VerificationButtonType = string;
export const useVerification = () => {
  const [modalStep, setModalStep] = useState(1);
  const [selectedImage, setSelectedImage] = useState<
    Record<VerificationButtonType, File | null>
  >({});
  const [doc, setDoc] = useState<string>("Passport");
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const {
    listDocuments: { data: listData },
    uploadFile: { data: uploadData },
  } = useSelector(generalSelector);
  const {
    sendDocuments: { data: sendSuccess },
    userId,
  } = useSelector(userSelector);

  const sendImages = () => {
    if (Object.values(selectedImage).some((value) => value !== null)) {
      let fdata = new FormData();

      for (const key in selectedImage) {
        const file = selectedImage[key];

        if (file !== null) {
          fdata.append(key, file, `${key.replace(/_/g, " ")}.jpg`);
        }
      }

      dispatch(GeneralActions.uploadFile.request(fdata));
    }
  };

  //   const compressImages = async (
  //     image: string,
  //     action: (url: string) => void
  //   ) => {
  //     const result = await Image.compress(image, {
  //       maxWidth: 800,
  //       quality: 0.5,
  //     });
  //     action(result);
  //   };
  useEffect(() => {
    if (!!uploadData) {
      const documents = uploadData.value.map((item) => {
        return {
          //@ts-ignore
          title: item.Key,
          file: item.Value,
        };
      });

      dispatch(
        UserActions.sendDocuments.request({
          id: userId as any,
          documents,
        })
      );
    }
  }, [uploadData]);

  // useEffect(() => {
  //   if (!!uploadData) {
  //     dispatch(
  //       UserActions.sendDocuments.request({
  //         id: userId as any,
  //         documents: [
  //           {
  //             title: doc,
  //             file: uploadData?.value[0].Value,
  //           },
  //           {
  //             title: "Selfie",
  //             file: uploadData?.value[1].Value,
  //           },
  //           {
  //             title: "Selfie with " + doc,
  //             file: uploadData?.value[2].Value,
  //           },
  //         ],
  //       })
  //     );
  //   }
  // }, [uploadData]);

  // useEffect(() => {
  //   if (sendSuccess) {
  //     NextStep();
  //   }
  // }, [sendSuccess]);

  // useEffect(() => {
  //   if (modalStep === 7) {
  //     sendImages();
  //   }
  // }, [modalStep]);

  const NextStep = () => {
    setModalStep(modalStep + 1);
  };

  const PrevStep = () => {
    setModalStep(modalStep - 1);
  };

  //   const wichNext = (i: string | null) => {
  //     if (i === null) {
  //       PrevStep();
  //     } else {
  //       NextStep();
  //     }
  //   };
  const documentSelect = (title: string) => {
    setDoc(title === "National ID" ? "NationalID" : "Passport");
    NextStep();
  };

  //   const changeDocPhoto = async (path: string | null) => {
  //     let result = null;
  //     if (path) {
  //       result = await Image.compress(path as string, {
  //         compressionMethod: "auto",
  //       });
  //     }
  //     setDocumentPhoto(result);
  //     wichNext(path);
  //   };

  //   const changeSelfiePhoto = async (path: string | null) => {
  //     let result = null;
  //     if (path) {
  //       result = await Image.compress(path as string, {
  //         compressionMethod: "auto",
  //       });
  //     }
  //     setSelfie(result);
  //     wichNext(path);
  //   };

  //   const changeWithDocPhoto = async (path: string | null) => {
  //     let result = null;
  //     if (path) {
  //       result = await Image.compress(path as string, {
  //         compressionMethod: "auto",
  //       });
  //     }
  //     setWithDocument(result);
  //     wichNext(path);
  //   };

  //   const headerHandler = () => {
  //     if (step <= 3 || step === 14) {
  //       navigation.goBack();
  //     } else if (step > 4 && step < 7) {
  //       setStep(3);
  //     } else if (step > 7 && step < 10) {
  //       setStep(6);
  //     } else if (step > 10 && step < 13) {
  //       setStep(9);
  //     } else {
  //       PrevStep();
  //     }
  //   };

  //   const progress = useMemo(() => {
  //     return _.round(step / totalSteps, 1);
  //   }, [step]);

  return {
    // step,
    // setStep,
    // progress,
    // changeDocPhoto,
    // documentPhoto,
    // selfie,
    // withDocument,
    sendImages,
    NextStep,
    PrevStep,
    setModalStep,
    modalStep,
    selectedImage,
    setSelectedImage,
    // changeSelfiePhoto,
    // changeWithDocPhoto,
    // headerHandler,
    documentSelect,
    // compressImages,
    listData,
  };
};
