import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
  },
  totalTextCont: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: theme.metrics.x2
  },
  totalText: {
    color: theme.colors.textSub,
    fontFamily: theme.fonts.f500.fontFamily,
    fontSize: 20,
    margin: 0
  },
  balanceText: {
    color: theme.colors.textSub,
    fontFamily: theme.fonts.f800.fontFamily,
    margin: 0
  },
});