export const toHex = num => {
  const val = Number(num);
  return '0x' + val.toString(16);
};

export const amountFormatTool = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const percentFormatTool = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 2,
  signDisplay: 'always',
});

export const secureAddress = s => {
  if (s.length <= 8) return s;
  return `${s.slice(0, 4)} **** ${s.slice(-4)}`;
};

export const seperateCardNumber = (num, hideData) => {
  // Remove any non-digit characters from the input string
  const number = num.toString().replace(/\s/g, '');

  // Split the input string into four-character chunks
  var chunks = [];
  for (var i = 0; i < number.length; i += 4) {
    chunks.push(number.substr(i, 4));
  }
  if (hideData) {
    return ['****', '****', '****', chunks[3] ?? '****'];
  } else {
    return chunks;
  }
};
