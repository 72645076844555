import React, { memo, useState, useMemo } from 'react';
import { useStyles } from './EscrowCreateTxPageStyles';
import { Box } from '@material-ui/core';
import { useThemeContext } from "../../theme";
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { escrowTxConstructorSelector } from '../../logic/escrowTxConstructor/EscrowTxConstructorSelectors';
import { escrowSelector } from '../../logic/escrow/EscrowSelectors';
import { GoBackButton } from './../../components/GoBackButton';
import { CustomButton } from './../../components/CustomButton';
import { TextInputUnderlined } from './../../components/TextInputUnderlined';
import { PartnerForm } from './PartnerForm';
import { EscrowPartnerFormInfo, EscrowTxConstructorActions } from '../../logic/escrowTxConstructor/EscrowTxConstructorSlice';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { validateEmail, validateEscrowTxDescription, validateEscrowTxTitle, validateFloat } from '../../helpers/validationHelper';
import { useHideErrors } from '../../hooks/useHideErrors';
import { EscrowActions, NewEscrowPayload } from '../../logic/escrow/EscrowRedux';
import { AgreeTerms, SmallLink, SmallText } from '../Login/LoginPageStyles';
import { Checkbox } from '@mui/material';
import { handleOpenLink } from '../../helpers/windowHelpers';
import { NoData } from '../../components/NoData';

export type EscrowCreateTxPageProps = {};

const EscrowCreateTxPage: React.FC<EscrowCreateTxPageProps> = ({}) => {
  const { theme } = useThemeContext();
  const s = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const editMode = !!params?.id;

  const {
    title,
    description,
    userAsPartner,
    partners
  } = useSelector(escrowTxConstructorSelector);

  const {
    new: {
      fetching: fetchingCreateEscrowTx
    },
    edit: {
      fetching: fetchingEditEscrowTx
    },
    viewingTx
  } = useSelector(escrowSelector);

  const [termsAccepted, setTermsAccepted] = useState(false);
  const { hideErrors, setHideErrors, onInputErrorAnimationEnd } = useHideErrors();

  // const tryToFillFormWithEditingTxData = (id: number) => {
  //   if (viewingTx && viewingTx.id === id) {
  //     dispatch(EscrowTxConstructorActions.prepareTxConstructorForEditing(viewingTx));
  //   }
  // }

  const validatePartnerInfo = (partner: EscrowPartnerFormInfo) => {
    return validateEmail(partner.email) && 
      partner.role &&
      partner.payment &&
      partner.account &&
      validateFloat(partner.amount);
  }

  const createEscrowButtonDisabled = useMemo(() => {
    if (!termsAccepted)
      return true;

    if (Object.keys(partners).length < 1)
      return true;

    if (!validatePartnerInfo(userAsPartner))
      return true;

    return Object.keys(partners).some((key) => !validatePartnerInfo(partners[key]));
  }, [termsAccepted, userAsPartner, partners]);

  const handleContinue = () => {
    if (validateEscrowTxTitle(title) && validateEscrowTxDescription(description)) {
      const payload: NewEscrowPayload = {
        title,
        description,
        // It is important to put userAsPartner first, because backend awaits partners[0] to be the user that is sending request
        partners: [userAsPartner].concat(Object.values(partners)).map((p) => ({
          customer_id: p.account!.customer.id,
          account_id: p.account!.id,
          role: p.role!,
          will_pay: p.payment === "Will Pay" ? Number(p.amount) : 0,
          will_receive: p.payment === "Will Receive" ? Number(p.amount) : 0,
        })),
        clearEscrowTxConstructorOnSuccess: true,
        successCallback: () => navigate("/main/escrow")
      };

      if (editMode) {
        dispatch(EscrowActions.edit.request({
          id: Number(params!.id),
          ...payload
        }));
      } else {
        dispatch(EscrowActions.new.request(payload));
      }
    } else {
      setHideErrors(false);
    }
  }

  // useLayoutEffect(() => {
  //   if (location.pathname.includes("/escrow-create-tx") && params?.id) {
  //     tryToFillFormWithEditingTxData(Number(params.id));
  //   }
  // }, [location, params]);

  return (
    <Box className={s.wrapper}>
      <Box className={s.top}>
        <GoBackButton onPress={() => navigate("/main/escrow")}/>

        <Box className={s.addPartnerBtn}>
          <CustomButton 
            title={"+ Add Partner"}
            colorActive={theme.colors.yellowMain}
            bgColorActive={theme.colors.transparent}
            onPress={() => dispatch(EscrowTxConstructorActions.addPartner())}
          />
        </Box>
      </Box>
      {/* <Box className={s.block}>
        <p 
          className={cn(s.label)} 
        >
          {"Transaction Type:"}
        </p>

      </Box> */}
      {(params?.id && !viewingTx) ?
        <NoData />
      :
        <>
          <Box className={s.block}>
            <p 
              className={cn(s.label)} 
            >
              {"Transaction Details"}
            </p>

            <Box className={s.formCont}>
              <TextInputUnderlined
                value={title}
                placeholder={"Transaction Title"}
                onChangeText={(text) => dispatch(EscrowTxConstructorActions.setTitle(text))}
                validationOk={hideErrors || validateEscrowTxTitle(title)}
                onErrorAnimationEnd={onInputErrorAnimationEnd}
              />
              <TextInputUnderlined
                value={description}
                placeholder={"Transaction Description"}
                onChangeText={(text) => dispatch(EscrowTxConstructorActions.setDescription(text))}
                validationOk={hideErrors || validateEscrowTxDescription(description)}
                onErrorAnimationEnd={onInputErrorAnimationEnd}
              />
            </Box>
          </Box>

          <PartnerForm 
            partner={userAsPartner}
            index={0}
            isMe={true}
            editMode={editMode}
          />

          {Object.values(partners).map((partner, index) => (
            <PartnerForm 
              key={`partner_b_${partner.localId}`}
              partner={partner}
              index={index + 1}
              editMode={editMode}
            />
          ))}

          <Box className={s.formFooter}>
            <AgreeTerms>
              <Checkbox
                onChange={(e) => setTermsAccepted(e.target.checked)}
                sx={{
                  color: theme.colors.yellowMain,
                  "&.Mui-checked": {
                    color: theme.colors.yellowMain,
                  },
                }}
              />
              <SmallText>
                By proceeding, you confirm that you agree to the platform's
                <SmallLink onClick={() => handleOpenLink("https://batbank.co.id/terms-conditions/")}>{"Terms and conditions\u00A0"}</SmallLink> 
                and acknowledge that this transaction is binding.
              </SmallText>
            </AgreeTerms>

            <Box className={s.createTxBtn}>
              <CustomButton 
                title={"Continue"}
                alt={createEscrowButtonDisabled ? "Make sure you've filled the form" : "Create Escrow transaction"}
                colorActive={theme.colors.black}
                bgColorActive={theme.colors.yellowMain}
                bgColorUnactive={theme.colors.shark}
                colorUnactive={theme.colors.greySubTextDesc}
                borderRadius={8}
                disabled={createEscrowButtonDisabled}
                onPress={handleContinue}
                spinner={editMode ? fetchingEditEscrowTx : fetchingCreateEscrowTx}
              />
            </Box>
          </Box>
        </>
      }
    </Box>
  );
};

const MemorizedComponent = memo(EscrowCreateTxPage);
export { MemorizedComponent as EscrowCreateTxPage };