import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  height: 100%;
  

  padding-top: 100px;
  padding-left: 336px;
  @media (max-width: 1300px) {
    padding-left: 0px;
  }
  @media (max-width: 800px) {
    column-gap: 0px;

    padding-top: 70px;
  }
`;
