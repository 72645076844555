import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';
import { isMobile } from "react-device-detect";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    // height: "100%",
    // backgroundColor: 'red',
    marginRight: theme.metrics.x4,
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden'
  },
  outerCircle: {
    display: "flex",
    flexDirection: "column",
    padding: theme.metrics.x,
    marginBottom: theme.metrics.x,
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerCircle: {
    display: "flex",
    flexDirection: "column",
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.itemBackground,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    cursor: "pointer"
  },
  titleText: {
    color: theme.colors.white,
    fontSize: 14,
    fontFamily: theme.fonts.f600.fontFamily,
    textAlign: 'center',
    margin: 0,
    padding: 0,
    whiteSpace: 'nowrap',
    "@media (max-width: 800px)": {
      display: isMobile ? undefined : "none"
    },
  },
  image: {
    display: "flex",
    width: '100%',
    height: '100%'
  }
});