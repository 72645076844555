import { useEffect } from "react";

const useEnterKeyPress = (
  isActive: boolean,
  callback: () => void,
  shiftCallback?: () => void // Добавляем коллбэк для обработки Shift + Enter
): void => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && isActive) {
        if (event.shiftKey) {
          shiftCallback && shiftCallback(); // Вызываем коллбэк для Shift + Enter
        } else {
          callback(); // Вызываем коллбэк для Enter
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isActive, callback, shiftCallback]);
};

export default useEnterKeyPress;
