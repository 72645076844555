import { FC, useState } from "react";
import { Text } from "../../../../components/Text/Text";
import { useThemeContext } from "../../../../theme";
import {
  CheckCircle,
  CheckSquare,
  CheckerContainer,
  IconCheck,
} from "../CreateNFTStyles";

type Props = {
  type: "circle" | "square";
  checked: boolean;
  text: string;
  setCheck: any;
  check?: string[];
  id: number;
  title?: string | null;
};

const Cheker: FC<Props> = ({
  type,
  checked,
  setCheck,
  text,
  id,
  check,
  title,
}) => {
  const { theme } = useThemeContext();

  const handleClick = () => {
    if (type === "circle") {
      setCheck(text);
    }
    if (type === "square") {
      check?.includes(text)
        ? setCheck((prev: any) => prev.filter((item: any) => item !== text))
        : setCheck((prev: any) => [...prev, text]);
    }
  };

  return (
    <CheckerContainer onClick={() => handleClick()}>
      {type === "circle" && <CheckCircle checkRadio={checked}></CheckCircle>}
      {type === "square" && (
        <CheckSquare check={checked}>
          <IconCheck src="/images/NFT_ItemPage/check.svg" alt="Checkbox" />
        </CheckSquare>
      )}

      <Text color={theme.colors.white} fontStyle={theme.fonts.f500} size="18px">
        {title}
      </Text>
    </CheckerContainer>
  );
};
export default Cheker;
