import React from "react";
import ContentLoader from "react-content-loader";
import { theme } from "../../theme/default/theme";

const SkeletonStories = (props: any) => (
  <ContentLoader
    speed={2}
    width={80}
    height={80}
    viewBox="0 0 80 80"
    backgroundColor={theme.colors.subBackground}
    foregroundColor={theme.colors.black}
    {...props}
    style={{ marginRight: "18px" }}
  >
    <circle cx="36" cy="30" r="30" />
    <rect x="2" y="65" rx="0" ry="0" width="70" height="14" />
  </ContentLoader>
);

export default SkeletonStories;
