import { flags } from "../assets/flags";
import { theme } from "../theme/default/theme";

export const LANGUAGES_DATA = [
  { value: `en`, label: `English`, key: "en", color: theme.colors.white },
  { value: `ru`, label: `Русский`, ley: "ru", color: theme.colors.white },
];

export const LANGUAGE_LABEL = {
  en: `${flags.GB.emoji} English`,
  ru: `${flags.RU.emoji} Русский`,
};
