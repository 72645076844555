import {
  ActionReducerMapBuilder,
  createAction,
  createReducer,
} from "@reduxjs/toolkit";
import {
  addAllRestReducers,
  addRestReducers,
  createRestActions,
  getDefaultRestState,
} from "../../store/restHelper";
import { NodeRestStateType } from "../../store/restHelper.d";
import {
  BuyCryptoElementsRestActions,
  ChangeAccountStatusRestActions,
  CoingeckoMarketChartsRestActions,
  ConvertCurrencyRestActions,
  DeleteAccountRestActions,
  DepositRestActions,
  FindAccountsRestActions,
  GetAccountsListRestActions,
  OpenAccountRestActions,
  RemitRestActions,
  SwapReceiveRestActions,
  TransferRestActions,
} from "./CryptoActions";
import { ZedAPIDefaultResponse } from "../user/UserRedux";
import {
  BankTransfer,
  BlockchainType,
  BusinessInfo,
  Country,
  DropdownItemConstructor,
  GetTransactionsItem,
  ShortCurrencyInfo,
  ShortCurrencyInfoBackRate,
  SwapReceiveTable,
  TransactionType,
  UserDocument,
  WithTimestamp,
  ZedApiKey,
  ZedApiNotificationInfo,
} from "../../types/types";
import fp from "lodash/fp";
import { RemitRequest } from "../../types/api";

export type NumberPair = [number, number];

export type CoingeckoCoinMarketChart = {
  prices: NumberPair[];
  market_caps: NumberPair[];
  total_volumes: NumberPair[];
  priceChange?: number;
};

export type Total = {
  Key: string;
  Value: string;
};

export type MarketInfo = {
  value: number;
  change: number;
};

export type Customer = {
  id: number;
  reg_date: string;
  first_name: string;
  last_name: string;
  gender: string;
  birth_date: string;
  customer_type: string;
  email: string;
  mobile: string;
  phone: string;
  country: Country;
  city: string;
  address: string;
  avatar: string;
  remark: string;
  documents: UserDocument[];
  business_info: BusinessInfo;
  api_key: ZedApiKey;
  notification: ZedApiNotificationInfo;
  otp_enabled: boolean;
  status: string;
};

export type CurrencyType = "Fiat" | "Crypto";

export type AccountStatus = "Closed" | "Active";

export type CryptoAddress = {
  blockchain: number;
  address_index: number;
  address: string;
  address_balance: number;
  last_update: string;
};

export type CryptoAccount = {
  id: number;
  date_time: string;
  customer: Customer;
  currency_id: number;
  currency_code: string;
  currency_price: string;
  currency_type: CurrencyType;
  address: CryptoAddress[];
  address_comment: string;
  currency_flag: string;
  balance: number;
  name: string;
  status: AccountStatus;
};

export type TotalSnapshot = WithTimestamp<{ total: Total[] }>;

export type CryptoAccountDropdownItem = DropdownItemConstructor<CryptoAccount>;

type GetAccountsListResponse = ZedAPIDefaultResponse<
  CryptoAccount[],
  { total: Total[] }
>;

type DepositResponse = GetTransactionsItem[];

type BuyCryptoElementsResponse = {
  pay: ShortCurrencyInfo[];
  receive: ShortCurrencyInfo[];
};

type CoingeckoMarketChartsTable = { [key: string]: CoingeckoCoinMarketChart };

export type GetAccountsListPayload = {
  customer_id?: string;
  account_id?: string;
  currency?: string;
  update_crypto_balances?: string;
  total_currency?: string;
};

export type TransferCheckOnlyResponse = {
  from_amount: string;
  from_currency: string;
  from_commission: string;
  to_amount: string;
  to_currency: string;
  to_name: string;
  rate: string;
  backRate: string;
};

type OpenAccountPayload = {
  customer_id: number;
  currency_id: number;
  hideToasts?: boolean;
};

type DepositPayload = {
  to_account_id: number;
  amount: number;
  type: TransactionType;
  receipt: string;
  fData?: FormData;
};

type BuyCryptoElementsPayload = {
  customer_id: number;
};

type DeleteAccountPayload = {
  id: number;
};

type ChangeAccountStatusPayload = {
  id: number;
  status: AccountStatus;
};

type TransferPayload = {
  from_account_id: number;
  to_account_id: number;
  deposit_address: string;
  to_blockchain: BlockchainType;
  amount: number;
  otp_code?: string;
  check_only: boolean;
  normal_sending: boolean;
  successCallback?: () => void;
  showErrorToastOnCheck?: boolean;
};

type ConvertCurrencyResponse = {
  from_currency_id: number;
  from_currency: string;
  to_currency_id: number;
  to_currency: string;
  from_amount: number;
  to_amount: number;
  rate: number;
  backRate: number;
};

type RemitResponse = GetTransactionsItem;

type SwapReceiveResponse = ShortCurrencyInfoBackRate[];

type ConvertCurrencyPayload = {
  from_currency_id: number;
  to_currency_id: number;
  from_amount: number;
};

type RemitPayload = {
  from_account_id: number;
  amount: number;
  type: TransactionType | string;
  otp_code: string;
  bank_transfer: BankTransfer;
};

type SwapReceivePayload = {
  pay_account_id?: number;
  successCallback?: (value: ShortCurrencyInfoBackRate[]) => void;
};

type FindAccountsPayload = {
  email: string;
};

type UpdateSwapReceiveTablePayload = {
  pay_account_id: number;
  value: ShortCurrencyInfoBackRate[];
};

export type AccountsByUserEmailTable = ObjectT<CryptoAccountDropdownItem[]>;

type UpdateAccountsByUserEmailTablePayload = {
  email: string;
  accounts: CryptoAccountDropdownItem[];
};

const getAccountsListRestActions = createRestActions<
  GetAccountsListResponse,
  GetAccountsListPayload
>(GetAccountsListRestActions);

const openAccountRestActions = createRestActions<
  ZedAPIDefaultResponse,
  OpenAccountPayload
>(OpenAccountRestActions);

const depositRestActions = createRestActions<
  DepositResponse,
  DepositPayload
>(DepositRestActions);

const buyCryptoElementsRestActions = createRestActions<
  BuyCryptoElementsResponse,
  BuyCryptoElementsPayload
>(BuyCryptoElementsRestActions);

const deleteAccountRestActions = createRestActions<
  ZedAPIDefaultResponse,
  DeleteAccountPayload
>(DeleteAccountRestActions);

const changeAccountStatusRestActions = createRestActions<
  ZedAPIDefaultResponse,
  ChangeAccountStatusPayload
>(ChangeAccountStatusRestActions);

const transferRestActions = createRestActions<
  ZedAPIDefaultResponse,
  TransferPayload
>(TransferRestActions);

const coingeckoMarketChartsRestActions = createRestActions<
  CoingeckoMarketChartsTable
>(CoingeckoMarketChartsRestActions);

const convertCurrencyRestActions = createRestActions<
  ConvertCurrencyResponse,
  ConvertCurrencyPayload
>(ConvertCurrencyRestActions);

const remitRestActions = createRestActions<
  RemitResponse,
  RemitRequest
>(RemitRestActions);

const swapReceiveRestActions = createRestActions<
  SwapReceiveResponse,
  SwapReceivePayload
>(SwapReceiveRestActions);

const findAccountsRestActions = createRestActions<
  void,
  FindAccountsPayload
>(FindAccountsRestActions);

const CryptoRestActions = {
  getAccountsList: getAccountsListRestActions,
  openAccount: openAccountRestActions,
  deposit: depositRestActions,
  buyCryptoElements: buyCryptoElementsRestActions,
  deleteAccount: deleteAccountRestActions,
  changeAccountStatus: changeAccountStatusRestActions,
  transfer: transferRestActions,
  coingeckoMarketCharts: coingeckoMarketChartsRestActions,
  convertCurrency: convertCurrencyRestActions,
  remit: remitRestActions,
  swapReceive: swapReceiveRestActions,
  findAccounts: findAccountsRestActions,
}

const CryptoActions = {
  ...CryptoRestActions,
  prepareRequiredCryptoThings: createAction(
    "crypto/prepareRequiredCryptoThings"
  ),
  setMarketCap: createAction<MarketInfo>("crypto/setMarketCap"),
  setMarketVolume: createAction<MarketInfo>("crypto/setMarketVolume"),
  setOldTotalSnapshot: createAction<TotalSnapshot | null>(
    "crypto/setOldTotalSnapshot"
  ),
  setExchangeScreenAccounts: createAction<CryptoAccountDropdownItem[]>(
    "crypto/setExchangeScreenAccounts"
  ),
  setExchangeFromCoin: createAction<CryptoAccountDropdownItem | null>(
    "crypto/setExchangeFromCoin"
  ),
  setExchangeToCoin: createAction<CryptoAccountDropdownItem | null>(
    "crypto/setExchangeToCoin"
  ),
  swapFromAndTo: createAction("crypto/swapFromAndTo"),
  setTransferCheckOnlyResult: createAction<TransferCheckOnlyResponse | null>(
    "crypto/setTransferCheckOnlyResult"
  ),
  setTransferCheckOnlyFetching: createAction<boolean>(
    "crypto/setTransferCheckOnlyFetching"
  ),
  setShowAvailableAccountsStillNotOpened: createAction<boolean>("crypto/setShowAvailableAccountsStillNotOpened"),
  setPaymentsPageSelectedAccount: createAction<CryptoAccount | null>("crypto/setPaymentsPageSelectedAccount"),
  setSwapReceiveTable: createAction<SwapReceiveTable>("crypto/setSwapReceiveTable"),
  updateSwapReceiveTable: createAction<UpdateSwapReceiveTablePayload>("crypto/updateSwapReceiveTable"),
  clearAccountsList: createAction("crypto/clearAccountsList"),
  updateAccountsByUserEmailTable: createAction<UpdateAccountsByUserEmailTablePayload>("crypto/updateAccountsByUserEmailTable"),
  mergeAccountsByUserEmailTable: createAction<AccountsByUserEmailTable>("crypto/mergeAccountsByUserEmailTable")
};

type CryptoRestNodes = keyof typeof CryptoRestActions;


type CryptoStore = {
  marketCap: MarketInfo | null;
  marketVolume: MarketInfo | null;
  oldTotal: TotalSnapshot | null;
  exchangeScreenAccounts: CryptoAccountDropdownItem[];
  exchangeFromCoin: CryptoAccountDropdownItem | null;
  exchangeToCoin: CryptoAccountDropdownItem | null;
  transferCheckOnlyFetching: boolean;
  transferCheckOnlyResult: TransferCheckOnlyResponse | null;
  showAvailableAccountsStillNotOpened: boolean;
  paymentsPageSelectedAccount: CryptoAccount | null;
  swapReceiveTable: SwapReceiveTable;
  accountsByUserEmailTable: AccountsByUserEmailTable;
};

const initialCryptoStore: CryptoStore = {
  marketCap: null,
  marketVolume: null,
  oldTotal: null,
  exchangeScreenAccounts: [],
  exchangeFromCoin: null,
  exchangeToCoin: null,
  transferCheckOnlyFetching: false,
  transferCheckOnlyResult: null,
  showAvailableAccountsStillNotOpened: false,
  paymentsPageSelectedAccount: null,
  swapReceiveTable: {},
  accountsByUserEmailTable: {}
};

const initialRestState = {
  getAccountsList: getDefaultRestState<GetAccountsListResponse>(),
  openAccount: getDefaultRestState(),
  deposit: getDefaultRestState<DepositResponse>(),
  buyCryptoElements: getDefaultRestState<BuyCryptoElementsResponse>(),
  deleteAccount: getDefaultRestState(),
  changeAccountStatus: getDefaultRestState(),
  transfer: getDefaultRestState(),
  coingeckoMarketCharts: getDefaultRestState<CoingeckoMarketChartsTable>(),
  convertCurrency: getDefaultRestState<ConvertCurrencyResponse>(),
  remit: getDefaultRestState<RemitResponse>(),
  swapReceive: getDefaultRestState<SwapReceiveResponse>(),
  findAccounts: getDefaultRestState()
};

type CryptoState = NodeRestStateType<
  CryptoRestNodes,
  CryptoStore & typeof initialRestState
>;

type Builder = ActionReducerMapBuilder<CryptoState>;

const cryptoReducer = createReducer(
  { ...initialCryptoStore, ...initialRestState },
  (builder) =>
    (
      fp.flow(addAllRestReducers<typeof CryptoRestActions>(CryptoRestActions))(builder) as Builder
    )
      .addCase(CryptoActions.setMarketCap, (state, action) => {
        state.marketCap = action.payload;
      })
      .addCase(CryptoActions.setMarketVolume, (state, action) => {
        state.marketVolume = action.payload;
      })
      .addCase(CryptoActions.setOldTotalSnapshot, (state, action) => {
        state.oldTotal = action.payload;
      })
      .addCase(CryptoActions.setExchangeScreenAccounts, (state, action) => {
        state.exchangeScreenAccounts = action.payload;
      })
      .addCase(CryptoActions.setExchangeFromCoin, (state, action) => {
        if (
          action.payload?.id &&
          state.exchangeToCoin &&
          action.payload.id === state.exchangeToCoin.id
        ) {
          [state.exchangeFromCoin, state.exchangeToCoin] = [
            state.exchangeToCoin,
            state.exchangeFromCoin,
          ];
        } else {
          state.exchangeFromCoin = action.payload;
        }
      })
      .addCase(CryptoActions.setExchangeToCoin, (state, action) => {
        if (
          action.payload?.id &&
          state.exchangeFromCoin &&
          action.payload.id === state.exchangeFromCoin.id
        ) {
          [state.exchangeFromCoin, state.exchangeToCoin] = [
            state.exchangeToCoin,
            state.exchangeFromCoin,
          ];
        } else {
          state.exchangeToCoin = action.payload;
        }
      })
      .addCase(CryptoActions.swapFromAndTo, (state, action) => {
        [state.exchangeFromCoin, state.exchangeToCoin] = [
          state.exchangeToCoin,
          state.exchangeFromCoin,
        ];
      })
      .addCase(CryptoActions.setTransferCheckOnlyResult, (state, action) => {
        state.transferCheckOnlyResult = action.payload;
        state.transferCheckOnlyFetching = false;
      })
      .addCase(CryptoActions.setTransferCheckOnlyFetching, (state, action) => {
        state.transferCheckOnlyFetching = action.payload;
      })
      .addCase(CryptoActions.setShowAvailableAccountsStillNotOpened, (state, action) => {
        state.showAvailableAccountsStillNotOpened = action.payload;
      })
      .addCase(CryptoActions.setPaymentsPageSelectedAccount, (state, action) => {
        state.paymentsPageSelectedAccount = action.payload;
      })
      .addCase(CryptoActions.setSwapReceiveTable, (state, action) => {
        state.swapReceiveTable = action.payload;
      })
      .addCase(CryptoActions.updateSwapReceiveTable, (state, action) => {
        state.swapReceiveTable[action.payload.pay_account_id] = action.payload.value;
      })
      .addCase(CryptoActions.clearAccountsList, (state) => {
        state.getAccountsList = initialRestState.getAccountsList;
      })
      .addCase(CryptoActions.updateAccountsByUserEmailTable, (state, action) => {
        const { email, accounts } = action.payload;
        state.accountsByUserEmailTable[email] = accounts;
      })
      .addCase(CryptoActions.mergeAccountsByUserEmailTable, (state, action) => {
        for (const email of Object.keys(action.payload)) {
          state.accountsByUserEmailTable[email] = action.payload[email];
        }
      })
);

export { cryptoReducer, CryptoActions };
