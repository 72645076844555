import { makeStyles } from '@material-ui/core/styles';
import { theme } from "../../theme/default/theme";

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 400,
    // height: 'min-content',
    backgroundColor: theme.colors.codGray,
    borderRadius: theme.metrics.x4,
    padding: theme.metrics.x4
  },
  topCont: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: theme.metrics.x4,
    cursor: 'pointer'
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  dateText: {
    fontFamily: theme.fonts.f400.fontFamily,
    fontSize: 14,
    color: theme.colors.greySubText,
  },
  titleText: {
    fontFamily: theme.fonts.f500.fontFamily,
    fontSize: 20,
    color: theme.colors.text,
    marginTop: theme.metrics.x3
  },
});