import React, { useState, useCallback } from "react";
import { useStyles } from "./SettingsItemStyles";
import { Box, Menu, MenuItem } from "@material-ui/core";
import { theme } from "../../theme/default/theme";
import { DataPickerItemType } from "../../types/types";
import cn from "classnames";

type BorderType = "none" | "medium" | "full";
type ItemType = "toogle" | "arrow" | "none" | "arrowVertical";

export type SettingsItemProps = {
  mode?: ItemType;
  disabled?: boolean;
  borderBottom?: BorderType | string;
  borderTop?: boolean;
  paddingLeft?: number;
  paddingRight?: number;
  label: string;
  secondLabel?: string;
  labelColor?: string;
  picker?: boolean;
  items?: DataPickerItemType[];
  rightLabel?: string | null;
  rLabelMarginRight?: number;
  rLabelColor?: string;
  value?: string | number | null | undefined;
  onPress?: () => void;
  onOpen?: () => void;
  onValueChange?: (value: string | number) => void;
  enableOnhover?: boolean;
};

const SettingsItem: React.FC<SettingsItemProps> = ({
  mode,
  disabled,
  borderBottom,
  borderTop,
  paddingLeft,
  paddingRight,
  label,
  secondLabel,
  labelColor,
  picker,
  items,
  rightLabel,
  rLabelMarginRight,
  rLabelColor,
  value,
  onPress,
  onOpen,
  onValueChange,
  enableOnhover,
}) => {
  const s = useStyles();

  const [hovered, setHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePress = (event: any) => {
    if (picker) {
      anchorEl === null && setAnchorEl(event.currentTarget);
    } else if (onOpen) onOpen();
    else onPress && onPress();
  };

  const handleClosePicker = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onValueChangeInner = (item: DataPickerItemType) => {
    // @ts-ignore
    onValueChange(item.value);
    handleClosePicker();
  };

  return (
    <Box
      className={cn(s.container, {
        [s.containerHovered]: enableOnhover && hovered,
      })}
      style={disabled ? undefined : { cursor: "pointer" }}
      onClick={(event) => !disabled && handlePress(event)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Box
        className={cn(s.contentContainer, {
          [s.borderBottom]: borderBottom === "medium",
          [s.borderTop]: borderTop,
        })}
      >
        <Box
          className={cn(s.rowInfoContainer)}
          style={{ paddingLeft, paddingRight }}
        >
          <Box>
            <p
              className={cn(s.labelStyle)}
              style={{ color: labelColor ?? theme.colors.white }}
            >
              {label}
            </p>
            {secondLabel && (
              <p className={cn(s.secondLabelStyle)}>{secondLabel}</p>
            )}
          </Box>
          {mode === "arrow" && (
            <Box className={cn(s.row)}>
              {rightLabel && (
                <p
                  className={cn(s.rLabel)}
                  style={{ marginRight: rLabelMarginRight, color: rLabelColor }}
                >
                  {rightLabel}
                </p>
              )}
            </Box>
          )}
          {picker && items && onValueChange && (
            <Menu
              id="long-menu-settings-item"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClosePicker}
              PaperProps={{
                style: {
                  maxHeight: 200,
                  width: "20ch",
                },
              }}
            >
              {items.map((item) => (
                <MenuItem
                  key={item.value}
                  selected={item.value === value}
                  onClick={() => onValueChangeInner(item)}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          )}
        </Box>
      </Box>
    </Box>
  );
};

SettingsItem.defaultProps = {
  mode: "arrow",
  disabled: false,
  borderBottom: "medium",
  borderTop: false,
  paddingLeft: theme.metrics.x4,
  paddingRight: theme.metrics.x4,
  picker: false,
  rightLabel: null,
  rLabelMarginRight: theme.metrics.x,
  enableOnhover: true,
  rLabelColor: theme.colors.white,
};

export { SettingsItem };
