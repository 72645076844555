import React, { useEffect } from 'react';
import { useStyles } from './EscrowCreateTxPageStyles';
import { Box } from '@material-ui/core';
import { useThemeContext } from "../../theme";
import cn from 'classnames';
import { EscrowPartnerFormInfo } from '../../logic/escrowTxConstructor/EscrowTxConstructorSlice';
import { CustomButton } from '../../components/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { SelectInput } from "./../../Pages/Payments/MethodOptional/Components";
import { WalletItem } from '../../components/WalletItem';
import { TextInputUnderlined } from './../../components/TextInputUnderlined';
import { CONFIG } from '../../config';
import { CryptoAccount, CryptoActions } from '../../logic/crypto/CryptoRedux';
import { NoData } from '../../components/NoData';
import { EscrowTxConstructorActions } from '../../logic/escrowTxConstructor/EscrowTxConstructorSlice';
import { SettingsItem } from '../../components/SettingsItem';
import { userSelector } from '../../logic/user/UserSelectors';
import { cryptoSelector } from '../../logic/crypto/CryptoSelectors';
import { prettyStringToNumber } from '../../helpers/formatHelpers';
import { useDebounce } from '../../hooks/useDebounce';
import { validateEmail, validateFloat } from '../../helpers/validationHelper';
import { useAmountInput } from '../../hooks/useAmountInput';

const ROLE_OPTIONS = [
  {
    label: "Buyer",
    value: "Buyer"
  },
  {
    label: "Seller",
    value: "Seller"
  },
  {
    value: "Broker",
    label: 'Broker',
  }
];

const PAYMENT_OPTIONS = [
  {
    value: "Will Pay",
    label: "Will Pay",
  },
  {
    value: "Will Receive",
    label: "Will Receive",
  },
];

export type PartnerFormProps = {
  partner: EscrowPartnerFormInfo;
  index: number;
  isMe?: boolean;
  editMode: boolean;
};

const PartnerForm: React.FC<PartnerFormProps> = ({
  partner,
  index,
  isMe = false,
  editMode = false
}) => {
  const { theme } = useThemeContext();
  const s = useStyles();
  const dispatch = useDispatch();

  const {
    whoAmI: { data: profile },
  } = useSelector(userSelector);

  const {
    accountsByUserEmailTable,
    exchangeScreenAccounts: userAccountsDropdownData,
    findAccounts: {
      fetching: fetchingFindAccounts
    }
  } = useSelector(cryptoSelector);

  const debouncedEmail = useDebounce(partner.email, () => 8e2);

  const { handleAmountInput } = useAmountInput();

  const prefillDataIfPossible = () => {
    if (!editMode && isMe && profile?.email && partner.email !== profile.email) {
      dispatch(EscrowTxConstructorActions.setFieldOfUserAsPartner({field: "email", value: profile.email})); 
    }
  }
  
  const clearSelectedAccount = () => {
    dispatch(EscrowTxConstructorActions.setFieldByPartnerLocalId({
      isMe, 
      partnerLocalId: partner.localId, 
      field: "account", 
      value: null
    }));
  }

  // React.useEffect(() => {
  //   console.log("debouncedEmail", debouncedEmail);
  // }, [debouncedEmail]);
  
  useEffect(() => {
    prefillDataIfPossible();
  }, [profile?.email]);

  useEffect(() => {
    if (!editMode && !isMe && validateEmail(debouncedEmail)) {
      // We should clear selected account value because after search accounts list might differ completely
      clearSelectedAccount();
      dispatch(CryptoActions.findAccounts.request({ email: debouncedEmail }));
    }
  }, [editMode, isMe, debouncedEmail]);

  return (
    <Box 
      className={s.block} 
      style={{marginTop: theme.metrics.x4}}
    >
      <Box className={s.rowBetween}>
        <p 
          className={cn(s.label)} 
        >
          {`Partner ${index + 1}${isMe ? " (You)" : ""}`}
        </p>

        {!isMe &&
          <Box>
            <CustomButton 
              title={"Delete"}
              colorActive={theme.colors.yellowMain}
              bgColorActive={theme.colors.transparent}
              onPress={() => dispatch(EscrowTxConstructorActions.deletePartnerByLocalId(partner.localId))}
            />
          </Box>
        }
      </Box>
      <Box className={s.formCont}>
        <TextInputUnderlined 
          value={partner.email}
          placeholder={"Email"}
          onChangeText={(text) => dispatch(EscrowTxConstructorActions.setEmailByPartnerLocalId({isMe, partnerLocalId: partner.localId, email: text}))}
          validationOk={!debouncedEmail || validateEmail(debouncedEmail)}
          editable={!isMe}
        />

        <Box className={s.inputCont}>
          <SettingsItem
            label={"Role"}
            labelColor={theme.colors.greySubText}
            onValueChange={(v) => dispatch(EscrowTxConstructorActions.setFieldByPartnerLocalId({isMe, partnerLocalId: partner.localId, field: "role", value: v}))}
            picker={true}
            items={ROLE_OPTIONS}
            value={partner.role}
            rightLabel={partner.role}
            paddingLeft={0}
            paddingRight={0}
          />
        </Box>

        <Box className={s.inputCont}>
          <SettingsItem
            label={"Payment"}
            labelColor={theme.colors.greySubText}
            onValueChange={(v) => dispatch(EscrowTxConstructorActions.setFieldByPartnerLocalId({isMe, partnerLocalId: partner.localId, field: "payment", value: v}))}
            picker={true}
            items={PAYMENT_OPTIONS}
            value={partner.payment}
            rightLabel={partner.payment}
            paddingLeft={0}
            paddingRight={0}
          />
        </Box>

        <Box className={s.inputCont}>
          <SelectInput 
            label={"Wallet"} 
            data={isMe ? userAccountsDropdownData : accountsByUserEmailTable[partner.email] ?? []}
            noDataText={"No wallets found,\nmake sure you entered correct email"}
            value={partner.account}
            onValueChange={(v) => dispatch(EscrowTxConstructorActions.setFieldByPartnerLocalId({isMe, partnerLocalId: partner.localId, field: "account", value: v}))}
            renderItem={(account: CryptoAccount | null, onPress) => (
              account === null ?
                <NoData 
                  text={"Select a wallet"}
                  fontSize={18}
                  loaderSize={18}
                  loading={fetchingFindAccounts}
                />
              :
                <WalletItem 
                  image={`${CONFIG.ZED_BASE_URL}/${account.currency_flag}`}
                  userHasThisWallet={true}
                  // userCurrencySymbol={getCurrencySymbol(userCurrency)}
                  userCurrencySymbol="$"
                  balance={account.balance * prettyStringToNumber(account.currency_price)}
                  cryptoBalance={account.balance}
                  name={account.currency_code}
                  walletName={account.name}
                  marginVertical={theme.metrics.x3}
                  onPress={onPress}
                  paddingLeft={0}
                />
            )}
          />
        </Box>

        <Box className={s.inputCont}>
          <TextInputUnderlined 
            value={partner.amount}
            placeholder={"Amount"}
            onChangeText={(text) => handleAmountInput(text, (clearText) => dispatch(EscrowTxConstructorActions.setFieldByPartnerLocalId({isMe, partnerLocalId: partner.localId, field: "amount", value: clearText})))}
            validationOk={!partner.amount || validateFloat(partner.amount)}
            // validationOk={hideErrors || validateFloat(amount)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export { PartnerForm };