import React from 'react';
import { useStyles } from './EscrowPartnerItemStyles';
import { Box } from '@material-ui/core';
import { useThemeContext } from "../../theme";
import { StringValueTable } from './../StringValueTable';
import VerifiedBadgeIcon from './../../assets/icons/verifiedBadge';
import { EscrowPartner } from '../../types/types';

export type EscrowPartnerItemProps = {
  escrowPartner: EscrowPartner;
};

const EscrowPartnerItem: React.FC<EscrowPartnerItemProps> = ({
  escrowPartner
}) => {
  const { theme } = useThemeContext();
  const s = useStyles();

  return (
    <Box className={s.container}>
      <Box className={s.rowBetween}>
        <Box className={s.rowCentered}>
          <span className={s.nameText}>
            {escrowPartner.customer_name}
          </span>

          <Box className={s.badgeIconCont}>
            <VerifiedBadgeIcon />
          </Box>
        </Box>

        <span className={s.roleText}>
          {escrowPartner.role}
        </span>
      </Box>

      <span className={s.secondaryText}>
        {escrowPartner.customer_email}
      </span>

      <Box className={s.decorLine} />

      <StringValueTable 
        items={[
          {
            id: "partnerStatus",
            label: "Status",
            value: escrowPartner.status
          },
          {
            id: "amount",
            label: escrowPartner.role === "Seller" ? "Will Receive" : "Will Pay",
            value: `${escrowPartner.role === "Seller" ? escrowPartner.will_receive : escrowPartner.will_pay} ${escrowPartner.currency_code}`
          }
        ]}
        border={"none"}
        withRowBottomBorder={false}
        padding={0}
      />
    </Box>
  );
};

export { EscrowPartnerItem };