import { useEffect } from "react";
import CardNFT from "../../../components/CardNFT/CardNFT";
import { Text } from "../../../components/Text/Text";
import { useThemeContext } from "../../../theme";
import {
  LoaderContainer,
  MainContainer,
  Right,
  Wrapper,
} from "./NFTCatalogPageStyles";
import { generalSelector } from "../../../logic/general/GeneralSelectors";
import { useDispatch, useSelector } from "react-redux";
import { nftSelector } from "../../../logic/nft/NftSelectors";
import { NftActions } from "../../../logic/nft/NftRedux";
import { userIdSelector } from "../../../logic/user/UserSelectors";
import { getBidTimerSeconds, getNftImage } from "../../../helpers/nftHelpers";
import { CONFIG } from "../../../config";
import LoaderRing from "../../../helpers/Loader/LoaderRing";
import i18next from "i18next";
import { BLOCKCHAIN_SHORT_NAME_TABLE } from "../../../data/zedpay";

const NFTCatalogPage = () => {
  const { theme } = useThemeContext();
  const dispatch = useDispatch();
  const { t } = i18next;

  const userId = useSelector(userIdSelector);

  const {
    currencyList: { data: currencyList },
  } = useSelector(generalSelector);

  const {
    listNfts: { data: nftList, fetching: fetchingNftList },
  } = useSelector(nftSelector);

  useEffect(() => {
    dispatch(
      NftActions.listNfts.request({
        customer_id: userId,
      })
    );
  }, [userId]);

  return (
    <Wrapper>
      <Text color={theme.colors.white} size="50px" fontStyle={theme.fonts.f600}>
        {t("nftHomeScreen.myNft")}
      </Text>
      <MainContainer>
        {/* <Left>
          <LeftContainer>
            <Text
              color={theme.colors.white}
              size="33px"
              fontStyle={theme.fonts.f600}
              margin="0 0 20px 0"
            >
              Search by Title
            </Text>
            <InputSearch placeholder="Search" />
          </LeftContainer>
          {windowWidth < 936 && (
            <Button onClick={() => setFiltersIsView(!filtersIsView)}>
              <img
                src="/images/NFT_Page/filter.svg"
                style={{ marginRight: "4px" }}
              />
              Filters
            </Button>
          )}
          <ToggleContainer view={filtersIsView}>
            <LeftContainer>
              <Text
                color={theme.colors.white}
                size="33px"
                fontStyle={theme.fonts.f600}
                margin="0 0 20px 0"
              >
                Sort by
              </Text>
              <FilterItemsContainer>
                {sort.map((el, index) => (
                  <FilterItem key={index} data={el} />
                ))}
              </FilterItemsContainer>
            </LeftContainer>
            <LeftContainer>
              <Text
                color={theme.colors.white}
                size="33px"
                fontStyle={theme.fonts.f600}
                margin="0 0 20px 0"
              >
                Categories
              </Text>
              <FilterItemsContainer>
                {filters.map((el, index) => (
                  <FilterItem key={index} data={el} />
                ))}
              </FilterItemsContainer>
            </LeftContainer>
          </ToggleContainer>
        </Left> */}
        {fetchingNftList ? (
          <LoaderContainer>
            <LoaderRing color={theme.colors.yellowMain} size="42px" />
          </LoaderContainer>
        ) : (
          <Right>
            {nftList?.map((el, index) => (
              <CardNFT
                currency_code={el.currency_code}
                currency_id={el.currency_id}
                likedByMe={el.liked_by_me}
                key={index}
                href={`/nft/item/${el.id}`}
                img={`${CONFIG.ZED_BASE_URL}${getNftImage(el)}`}
                name={el.nft_user.username}
                title={el.title}
                verif={false}
                avatar={`${CONFIG.ZED_BASE_URL}${el.nft_user.logo}`}
                bottomLeftImage={`${CONFIG.ZED_BASE_URL}${CONFIG.ZED_ASSETS_PATH.NFT_FILES}${BLOCKCHAIN_SHORT_NAME_TABLE[el.blockchain.toUpperCase()]}.png`}
                timer={getBidTimerSeconds(el)}
                price={el.price}
              />
            ))}
          </Right>
        )}
      </MainContainer>
    </Wrapper>
  );
};
export default NFTCatalogPage;
