import React, { memo } from "react";
import { useStyles } from "./CustomButtonStyles";
import { Box } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import classNames from "classnames";

export type CustomButtonProps = {
  height?: number;
  fontSize?: number;
  title?: string;
  disabled?: boolean;
  borderWidth?: number;
  borderRadius?: number;
  borderColorActive?: string;
  borderColorUnactive?: string;
  bgColorActive?: string;
  bgColorUnactive?: string;
  colorActive?: string;
  colorUnactive?: string;
  spinner?: boolean;
  alt?: string;
  icon?: React.ReactNode;
  defaultSizing?: boolean;
  marginTop?: number;
  marginBottom?: number;
  onPress: () => void;
};

const CustomButton: React.FC<CustomButtonProps> = ({
  height,
  fontSize,
  title,
  disabled,
  borderWidth,
  borderRadius,
  borderColorActive,
  borderColorUnactive,
  bgColorActive,
  bgColorUnactive,
  colorActive,
  colorUnactive,
  spinner,
  alt,
  icon,
  defaultSizing,
  marginTop,
  marginBottom,
  onPress,
}) => {
  const s = useStyles();

  return (
    <Box
      className={classNames(s?.container)}
      style={{
        backgroundColor: disabled ? bgColorUnactive : bgColorActive,
        border: `${borderWidth}px solid ${
          disabled ? borderColorUnactive : borderColorActive
        }`,
        // borderColor: disabled? borderColorUnactive: borderColorActive,
        // borderWidth: borderWidth,
        borderRadius: borderRadius,
        fontSize,
        height: defaultSizing ? 40 : height,
        marginTop,
        marginBottom
      }}
      onClick={() => !disabled && !spinner && onPress()}
      title={alt}
    >
      {spinner ? (
        <CircularProgress size={20} />
      ) : (
        icon ?? (
          <p
            className={classNames(s?.titleText)}
            style={{ color: disabled ? colorUnactive : colorActive }}
          >
            {title}
          </p>
        )
      )}
    </Box>
  );
};

CustomButton.defaultProps = {
  title: "",
  height: 40,
  fontSize: 17,
  borderWidth: 0,
  alt: undefined,
  spinner: false,
  disabled: false,
  borderRadius: 16,
  colorActive: "black",
  colorUnactive: "gray",
  bgColorActive: "white",
  bgColorUnactive: "black",
};

const MemorizedComponent = memo(CustomButton);
export { MemorizedComponent as CustomButton };
