import { Instruction, InstructionGroup } from "@appklaar/codebud";
import { clearObjectFromEmptyStrings } from "./../helpers/formatHelpers";
import { CardsActions } from "./../logic/cards/CardsRedux";

export const getCardsInstructions = (store: any): (Instruction | InstructionGroup)[] => ([
  {
    groupId: "Cards",
    groupDescription: "Cards entity instructions",
    groupColor: "#FED337",
    groupInstructions: [
      {
        id: "getMyCards",
        description: "Get my cards list.",
        parametersDescription: {
          customerId: "number",
        },
        handler: (data) => {
          store.dispatch(CardsActions.getMyCards.request(data));
        },
      },
      {
        id: "getTransactions",
        description:
          "Get a list of recent transactions of an account. account_id is mandatory.",
        parametersDescription: {
          customer_id: "number",
        },
        handler: (data) => {
          store.dispatch(CardsActions.getTransactions.request(data));
        },
      },
      {
        id: "getAllowedCountries",
        description: "(Cards) Get a list of allowed countries.",
        handler: () => {
          store.dispatch(CardsActions.allowedCountries.request());
        },
      },
      {
        id: "getCardFeatures",
        description: "Get a list of card features.",
        handler: () => {
          store.dispatch(CardsActions.cardFeatures.request());
        },
      },
      {
        id: "orderNewVirtualCard",
        description: "Request a new virtual card.",
        handler: () => {
          store.dispatch(
            CardsActions.createVirtualCard.request({
              customer_id: 229,
              currency_id: 33,
              plan: "Golden",
              type: "Visa",
              rechargeable: true,
              name: "Developer Test",
              balance: 0,
              expire_in_year: 2,
            })
          );
        },
      },
      {
        id: "issueVirtualCard",
        description: "Issue a new virtual card.",
        handler: () => {
          store.dispatch(
            CardsActions.issueVirtualCard.request({
              customer_id: 229,
              surname: "Test",
              amount: 100,
              name: "Developer",
              address_line1: "Somestreet, 20",
              city: "New York",
              state: "",
              country: 236,
              zip: "123456",
              phone: "38022899996",
              email: "alexn21200207@yandex.ru",
              network: "Visa",
              referral_code: "",
            })
          );
        },
      },
      {
        id: "getCardCVV2",
        description: "Get card's CVV2.",
        parametersDescription: {
          id: "string",
        },
        handler: (data) => {
          store.dispatch(CardsActions.getCVV.request(data));
        },
      },
      {
        id: "cardTransactions",
        description:
          "Get card transaction list. month and year are optional and only apply on physical cards.",
        parametersDescription: {
          id: "string",
          month: "string",
          year: "string",
        },
        handler: (data) => {
          store.dispatch(
            CardsActions.cardTransactions.request(
              clearObjectFromEmptyStrings(data)
            )
          );
        },
      },
      {
        id: "activateCard",
        description: "Activate an assigned physical card.",
        parametersDescription: {
          id: "number",
          otp_code: "string",
        },
        handler: (data) => {
          store.dispatch(CardsActions.activate.request(data));
        },
      },
      {
        id: "unloadVirtualCard",
        description: "Withdraw virtual card balance.",
        parametersDescription: {
          id: "number",
          amount: "number",
          otp_code: "string"
        },
        handler: (data) => {
          store.dispatch(CardsActions.unloadVirtualCard.request(data));
        }
      },
      {
        id: "unloadPhysicalCard",
        description: "Withdraw physical card balance.",
        parametersDescription: {
          id: "number",
          amount: "number",
          otp_code: "string"
        },
        handler: (data) => {
          store.dispatch(CardsActions.unloadPhysicalCard.request(data));
        }
      },
      {
        id: "cardCommissions",
        description: "Get a list of commissions on card actions.",
        handler: () => {
          store.dispatch(CardsActions.cardCommissions.request());
        }
      },
    ]
  }
]);