import React from 'react';
import { useStyles } from './GoBackButtonStyles';
import { Box } from '@material-ui/core';
import ChevronLeftIcon from './../../assets/icons/chevronLeft';

export type GoBackButtonProps = {
  text?: string;
  onPress: () => void;
};

const GoBackButton: React.FC<GoBackButtonProps> = ({
  text = "Go Back",
  onPress
}) => {
  const s = useStyles();

  return (
    <Box className={s.container} onClick={onPress}>
      <Box className={s.iconCont}>
        <ChevronLeftIcon />
      </Box>
      <span className={s.text}>{text}</span>
    </Box>
  );
};

export { GoBackButton };