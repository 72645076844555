import React from "react";
import ContentLoader from "react-content-loader";
import { theme } from "../../theme/default/theme";

export const SkeletonQR = (props: any) => {
  const size = props.desktop ? 200 : 280;

  return (
    <ContentLoader
      speed={2}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      backgroundColor={theme.colors.subBackground}
      foregroundColor={theme.colors.black}
      {...props}
      style={{ margin: "30px 0 20px 0" }}
    >
      <circle cx="19" cy="20" r="15" />
      <rect x="49" y="4" rx="0" ry="0" width="77" height="13" />
      <rect x="49" y="23" rx="0" ry="0" width="66" height="9" />
      <rect x="0" y="0" rx="0" ry="0" width={size} height={size} />
    </ContentLoader>
  );
};

export const SkeletonQRCode = (props: any) => (
  <ContentLoader
    speed={2}
    width={180}
    height={30}
    viewBox="0 0 180 30"
    backgroundColor={theme.colors.subBackground}
    foregroundColor={theme.colors.black}
    {...props}
  >
    <rect x="0" y="4" rx="0" ry="0" width="180" height="22" />
  </ContentLoader>
);
