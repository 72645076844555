import styled from "styled-components";
import { Text } from "../../../../../components/Text/Text";
import { useThemeContext } from "../../../../../theme";
import { FC } from "react";

type Props = {
  title?: string;
  subTitle?: string;
  img: string;
  name?: string;
};

const Person: FC<Props> = ({ img, name, title, subTitle }) => {
  const { theme } = useThemeContext();
  return (
    <Wrapper>
      <TextContainer>
        {title && (
          <Text
            color={theme.colors.white}
            size="20px"
            fontStyle={theme.fonts.f600}
          >
            {title}
          </Text>
        )}
        {subTitle && (
          <Text
            color={theme.colors.greySubText}
            size="20px"
            fontStyle={theme.fonts.f600}
          >
            {subTitle}
          </Text>
        )}
      </TextContainer>

      <ContainerBot>
        <Avatar src={img} />
        <Text
          color={theme.colors.white}
          size="20px"
          fontStyle={theme.fonts.f600}
        >
          {name}
        </Text>
      </ContainerBot>
    </Wrapper>
  );
};
export default Person;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 20px;
  @media (max-width: 700px) {
    margin-top: 0;
  }
`;
const TextContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
`;

const ContainerBot = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
`;
const Avatar = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  @media (max-width: 700px) {
    width: 50px;
    height: 50px;
  }
`;
