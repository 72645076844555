import { FC } from "react";
import styled, { keyframes } from "styled-components";

type Props = {
  color?: string;
  size: string;
};

const LoaderRing: FC<Props> = ({ color, size }) => {
  return (
    <Wrapper size={size}>
      <LdsRingChild size={size} color={color} />
      <LdsRingChild size={size} color={color} />
      <LdsRingChild size={size} color={color} />
      <LdsRingChild size={size} color={color} />
    </Wrapper>
  );
};
export default LoaderRing;

const ldsRingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div<{ size: string }>`
  display: inline-block;
  position: relative;
  width: ${(p) => p.size};
  height: ${(p) => p.size};
`;

const LdsRingChild = styled.div<{
  color?: string;
  size: string;
}>`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: ${(p) => p.size};
  height: ${(p) => p.size};
  border: 5px solid ${(p) => p.color || "#cef"};
  border-radius: 50%;
  animation: ${ldsRingAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${(p) => p.color || "#cef"} transparent transparent transparent;

  &:nth-child(1) {
    animation-delay: -0.45s;
  }

  &:nth-child(2) {
    animation-delay: -0.3s;
  }

  &:nth-child(3) {
    animation-delay: -0.15s;
  }
`;
