import { useEffect, useMemo, useState } from "react";
import { Text } from "../../components/Text/Text";
import { useThemeContext } from "../../theme";
import {
  ButtonChange,
  DeleteAccount,
  GridContainer,
  Line,
  Wrapper,
} from "./ProfileStyles";
import { userSelector } from "../../logic/user/UserSelectors";
import { useDispatch, useSelector } from "react-redux";
import Item from "./components/profileItem/Item";
import { UserActions } from "../../logic/user/UserRedux";
import moment from "moment";
import { AvailableGendersSet, Gender } from "../../types/types";
import i18next from "i18next";
import LoaderRing from "../../helpers/Loader/LoaderRing";
import { generalSelector } from "../../logic/general/GeneralSelectors";
import { useDependentValue } from "../../helpers/customHooks";
import { flags } from "../../assets/flags";
import { validateName, validatePhone } from "../../helpers/validationHelper";
import AreYouSure from "../../components/Modals/AreYouSure/AreYouSure";
import ChangePassword from "./components/changePassword/ChangePassword";
import Settings from "./components/settingsProfile/Settings";
import { useLocation } from "react-router-dom";
import TwoSAuth from "./components/TwoSAuth/TwoSAuth";

const Profile = () => {
  const { theme } = useThemeContext();
  const { t } = i18next;
  const location = useLocation();
  const {
    listCountries: { data: countriesData },
  } = useSelector(generalSelector);
  const {
    userId,

    editProfile: { fetching: fetchingEditProfile },
    whoAmI: { data: profile, fetching: fetchingProfile },
  } = useSelector(userSelector);
  const dispatch = useDispatch();
  const [variant, setVariant] = useState<"viewing" | "edit">("viewing");
  const [hideErrors, setHideErrors] = useState(true);
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const [profileData, setProfileData] = useState({
    first: profile?.first_name,
    last: profile?.last_name,
    gender: profile?.gender,
    phone: profile?.phone,
    email: profile?.email,
    bDate: profile?.birth_date,
    country: profile?.country?.id,
    city: profile?.city,
    adress: profile?.address,
  });

  useEffect(() => {
    const state = location.state;
    if (state && state.state) {
      setVariant(state.state);
    }
    if (state && state.scrollToTwoSAuth) {
      const twoSAuthElement = document.getElementById("twoSAuth");
      if (twoSAuthElement) {
        twoSAuthElement.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }, [location.state]);

  const GENDERS_DATA = useMemo(
    () => [
      {
        value: "Not_specified",
        label: t("profile.gender.notSpecified"),
        key: "Not_specified",
      },
      {
        value: "Male",
        label: t("profile.gender.male"),
      },
      {
        value: "Female",
        label: t("profile.gender.female"),
      },
      {
        value: "Non_binary",
        label: t("profile.gender.nonBinary"),
      },
      {
        value: "Transgender",
        label: t("profile.gender.transgender"),
      },
      {
        value: "Intersex",
        label: t("profile.gender.intersex"),
      },
    ],
    []
  );

  const GENDER_LABEL = useMemo(
    () => ({
      Not_specified: t("notSpecified"),
      Male: t("male"),
      Female: t("female"),
      Non_binary: t("nonBinary"),
      Transgender: t("transgender"),
      Intersex: t("intersex"),
    }),
    []
  );
  const countryText = useDependentValue(() => {
    if (!profile?.country) return "-";

    const key: string = profile.country.alpha_2;
    //@ts-ignore
    const flag = flags[key]?.emoji ?? "";

    return `${flag} ${profile.country.name}`;
  }, [profileData.country]);

  const inputHandler = (e: { target: { name: string; value: string } }) => {
    setProfileData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onPressSave = () => {
    if (
      validateName(profileData.first) &&
      validateName(profileData.last) &&
      validatePhone(profileData.phone)
    ) {
      dispatch(
        UserActions.editProfile.request({
          params: {
            id: userId as number,
            first_name: profileData.first,
            last_name: profileData.last,
            gender: profileData.gender as Gender,
            // birth_date: birthDate.replace(/\//g, "-"),
            birth_date: profileData.bDate,
            customer_type: "Individual",
            email: profile?.email,
            mobile: profileData.phone,
            // phone: phone,
            phone: profileData.phone,
            country_id: profileData.country as number,
            city: profileData.city,
            address: profileData.adress,
          },
          successCallback: () => setVariant("viewing"),
        })
      );

      setHideErrors(true);
    } else {
      setHideErrors(false);
    }
  };

  useEffect(() => {
    setProfileData({
      first: profile?.first_name,
      last: profile?.last_name,
      //@ts-ignore
      gender: AvailableGendersSet.has(profile?.gender)
        ? profile?.gender
        : "Not_specified",
      phone: profile?.phone,
      email: profile?.email,
      bDate: profile?.birth_date
        ? moment(profile?.birth_date, "DD/MM/YYYY").format("YYYY-MM-DD")
        : "",
      //@ts-ignore
      country: profile?.country.id,
      city: profile?.city,
      adress: profile?.address,
    });
  }, [profile]);

  return (
    <>
      <Wrapper>
        <Text
          color={theme.colors.white}
          size="44px"
          fontStyle={theme.fonts.f600}
        >
          {t("profile.profileDetails")}
        </Text>
        {/* новая таблица с данными */}
        <GridContainer>
          <Item
            variant={variant}
            label={t("profile.items.firstName")}
            value={profileData.first}
            id={"first"}
            inputHandler={inputHandler}
            error={hideErrors}
          />
          <Item
            variant={variant}
            label={t("profile.items.lastName")}
            value={profileData.last}
            id={"last"}
            inputHandler={inputHandler}
            error={hideErrors}
          />
          <Item
            variant={variant}
            label={t("profile.items.gender")}
            //@ts-ignore
            value={GENDER_LABEL[profileData.gender]}
            id={"gender"}
            items={GENDERS_DATA}
            inputHandler={inputHandler}
            setValue={setProfileData}
            select
          />
          <Item
            variant={variant}
            label={t("profile.items.phoneNumber")}
            value={profileData.phone}
            id={"phone"}
            inputHandler={inputHandler}
            error={hideErrors}
          />
          <Item
            variant={variant}
            label={t("profile.email")}
            value={profileData.email}
            id={"email"}
            inputHandler={inputHandler}
            error={hideErrors}
          />
          <Item
            variant={variant}
            label={t("profile.items.bDate")}
            value={profileData.bDate}
            id={"bDate"}
            inputHandler={inputHandler}
            error={hideErrors}
          />
          <Item
            variant={variant}
            label={t("profile.items.country")}
            rightLabel={
              countriesData?.countriesTable[profileData.country as any]
            }
            value={countryText}
            items={countriesData?.countries}
            id={"country"}
            setValue={setProfileData}
            select
          />
          <Item
            variant={variant}
            label={t("profile.items.city")}
            value={profileData.city}
            id={"city"}
            inputHandler={inputHandler}
            error={hideErrors}
          />
          <Item
            variant={variant}
            label={t("profile.items.adress")}
            value={profileData.adress}
            id={"adress"}
            inputHandler={inputHandler}
            error={hideErrors}
          />
        </GridContainer>

        <ButtonChange
          onClick={() => {
            if (variant === "viewing") {
              setVariant("edit");
            }

            if (variant === "edit") {
              onPressSave();
            }
          }}
        >
          {!fetchingEditProfile ? (
            <Text
              color={theme.colors.black}
              size="20px"
              fontStyle={theme.fonts.f600}
            >
              {variant === "viewing"
                ? `${t("profile.edit")}`
                : `${t("profile.save")}`}
            </Text>
          ) : (
            <LoaderRing size="20px" color={theme.colors.black} />
          )}
        </ButtonChange>

        <DeleteAccount onClick={() => setConfirmIsOpen(true)}>
          <Text
            color={theme.colors.red}
            size="20px"
            fontStyle={theme.fonts.f600}
          >
            {t("profile.deleteAccount")}
          </Text>
        </DeleteAccount>

        <Line />

        {/* settings block */}
        <Settings />
        <div id="twoSAuth">
          <Line />
        </div>

        <TwoSAuth />

        <Line />
        <ChangePassword />
      </Wrapper>
      {confirmIsOpen && (
        <AreYouSure
          func={() => {}}
          //fetch={closeFetch}
          setModal={setConfirmIsOpen}
          // success={ticketSuccess}

          yes={{ text: t("profile.yes"), color: theme.colors.red }}
          no={{ text: t("profile.no"), color: theme.colors.green }}
        />
      )}
    </>
  );
};
export default Profile;
