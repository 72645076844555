import React, { memo, useState, useMemo } from 'react';
import { useStyles } from './CardToCardStyles';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../../../../../components/CustomButton';
import { useThemeContext } from '../../../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { cardsSelector } from '../../../../../logic/cards/CardsSelectors';
import { useHideErrors } from '../../../../../hooks/useHideErrors';
import { useAmountInput } from '../../../../../hooks/useAmountInput';
import { useDependentValue } from '../../../../../helpers/customHooks';
import { couldBeMaster, couldBeVisa, validate2FACode, validateCardNumberVisaOrMaster, validateFloat } from '../../../../../helpers/validationHelper';
import { CardsActions } from '../../../../../logic/cards/CardsRedux';
import { StringValueItem } from '../../../../../components/StringValueItem';
import { formatCardNumberWithSpaces } from '../../../../../helpers/formatHelpers';
import { TextInputUnderlined } from '../../../../../components/TextInputUnderlined';
import cn from 'classnames';
import { ShowDetails } from '../../../../../components/ShowDetails';

const VISA_IMAGE = require("./../../../../../assets/images/visa.png");
const MASTERCARD_IMAGE = require("./../../../../../assets/images/mastercard.png");

export type CardToCardProps = {};

const CardToCard: React.FC<CardToCardProps> = ({}) => {
  const s = useStyles();
  // @ts-ignore
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const dispatch = useDispatch();

  const {
    cardsOperationsPageSelectedCard: card,
    cardToCard: {
      fetching: fetchingSend
    }
  } = useSelector(cardsSelector);

  const [toCardNumber, setToCardNumber] = useState("");
  const [code2FA, setCode2FA] = useState("");

  const {
    hideErrors,
    setHideErrors,
    onInputErrorAnimationEnd
  } = useHideErrors();

  const {
    amount,
    setAmount,
    handleAmountInput
  } = useAmountInput();

  const toCardNumberClear = useDependentValue(() => toCardNumber.replace(/\s/g, ""), [toCardNumber]);

  const cardImage = useMemo(() => {
    if (couldBeVisa(toCardNumberClear))
      return (<img src={VISA_IMAGE} className={cn(s.imgContain)}/>);
    if (couldBeMaster(toCardNumberClear))
      return (<img src={MASTERCARD_IMAGE} className={cn(s.imgContain)}/>);

    return null;
  }, [toCardNumberClear, s]);

  const onPressSend = () => {
    if (!card)
      return;

    if (validateCardNumberVisaOrMaster(toCardNumberClear) && validate2FACode(code2FA) && validateFloat(amount)) {
      dispatch(CardsActions.cardToCard.request({
        from_id: card.id,
        to_card_number: toCardNumberClear,
        amount: Number(amount),
        otp_code: code2FA
      }));
    } else {
      setHideErrors(false);
    }
  }

  if (!card)
    return null;

  return (
    <Box className={cn(s.globalContainer)}>
      <Box className={cn(s.container)}>
        <Box className={cn(s.spaceBetween)}>
          <Box className={cn(s.topBlock)}>
            <Box className={cn(s.inputsContainer)}>
              <StringValueItem 
                label={t("fromCard")}
                value={formatCardNumberWithSpaces(card.card_number)}
              />

              <Box className={cn(s.cardInput)}>
                <Box className={cn(s.cardNetworkImgCont)}>
                  {cardImage}
                </Box>
                <TextInputUnderlined
                  value={toCardNumber} 
                  mask={"9999 9999 9999 9999"}
                  onChangeText={(text) => setToCardNumber(text)} 
                  validationOk={hideErrors || validateCardNumberVisaOrMaster(toCardNumberClear)}
                  label={t("toCard") as string}
                  placeholder={'0000 0000 0000 0000'}
                  onErrorAnimationEnd={onInputErrorAnimationEnd}
                />
              </Box>

              <TextInputUnderlined
                value={amount} 
                placeholder={t("amount") as string}
                onChangeText={(text) => handleAmountInput(text, setAmount)} 
                validationOk={hideErrors || validateFloat(amount)}
                onErrorAnimationEnd={onInputErrorAnimationEnd}
              />

              <TextInputUnderlined
                value={code2FA} 
                placeholder={t("twoFACode") as string}
                onChangeText={(text) => setCode2FA(text)} 
                validationOk={hideErrors || validate2FACode(code2FA)}
                onErrorAnimationEnd={onInputErrorAnimationEnd}
              />

              <ShowDetails marginBottom={0}>
                <StringValueItem 
                  label={t("payments.operationTime")}
                  value={t("defaultConfirmOperationTime")}
                />
              </ShowDetails>
            </Box>
          </Box>

          <CustomButton
            title={t("buttons.send") as string}
            bgColorActive={theme.colors.yellowMain}
            colorActive={theme.colors.black}
            defaultSizing
            spinner={fetchingSend}
            onPress={onPressSend}
          />
        </Box>
      </Box>
    </Box>
  );
};

CardToCard.defaultProps={}

const MemorizedComponent = memo(CardToCard);
export { MemorizedComponent as CardToCard };