import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStyles = makeStyles({
  container: {

  },
});

export const RefferalIdCont = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 12px auto;
`;

export const Copy = styled.img`
  margin: 0 0 5px 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;