import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  globalContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    width: '100%',
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative',
    "--animate-duration": "1000ms"
  },
  input: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    border: '0',
    outline: 0,
    fontSize: 18,
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px #000000 inset",
      WebkitTextFillColor: "#ffffff",
    },
    "&:-webkit-autofill::first-line": {
      fontSize: 18,
    }
  },
  clearBtn: {
    position: 'absolute',
    right: 4,
    cursor: 'pointer'
  },
  rightIconContainer: {
    position: 'absolute',
    right: theme.metrics.x4,
    zIndex: 3,
    paddingTop: theme.metrics.x
  },
});