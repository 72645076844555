export const DEFAULT_ACTIVE_BOX = 0;

export enum PaymentsTabs {
  TopUp = 0,
  Withdraw,
  Exchange,
  WalletInfo
};

export enum TopUpBoxes {
  CryptoTopUp = 0,
  FiatOfflineTopUp = 1
};

export enum WithdrawBoxes {
  CryptoToBlockchain = 0,
  FiatToBank,
  InternalFiatTransfer
};

export enum ExchangeBoxes {
  Exchange = 0
};

export enum WalletInfoBoxes {
  MainInfo = 0
};