import styled, { css, keyframes } from "styled-components";
import { templates } from "../../../theme/templates";

const shakeAnimation = keyframes`
  0% { transform: translateX(0); }
  10%, 90% { transform: translateX(-5px); }
  20%, 80% { transform: translateX(5px); }
  30%, 50%, 70% { transform: translateX(-5px); }
  40%, 60% { transform: translateX(5px); }
  100% { transform: translateX(0); }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  justify-content: space-between;
  padding: 0 8px;
  position: relative;
  height: calc(100vh - 132px);
  height: 100%;
  @media (max-width: 1030px) {
    width: 100%;
  }
  @media (max-width: 800px) {
    padding: 0 10px;
  }
`;
export const HeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const InputWrapper = styled.div`
  position: absolute;
  bottom: 0;
  box-shadow: 0 -10px 12px 10px black;
  min-height: 68px;
  width: calc(100% - 15px);
  border-radius: 12px;
  column-gap: 10px;
  ${templates.centerContent};
  background-color: ${(p) => p.theme.colors.subBackground};
  padding: 10px 40px;

  @media (max-width: 800px) {
    padding: 10px 15px;
    width: calc(100% - 20px);
  }
`;
export const CloseTicket = styled.div`
  cursor: pointer;
  transition: 0.3s;
`;
export const Input = styled.input<{error: boolean}>`
  all: unset;
  height: 40px;
  border: 3px solid ${(p) => p.theme.colors.borderGrey};
  border-radius: 12px;
  font-size: 20px;
  padding: 0 10px;
  width: 100%;
  color: ${(p) => p.theme.colors.white};
  ${(p) =>
    p.error &&
    css`
      border-color: red;
      animation: ${shakeAnimation} 0.4s linear;
    `}
`;
export const IconAdd = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;
export const BackArrow = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    scale: 1.2;
  }
`;
// message
export const MessagesArea = styled.div`
  margin-top: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  row-gap: 10px;
  padding-bottom: 100px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const MessageWrapper = styled.div<{ direction: boolean }>`
  padding: 10px;
  background-color: ${(p) => p.theme.colors.subBackground};
  width: 65%;
  border-radius: 12px;
  margin: ${(p) => (p.direction ? "0 auto 0 0" : "0 0 0 auto")};
`;
