import { FC } from "react";
import { useThemeContext } from "../../../../../theme";
import { ContentBox } from "../../../../ContentBox/ContentBox";
import { Text } from "../../../../Text/Text";
import { CustomButton } from "./../../../../../components/CustomButton";
import styled from "styled-components";
import i18next from "i18next";

type Props = {
  title: string;
  desc: string;
  func: any;
  id: string;
  images?: any;
};

const ChoseImage: FC<Props> = ({ title, desc, func, id, images }) => {
  const { theme } = useThemeContext();
  const { t } = i18next;

  return (
    <>
      <Text
        color={theme.colors.white}
        size="28px"
        fontStyle={theme.fonts.f600}
        margin="0 48px"
        textAlign="center"
      >
        {title}
      </Text>
      <Text
        color={theme.colors.greySubText}
        size="16px"
        fontStyle={theme.fonts.f700}
        margin="24px 0 0 0"
        textAlign="center"
      >
        {desc}
      </Text>
      <MainImage src={images.main} alt="asd" style={{ marginTop: "32px" }} />
      <Text
        color={theme.colors.white}
        size="18px"
        fontStyle={theme.fonts.f700}
        margin="16px 0 0 0"
      >
        {t("Verification.mainMistakes")}
      </Text>
      <ContentBox
        backColor="none"
        justyfiContent="space-between"
        width="100%"
        margin="24px 0 20px 0"
      >
        <ContentBox direction="column">
          <SmallImage src={images.small1} alt="" />
          <Text
            color={theme.colors.white}
            size="14px"
            fontStyle={theme.fonts.f700}
            margin="8px 0 0 0"
          >
            {t("Verification.tooDark")}
          </Text>
        </ContentBox>
        <ContentBox direction="column">
          <SmallImage src={images.small2} alt="" />
          <Text
            color={theme.colors.white}
            size="14px"
            fontStyle={theme.fonts.f700}
            margin="8px 0 0 0"
          >
            {id === "selfie"
              ? t("Verification.notNeutral")
              : t("Verification.tooSmall")}
          </Text>
        </ContentBox>
        <ContentBox direction="column">
          <SmallImage src={images.small3} alt="" />
          <Text
            color={theme.colors.white}
            size="14px"
            fontStyle={theme.fonts.f700}
            margin="8px 0 0 0"
          >
            {t("Verification.tooBlurry")}
          </Text>
        </ContentBox>
      </ContentBox>

      <CustomButton 
        defaultSizing={true}
        onPress={() => func(id.replace(/\s/g, "_"))}
        title={t("Verification.chooseFile") as string}
        bgColorActive={theme.colors.yellowMain}
      />
    </>
  );
};
export default ChoseImage;
const MainImage = styled.img`
  width: 120px;
  @media (max-width: 700px) {
    width: 100px;
  }
  @media (max-height: 700px) {
    width: 100px;
  }
`;
const SmallImage = styled.img`
  width: 100px;
  @media (max-width: 700px) {
    width: 76px;
  }
  @media (max-height: 700px) {
    width: 76px;
  }
`;