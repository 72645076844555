import { BlockchainType } from "../types/types";

export const BLOCKCHAIN_TYPE_TABLE: {[key: number]: BlockchainType} = {
  0: "none",
  1: "bitcoin",
  6: "ethereum",
  10: "binance_smart_chain",
  11: "tron"
} as const;

export const BLOCKCHAIN_NAME_TABLE: {[key: number]: string} = {
  0: "None",
  1: "Bitcoin",
  6: "Ethereum",
  10: "Binance Smart Chain",
  11: "Tron"
} as const;

export const BLOCKCHAIN_LABEL_TABLE: {[key: string]: string} = {
  "none": "None",
  "bitcoin": "Bitcoin",
  "ethereum": "Ethereum",
  "binance_smart_chain": "Binance Smart Chain",
  "tron": "Tron"
}

export const BLOCKCHAIN_SHORT_NAME_TABLE: {[key: string]: string} = {
  "ETHEREUM": "eth",
  "TRON": "trx",
  "BINANCE SMART CHAIN": "bsc"
} as const;