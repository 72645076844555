import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: "row",
    justifyContent: 'space-between',
    flexWrap: "wrap"
  },
  itemRow: {
    display: 'flex',
    width: '49%',
    // backgroundColor: 'blue',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.metrics.x3
  },
  text: {
    fontSize: 12,
    fontFamily: theme.fonts.f400.fontFamily,
    paddingTop: theme.metrics.x05,
    flex: 1,
    margin: 0
  },
  icon: {
    display: 'flex',
    marginRight: theme.metrics.x
  }
});