import {
  put,
  select,
  takeLatest,
  call
} from 'redux-saga/effects';
import { api } from '../../api';
import { zedApiResponseValidator } from '../../helpers/sagaHelpers';
import * as T from '../../types/api';
import { EscrowPartnerFormInfo, EscrowTxConstructorActions } from './EscrowTxConstructorSlice';
import { Action } from '@reduxjs/toolkit';
import { EscrowPartner } from '../../types/types';
import { AccountsByUserEmailTable, CryptoAccountDropdownItem, CryptoActions } from '../crypto/CryptoRedux';
import { getId } from '../../helpers/random';
import { exchangeScreenAccountsSelector } from '../crypto/CryptoSelectors';
import { ApiResponse } from 'apisauce';
import { CodeBud } from '@appklaar/codebud';

const convertEscrowPartnerToEscrowPartnerFormInfo = (partner: EscrowPartner, account: CryptoAccountDropdownItem | null = null): EscrowPartnerFormInfo => ({
  localId: getId(),
  email: partner.customer_email,
  role: partner.role,
  payment: partner.will_pay > 0 ? "Will Pay" : "Will Receive",
  account,
  amount: partner.will_pay > 0 ? partner.will_pay.toString() : partner.will_receive.toString()
});

function* prepareTxConstructorForEditingSaga(action: Action): any {
  if (EscrowTxConstructorActions.prepareTxConstructorForEditing.match(action)) {
    try {
      yield put(EscrowTxConstructorActions.setPreparingTxConstructorForEditing(true));
      const { tx, successCallback } = action.payload;

      // Pass basix tx info to the constructor
      yield put(EscrowTxConstructorActions.setTitle(tx.title));
      yield put(EscrowTxConstructorActions.setDescription(tx.description));

      // Pass user (partners[0]) info to the constructor
      const userAccountsDropdownData: ReturnType<typeof exchangeScreenAccountsSelector> = yield select(exchangeScreenAccountsSelector);
      const chosenAccount = userAccountsDropdownData.find((account) => account.id === tx.partners[0].account_id);
      const userAsPartner: ReturnType<typeof convertEscrowPartnerToEscrowPartnerFormInfo> = yield call(convertEscrowPartnerToEscrowPartnerFormInfo, tx.partners[0], chosenAccount);
      yield put(EscrowTxConstructorActions.setUserAsPartner(userAsPartner));

      // Pass other partners info to the constructor
      const otherPartners: ObjectT<EscrowPartnerFormInfo> = {};
      const accountsByUserEmailTable: AccountsByUserEmailTable = {};
      for (let i = 1; i < tx.partners.length; i++) {
        const partner = tx.partners[i];

        const findAccountsResponse: ApiResponse<T.FindAccountsResponse> = yield call(
          api.findAccountsGet,
          {
            email: partner.customer_email
          }
        );
        if (zedApiResponseValidator(findAccountsResponse)) {
          const responseData = findAccountsResponse.data!;
          accountsByUserEmailTable[partner.customer_email] = responseData.value.map((a) => ({
            ...a,
            title: a.name,
            value: a.id,
          }));
        }
      }

      yield put(CryptoActions.mergeAccountsByUserEmailTable(accountsByUserEmailTable));

      for (let i = 1; i < tx.partners.length; i++) {
        const partner = tx.partners[i];
        const chosenAccount = accountsByUserEmailTable[partner.customer_email].find((account) => account.id === partner.account_id);
        const partnerFormInfo: ReturnType<typeof convertEscrowPartnerToEscrowPartnerFormInfo> = yield call(convertEscrowPartnerToEscrowPartnerFormInfo, partner, chosenAccount);
        otherPartners[partnerFormInfo.localId] = partnerFormInfo;
      }

      yield put(EscrowTxConstructorActions.setPartners(otherPartners));

      // Preparation is done
      if (successCallback)
        yield call(successCallback);

      yield put(EscrowTxConstructorActions.setPreparingTxConstructorForEditing(false));
    } catch (error) {
      console.warn("prepareTxConstructorForEditingSaga error", error);
      CodeBud.captureEvent("prepareTxConstructorForEditingSaga error", error);
      yield put(EscrowTxConstructorActions.setPreparingTxConstructorForEditing(false));
    }
  }
}


export function* EscrowtxConstructorSaga() {
  yield* [
    takeLatest(EscrowTxConstructorActions.prepareTxConstructorForEditing.type, prepareTxConstructorForEditingSaga),
  ];
}