
import styled, { css, keyframes } from "styled-components";

const shakeAnimation = keyframes`
  0% { transform: translateX(0); }
  10%, 90% { transform: translateX(-5px); }
  20%, 80% { transform: translateX(5px); }
  30%, 50%, 70% { transform: translateX(-5px); }
  40%, 60% { transform: translateX(5px); }
  100% { transform: translateX(0); }
`;

export const Wrapper = styled.div <{hideError: boolean}>`
display: flex;
justify-content: space-between;
border-bottom: 1px solid ${p => p.theme.colors.borderGrey};
padding-bottom: 10px;
&:focus-within {
    border-color: ${p => p.hideError ? 'red' : p.theme.colors.yellowMain};
  }
    ${(p) =>
    p.hideError &&
    css`
    
      border-bottom-color: red;
      animation: ${shakeAnimation} 0.4s linear;
    `}
`;
export const Icon = styled.img`
cursor: pointer;
transform: rotate(270deg);
fill: white;
`;
export const Input = styled.input`
  all: unset;
  width: 100%;
  height: 100%; 
  font-size: 18px;
  font-family:'Gilroy-Medium';
  font-weight: 500;
  color: ${ p => p.theme.colors.white}; 
  &::placeholder {
    color: ${ p => p.theme.colors.greySubText}; 
  }

`;