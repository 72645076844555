import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 16px;
  border: 1px solid #323232;
  background-color: ${(p) => p.theme.colors.subBackground};
  position: relative;
  margin-top: 24px;
  @media (max-width: 900px) {
    height: 56px;
  }
`;
export const InputField = styled.input`
  all: unset;
  height: 60px;
  padding-left: 16px;
  width: 100%;
  font-size: 20px;
  color: ${(p) => p.theme.colors.white};

  @media (max-width: 900px) {
    height: 56px;
  }
`;
export const Eye = styled.img`
  position: absolute;
  right: 16px;
  top: 14px;
  cursor: pointer;
`;
