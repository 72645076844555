import { FC } from "react";
import { Text } from "../../../../components/Text/Text";
import { useThemeContext } from "../../../../theme";
import {
  ButtonOpen,
  Icon,
  SelectArea,
  SelectItem,
} from "../../../Profile/components/profileItem/ItemStyles";
import { Select } from "../KysStyles";
type Props = {
  view: any;
  setView: any;
  items: any;
  value: any;
  setValue: any;
};

const SelectInput: FC<Props> = ({ view, setView, items, value, setValue }) => {
  const { theme } = useThemeContext();
  return (
    <>
      <Select>
        <Text
          color={theme.colors.white}
          size="21px"
          fontStyle={theme.fonts.f500}
          textAlign="left"
          margin="0 auto 0 12px"
        >
          {value.label}
        </Text>
        <ButtonOpen
          style={{ top: "10px", right: "8px" }}
          onClick={() => setView(!view)}
        >
          <Icon deg={view} src="/images/Select_arrow_Down.png" />
        </ButtonOpen>
        {view && (
          <SelectArea
            style={{
              height: "200px",
              top: "47px",
            }}
          >
            {items.map((el: any) => (
              <SelectItem
                onClick={() => {
                  setView(false);
                  setValue(el);
                }}
              >
                <Text
                  color={theme.colors.white}
                  hoverColor={theme.colors.yellowMain}
                  size="21px"
                  fontStyle={theme.fonts.f600}
                >
                  {el.label}
                </Text>
              </SelectItem>
            ))}
          </SelectArea>
        )}
      </Select>
    </>
  );
};
export default SelectInput;
