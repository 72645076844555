import { FC } from "react";
import styled from "styled-components";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import CardNFT from "../../../../components/CardNFT/CardNFT";
import { CONFIG } from "../../../../config";
import { getNftImage } from "../../../../helpers/nftHelpers";
import { BLOCKCHAIN_SHORT_NAME_TABLE } from "../../../../data/zedpay";

type Props = {
  cards: any;
  sellers?: boolean;
  collection?: boolean;
};

const NftList: FC<Props> = ({ cards, sellers, collection }) => {
  const windowWidth = useWindowWidth();

  const sliceNumber = () => {
    if (windowWidth > 1979) {
      return 5;
    } else if (windowWidth < 1979 && windowWidth >= 1656) {
      return 4;
    } else if (windowWidth < 1656 && windowWidth >= 995) {
      return 3;
    } else if (windowWidth < 995) {
      return 2;
    }
  };
  return (
    <GridContainer>
      {cards?.slice(0, sliceNumber()).map((el: any, index: any) =>
        sellers ? (
          // <CardNFTSeller
          //   key={index}
          //   img={el.img}
          //   name={el.name}
          //   followers={el.followers}
          //   verification={el.verification}
          // />
          <></>
        ) : (
          <CardNFT
            isCollection={collection}
            currency_id={el.currency_id}
            likedByMe={el.liked_by_me}
            key={index}
            counterCollection={el.items}
            href={
              collection
                ? `/nft/item-collection/${el.id}`
                : `/nft/item/${el.id}`
            }
            img={
              collection
                ? `${CONFIG.ZED_BASE_URL}${el.logo}`
                : `${CONFIG.ZED_BASE_URL}${getNftImage(el)}`
            }
            name={el.nft_user.username}
            title={el.title}
            verif={false}
            avatar={`${CONFIG.ZED_BASE_URL}${el.nft_user.logo}`}
            bottomLeftImage={collection ? undefined : `${CONFIG.ZED_BASE_URL}${CONFIG.ZED_ASSETS_PATH.NFT_FILES}${BLOCKCHAIN_SHORT_NAME_TABLE[el.blockchain.toUpperCase()]}.png`}
            price={el.price}
          />
        )
      )}
    </GridContainer>
  );
};
export default NftList;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  width: 100%;
  overflow-x: hidden;
  @media (max-width: 656px) {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }
  @media (max-width: 400px) {
    grid-template-columns: repeat(1, minmax(150px, 1fr));
  }

  & > div {
    width: 100%;
  }
`;
