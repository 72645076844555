import React, { FC } from "react";
import {
  BlockItemWrapper,
  Button,
  ButtonsContainer,
  LayerContent,
  Picture,
  ProfilePicture,
} from "./BlockItemStyles";
import { Text } from "../../../../components/Text/Text";
import { useThemeContext } from "../../../../theme";
import { Link, useNavigate } from "react-router-dom";
import i18next from "i18next";
import { CONFIG } from "../../../../config";
import { handleOpenLink } from "../../../../helpers/windowHelpers";

type Props = {
  bgGradient: any;
  title: string;
  img: string;
};
type Props2 = {
  bgGradient: any;
  title: string;
  white?: boolean;
  img: string;
  href: string;
  profile?: boolean;
};

export const BlockItem: FC<Props> = ({ bgGradient, title, img }) => {
  const { theme } = useThemeContext();
  const { t } = i18next;
  const navigate = useNavigate();

  return (
    <BlockItemWrapper bgGradient={bgGradient}>
      <LayerContent>
        <Text
          color={theme.colors.black}
          size="32px"
          fontStyle={theme.fonts.f700}
          maxWidth="70%"
        >
          {title}
        </Text>
        <Text
          maxWidth="60%"
          color={theme.colors.black}
          size="18px"
          fontStyle={theme.fonts.f500}
        >
          {t("nftHomeScreen.nftLedgers")}
        </Text>
        <ButtonsContainer>
          <Button onClick={() => handleOpenLink(CONFIG.LINKS.NFT_MARKETPLACE)}>
            <Text
              color={theme.colors.white}
              size="24px"
              fontStyle={theme.fonts.f600}
            >
              {t("nftHomeScreen.discover")}
            </Text>
          </Button>

          <Button
            style={{
              backgroundColor: "transparent",
              border: `1px solid ${theme.colors.black}`,
            }}
            onClick={() => navigate("/nft/create-nft")}
          >
            <Text
              color={theme.colors.black}
              size="24px"
              fontStyle={theme.fonts.f600}
            >
              {t("nftHomeScreen.create")}
            </Text>
          </Button>
        </ButtonsContainer>
      </LayerContent>

      <Picture src={img} />
    </BlockItemWrapper>
  );
};
export const BlockItem2: FC<Props2> = ({
  bgGradient,
  white,
  title,
  img,
  href,
  profile,
}) => {
  const { theme } = useThemeContext();
  const { t } = i18next;
  const navigate = useNavigate();
  return (
    <BlockItemWrapper bgGradient={bgGradient} block2>
      <LayerContent>
        <Text
          color={white ? theme.colors.white : theme.colors.black}
          size="32px"
          fontStyle={theme.fonts.f700}
        >
          {title}
        </Text>
        <Button bgColor={white} onClick={() => navigate(href)}>
          <Text
            color={white ? theme.colors.black : theme.colors.white}
            size="24px"
            fontStyle={theme.fonts.f600}
          >
            {t("nftHomeScreen.open")}
          </Text>
        </Button>
      </LayerContent>
      {profile ? <ProfilePicture src={img} /> : <Picture src={img} />}
    </BlockItemWrapper>
  );
};
