import React, { FC } from "react";
import { ContentBox } from "../../../components/ContentBox/ContentBox";
import { text } from "../../../theme/text";
import {
  LeftWrapper,
  LeftBox,
  PaymentIcon,
  WhiteButton,
} from "./SelectMethodStyles";
import { Text } from "../../../components/Text/Text";
import { useThemeContext } from "../../../theme";
import { BoxData } from "../../../types/types";
import useWindowWidth from "../../../hooks/useWindowWidth";

type Props = {
  boxesData: BoxData[][];
  activeTab: number;
  activeBox: number;
  setActiveBox?: (value: number) => void;
};

const SelectMethod: FC<Props> = ({
  boxesData,
  activeBox,
  activeTab,
  setActiveBox,
}) => {
  const { theme } = useThemeContext();

  const windowWidth = useWindowWidth();

  return (
    <LeftWrapper>
      {boxesData[activeTab].map((box) => (
        <LeftBox
          key={box.id}
          active={activeBox === box.id}
          onClick={() => setActiveBox && setActiveBox(box.id)}
        >
          {windowWidth > 900 ? (
            <>
              <PaymentIcon>
                <img
                  src={box.iconSrc}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </PaymentIcon>
              <ContentBox
                backColor="none"
                direction="column"
                alignItems="flex-start"
                margin="0 0 0 16px"
              >
                <Text
                  color={theme.colors.white}
                  size="32px"
                  fontStyle={theme.fonts.f600}
                >
                  {box.title}
                </Text>
                <Text
                  color={text.colors.lightGray}
                  size="20px"
                  fontStyle={theme.fonts.f400}
                  margin="6px 0 0 0"
                >
                  {box.description}
                </Text>
                {box.buttons.length > 0 && (
                  <ContentBox backColor="none" margin="32px 0 0 0">
                    {box.buttons.map((button, index) => (
                      <WhiteButton
                        key={index}
                        style={{
                          ...(index > 0 && { marginLeft: "16px" }),
                          ...(button.iconSrc && {
                            width: "48px",
                            height: "32px",
                          }),
                        }}
                      >
                        {button.iconSrc ? (
                          <img src={button.iconSrc} alt="asd" />
                        ) : (
                          <Text size="16px" color={theme.colors.black}>
                            {button.label}
                          </Text>
                        )}
                      </WhiteButton>
                    ))}
                  </ContentBox>
                )}
              </ContentBox>
            </>
          ) : (
            <>
              <Text
                color={theme.colors.white}
                size="32px"
                fontStyle={theme.fonts.f600}
                textAlign="center"
              >
                {box.title}
              </Text>
            </>
          )}
        </LeftBox>
      ))}
    </LeftWrapper>
  );
};
export default SelectMethod;
