import styled from "styled-components";
import { templates } from "../../../theme/templates";

export const GridContainer = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
  row-gap: 30px;
  column-gap: 24px;
  align-items: end;
  @media (max-width: 550px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
    margin-top: 16px;
  }
`;
export const ButtonSend = styled.div`
width: 300px;
cursor: pointer;
height: 55px;
border-radius: 8px;
${templates.centerContent};
margin-top: 30px;
background-color: ${p => p.theme.colors.yellowMain};
`;