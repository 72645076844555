import i18next from "i18next";
import { Text } from "../../../components/Text/Text";
import { useThemeContext } from "../../../theme";
import { Wrapper } from "../NFTItemPage/NFTItemPageStyle";
import PlanItem from "./components/PlanItem";
import { ContainerRow } from "../CreateNFT/CreateNFTStyles";
import { ItemsContainer, SemiString } from "./NFTPlansStyled";
import { useDispatch, useSelector } from "react-redux";
import { nftSelector } from "../../../logic/nft/NftSelectors";
import { NftActions } from "../../../logic/nft/NftRedux";
import { useEffect } from "react";

const NFTPlansPage = () => {
  const { theme } = useThemeContext();
  const { t } = i18next;

  const dispatch = useDispatch();

  const {
    getPlans: { data: nftPlans, fetching: fetchingNftPlans },
  } = useSelector(nftSelector);
  useEffect(() => {
    dispatch(NftActions.getPlans.request());
  }, []);
  return (
    <>
      <Wrapper>
        <Text
          size="48px"
          color={theme.colors.white}
          fontStyle={theme.fonts.f600}
        >
          <p style={{ textTransform: "uppercase", margin: "0" }}>
            {t("nftPlansScreen.headerTitle")}
          </p>
        </Text>

        <Text color={theme.colors.greySubText} fontStyle={theme.fonts.f500}>
          {t("nftPlansScreen.forThoseLooking")}
          <SemiString>{t("nftPlansScreen.upgradeToHigherPlan")}</SemiString>
        </Text>

        <ItemsContainer>
          {nftPlans?.plans?.map((el, index) => (
            <PlanItem key={index} data={el} />
          ))}
        </ItemsContainer>
      </Wrapper>
    </>
  );
};
export default NFTPlansPage;
