import { FC } from "react";
import { NftPlan } from "../../../../types/types";
import { Picture, Wrapper } from "./PlansItemStyle";
import { Text } from "../../../../components/Text/Text";
import { useThemeContext } from "../../../../theme";

type Props = {
  data: NftPlan;
};

const PLANS_IMAGES = [
  "/images/NFT_Page/gift.png",
  "/images/NFT_Page/silver.png",
  "/images/NFT_Page/gold.png",
  "/images/NFT_Page/diamond.png",
];

const PlanItem: FC<Props> = ({ data }) => {
  const { theme } = useThemeContext();
  return (
    <>
      <Wrapper active={data.status === "Active"}>
        <Text
          size="28px"
          color={theme.colors.white}
          fontStyle={theme.fonts.f500}
        >
          {data.title}
        </Text>
        <Picture src={PLANS_IMAGES[data.order - 1]} />
        <Text
          size="36px"
          color={theme.colors.white}
          fontStyle={theme.fonts.f600}
        >
          {data.price} {data.currency_code}
        </Text>
        <Text color={theme.colors.greySubText} fontStyle={theme.fonts.f500}>
          {data.max_nft_items} NFT's
        </Text>
        <Text color={theme.colors.greySubText} fontStyle={theme.fonts.f500}>
          {data.max_collections} Collections
        </Text>

        <Text color={theme.colors.greySubText} fontStyle={theme.fonts.f500}>
          {data.tag_limit} Tags
        </Text>

        <Text color={theme.colors.greySubText} fontStyle={theme.fonts.f500}>
          {data.royalty_limit} Royalty
        </Text>

        <Text color={theme.colors.greySubText} fontStyle={theme.fonts.f500}>
          {data.auction_length} Days Auctions
        </Text>

        <Text color={theme.colors.greySubText} fontStyle={theme.fonts.f500}>
          {data.transfer_commission} Commission
        </Text>
      </Wrapper>
    </>
  );
};
export default PlanItem;
