import { useDispatch, useSelector } from "react-redux";
import { nftSelector } from "../../../../logic/nft/NftSelectors";
import { userIdSelector } from "../../../../logic/user/UserSelectors";
import { NftActions } from "../../../../logic/nft/NftRedux";
import { useCallback, useEffect } from "react";
import { cryptoSelector } from "../../../../logic/crypto/CryptoSelectors";
import { useNavigate } from "react-router-dom";

export const useNftPage = () => {

  const navigate = useNavigate()

    const dispatch = useDispatch()
    const {
        listNftUsers: {data: account}
      } = useSelector(nftSelector);
      const userId = useSelector(userIdSelector);

      const {
        fetchingNftProfile,
        nftProfile,
        createNftProfile: {
          fetching: fetchingCreateNftProfile
        }
      } = useSelector(nftSelector);

      const {
        getAccountsList: {
          fetching: fetchingMyAccounts
        },
        exchangeScreenAccounts: accountsDropdownData
      } = useSelector(cryptoSelector);

      useEffect(() => {
        dispatch(NftActions.listNftUsers.request({
          customer_id: userId,
          fetchAdditionalInfoOnSuccess: true
          
        }));
        dispatch(NftActions.listNfts.request({}))
        dispatch(NftActions.listCategories.request());
      }, []);

      const redirect = () => {
        const state = {
          variant: 'edit',
        };
        navigate("/main/nftProfile", { state });
      };

      const createNftProfile = useCallback(() => {
        
          dispatch(NftActions.createNftProfile.request({
            primary_wallet_type: "ZedPay",
            successCallback: () => redirect()
          }));
        
        
      }, []);

      const {
        listNfts: {
          data: nftList,
          fetching: fetchingNftList
        },
        listCollections:{data: collectionList, fetching: fetchingCollectionNft}
      } = useSelector(nftSelector);
    
      useEffect(() => {
        dispatch(NftActions.listNfts.request({}))
        dispatch(NftActions.listCollections.request({customer_id: userId}))
      },[])
       
      

      


      return {nftProfile,account,collectionList,fetchingCollectionNft,fetchingNftList, createNftProfile,fetchingNftProfile,fetchingCreateNftProfile,fetchingMyAccounts,redirect, nftList}
}