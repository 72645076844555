import { ApiResponse } from 'apisauce';
import { RestActionsType } from './../../store/restHelper.d';
import { ApiResponseValidator, classicApiResponseValidator } from './apiResponseValidators';
import { ApiResponseDataTransformer, classicApiResponseDataTransformer } from './apiResponseDataTransformers';
import { Action } from '@reduxjs/toolkit';
import {
  call,
  put,
} from 'redux-saga/effects';
import { RequestErrorFromResponse } from './../../api';
import { CacheHelperOptions, cacheHelper } from './sagaHelpers';
import { showToast } from '../alertService';
import { RetryApiCallParams, retryApiCall } from './retryApiCall';
import moment from 'moment';
import i18next from 'i18next';

type ToastOptions<ApiResponseT = any> = {
  showSuccess?: boolean;
  showFailure?: boolean;
  getInfoFn?: (data: ApiResponseT, success: boolean) => string;
};

type SagaFactoryParams<ApiResponseT = any, TransformResultT = any> = {
  restActions: RestActionsType;
  apiRequest: (...args: any[]) => Promise<ApiResponse<ApiResponseT>>;
  apiResponseValidator?: ApiResponseValidator;
  apiResponseDataTransformer?: ApiResponseDataTransformer<ApiResponseT, TransformResultT>;
  retryOptions?: Omit<RetryApiCallParams, "apiRequest" | "args" | "apiResponseValidator">;
  cachingOptions?: CacheHelperOptions & {storeToLocalStorageFn: (data: any) => void, dataKey: string, cacheLifetime?: number};
  toastOptions?: ToastOptions<ApiResponseT>;
  successSaga?: (payload: any, result: TransformResultT) => Generator;
  failureSaga?: (payload: any, apiResponse: ApiResponse<ApiResponseT>) => Generator;
};

export function sagaFactory<ApiResponseT = any, TransformResultT = any>(
  {
    restActions,
    apiRequest,
    apiResponseValidator = classicApiResponseValidator,
    apiResponseDataTransformer = classicApiResponseDataTransformer,
    retryOptions,
    cachingOptions,
    toastOptions,
    successSaga,
    failureSaga
  }: SagaFactoryParams<ApiResponseT, TransformResultT>
) {
  return function* (action: Action) {
    if (restActions.request.match(action)) {
      try {
        if (cachingOptions && !action.payload?.ignoreCache) {
          const cached: boolean = yield call(cacheHelper, {
            getFromLocalStorageFn: cachingOptions.getFromLocalStorageFn,
            successFn: cachingOptions.successFn
          }, cachingOptions.cacheLifetime);
    
          if (cached)
            return;
        }

        const apiRequestArgs = [];

        if (action.payload)
          apiRequestArgs.push(action.payload);
        
        let apiResponse: ApiResponse<ApiResponseT>;
        if (retryOptions) {
          apiResponse = yield call(retryApiCall, {
            apiRequest,
            args: apiRequestArgs,
            apiResponseValidator,
            ...retryOptions
          });
        } else {
          apiResponse = yield call(apiRequest, ...apiRequestArgs);
        }

        if (apiResponseValidator(apiResponse)) {
          let responseDataTransformed = apiResponseDataTransformer(apiResponse.data!);

          if (cachingOptions) {
            responseDataTransformed = {
              [cachingOptions.dataKey]: responseDataTransformed,
              timestamp: moment().valueOf()
            } as any;

            yield call(cachingOptions.storeToLocalStorageFn, responseDataTransformed);
          }

          if (action.payload?.successCallback) {
            yield call(action.payload.successCallback, responseDataTransformed);
          }

          if (successSaga) {
            yield call(successSaga, action.payload, responseDataTransformed);
          }

          if (toastOptions?.showSuccess) {
            yield call(showToast, {
              title: i18next.t('success'),
              // @ts-ignore
              info: responseDataTransformed?.message ?? toastOptions.getInfoFn?.(apiResponse.data, true) ?? "",
              type: "success",
            });
          }
          
          yield put(restActions.success(responseDataTransformed));
        } else {
          if (action.payload?.failureCallback) {
            yield call(action.payload.failureCallback);
          }

          if (failureSaga) {
            yield call(failureSaga, action.payload, apiResponse);
          }

          if (toastOptions?.showFailure) {
            yield call(showToast, {
              title: i18next.t('oops') as string,
              // @ts-ignore
              info: apiResponse?.data?.message ?? "Error",
              type: "error",
            });
          }
          
          throw new RequestErrorFromResponse(apiResponse);
        }
      } catch (error) {
        yield put(restActions.failure(error));
      }
    }
  };
}