import React, { useEffect } from "react";
import { BlocksContainer, TopContainer, Wrapper } from "./NFTHomePageStyle";
import { BlockItem, BlockItem2 } from "./components/BlockItem";
import { gradientsSC } from "../../../theme/default/colors";
import EmptyPage from "./components/EmptyPage";
import { useNftPage } from "./hooks/useNftPage";
import NftList from "./components/NftList";
import ListTitle from "./components/ListTitle";
import i18next from "i18next";
import { LoaderContainer } from "../NFTCatalogPage/NFTCatalogPageStyles";
import LoaderRing from "../../../helpers/Loader/LoaderRing";
import { useDispatch, useSelector } from "react-redux";
import { NftActions } from "../../../logic/nft/NftRedux";
import { userIdSelector } from "../../../logic/user/UserSelectors";

const NFTPage = () => {
  const { t } = i18next;
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);
  const {
    fetchingNftProfile,
    fetchingMyAccounts,
    nftProfile,
    fetchingCreateNftProfile,
    nftList,
    collectionList,
    fetchingCollectionNft,
    fetchingNftList,
  } = useNftPage();

  useEffect(() => {
    dispatch(NftActions.listNfts.request({}));
    dispatch(NftActions.listCollections.request({ customer_id: userId }));
  }, []);
  return (
    <Wrapper>
      {nftProfile ? (
        <>
          <TopContainer>
            <BlockItem
              bgGradient={gradientsSC.orange}
              title={t("nftHomeScreen.discoverCollect")}
              img="/images/NFT_Page/block1.png"
            />
            <BlocksContainer>
              <BlockItem2
                bgGradient={gradientsSC.green}
                title={t("nftHomeScreen.myProfile")}
                img="/images/NFT_Page/_ (1).png"
                href="/nft/profile"
                profile
              />
              <BlockItem2
                bgGradient={gradientsSC.purple}
                title={t("nftHomeScreen.myNft")}
                white
                img="/images/NFT_Page/block2.png"
                href="/nft/catalog"
              />
              <BlockItem2
                bgGradient={gradientsSC.blue}
                title={t("nftHomeScreen.myCollections")}
                img="/images/NFT_Page/block3.png"
                href="/nft/my-collection"
              />
            </BlocksContainer>
          </TopContainer>

          <ListTitle
            title={t("nftHomeScreen.trendingCollection")}
            href="/nft/my-collection"
          />
          {fetchingCollectionNft ? (
            <LoaderContainer>
              <LoaderRing size={"46px"} color="white" />
            </LoaderContainer>
          ) : (
            <NftList cards={collectionList} collection />
          )}

          {/* <ListTitle title={t("nftHomeScreen.topSellers")} />
          <NftList cards={sellers} sellers /> */}

          <ListTitle title={t("nftHomeScreen.popular")} href={"/nft/catalog"} />
          {fetchingNftList ? (
            <LoaderContainer>
              <LoaderRing size={"46px"} color="white" />
            </LoaderContainer>
          ) : (
            <NftList cards={nftList} />
          )}
        </>
      ) : (
        <EmptyPage
          fetchingProfile={fetchingNftProfile || fetchingMyAccounts}
          fetchingCreate={fetchingCreateNftProfile}
        />
      )}
    </Wrapper>
  );
};
export default NFTPage;
