import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container:{
    display: 'flex',
    width:'100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.metrics.x2,
    paddingBottom: theme.metrics.x2,
    borderBottom: `1px solid ${theme.colors.borderMdm}`,
    cursor: 'pointer'
  },
  text: {
    fontFamily: theme.fonts.f500.fontFamily,
    fontSize: theme.metrics.x3,
    letterSpacing: -0.3,
    color: theme.colors.white,
    maxWidth: '50%',
  }
});