import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: ${(p) => p.theme.colors.black};
  padding: 59px 24px;
  @media (max-width: 900px) {
    padding: 29px 8px;
  }
`;
export const ItemsContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
`;
