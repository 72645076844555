import React, { Dispatch, FC, SetStateAction, useContext } from "react";
import {
  AnimationContainer,
  Button,
  ButtonsContainer,
  CloseButton,
  Wrapper,
} from "./LogoutModalStyles";
import { ContentBox } from "../../ContentBox/ContentBox";

import { Text } from "../../Text/Text";
import { text } from "../../../theme/text";
import { NavLink } from "react-router-dom";
import { useThemeContext } from "../../../theme";
import { ThemeProvider } from "styled-components";
import BlurWrapper from "../../BlurWrapper/BlurWrapper";

type Props = {
  setVisible: Dispatch<SetStateAction<boolean>>;
  logout: () => void;
};

const LogoutModal: FC<Props> = ({ setVisible, logout }) => {
  const { theme } = useThemeContext();
  return (
    <BlurWrapper setModal={() => setVisible(false)}>
      <AnimationContainer onClick={(e) => e.stopPropagation()}>
        <ContentBox
          direction="column"
          backColor={theme.colors.subBackground}
          padding="31px 24px"
          alignItems="center"
          width="690px"
        >
          <CloseButton onClick={() => setVisible(false)}>
            <img src="/images/Close_Button.svg" alt="asd" />
          </CloseButton>
          <Text
            color={theme.colors.white}
            size="40px"
            fontStyle={theme.fonts.f600}
          >
            Log out
          </Text>
          <Text color={text.colors.gray} size="18px" margin="35px 0 0 0">
            Are you sure you want to log out?
          </Text>
          <img
            src="/images/Logout.png"
            alt="asd"
            style={{ marginTop: "30px" }}
          />
          <ButtonsContainer>
            <Button backColor="none" border onClick={() => setVisible(false)}>
              <Text
                color={text.colors.yellow}
                size="20px"
                fontStyle={theme.fonts.f600}
              >
                Cancel
              </Text>
            </Button>
            <Button backColor="#E86F6F" onClick={logout}>
              <NavLink to="/">
                <Text
                  color={theme.colors.white}
                  size="20px"
                  fontStyle={theme.fonts.f600}
                >
                  Log out
                </Text>
              </NavLink>
            </Button>
          </ButtonsContainer>
        </ContentBox>
      </AnimationContainer>
    </BlurWrapper>
  );
};
export default LogoutModal;
