import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    color: theme.colors.white,
    fontFamily: theme.fonts.f600.fontFamily,
    fontSize: 26,
    maxWidth: "50%",
    textAlign: 'center'
  },
  bottomBtn: {
    display: "flex",
    width: "50%"
  }
});