import React, {
  memo,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import { useStyles } from "./SlidingPickerV2Styles";
import { Box } from "@material-ui/core";
import { theme } from "../../theme/default/theme";
import Animated, { useSharedValue, withTiming } from "react-native-reanimated";
import cn from "classnames";
import useWindowWidth from "../../hooks/useWindowWidth";

export type SlidingPickerV2Props = {
  periods: string[];
  pickedPeriod: number;
  height?: number;
  bgColor?: string;
  labelFontSize?: number;
  mainLineBgColor?: string;
  mainLineBorderRadius?: number;
  animationDuration?: number;
  setPickedPeriod: (index: number) => void;
};

const SlidingPickerV2: React.FC<SlidingPickerV2Props> = ({
  periods,
  height,
  mainLineBgColor,
  pickedPeriod,
  bgColor,
  labelFontSize,
  mainLineBorderRadius,
  animationDuration,
  setPickedPeriod,
}) => {
  const s = useStyles();

  const containerRef = useRef<null | HTMLDivElement>(null);

  const windowWidth = useWindowWidth();
  // width style props for sliding block
  const [w, setW] = useState(0);
  // left style prop for sliding block
  const left = useSharedValue(0);

  useLayoutEffect(() => {
    setW((containerRef?.current?.offsetWidth ?? 0) / periods.length);
  }, [periods, windowWidth]);

  useEffect(() => {
    left.value = withTiming(pickedPeriod * w, { duration: animationDuration });
  }, [pickedPeriod, w]);

  return (
    <div
      ref={containerRef}
      className={cn(s.container)}
      style={{
        borderRadius: mainLineBorderRadius,
        height,
        backgroundColor: bgColor,
      }}
    >
      {periods.map((item, index) => (
        <Box
          key={index}
          className={cn(s.spaceContainer)}
          onClick={() => setPickedPeriod(index)}
        >
          <Box className={cn(s.block)}>
            <p
              className={cn(s.periodLabel)}
              style={{
                color:
                  index === pickedPeriod
                    ? theme.colors.black
                    : theme.colors.greySubTextDesc,
                fontSize: labelFontSize,
              }}
            >
              {item}
            </p>
          </Box>
        </Box>
      ))}
      <Animated.View
        style={{
          position: "absolute",
          zIndex: 2,
          width: w,
          // @ts-ignore
          height: height - 1,
          left,
          borderRadius: mainLineBorderRadius,
          backgroundColor: mainLineBgColor,
        }}
      />
    </div>
  );
};

SlidingPickerV2.defaultProps = {
  labelFontSize: theme.metrics.x3,
  bgColor: theme.colors.woodsmoke,
  mainLineBorderRadius: theme.metrics.x2,
  mainLineBgColor: theme.colors.yellowMain,
  animationDuration: 150,
  height: 32,
};

const MemorizedComponent = memo(SlidingPickerV2);
export { MemorizedComponent as SlidingPickerV2 };
