import {
  addAllRestReducers,
  addRestReducers,
  createRestActions,
  getDefaultRestState,
} from "../../store/restHelper";
import {
  GetAdRestActions,
  GetBlogRestActions,
  GetNewsRestActions,
  TicketChangeRestActions,
  TicketOpenRestActions,
  TicketReplyRestActions,
  TicketsListRestActions,
  TicketsTypesListRestActions,
} from "./SocialActions";
import {
  AdResponse,
  BlogResponse,
  NewsResponse,
  TicketChangeRequest,
  TicketOpenRequest,
  TicketOpenResponse,
  TicketReplyRequest,
  TicketReplyResponse,
  TicketsListItem,
  TicketsListRequest,
  TicketsTypesItem,
} from "../../types/api";
import { NodeRestStateType } from "../../store/restHelper.d";
import {
  ActionReducerMapBuilder,
  createAction,
  createReducer,
} from "@reduxjs/toolkit";
import fp from "lodash/fp";

type TicketsTypesResponse = TicketsTypesItem[]

type TicketsListResponse = TicketsListItem[];


const getNewsRestActions = createRestActions<
  NewsResponse
>(GetNewsRestActions);

const getBlogRestActions = createRestActions<
  BlogResponse
>(GetBlogRestActions);

const getAdRestActions = createRestActions<AdResponse>(
  GetAdRestActions
);

const ticketsListRestActions = createRestActions<
  TicketsListResponse,
  TicketsListRequest
>(TicketsListRestActions);

const ticketsTypesListRestActions = createRestActions<
  TicketsTypesResponse
>(TicketsTypesListRestActions);

const ticketOpenRestActions = createRestActions<
  TicketOpenResponse,
  TicketOpenRequest
>(TicketOpenRestActions);

const ticketReplyRestActions = createRestActions<
  TicketReplyResponse,
  TicketReplyRequest
>(TicketReplyRestActions);

const ticketChangeRestActions = createRestActions<
  TicketReplyResponse,
  TicketChangeRequest
>(TicketChangeRestActions);

const SocialRestActions ={
  news: getNewsRestActions,
  blog: getBlogRestActions,
  ad: getAdRestActions,
  ticketsList: ticketsListRestActions,
  ticketsTypes: ticketsTypesListRestActions,
  ticketOpen: ticketOpenRestActions,
  ticketReply: ticketReplyRestActions,
  ticketChange: ticketChangeRestActions,
}

const SocialActions = {
  ...SocialRestActions
};

type SocialRestNodes = keyof typeof SocialRestActions;
type SocialStore = {};

const initialSocialStore:SocialStore = {

}

const initialRestState = {
  news: getDefaultRestState<NewsResponse>(),
  blog: getDefaultRestState<BlogResponse>(),
  ad: getDefaultRestState<AdResponse>(),
  ticketsList: getDefaultRestState<TicketsListResponse>(),
  ticketsTypes: getDefaultRestState<TicketsTypesResponse>(),
  ticketOpen: getDefaultRestState<TicketOpenResponse>(),
  ticketReply: getDefaultRestState<TicketReplyResponse>(),
  ticketChange: getDefaultRestState<any>(),
};

type SocialState = NodeRestStateType<SocialRestNodes, SocialStore & typeof initialRestState>;

type Builder = ActionReducerMapBuilder<SocialState>;

const socialReducer = createReducer(
  {...initialSocialStore, ...initialRestState},
  (builder) =>
    fp.flow(addAllRestReducers<typeof SocialRestActions>(SocialRestActions))(builder) as Builder
  // .addCase(SocialActions.storeCurrentTicketId, (state, action) => {
  //   state.currentTicketId = action.payload;
  // })
);

export { socialReducer, SocialActions };
