import { ActionReducerMapBuilder, createAction, createReducer } from '@reduxjs/toolkit';
import { addAllRestReducers, addRestReducers, createRestActions, getDefaultRestState } from './../../store/restHelper';
import { NodeRestStateType } from './../../store/restHelper.d';
import { ChangeFollowStatusRestActions, ChangeLikeStatusRestActions, ChangePlanRestActions, CreateNftCollectionRestActions, CreateNftProfileRestActions, CreateNftRestActions, DeleteNftProfileRestActions, EditNftCollectionRestActions, EditNftProfileRestActions, EditNftRestActions, FinancialReportRestActions, GetContractCostRestActions, GetPlansRestActions, ListCategoriesRestActions, ListCollectionsRestActions, ListContractsRestActions, ListNftUsersRestActions, ListNftsRestActions, ViewingNft, ViewingNftProfile } from './NftActions';
import { BlockchainType, GeneralStatus, NftCategory, NftCollection, NftItem, NftItemStatus, NftPlan, NftProperty, NftSocialMediaLink, NftTag, NftUserInfo, NftUserWalletType, SmartContract, WithTimestamp } from '../../types/types';
import fp from 'lodash/fp';
import { UserInfo } from '../user/UserRedux';

// Key - collectionId
export type NftsByCollectionTable = {[key: string]: NftItem[]};
// Key - userId
export type CollectionsByUserTable = {[key: string]: NftCollection[]};

type GetPlansResponse = WithTimestamp<{
  plans: NftPlan[];
}>;

type GetContractCostResponse = void;

type FinancialReportResponse = {};

type ListNftUsersResponse = NftUserInfo[];

type ListCategoriesResponse = WithTimestamp<{
  categories: NftCategory[];
}>;

type ListCollectionsResponse = NftCollection[];

type ChangePlanResponse = any;

type ListNftsResponse = NftItem[];

type FinancialReportRequest = {
  from: string;
  to: string;
};

type CreateNftProfilePayload = {
  primary_wallet_type: NftUserWalletType;
  successCallback?: () => void;
  failureCallback?: () => void;
};

type DeleteNftProfilePayload = {
  id: number;
};

type EditNftProfilePayload = {
  id: number;
  logo?: boolean;
  banner?: boolean;
  fData?: FormData;
  username: string;
  Bio: string;
  links: NftSocialMediaLink[];
  status: GeneralStatus;
};

type CreateNftPayload = {
  nft_user_id: number;
  collection_id: number;
  contract_id: number;
  title: string;
  description: string;
  category: string;
  link: string;
  fData?: FormData;
  properties: NftProperty[];
  tags: NftTag[];
  blockchain: BlockchainType;
  account_id: number;
  currency_id: number;
  price: number;
  instant_sale: boolean;
  allow_bid: boolean;
  bid_expiry: string;
  unlock_on_purchase: boolean;
  royalties: number;
  new_contract_name: string;
  new_contract_symbol: string;
};

type ListNftUsersPayload = {
  customer_id?: number;
  fetchAdditionalInfoOnSuccess?: boolean;
}

type ListCollectionsPayload = {
  customer_id?: number;
  id?: number;
};

type ChangePlanPayload = {
  id: number
  plans: {title: string}[];
};

type ChangeLikeStatusPayload = {
  id: number;
  liked_by_me: boolean;
  successCallback?: () => void;
  failureCallback?: () => void;
};


type ListNftsPayload = {
  customer_id?: number;
  collection_id?: number;
};

type ChangeFollowStatusPayload = {
  id: number;
  followed_by_me: boolean;
};

type ListContractsPayload = {
  customer_id?: number;
  ignoreCache?: boolean;
};

type ListContractsResponse = WithTimestamp<{
  contracts: SmartContract[];
}>;

type CreateNftCollectionPayload = {
  nft_user_id: number;
  category: string;
  title: string;
  description: string;
  fData?: FormData;
  links: NftSocialMediaLink[];
  successCallback?: () => void
};

type EditNftCollectionPayload = {
  id: number;
  category: string;
  title: string;
  description: string;
  fData?: FormData;
  logoLink?: string;
  coverLink?: string;
  bannerLink?: string;
  links: NftSocialMediaLink[];
  status: GeneralStatus;
};

type EditNftPayload = {
  id: number;
  collection_id: number;
  title: string;
  description: string;
  category: string;
  link: string;
  fData?: FormData;
  posterLink?: string;
  properties: NftProperty[];
  tags: NftTag[];
  currency_id: number;
  price: number;
  instant_sale: boolean;
  allow_bid: boolean;
  bid_expiry: string;
  unlock_on_purchase: boolean;
  royalties: number;
  status: NftItemStatus;
};


const getPlansRestActions = createRestActions<
  GetPlansResponse
>(GetPlansRestActions);

const getContractCostRestActions = createRestActions<
  GetContractCostResponse
>(GetContractCostRestActions);

const financialReportRestActions = createRestActions<
  FinancialReportResponse,
  FinancialReportRequest
>(FinancialReportRestActions);

const createNftProfileRestActions = createRestActions<
  void,
  CreateNftProfilePayload
>(CreateNftProfileRestActions);

const deleteNftProfileRestActions = createRestActions<
  void,
  DeleteNftProfilePayload
>(DeleteNftProfileRestActions);

const editNftProfileRestActions = createRestActions<
  void,
  EditNftProfilePayload
>(EditNftProfileRestActions);

const listNftUsersRestActions = createRestActions<
  ListNftUsersResponse,
  ListNftUsersPayload
>(ListNftUsersRestActions);

const listCategoriesRestActions = createRestActions<
  ListCategoriesResponse
>(ListCategoriesRestActions);

const listCollectionsRestActions = createRestActions<
  ListCollectionsResponse,
  ListCollectionsPayload
>(ListCollectionsRestActions);

const editNftRestActions = createRestActions<
  void,
  EditNftPayload
>(EditNftRestActions);

const changePlanRestActions = createRestActions<
  ChangePlanResponse,
  ChangePlanPayload
>(ChangePlanRestActions);

const changeLikeStatusRestActions = createRestActions<
  void,
  ChangeLikeStatusPayload
>(ChangeLikeStatusRestActions);

const listNftsRestActions = createRestActions<
  ListNftsResponse,
  ListNftsPayload
>(ListNftsRestActions);

const changeFollowStatusRestActions = createRestActions<
  void,
  ChangeFollowStatusPayload
>(ChangeFollowStatusRestActions);

const createNftCollectionRestActions = createRestActions<
  void,
  CreateNftCollectionPayload
>(CreateNftCollectionRestActions);

const createNftRestActions = createRestActions<
  void,
  CreateNftPayload
>(CreateNftRestActions);

const listContractsRestActions = createRestActions<
  ListContractsResponse,
  ListContractsPayload
>(ListContractsRestActions);

type UpdateNftsByCollectionTablePayload = {
  collectionId: number;
  nfts: NftItem[];
};

type UpdateCollectionsByUserTablePayload = {
  userId: number;
  collections: NftCollection[];
};
type SetViewingNftProfilePayload = {
  id: number
}
type SetViewingNftProfileResponse = {
  profile: UserInfo
}

const editNftCollectionRestActions = createRestActions<
  void,
  EditNftCollectionPayload
>(EditNftCollectionRestActions);

const setViewingNftProfile = createRestActions<
SetViewingNftProfileResponse, SetViewingNftProfilePayload
>(ViewingNftProfile)

const NftRestActions = {
  getPlans: getPlansRestActions,
  getContractCost: getContractCostRestActions,
  financialReport: financialReportRestActions,
  createNftProfile: createNftProfileRestActions,
  deleteNftProfile: deleteNftProfileRestActions,
  editNftProfile: editNftProfileRestActions,
  editNftCollection: editNftCollectionRestActions,
  listNftUsers: listNftUsersRestActions,
  listCategories: listCategoriesRestActions,
  listCollections: listCollectionsRestActions,
  changePlan: changePlanRestActions,
  editNft: editNftRestActions,
  changeLikeStatus: changeLikeStatusRestActions,
  listNfts: listNftsRestActions,
  changeFollowStatus: changeFollowStatusRestActions,
  listContracts: listContractsRestActions,
  createNftCollection: createNftCollectionRestActions,
  createNft: createNftRestActions,

}

const NftActions = {
  ...NftRestActions,
  setFetchingNftProfile: createAction<boolean>("nft/setFetchingNftProfile"),
  setNftProfile: createAction<NftUserInfo | undefined>("nft/setNftProfile"),
  setViewingNftProfile: createAction<NftUserInfo | undefined>("nft/setViewingNftProfile"),
  setMyNftCollections: createAction<NftCollection[]>("nft/setMyNftCollections"),
  setFetchingMyNftCollections: createAction<boolean>("nft/setFetchingMyNftCollections"),
  updateNftsByCollectionTable: createAction<UpdateNftsByCollectionTablePayload>("nft/updateNftsByCollectionTable"),
  updateCollectionsByUserTable: createAction<UpdateCollectionsByUserTablePayload>("nft/updateCollectionsByUserTable")
};

type NftRestNodes = keyof typeof NftRestActions;

type NftStore = {
  fetchingNftProfile: boolean;
  nftProfile: NftUserInfo | undefined;
  myNftCollections: NftCollection[];
  fetchingMyNftCollections: boolean;
  nftsByCollectionTable: NftsByCollectionTable;
  collectionsByUserTable: CollectionsByUserTable;
  viewingNftProfile: NftUserInfo | undefined
};

const initialNftStore: NftStore = {
  fetchingNftProfile: false,
  nftProfile: undefined,
  myNftCollections: [],
  fetchingMyNftCollections: false,
  nftsByCollectionTable: {},
  collectionsByUserTable: {},
  viewingNftProfile: undefined
}

const initialRestState = {
  getPlans: getDefaultRestState<GetPlansResponse>(),
  getContractCost: getDefaultRestState<GetContractCostResponse>(),
  financialReport: getDefaultRestState<FinancialReportResponse>(),
  createNftProfile: getDefaultRestState(),
  deleteNftProfile: getDefaultRestState(),
  editNftProfile: getDefaultRestState(),
  editNftCollection: getDefaultRestState(),
  listNftUsers: getDefaultRestState<ListNftUsersResponse>(),
  listCategories: getDefaultRestState<ListCategoriesResponse>(),
  listCollections: getDefaultRestState<ListCollectionsResponse>(),
  changePlan: getDefaultRestState(),
  editNft: getDefaultRestState(),
  changeLikeStatus: getDefaultRestState(),
  listNfts: getDefaultRestState<ListNftsResponse>(),
  changeFollowStatus: getDefaultRestState(),
  createNftCollection: getDefaultRestState(),
  createNft: getDefaultRestState(),
  listContracts: getDefaultRestState<ListContractsResponse>(),
  setViewingNftProfile: getDefaultRestState()
};

type NftState = NodeRestStateType<
  NftRestNodes, 
  NftStore & typeof initialRestState
>;

type Builder = ActionReducerMapBuilder<NftState>;

const nftReducer = createReducer(
  {...initialNftStore, ...initialRestState}, 
  builder =>
    (fp.flow(addAllRestReducers<typeof NftRestActions>(NftRestActions))(builder) as Builder)
    .addCase(NftActions.setFetchingNftProfile, (state, action) => {
      state.fetchingNftProfile = action.payload;
    })  
    .addCase(NftActions.setNftProfile, (state, action) => {
        state.nftProfile = action.payload;
      })
      .addCase(NftActions.setMyNftCollections, (state, action) => {
        state.myNftCollections = action.payload;
      })
      .addCase(NftActions.setFetchingMyNftCollections, (state, action) => {
        state.fetchingMyNftCollections = action.payload;
      })
      .addCase(NftActions.updateNftsByCollectionTable, (state, action) => {
        state.nftsByCollectionTable[action.payload.collectionId] = action.payload.nfts;
      })
      .addCase(NftActions.updateCollectionsByUserTable, (state, action) => {
        state.collectionsByUserTable[action.payload.userId] = action.payload.collections;
      })
      .addCase(NftActions.setViewingNftProfile, (state, action) => {
        state.viewingNftProfile = action.payload;
      })
);

export { nftReducer, NftActions };