import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nftSelector } from "../../../../logic/nft/NftSelectors";
import { useNftCategoryDropdownData } from "../../../../hooks/useNftCategoryDropdownData";
import { useHideErrors } from "../../../../hooks/useHideErrors";
import { validateNftCollectionDescription, validateNftCollectionTitle } from "../../../../helpers/validationHelper";
import { prepareImageName } from "../../../../helpers/formatHelpers";
import { NftActions } from "../../../../logic/nft/NftRedux";
import { useLinksState } from "../../../../hooks/useLinksState";
import { userIdSelector } from "../../../../logic/user/UserSelectors";

export const useCreateNFTCollection = () => {

    const dispatch = useDispatch()
    const {
        nftProfile,
        createNftCollection: {
          fetching: fetchingCreateNftCollection
        },editNftCollection: {
          fetching: fetchingEditNftCollection
        }
      } = useSelector(nftSelector);
      const userId = useSelector(userIdSelector)
          
      const {
        categoryDropdownData,
        category, 
        setCategory,
        setCategoryByTitle
      } = useNftCategoryDropdownData();

      const [images, setImages] = useState<{
        logo: File | null,
        cover: File | null,
        banner: File | null,
      }>({
        banner:null,
        cover:null,
        logo:null
      })
      const [inputsData, setInputsData] = useState({
        title: '',
        description: '',
        category: category,
      })

     

    
      const {
        links,
        setLink,
        setFilledLinks,
        validateLinks,
        getLinksArray
      } = useLinksState();
    
      const {
        hideErrors,
        setHideErrors,
        onInputErrorAnimationEnd
      } = useHideErrors();

      const handleInput = (e: any) => {
        setInputsData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      };
      const handleSelect = (name: string, value: any) => {
        setInputsData((prev) => ({ ...prev, [name]: value }));
      };

      useEffect(() => {
        dispatch(NftActions.listNftUsers.request({
          customer_id: userId,
          fetchAdditionalInfoOnSuccess: true
          
        }));
        dispatch(NftActions.listCategories.request())
        dispatch(NftActions.listCollections.request({
          customer_id: userId
        }))
       
       
      },[])
    
    


      return{
        categoryDropdownData,
        links,
        setLink,
        inputsData,
        setInputsData,
        getLinksArray,
        handleInput,
        handleSelect,
        nftProfile,
        validateLinks,
        images,
        category,
        setImages,
        setFilledLinks,
        fetchingCreateNftCollection,fetchingEditNftCollection
        

      }
}