import { restActionCreatorHelper } from "./../../helpers/restActionCreatorHelper";

const socialRestAction = restActionCreatorHelper(`social`);

export const GetNewsRestActions = socialRestAction("news");
export const GetBlogRestActions = socialRestAction("blog");
export const GetAdRestActions = socialRestAction("ad");
export const TicketsListRestActions = socialRestAction("ticketsList");
export const TicketsTypesListRestActions = socialRestAction("tiketsTypes");
export const TicketOpenRestActions = socialRestAction("tiketOpen");
export const TicketReplyRestActions = socialRestAction("tiketReply");
export const TicketChangeRestActions = socialRestAction("ticketChange");
