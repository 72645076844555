import { Link, useNavigate } from "react-router-dom";
import { Text } from "../../../components/Text/Text";
import i18n from "../../../i18n/i18n";
import { useThemeContext } from "../../../theme";
import { Picture, TextContainer, Wrapper } from "./NeedVerificationStyles";

const NeedVerification = () => {
  const { theme } = useThemeContext();
  const { t } = i18n;
  return (
    <Link to="verification">
      <Wrapper>
        <TextContainer>
          <Text
            color={theme.colors.white}
            size="30px"
            fontStyle={theme.fonts.f600}
            hoverColor="none"
          >
            {t("Verification.verification")}
          </Text>
          <Text
            size="18px"
            color={theme.colors.textDescription}
            fontStyle={theme.fonts.f600}
            hoverColor="none"
          >
            {t("mainPage.needVerification")}
          </Text>
        </TextContainer>
        <Picture src="/images/main/verification.png" alt="verification img" />
      </Wrapper>
    </Link>
  );
};
export default NeedVerification;
