import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
  >
    <rect
      width={23.25}
      height={23.25}
      x={0.375}
      y={0.375}
      stroke="#464646"
      strokeWidth={0.75}
      rx={11.625}
    />
    <g clipPath="url(#a)">
      <path
        fill="#169C28"
        fillRule="evenodd"
        d="M16.598 6.833a1.125 1.125 0 0 1 0 1.591l-3.713 3.713a1.125 1.125 0 1 1-1.591-1.591l3.712-3.713a1.125 1.125 0 0 1 1.592 0Zm3.75 0a1.125 1.125 0 0 1 0 1.591l-7.373 7.373a1.2 1.2 0 0 1-1.696 0l-3.13-3.13a1.125 1.125 0 1 1 1.591-1.591l2.387 2.386 6.63-6.629a1.125 1.125 0 0 1 1.59 0Zm-15.95 4.243a1.125 1.125 0 0 1 1.592 0l3.182 3.182a1.123 1.123 0 0 1-.35 1.86 1.124 1.124 0 0 1-1.24-.27L4.4 12.668a1.125 1.125 0 0 1 0-1.591l-.001-.001Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M3 3h18v18H3z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
