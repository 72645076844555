import styled from "styled-components";

import { text } from "../../../theme/text";
import { templates } from "../../../theme/templates";

export const Wrapper = styled.div`
  width: 310px;
  height: 560px;
  border-radius: 8px;
  background-color: ${(p) => p.theme.colors.subBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 10px;

  @media (max-width: 477px) {
    width: 90%;
  }
`;
export const Button = styled.div<{ bgColor: string }>`
  ${templates.centerContent};
  padding: 0 41px;
  text-align: center;
  border-radius: 16px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  height: 68px;
  padding: 5px;
  background-color: ${(p) => p.bgColor || p.theme.colors.white};
  @media (max-width: 700px) {
    width: calc(100% - 24px);
    height: auto;
    min-height: 46px;
  }
`;
export const Picture = styled.img`
  transform: scale(0.7);
  margin-top: -40px;
`;
