import styled from "styled-components";

export const Wrapper = styled.div`
padding: 24px ;
@media(max-width: 900px){
    padding: 15px 16px;
}

`;
export const Line = styled.div`
width: 100%;
background-color: ${p => p.theme.colors.greySubText};
height: 1px;


`;

export const CardsWrapper = styled.div`
display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 24px;
  width: 100%; 
`;