import React from "react";
import { useStyles } from "./ValidationInfoStyles";
import { Box } from "@material-ui/core";
import { useThemeContext } from "../../theme";
import classNames from "classnames";
import SuccessIcon from "./assets/success";
import ErrorIcon from "./assets/error";

type ValidationItem = {
  text: string;
  ok: boolean;
  id: string;
};

export type ValidationInfoProps = {
  validations: ValidationItem[];
};

const ValidationInfo: React.FC<ValidationInfoProps> = ({ validations }) => {
  const s = useStyles();
  const { theme } = useThemeContext();

  return (
    <Box className={classNames(s.container)}>
      {validations.map((v: ValidationItem) => (
        <Box key={v.id} className={classNames(s.itemRow)}>
          <Box className={classNames(s.icon)}>
            {v.ok ? <SuccessIcon /> : <ErrorIcon />}
          </Box>
          <p
            className={classNames(s.text)}
            style={{
              color: v.ok ? theme.colors.feijoa : theme.colors.greySubText,
            }}
          >
            {v.text}
          </p>
        </Box>
      ))}
    </Box>
  );
};

ValidationInfo.defaultProps = {};

export { ValidationInfo };
