import React from 'react';
import { useStyles } from './VerificationRequiredStyles';
import { Box } from '@material-ui/core';
import { t } from 'i18next';
import { CustomButton } from '../CustomButton';
import { useThemeContext } from '../../theme';
import { useNavigate } from "react-router-dom";
import classNames from 'classnames';

export type VerificationRequiredProps = {
  bgColor?: string;
  text?: string;
};

const VerificationRequired: React.FC<VerificationRequiredProps> = ({
  bgColor,
  text
}) => {
  const s = useStyles();
  const { theme } = useThemeContext();
  const navigate = useNavigate();

  return (
    <Box className={classNames(s.container)} style={{backgroundColor: bgColor}}>
      <p className={classNames(s.text)}>
        {text}
      </p>

      <Box className={classNames(s.bottomBtn)}>
        <CustomButton
          title={t("toVerification") as string}
          bgColorActive={theme.colors.yellowMain}
          colorActive={theme.colors.black}
          defaultSizing
          onPress={() => navigate("/main/verification")}
        />
      </Box>
    </Box>
  );
};

VerificationRequired.defaultProps={
  text: t("fullVerificationRequired") as string
}

export { VerificationRequired };