import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./reducers";
import { CodeBud } from "@appklaar/codebud";
import { enableMapSet } from "immer";
import { getSagaMiddleware } from "./sagaMiddleware";

enableMapSet();

function getDefaultMiddleware() {
  const middleware = [];
  middleware.push(getSagaMiddleware());

  middleware.push(CodeBud.createReduxActionMonitorMiddleware(200, [
    /^escrowTxConstructor\/setTitle$/,
    /^escrowTxConstructor\/setDescription$/,
    /^escrowTxConstructor\/setEmailByPartnerLocalId$/,
    /^escrowTxConstructor\/setFieldByPartnerLocalId$/
  ]));

  return middleware;
}

function getDefaultEnhancers() {
  const enhancers: any[] = [];

  return enhancers;
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware(),
  enhancers: getDefaultEnhancers(),
});

export type RootStore = typeof store;
