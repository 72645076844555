import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
  >
    <path
      fill={props.color}
      d="M.315 7.518 4.124.593a1 1 0 0 1 1.752 0l3.809 6.925A1 1 0 0 1 8.809 9H1.19a1 1 0 0 1-.876-1.482Z"
    />
  </svg>
)
export default SvgComponent
