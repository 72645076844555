import { Instruction, InstructionGroup } from "@appklaar/codebud";
import { NftActions } from "./../logic/nft/NftRedux";

export const getNftInstructions = (store: any): (Instruction | InstructionGroup)[] => ([
  {
    groupId: "NFT",
    groupDescription: "NFT entity instructions",
    groupColor: "#C193EF",
    groupInstructions: [
      {
        id: "nftGetPlans",
        description: "Get a list of subscription plans.",
        handler: () => {
          store.dispatch(NftActions.getPlans.request());
        }
      },
      {
        id: "getContractCost",
        description: "Get the cost of deploying Smart Contracts.",
        handler: () => {
          store.dispatch(NftActions.getContractCost.request());
        }
      },
      {
        id: "nftFinancialReport",
        description: "Get NFT financial report for a specific period.",
        parametersDescription: {
          from: "string",
          to: "string"
        },
        handler: (data) => {
          store.dispatch(NftActions.financialReport.request(data));
        }
      },
      {
        id: "deleteNftProfile",
        description: "Delete NFT user profile.",
        parametersDescription: {
          id: "number"
        },
        handler: (data) => {
          store.dispatch(NftActions.deleteNftProfile.request(data));
        }
      },
      {
        id: "listNftUsers",
        description: "Get a list of NFT users",
        parametersDescription: {
          customer_id: "number"
        },
        handler: (data) => {
          store.dispatch(NftActions.listNftUsers.request(data));
        }
      },
      {
        id: "listNftCategories",
        description: "Get a list of NFT categories to be registered with NFT Collections.",
        handler: () => {
          store.dispatch(NftActions.listCategories.request());
        }
      },
      {
        id: "listNftCollections",
        description: "Get a list of NFT collections. Filters are optional.",
        handler: () => {
          store.dispatch(NftActions.listCollections.request({}));
        }
      },
      {
        id: "changeNftPlan",
        description: "Subscribe to a new NFT plan. id is the NFT User ID parameter.",
        parametersDescription: {
          id: "number",
          plans: "array"
        },
        handler: (data) => {
          store.dispatch(NftActions.changePlan.request(data));
        }
      },
      {
        id: "changeNftLikeStatus",
        description: "Like or dislike an NFT.",
        parametersDescription: {
          id: "number",
          liked_by_me: "boolean"
        },
        handler: (data) => {
          store.dispatch(NftActions.changeLikeStatus.request(data));
        }
      },
      {
        id: "listNfts",
        description: "Get a list of NFTs. Filters are optional.",
        handler: () => {
          store.dispatch(NftActions.listNfts.request({}));
        }
      },
      {
        id: "changeNftFollowStatus",
        description: "Follow or unfollow an NFT user. ",
        parametersDescription: {
          id: "number",
          followed_by_me: "boolean"
        },
        handler: (data) => {
          store.dispatch(NftActions.changeFollowStatus.request(data));
        }
      },
    ]
  },
]);