import React, { memo } from "react";
import { useStyles } from "./BankInfoItemStyles";
import { Box } from "@material-ui/core";
import cn from "classnames";
import { useThemeContext } from "../../theme";

export type BankInfoItemProps = {
  onPress: () => void;
  title: string;
  value: string;
};

const BankInfoItem: React.FC<BankInfoItemProps> = ({
  onPress,
  title,
  value,
}) => {
  const s = useStyles();
  const { theme } = useThemeContext();

  return (
    <Box onClick={onPress} className={cn(s.container)}>
      <p
        className={cn(s.text)}
        style={{ textTransform: "capitalize", fontSize: theme.metrics.x3 }}
      >
        {title.replace("_", " ").replace("_", " ").replace("_", " ")}:
      </p>
      <p
        className={cn(s.text)}
        style={{ color: theme.colors.yellowMain, textAlign: "right" }}
      >
        {value}
      </p>
    </Box>
  );
};

BankInfoItem.defaultProps = {};

const MemorizedComponent = memo(BankInfoItem);
export { MemorizedComponent as BankInfoItem };
