import styled from "styled-components";
import { templates } from "../../theme/templates";

export const Wrapper = styled.div`
  padding: 24px;
  background-color: ${(p) => p.theme.colors.black};
  @media (max-width: 700px) {
    padding: 24px 16px;
  }
  //Resize 75% 
  /* transform: scale(0.75);
  transform-origin: 0 0;
  width: 133.33%;
  height: 100vh;
  margin-bottom: -33.33vh; */
`;
export const TabsContainer = styled.div`
  ${templates.centerItems};
  justify-content: space-between;
  padding: 40px 70px;
  border: 1px solid #323232;
  border-radius: 24px;
  @media (max-width: 620px) {
    padding: 27px 16px;
  }
`;
export const GridContainer = styled.div`
  margin-top: 40px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  row-gap: 24px;
  column-gap: 24px;
  justify-content: flex-start;
  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(6, 1fr);
    row-gap: 24px;
    margin-top: 16px;
  }
`;
export const ImageContainer = styled.div`
  ${templates.centerContent};
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #565858;
  @media (max-width: 700px) {
    min-width: 30px;
    height: 30px;
  }
`;
export const ImageIcon = styled.img`
  @media (max-width: 700px) {
    scale: 0.6;
  }
`;
export const ImageCard = styled.img`
  width: 350px;
  border-radius: 18px;
  @media (max-width: 700px) {
    width: 210px;
   
  }
`;
export const SliderContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  column-gap: 20px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
export const BackButton = styled.img`
width: 40px;
height: 40px;
cursor: pointer;
position: absolute;
top: 10px;
left: 15px;
`;