import styled from "styled-components";
import { templates } from "../../../theme/templates";

import { text } from "../../../theme/text";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 23px;
  background-color: ${(p) => p.theme.colors.black};
  min-height: 107vh;
  @media (max-width: 900px) {
    padding: 15px 16px;
    min-height: calc(107vh + 30px);
  }
`;
export const TabsBar = styled.div`
  display: flex;
  column-gap: 5px;
  @media (max-width: 900px) {
    column-gap: 20px;
  }
`;
export const Tab = styled.div<{ active: boolean }>`
  ${templates.centerContent};
  padding: 13px 30px;
  border-radius: 29px;
  background-color: ${(p) => (p.active ? p.theme.colors.yellowMain : "black")};
  color: ${(p) => (p.active ? p.theme.colors.black : text.colors.lightGray)};
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  @media (max-width: 900px) {
    padding: 6px 11px 10px;
    font-size: 18px;
  }
`;
// main wrapper
export const ContentWrapper = styled.div`
  display: flex;
  // width
  column-gap: 24px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
  // background-color: ${(p) => 'green'};
`;
// left

//right
export const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(p) => p.theme.colors.subBackground};
  margin: 32px 0;
  @media (max-width: 700px) {
    margin: 16px 0;
  }
`;
