import { ChangeEvent, FC } from "react";
import { FileContainer } from "../../../../components/Modals/CreateTicket/CreateTicketStyles";
import { IconAdd } from "../../../Support/Chat/ChatStyles";

type Props = {
  image: any;
  setImage: any;
  id: any;
  name: any;
};

const ImagePicker: FC<Props> = ({ image, setImage, id, name }) => {
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const inputName = event.target.name;
    if (file) {
      setImage((prev: any) => ({ ...prev, [inputName]: file }));
    }
  };

  const handleClick = (fileName: string) => {
    const fileInput = document.getElementById(id) as HTMLInputElement;

    if (fileInput) {
      fileInput.click();
    }
  };
  return (
    <>
      <FileContainer
        style={{ height: "auto", minHeight: "350px" }}
        onClick={() => handleClick(id)}
      >
        {image ? (
          <img
            src={URL.createObjectURL(image)}
            alt="Selected file"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "12px",
            }}
          />
        ) : (
          <IconAdd src="/images/support/add.svg" />
        )}

        <input
          id={id}
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
          name={name}
        />
      </FileContainer>
    </>
  );
};
export default ImagePicker;
