import { useState } from "react";
import styled from "styled-components";
import { theme } from "../../../theme/default/theme";
import { Text } from "../../../components/Text/Text";

const Arrows = () => {
  return (
    <ArrowsWrapper>
      <Arrow src="/images/markets/sort-arrow.svg" />{" "}
      <Arrow
        style={{ transform: "rotate(180deg)" }}
        src="/images/markets/sort-arrow.svg"
      />
    </ArrowsWrapper>
  );
};

const MarketSort = () => {
  const [like, setLike] = useState(false);
  return (
    <Wrapper>
      <Container>
        <Text
          color={theme.colors.greySubText}
          size="14px"
          fontStyle={theme.fonts.f400}
          margin="2px 0 0 4px"
        >
          Market
        </Text>
        <Arrows />
      </Container>

      <Container>
        <Text
          color={theme.colors.greySubText}
          size="14px"
          fontStyle={theme.fonts.f400}
        >
          Last Price
        </Text>
        <Arrows />
      </Container>

      <Container>
        <Text
          color={theme.colors.greySubText}
          size="14px"
          fontStyle={theme.fonts.f400}
        >
          Change
        </Text>
        <Arrows />
      </Container>

      <Container>
        <Text
          color={theme.colors.greySubText}
          size="14px"
          fontStyle={theme.fonts.f400}
        >
          High
        </Text>
        <Arrows />
      </Container>

      <Container>
        <Text
          color={theme.colors.greySubText}
          size="14px"
          fontStyle={theme.fonts.f400}
        >
          Low
        </Text>
        <Arrows />
      </Container>

      <Container>
        <Text
          color={theme.colors.greySubText}
          size="14px"
          fontStyle={theme.fonts.f400}
        >
          24H Vol
        </Text>
        <Arrows />
      </Container>
    </Wrapper>
  );
};
export default MarketSort;
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  padding: 22px 0;
  border-bottom: 1px solid ${theme.colors.greySubTextDesc};
  align-items: center;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
`;
const ArrowsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  row-gap: 2px;
`;
const Arrow = styled.img``;
