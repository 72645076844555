import * as React from "react"

const SvgComponent = (props) => (
  <svg
    {...props}
    fill={props.color}
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21 8H9a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1Zm-1 12H10V10h10ZM6 15a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v2a1 1 0 0 1-2 0V4H4v10h1a1 1 0 0 1 1 1Z" />
  </svg>
)

export default SvgComponent