// Список токенов, курс которых мы получаем с coingecko
export const COINGECKO_COINS = {
  "bitcoin": {
    "id": "bitcoin",
    "symbol": "btc",
    "name": "Bitcoin",
    "image": "/files/crypto/BTC.png"
  },
  "ethereum": {
    "id": "ethereum",
    "symbol": "eth",
    "name": "Ethereum",
    "image": "/files/crypto/ETH.png"
  },
  "tether": {
    "id": "tether",
    "symbol": "usdt",
    "name": "Tether",
    "image": "/files/crypto/usdt.png"
  },
  "zedxion": {
    "id": "zedxion",
    "symbol": "zedxion",
    "name": "Zedxion",
    "image": "/files/crypto/zedxion.png"
  },
  "zedxion-usdz": {
    "id": "zedxion-usdz",
    "symbol": "usdz",
    "name": "USDZ", // "Zedxion USDZ"
    "image": "/files/crypto/zedxion.png"
  },
  "binancecoin": {
    "id": "binancecoin",
    "symbol": "bnb",
    "name": "BNB",
    "image": "/files/crypto/bnb.png"
  },
  "usd-coin": {
    "id": "usd-coin",
    "symbol": "usdc",
    "name": "USD Coin",
    "image": "/files/crypto/usdc.png"
  },
  "ripple": {
    "id": "ripple",
    "symbol": "xrp",
    "name": "XRP",
    "image": "/files/crypto/xrp.png"
  },
  "cardano": {
    "id": "cardano",
    "symbol": "ada",
    "name": "Cardano",
    "image": "/files/crypto/ada.png"
  },
  "staked-ether": {
    "id": "staked-ether",
    "symbol": "steth",
    "name": "Lido Staked Ether",
    "image": "/files/crypto/ETH.png"
  },
  "dogecoin": {
    "id": "dogecoin",
    "symbol": "doge",
    "name": "Dogecoin",
    "image": "/files/crypto/doge.png"
  },
  "solana": {
    "id": "solana",
    "symbol": "sol",
    "name": "Solana",
    "image": "/files/crypto/sol.png"
  },
  "matic-network": {
    "id": "matic-network",
    "symbol": "matic",
    "name": "Polygon",
    "image": "/files/crypto/matic.png"
  },
  "litecoin": {
    "id": "litecoin",
    "symbol": "ltc",
    "name": 'LTC', // "Litecoin"
    "image": "/files/crypto/ltc.png"
  },
  "polkadot": {
    "id": "polkadot",
    "symbol": "dot",
    "name": "Polkadot",
    "image": "/files/crypto/dot.png"
  },
  "tron": {
    "id": "tron",
    "symbol": "trx",
    "name": "TRON",
    "image": "/files/crypto/trx.png"
  },
  "binance-usd": {
    "id": "binance-usd",
    "symbol": "busd",
    "name": "BUSD", // "Binance USD" actually from coingecko api
    "image": "/files/crypto/busd.png"
  },
  "shiba-inu": {
    "id": "shiba-inu",
    "symbol": "shib",
    "name": "Shiba Inu",
    "image": "/files/crypto/shib.png"
  },
  "avalanche-2": {
    "id": "avalanche-2",
    "symbol": "avax",
    "name": "Avalanche",
    "image": "/files/crypto/avax.png"
  },
  "dai": {
    "id": "dai",
    "symbol": "dai",
    "name": "Dai",
    "image": "/files/crypto/dai.png"
  },
  "wrapped-bitcoin": {
    "id": "wrapped-bitcoin",
    "symbol": "wbtc",
    "name": "Wrapped Bitcoin",
    "image": "/files/crypto/wbtc.png"
  },
  "uniswap": {
    "id": "uniswap",
    "symbol": "uni",
    "name": "Uniswap",
    "image": "/files/crypto/uni.png"
  },
} as {[key: string]: {id: string, symbol: string, name: string, image: string}};