import { useState } from "react";
import Chat from "./Chat";
import SideBar from "./SideBar";
import { LoaderPosition, Wrapper } from "./SupportStyles";
import TicketModal from "../../components/Modals/CreateTicket";
import { useTicketsList } from "./hooks/useTicketsList";
import { CircularProgress } from "@material-ui/core";
import LoaderRing from "../../helpers/Loader/LoaderRing";
import { useThemeContext } from "../../theme";
import useWindowWidth from "../../hooks/useWindowWidth";

const SupportPage = () => {
  const [id, setId] = useState(0);
  const [modalIsActive, setModalIsActive] = useState(false);
  const [pageStep, setPageStep] = useState("tickets");
  const { data } = useTicketsList();
  const { theme } = useThemeContext();
  const windowWidth = useWindowWidth();

  return (
    <>
      <Wrapper>
        {data ? (
          <>
            {windowWidth <= 1030 && pageStep === "tickets" && (
              <SideBar
                data={data}
                setId={setId}
                setModal={setModalIsActive}
                setPageStep={setPageStep}
              />
            )}

            {windowWidth > 1030 && (
              <SideBar
                data={data}
                setId={setId}
                setModal={setModalIsActive}
                setPageStep={setPageStep}
              />
            )}

            {windowWidth <= 1030 && pageStep === "chat" && (
              <Chat data={data} id={id} setPageStep={setPageStep} />
            )}

            {windowWidth > 1030 && (
              <Chat data={data} id={id} setPageStep={setPageStep} />
            )}
          </>
        ) : (
          <LoaderPosition>
            <LoaderRing size="70px" color={theme.colors.yellowMain} />
          </LoaderPosition>
        )}
      </Wrapper>
      {modalIsActive && <TicketModal setModal={setModalIsActive} />}
    </>
  );
};
export default SupportPage;
