import React, { useEffect, useState } from "react";
import { useThemeContext } from "../../../theme";
import {
  ContainerColumn,
  ContainerRow,
  InputsWrapper,
  RowWrapper,
  Wrapper,
} from "./CreateNFTStyles";
import DatePicker from "react-datepicker";
import { Text } from "../../../components/Text/Text";
import Input from "./components/Input";
import { Btn } from "../../../components/Button/Btn";
import Cheker from "./components/Cheker";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { DropdownItem } from "../../Payments/MethodOptional/Components";
import { useNftCategoryDropdownData } from "../../../hooks/useNftCategoryDropdownData";
import { useHideErrors } from "../../../hooks/useHideErrors";
import {
  validateFloat,
  validateLinkAllowEmpty,
  validateNftDescription,
  validateNftTitle,
} from "../../../helpers/validationHelper";
import { prepareImageName } from "../../../helpers/formatHelpers";
import { showToast } from "../../../helpers/alertService";
import { getTagsFromStringSeparatedWithCommas } from "../../../helpers/strings";
import { NftActions } from "../../../logic/nft/NftRedux";
import { useDispatch, useSelector } from "react-redux";
import { BlockchainType, NftPlan } from "../../../types/types";
import { userIdSelector } from "../../../logic/user/UserSelectors";
import { useDependentValue } from "../../../helpers/customHooks";
import moment from "moment";
import i18next from "i18next";
import { CryptoActions } from "../../../logic/crypto/CryptoRedux";
import { CONFIG } from "../../../config";
import { useAccountDropdownData } from "../../../hooks/useAccountDropdownData";
import { useMyNftCollectionsDropdownData } from "../../../hooks/useMyNftCollectionsDropdownData";
import { useSmartContractsDropdownData } from "../../../hooks/useSmartContractsDropdownData";
import { useCurrencyDropdownData } from "../../../hooks/useCurrencyDropdownData";
import { nftSelector } from "../../../logic/nft/NftSelectors";
import { useLocation } from "react-router-dom";
import { IconAdd } from "../../Support/Chat/ChatStyles";

import "react-datepicker/dist/react-datepicker.css";

const NFT_BLOCKCHAIN_DROPDOWN_DATA: DropdownItem[] = [
  { title: "Ethereum", value: "ethereum", id: "ethereum" },
  {
    title: "Binance Smart Chain",
    value: "binance_smart_chain",
    id: "binance_smart_chain",
  },
  { title: "Tron", value: "tron", id: "tron" },
];
const ALLOWED_CURRENCIES_CODES = ["ETH", "BNB", "TRX", "USDT", "BTC"].concat(CONFIG.RUN_MODE === "PRODUCTION" ? ["ZEDXION", "USDZ"] : []);

const MIN_BID_EXPIRY_DATE = moment().add(1, "days").toDate();

const CreateNFT = () => {
  const { theme } = useThemeContext();
  const dispatch = useDispatch();
  const { t } = i18next;
  const userId = useSelector(userIdSelector);
  const windowWidth = useWindowWidth();
  const { hideErrors, setHideErrors } = useHideErrors();
  const location = useLocation();
  const titlesMargin = windowWidth < 700 ? "15px 0 -14px 0" : "45px 0 -14px 0";

  const {
    nftProfile,
    createNft: { fetching: fetchingCreateNft },
    editNft: { fetching: fetchingEditNft },
  } = useSelector(nftSelector);

  const [images, setImages] = useState<{
    file: File | null;
    poster: File | null;
  }>({
    file: null,
    poster: null,
  });

  const [radioCheck, setRadioCheck] = useState("Use an existing contract");
  const [check, setCheck] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [newTagValue, setNewTagValue] = useState("");
  const [tagError, setTagError] = useState(false);
  const { account, accountDropdownData, setAccount } = useAccountDropdownData();

  const { categoryDropdownData, category, setCategory } =
    useNftCategoryDropdownData();
  const { collectionsDropdownData, collection, setCollection } =
    useMyNftCollectionsDropdownData();
  const { contractsDropdownData, contract, setContract } =
    useSmartContractsDropdownData();
  const { currencyDropdownData, currency, setCurrency, setCurrencyByValue } =
    useCurrencyDropdownData(ALLOWED_CURRENCIES_CODES);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [contractName, setContractName] = useState("");
  const [contractSymbol, setContractSymbol] = useState("");
  const [price, setPrice] = useState("");
  const [royalties, setRoyalties] = useState("");

  const [inputsData, setInputsData] = useState({
    category: category,
    collection: collection,
    blockchain: { title: "Ethereum", value: "ethereum" },
    contract: contract,
    properties: [{ title: "", value: "" }],
    tags: [],
    currency: currency,
    checkes: []
  });

  const [bidExpiryDate, setBidExpiryDate] = useState(MIN_BID_EXPIRY_DATE);

  const handlePropInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    propertyName: string
  ) => {
    setInputsData((prev) => {
      const updatedProperties = [...prev.properties];
      const propertyToUpdate = updatedProperties[index];

      if (propertyName === "type") {
        propertyToUpdate.title = e.target.value;
      } else if (propertyName === "value") {
        propertyToUpdate.value = e.target.value;
      }

      return {
        ...prev,
        properties: updatedProperties,
      };
    });
  };

  const handleSelect = (name: string, value: any) => {
    setInputsData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectAcc = (name: string, value: any) => {
    setAccount(value);
  };

  const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === "Enter" &&
      newTagValue.trim() !== "" &&
      currentPlan?.tag_limit !== undefined
    ) {
      if (!tags.includes(newTagValue)) {
        if (tags.length < currentPlan.tag_limit) {
          setTags((prevTags) => [...prevTags, newTagValue]);
          setNewTagValue("");
        } else {
          setTagError(true);
        }
      }
    }
  };
  const handleNewTagValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTagValue(e.target.value);
  };
  const deleteTag = (index: number) => {
    const updatedTags = tags.filter((_, i) => i !== index);
    setTags(updatedTags);
    setTagError(false);
  };

  const addPropField = () => {
    setInputsData((prev) => ({
      ...prev,
      properties: [...prev.properties, { title: "", value: "" }],
    }));
  };
  const removeLastPropField = (index: number) => {
    setInputsData((prev) => {
      const updatedProperties = [...prev.properties];
      updatedProperties.splice(index, 1);
      return {
        ...prev,
        properties: updatedProperties,
      };
    });
  };

  const currentPlan = useDependentValue<NftPlan | undefined>(() => {
    if (!nftProfile) return undefined;

    return nftProfile.plans.find((p) => p.status === "Active");
  }, [nftProfile]);
  const royaltiesLimit = useDependentValue<number>(() => {
    if (!currentPlan) return 10;

    return Number(currentPlan.royalty_limit.replace(/\D/g, ""));
  }, [currentPlan]);

  const auctionLengthLimit = useDependentValue<number>(() => {
    if (!currentPlan) return 30;

    return currentPlan.auction_length;
  }, [currentPlan]);

  const onPressCreate = () => {
    if (!nftProfile) {
      return;
    }

    if (
      validateNftTitle(title) &&
      validateNftDescription(description) &&
      validateLinkAllowEmpty(link) &&
      validateFloat(price) &&
      validateFloat(royalties)
    ) {
      const tagsPrepared = getTagsFromStringSeparatedWithCommas(
        inputsData.tags.join(",")
      );
      if (tagsPrepared.length > currentPlan!.tag_limit) {
        showToast({
          title: t("createNftScreen.tagsLimitExceeded"),
          info: `${t("createNftScreen.maxAmountOfTags")}: ${
            currentPlan!.tag_limit
          }`,
          type: "warn",
        });
        return;
      }
      if (Number(royalties) > royaltiesLimit) {
        showToast({
          title: t("createNftScreen.royaltyLimitExceeded"),
          info: `${t("createNftScreen.maxRoyalties")}: ${
            currentPlan!.royalty_limit
          }`,
          type: "warn",
        });
        return;
      }

      const fData = new FormData();
      if (images.file) {
        const fileBlob = new Blob([images.file], {
          type: "image/jpeg",
        });
        fData.append(
          "file",
          fileBlob,
          prepareImageName(images.file?.name as string)
        );
      }
      if (images.poster) {
        const posterBlob = new Blob([images.poster], {
          type: "image/jpeg",
        });
        fData.append(
          "poster",
          posterBlob,
          prepareImageName(images.poster?.name as string)
        );
      }

      dispatch(
        NftActions.createNft.request({
          nft_user_id: nftProfile.id,
          collection_id: Number(inputsData.collection.value),
          contract_id:
            radioCheck === "Use an existing contract"
              ? (inputsData.contract.value as number)
              : 0,
          title,
          description,
          category: inputsData.category.value as string,
          link,
          fData,
          properties: inputsData.properties,
          tags: tagsPrepared,
          blockchain: inputsData.blockchain.value as BlockchainType,
          account_id: account.id,
          currency_id: inputsData.currency.value as number,
          price: Number(price),
          instant_sale: check.includes("Instant Sale"),
          allow_bid: check.includes("Receive Bids"),
          bid_expiry: moment(bidExpiryDate).format("YYYY-MM-DD"),
          unlock_on_purchase: check.includes("Unlock on Purchase"),
          royalties: Number(royalties),
          new_contract_name:
            radioCheck === "Use an existing contract"
              ? ""
              : contractName,
          new_contract_symbol:
            radioCheck === "Use an existing contract"
              ? ""
              : contractSymbol,
        })
      );
    } else {
      setHideErrors(false);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const onPressEdit = () => {
    const fData = new FormData();
    if (images.poster) {
      const posterBlob = new Blob([images.poster], {
        type: "image/jpeg",
      });
      fData.append(
        "poster",
        posterBlob,
        prepareImageName(images.poster?.name as string)
      );
    }

    const tagsPrepared = getTagsFromStringSeparatedWithCommas(
      inputsData.tags.join(",")
    );

    dispatch(
      NftActions.editNft.request({
        id: location.state.id,
        collection_id: collection.value as number,
        title,
        description,
        category: category.value as string,
        link,
        fData: images.poster ? fData : undefined,
        posterLink: images.poster ? undefined : location.state.nft_poster,
        properties: inputsData.properties,
        tags: tagsPrepared,
        currency_id: inputsData.currency.value as number,
        price: Number(price),
        instant_sale: check.includes("Instant Sale"),
        allow_bid: check.includes("Receive Bids"),
        bid_expiry: moment(bidExpiryDate).format("YYYY-MM-DD"),
        unlock_on_purchase: check.includes("Unlock on Purchase"),
        royalties: Number(royalties),
        status: "Pending",
      })
    );
  };

  useEffect(() => {
    dispatch(
      NftActions.listNftUsers.request({
        customer_id: userId,
        fetchAdditionalInfoOnSuccess: true,
      })
    );

    dispatch(NftActions.listCategories.request());

    dispatch(
      NftActions.listCollections.request({
        customer_id: userId,
      })
    );

    dispatch(
      CryptoActions.getAccountsList.request({
        total_currency: CONFIG.ZED_API_TOTAL_CURRENCY,
      })
    );
  }, [userId]);

  useEffect(() => {
    //@ts-ignore
    setInputsData((prev) => ({
      ...prev,
      collection: collection,
      currency: currency,
      checkes: check,
      tags: tags,
    }));
  }, [check, radioCheck, tags, collection, currency]);

  useEffect(() => {
    if (location.state) {
      // console.log('here', location.state)
      setTitle(location.state.title);
      setDescription(location.state.description);
      setPrice(location.state.price);
      setRoyalties(location.state.royalties.toString());
      setInputsData((prev) => ({
        ...prev,
        category: {
          title: location.state.category,
          value: location.state.category,
        },
        collection: {
          title: location.state.collection,
          value: location.state.id,
        },
        properties: location.state.properties,
      }));
      setTags(location.state.tags.map((item: { tag: any }) => item.tag));
      setCheck((prev) => [
        ...prev,
        location.state.instant_sale && "Instant Sale",
      ]);
      setCheck((prev) => [
        ...prev,
        location.state.unlock_on_purchase && "Unlock on Purchase",
      ]);
      setCheck((prev) => [...prev, location.state.allow_bid && "Receive Bids"]);
      setCurrencyByValue(location.state.currency_id);
      if (location.state.allow_bid)
        setBidExpiryDate(moment(location.state.bid_expiry).toDate());
    }
  }, [location.state]);

  return (
    <Wrapper>
      <Text
        color={theme.colors.white}
        size="51.1px"
        fontStyle={theme.fonts.f600}
      >
        {t(location.state ? "Edit nft" : "createNftScreen.createNewNftAndAdd")}
      </Text>
      <InputsWrapper>
        <ContainerRow>
          {!location.state && (
            <ContainerColumn>
              <Input
                title={t("createNftScreen.nftFile")}
                type={"select file"}
                selectedImage={images.file}
                setSelectedImage={setImages}
                name="file"
                file
                error={hideErrors || !!images.file}
              />
              <Text
                color={theme.colors.greySubText}
                size="16px"
                fontStyle={theme.fonts.f500}
                margin="10px 0 0 0"
              >
                {t("createNftScreen.fileTypesSupported")}
              </Text>
            </ContainerColumn>
          )}

          <ContainerColumn>
            <Input
              title={`${t("createNftScreen.poster")} (300*300px)`}
              type={"select file"}
              selectedImage={images.poster}
              setSelectedImage={setImages}
              name="poster"
              file
              error={true}
            />
            <Text
              color={theme.colors.greySubText}
              size="16px"
              fontStyle={theme.fonts.f500}
              margin="10px 0 0 0"
            >
              {t("createNftScreen.choosePosterIf")}
            </Text>
          </ContainerColumn>
        </ContainerRow>
        <Input
          type="input"
          title={t("createNftScreen.title")}
          handleInput={(e: any) => setTitle(e.target.value)}
          name="title"
          value={title}
          error={hideErrors || validateNftTitle(title)}
        />
        <Input
          type="input"
          title={t("createNftScreen.description")}
          name="description"
          handleInput={(e: any) => setDescription(e.target.value)}
          value={description}
          error={hideErrors || validateNftDescription(description)}
        />

        <Input
          type="select"
          title={t("createNftCollectionScreen.category")}
          name="category"
          handleInput={handleSelect}
          value={inputsData.category.title}
          items={categoryDropdownData}
          error={hideErrors || inputsData.category.title !== "Category:"}
        />
        <Input
          type="input"
          title={t("createNftScreen.link")}
          name="link"
          handleInput={(e: any) => setLink(e.target.value)}
          value={link}
          error={
            hideErrors ||
            validateLinkAllowEmpty(link) ||
            link.length !== 0
          }
        />
        {!location.state && (
          <ContainerRow>
            <Input
              title={t("createNftScreen.collection")}
              type={"select"}
              name="collection"
              handleInput={handleSelect}
              value={collection.title}
              items={collectionsDropdownData}
              error={hideErrors || inputsData.category.title !== "Collection:"}
            />

            <Input
              title={t("blockchain")}
              type={"select"}
              name="blockchain"
              handleInput={handleSelect}
              value={inputsData.blockchain.title}
              items={NFT_BLOCKCHAIN_DROPDOWN_DATA}
              error={hideErrors || inputsData.category.title !== "Blockchain:"}
            />
          </ContainerRow>
        )}

        {!location.state && (
          <>
            {" "}
            <Text
              color={theme.colors.white}
              size="24px "
              fontStyle={theme.fonts.f600}
              margin={titlesMargin}
            >
              {t("createNftScreen.contract")}
            </Text>
            <Cheker
              id={0}
              type={"circle"}
              checked={radioCheck === "Create a new contract"}
              text={"Create a new contract"}
              title={t("createNftScreen.createNewContract")}
              setCheck={setRadioCheck}
            />
            <Cheker
              id={1}
              type={"circle"}
              checked={radioCheck === "Use an existing contract"}
              text={"Use an existing contract"}
              title={t("createNftScreen.useAnExistingContract")}
              setCheck={setRadioCheck}
            />
            {radioCheck === "Use an existing contract" ? (
              <Input
                title={t("createNftScreen.contract")}
                name="contract"
                value={inputsData.contract.title}
                handleInput={handleSelect}
                items={contractsDropdownData}
                type={"select"}
                error={true}
              />
            ) : (
              <ContainerRow>
                <Input
                  title={t("createNftScreen.contractName")}
                  type={"input"}
                  name="contractName"
                  handleInput={(e: any) => setContractName(e.target.value)}
                  value={contractName}
                  error={true}
                />

                <Input
                  title={t("createNftScreen.contractSymbol")}
                  type={"input"}
                  name="contractSymbol"
                  handleInput={(e: any) => setContractSymbol(e.target.value)}
                  value={contractSymbol}
                  error={true}
                />
              </ContainerRow>
            )}
          </>
        )}

        <Text
          color={theme.colors.white}
          size="24px "
          fontStyle={theme.fonts.f600}
          margin={titlesMargin}
        >
          {t("createNftScreen.properties")}
        </Text>
        {inputsData.properties.map((el, index) => (
          <RowWrapper key={index}>
            <ContainerRow style={{ alignItems: "center", width: "100%" }}>
              <Input
                title={t("createNftScreen.type")}
                type={"input"}
                name="type"
                propIndex={index}
                value={el.title}
                handleInput={handlePropInput}
                error={true}
              />

              <Input
                title={t("createNftScreen.value")}
                type={"input"}
                name="value"
                propIndex={index}
                value={el.value}
                handleInput={handlePropInput}
                error={true}
              />
            </ContainerRow>
            {index > 0 && (
              <IconAdd
                style={{ margin: "25px 0 0 5px" }}
                onClick={() => removeLastPropField(index)}
                src="/images/support/delete.svg"
              />
            )}
          </RowWrapper>
        ))}

        <IconAdd
          style={{ margin: "-10px auto 0" }}
          onClick={() => addPropField()}
          src="/images/support/add_color.svg"
        />

        <Text
          color={theme.colors.white}
          size="24px "
          fontStyle={theme.fonts.f600}
          margin={titlesMargin}
        >
          {t("createNftScreen.tags")}
        </Text>
        <Input
          title={t("createNftScreen.type")}
          type={"input"}
          name="tags"
          tags={tags}
          value={newTagValue}
          handleEnterKey={(e: any) => {
            if (e.target.value.trim() !== "") {
              handleEnterKey(e);
            }
          }}
          deleteTag={deleteTag}
          handleInput={handleNewTagValueChange}
          error={!tagError}
        />
        <ContainerRow>
          <Text
            color={theme.colors.greySubText}
            size="16px"
            fontStyle={theme.fonts.f500}
            margin="-14px 0 0 0"
          >
            {t("createNftScreen.pressEnter")}
          </Text>
          {tagError && (
            <Text
              color={theme.colors.red}
              size="16px"
              fontStyle={theme.fonts.f500}
              margin="-14px 0 0 0"
            >
              ({t("createNftScreen.max")} {currentPlan?.tag_limit}{" "}
              {t("createNftScreen.tagsLowcase")})
            </Text>
          )}
        </ContainerRow>
        {!location.state && (
          <Input
            title={t("createNftScreen.wallet")}
            type={"select"}
            name="wallet"
            handleInput={handleSelectAcc}
            value={account.title}
            items={accountDropdownData}
            error={true}
          />
        )}

        <ContainerRow>
          <Input
            title={t("createNftScreen.price")}
            type={"input"}
            name="price"
            handleInput={(e: any) => setPrice(e.target.value)}
            value={price}
            error={
              hideErrors ||
              (price === ""
                ? false
                : validateFloat(price))
            }
          />

          <Input
            title={t("createNftScreen.currency")}
            type={"select"}
            name="currency"
            handleInput={handleSelect}
            value={inputsData.currency.title}
            items={currencyDropdownData}
            error={true}
          />
        </ContainerRow>
        <Input
          title={`${t("createNftScreen.royalties")} (%)`}
          type={"input"}
          name="royalties"
          handleInput={(e: any) => setRoyalties(e.target.value)}
          value={royalties}
          error={
            hideErrors ||
            (royalties === ""
              ? false
              : validateFloat(royalties))
          }
        />
        <Cheker
          id={0}
          type={"square"}
          check={check}
          checked={check.includes("Instant Sale")}
          text={"Instant Sale"}
          title={t("createNftScreen.instantSale")}
          setCheck={setCheck}
        />
        <Cheker
          id={1}
          type={"square"}
          check={check}
          checked={check.includes("Unlock on Purchase")}
          title={t("createNftScreen.unlockOnPurchase")}
          text={"Unlock on Purchase"}
          setCheck={setCheck}
        />
        <Cheker
          id={2}
          type={"square"}
          check={check}
          checked={check.includes("Receive Bids")}
          title={t("createNftScreen.receiveBids")}
          text={"Receive Bids"}
          setCheck={setCheck}
        />

        {check.includes("Receive Bids") &&
          <ContainerRow>
            <DatePicker 
              selected={bidExpiryDate}
              onChange={(date) => setBidExpiryDate(date as any)}
              includeDateIntervals={[{start: MIN_BID_EXPIRY_DATE, end: moment().add(auctionLengthLimit, "days").toDate()}]}
              placeholderText={"Please select when bid should end"}
              withPortal={true}
              showIcon={true}
            />
          </ContainerRow>
        }

        <Btn
          label={t(location.state ? "buttons.update" : "buttons.create")}
          type={"authPage"}
          buttonActive={true}
          spinner={fetchingCreateNft || fetchingEditNft}
          func={() => (location.state ? onPressEdit() : onPressCreate())}
        />
      </InputsWrapper>
    </Wrapper>
  );
};

export default CreateNFT;