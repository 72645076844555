import { showToast } from "./alertService";
import i18next from "i18next";

export const copyToClipboard = (s: string) => {
  navigator.clipboard.writeText(s);
  showToast({
    type: "success",
    title: i18next.t("success"),
    info: i18next.t("CopiedToClipboard")
  });
}