import styled from "styled-components";


export const LogoContainer = styled.div`
width: 160px;
height: 160px;
border: 2px solid ${p => p.theme.colors.yellowMain};
border-radius: 12px;
`;
export const CoverContainer = styled.div`
width: 180px;
height: 160px;
border: 2px solid ${p => p.theme.colors.yellowMain};
border-radius: 12px;
`;
export const BannerContainer = styled.div`
width: 70%;
height: 160px;
border: 2px solid ${p => p.theme.colors.yellowMain};
border-radius: 12px;
`;