import i18next from "i18next";
import { Text } from "../../../components/Text/Text";
import { useThemeContext } from "../../../theme";
import { Btn } from "../../../components/Button/Btn";
import { Input, Wrapper } from "./KysStyles";
import { useEffect, useState } from "react";
import ImagePicker from "./ImagePicker/ImagePicker";
import SelectInput from "./Select/Select";
import {
  FCF_ADDRESS_LABELS_ARRAY,
  FCF_ADDRESS_TYPES_ARRAY,
  FCF_ADDRESS_TYPE_TABLE,
  FCF_ID_LABELS_ARRAY,
  FCF_ID_LANGUAGES_ARRAY,
  FCF_ID_TABLE,
  FCF_ID_TYPES_ARRAY,
  FcfAddressType,
  FcfIdType,
} from "../../../types/types";
import {
  validateDateYYYYMMDD,
  validateIdNumber,
} from "../../../helpers/validationHelper";
import { handleInputWithDateMask } from "./helpers";
import { prepareImageName } from "../../../helpers/formatHelpers";
import { useDispatch, useSelector } from "react-redux";
import { CardsActions } from "../../../logic/cards/CardsRedux";
import { cardsSelector } from "../../../logic/cards/CardsSelectors";
import { showToast } from "../../../helpers/alertService";
import { BackButton } from "../CardsStyles";
import { useNavigate } from "react-router-dom";

export const DROPDOWN_DATA_ID_DOCUMENT_TYPE = FCF_ID_TYPES_ARRAY.map(
  (t: any, index: any) => ({
    label: FCF_ID_LABELS_ARRAY[index],
    value: t,
    id: t,
  })
);

export const DROPDOWN_DATA_LANGUAGE = FCF_ID_LANGUAGES_ARRAY.map(
  (lan: any) => ({
    label: lan,
    value: lan,
    id: lan,
  })
);

export const DROPDOWN_DATA_ADDRESS_TYPE = FCF_ADDRESS_TYPES_ARRAY.map(
  (add: any, index: any) => ({
    label: FCF_ADDRESS_LABELS_ARRAY[index],
    value: add,
    id: add,
  })
);

const Kyc = ({ setStep }: any) => {
  const { t } = i18next;
  const navigate = useNavigate();
  const {
    submitKyc: { fetching: fetchingSubmitKyc },
  } = useSelector(cardsSelector);
  const dispatch = useDispatch();
  const { theme } = useThemeContext();
  //Step
  const [kycStep, setKycStep] = useState(0);
  //ERRORS
  const [hideError, setHideError] = useState(true);
  //Language
  const [viewLangs, setViewLangs] = useState(false);
  const [lang, setLang] = useState(DROPDOWN_DATA_LANGUAGE[0]);
  //ID
  const [viewId, setViewId] = useState(false);
  const [id, setId] = useState(DROPDOWN_DATA_ID_DOCUMENT_TYPE[0]);
  //Adress
  const [viewAdressType, setViewAddressType] = useState(false);
  const [adressType, setAddressType] = useState(DROPDOWN_DATA_ADDRESS_TYPE[0]);
  //Images
  const [selectedFiles, setSelectedFiles] = useState<{
    front: File | null;
    back: File | null;
    addressFront: File | null;
    addressBack: File | null;
  }>({
    front: null,
    back: null,
    addressFront: null,
    addressBack: null,
  });
  //Inputs
  const [inputsData, setInputsData] = useState({
    idNumber: "",
    idIssueDate: "",
    idExpiryDate: "",
    adressIssueDate: "",
  });

  const handleInputs = (e: any) => {
    setInputsData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onPress = () => {
    if (
      validateIdNumber(inputsData.idNumber) &&
      validateDateYYYYMMDD(inputsData.idIssueDate) &&
      validateDateYYYYMMDD(inputsData.idExpiryDate) &&
      selectedFiles.front &&
      selectedFiles.back
    ) {
      setKycStep(1);
      setHideError(true);
    } else {
      if (!selectedFiles.front || !selectedFiles.back) {
        showToast({
          type: "error",
          title: "",
          info: t("kycScreen.allDocumentsMustBeUploaded"),
        });
      }
      setHideError(false);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  const confirm = () => {
    if (
      validateDateYYYYMMDD(inputsData.adressIssueDate) &&
      selectedFiles.addressBack &&
      selectedFiles.addressFront
    ) {
      const fData = new FormData();
      if (selectedFiles.front) {
        fData.append(
          "kyc_id_front",
          selectedFiles.front,
          prepareImageName(selectedFiles.front?.name as string)
        );
      }

      if (selectedFiles.back) {
        const kycIdBackBlob = new Blob([selectedFiles.back], {
          type: "image/jpeg",
        });
        fData.append(
          "kyc_id_back",
          kycIdBackBlob,
          prepareImageName(selectedFiles.back?.name as string)
        );
      }

      if (selectedFiles.addressFront) {
        const kycAddressFrontBlob = new Blob([selectedFiles.addressFront], {
          type: "image/jpeg",
        });
        fData.append(
          "kyc_address_front",
          kycAddressFrontBlob,
          prepareImageName(selectedFiles.addressFront?.name as string)
        );
      }

      if (selectedFiles.addressBack) {
        const kycAddressBackBlob = new Blob([selectedFiles.addressBack], {
          type: "image/jpeg",
        });
        fData.append(
          "kyc_address_back",
          kycAddressBackBlob,
          prepareImageName(selectedFiles.addressBack?.name as string)
        );
      }

      dispatch(
        CardsActions.submitKyc.request({
          id: {
            id: FCF_ID_TABLE[id.value],
            idNumber: inputsData.idNumber,
            idExpDate: inputsData.idExpiryDate.replace(/\//g, "-"),
            idIssueDate: inputsData.adressIssueDate.replace(/\//g, "-"),
            idType: adressType.value as FcfIdType,
            // @ts-ignore
            idDocLan: lang.value,
            front_side: "true",
            back_side: selectedFiles.back ? "true" : "",
          },
          address: {
            id: FCF_ADDRESS_TYPE_TABLE[adressType.value],
            address_TypeId: adressType.value as FcfAddressType,
            address_IssuedDate: inputsData.adressIssueDate.replace(/\//g, "-"),
            address_LangId: "English",
            front_side: "true",
            back_side: selectedFiles.addressBack ? "true" : "",
          },

          fData,
        })
      );

      showToast({
        type: "success",
        title: "",
        info: t("kycScreen.cardRequestSent"),
      });
      setTimeout(() => navigate("/main"), 2000);
    } else {
      setHideError(false);
      showToast({
        type: "error",
        title: "",
        info: t("kycScreen.allDocumentsMustBeUploaded"),
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  //Скролл вверх при изменении *kycStep*
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [kycStep, hideError]);

  return (
    <Wrapper>
      {kycStep === 1 && (
        <BackButton
          src="/images/support/back_arrow.svg"
          onClick={() => setKycStep(0)}
        />
      )}

      {kycStep === 0 && (
        <>
          <Text
            color={theme.colors.white}
            size="21px"
            fontStyle={theme.fonts.f600}
          >
            {t("kycScreen.submitYourDocsDesc")}
          </Text>
          <Text
            color={theme.colors.red}
            size="21px"
            fontStyle={theme.fonts.f500}
          >
            {t("kycScreen.englishReminder")}
          </Text>
          <Text
            color={theme.colors.white}
            size="21px"
            fontStyle={theme.fonts.f600}
            margin="30px 0 10px 0"
          >
            {t("kycScreen.submitYourDocsDesc")}
            idDocument
          </Text>
          {/* SELECT ID TYPE */}
          <SelectInput
            view={viewId}
            setView={setViewId}
            items={DROPDOWN_DATA_ID_DOCUMENT_TYPE}
            value={id}
            setValue={setId}
          />
          {/* INPUT ID NUMBER*/}
          <Input
            placeholder="ID Number"
            value={inputsData.idNumber}
            error={hideError || validateIdNumber(inputsData.idNumber)}
            name="idNumber"
            onChange={handleInputs}
          />
          {/* INPUT */}
          <Input
            placeholder="yyyy/mm/dd"
            value={inputsData.idIssueDate}
            error={hideError || validateDateYYYYMMDD(inputsData.idIssueDate)}
            name="idIssueDate"
            onChange={(e) => handleInputWithDateMask(e, setInputsData)}
          />
          <Text
            color={theme.colors.white}
            margin="0 auto"
            fontStyle={theme.fonts.f600}
          >
            {t("kycScreen.issueDate")}
          </Text>
          {/* INPUT */}
          <Input
            placeholder="yyyy/mm/dd"
            value={inputsData.idExpiryDate}
            error={hideError || validateDateYYYYMMDD(inputsData.idExpiryDate)}
            name="idExpiryDate"
            onChange={(e) => handleInputWithDateMask(e, setInputsData)}
          />
          <Text
            color={theme.colors.white}
            margin="0 auto"
            fontStyle={theme.fonts.f600}
          >
            {t("kycScreen.expiryDate")}
          </Text>
          <Text
            color={theme.colors.white}
            size="21px"
            fontStyle={theme.fonts.f600}
            margin="30px 0 10px 0"
          >
            {t("kycScreen.language")}
          </Text>
          <SelectInput
            view={viewLangs}
            setView={setViewLangs}
            items={DROPDOWN_DATA_LANGUAGE}
            value={lang}
            setValue={setLang}
          />

          <Text
            color={theme.colors.white}
            size="21px"
            fontStyle={theme.fonts.f600}
            margin="30px 0 10px 0"
          >
            {t("kycScreen.frontSide")}
          </Text>
          <ImagePicker
            image={selectedFiles.front}
            setImage={setSelectedFiles}
            name={"front"}
            id="fileInputFront"
          />
          <Text
            color={theme.colors.white}
            size="21px"
            fontStyle={theme.fonts.f600}
            margin="30px 0 10px 0"
          >
            {t("kycScreen.backSide")}
          </Text>
          <ImagePicker
            image={selectedFiles.back}
            setImage={setSelectedFiles}
            name={"back"}
            id="fileInputBack"
          />
          <Btn
            label={"Confirm"}
            type={"verification"}
            buttonActive={true}
            func={onPress}
          />
        </>
      )}
      {kycStep === 1 && (
        <>
          <Text
            color={theme.colors.white}
            size="21px"
            fontStyle={theme.fonts.f600}
          >
            {t("kycScreen.proofOfAddress")}
          </Text>
          <Text
            color={theme.colors.red}
            size="21px"
            fontStyle={theme.fonts.f500}
          >
            {t("kycScreen.mustBeEnglish")}
          </Text>
          <Text
            color={theme.colors.white}
            size="21px"
            fontStyle={theme.fonts.f600}
            margin="30px 0 10px 0"
          >
            {t("kycScreen.addressType")}
          </Text>
          {/* SELECT */}
          <SelectInput
            view={viewAdressType}
            setView={setViewAddressType}
            items={DROPDOWN_DATA_ADDRESS_TYPE}
            value={adressType}
            setValue={setAddressType}
          />
          {/* INPUT */}
          <Input
            placeholder="yyyy/mm/dd"
            value={inputsData.adressIssueDate}
            error={
              hideError || validateDateYYYYMMDD(inputsData.adressIssueDate)
            }
            name="adressIssueDate"
            onChange={(e) => handleInputWithDateMask(e, setInputsData)}
          />
          <Text
            color={theme.colors.white}
            margin="0 auto"
            fontStyle={theme.fonts.f600}
          >
            {t("kycScreen.issueDate")}
            issue date
          </Text>
          <Text
            color={theme.colors.white}
            size="21px"
            fontStyle={theme.fonts.f600}
            margin="30px 0 10px 0"
          >
            {t("kycScreen.frontSide")}
          </Text>
          <ImagePicker
            image={selectedFiles.addressFront}
            setImage={setSelectedFiles}
            name={"addressFront"}
            id="fileInputAddressFront"
          />
          <Text
            color={theme.colors.white}
            size="21px"
            fontStyle={theme.fonts.f600}
            margin="30px 0 10px 0"
          >
            {t("kycScreen.backSide")}
          </Text>
          <ImagePicker
            image={selectedFiles.addressBack}
            setImage={setSelectedFiles}
            name={"addressBack"}
            id="fileInputAddressBack"
          />
          <Btn
            label={"Confirm"}
            type={"verification"}
            buttonActive={true}
            spinner={fetchingSubmitKyc}
            func={() => confirm()}
          />
        </>
      )}
    </Wrapper>
  );
};
export default Kyc;
