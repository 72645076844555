function SvgComponent(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.333 5.833h13.334M4.167 5.833l.833 10A1.667 1.667 0 006.667 17.5h6.666A1.666 1.666 0 0015 15.833l.833-10M7.5 5.833v-2.5a.833.833 0 01.833-.833h3.334a.833.833 0 01.833.833v2.5M8.333 10l3.334 3.333m0-3.333l-3.334 3.333"
        stroke="#fff"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgComponent
