import styled from "styled-components";
import { templates } from "../../../../theme/templates";

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: calc(100vh - 124px);

`;
export const Picture = styled.img`
margin-bottom: 24px;
@media(max-width: 500px){
width: 90%;
}

`;
export const Button = styled.div`
${templates.centerContent};
width: 45%;
min-width: 280px;
height: 60px;
margin-top: 24px;
cursor: pointer;
border-radius: 16px;
background-color: ${p => p.theme.colors.yellowMain};
@media(max-width: 700px){

}
`;