import styled from "styled-components";

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
width: 100%;
`;

export const Banner = styled.div<{bg:string}>`
width: 100%;
position: relative;
height: 200px;
margin-top: -15px;
background-image: url(${p => p.bg});
display: flex;
justify-content: center;
background-size: cover;
@media(max-width: 800px){
    margin-top: 0;
}
`;
export const CollectionImage = styled.div<{image: string}>`
position: absolute;
width: 180px;
height: 180px;
background-color: white;
border-radius:12px;
bottom: -100px;
left: 24px;
background-image: url(${p => p.image});
background-size: cover;
border: 1px solid ${p => p.theme.colors.yellowMain};
`;
export const InfoWrapper = styled.div`
margin-top: 130px;
width: 100%;
display: flex;
flex-direction: column;
padding: 0 24px;
`;