import {theme} from '../theme/default/theme';

export const CURRENCY_DATA = [
  {value: `usd`, label: `USD`, key: 'usd', color: theme.colors.white},
  {value: `eur`, label: `EUR`, key: 'eur', color: theme.colors.white},
];

export const CURRENCY_LABEL = {
  usd: `USD`,
  eur: `EUR`,
};