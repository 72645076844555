import React, { useEffect, useState } from "react";
import {
  BoxWBorder,
  Tab,
  Tabs,
  Logo,
  Wrapper,
  useStyles,
} from "./HeaderStyles";
import { ContentBox } from "../../../components/ContentBox/ContentBox";
import { Text } from "../../../components/Text/Text";
import LogoutModal from "../../../components/Modals/LogoutModal";
import HistoryBar from "../../../components/HistoryBar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SelectLang from "../../../components/Modals/SelectLang";
import { useThemeContext } from "../../../theme";
// import HeaderMenu from "../../../components/Modals/HeaderMenu";
import DropDownMenu from "../../../components/Modals/DrowDownMenu";
import i18n from "../../../i18n/i18n";
import { UserActions } from "../../../logic/user/UserRedux";
import { useDispatch, useSelector } from "react-redux";
import { isDesktop } from "react-device-detect";
import SideBarMobile from "../../../components/SideBarMobile/SideBarMobile";
import { storieSelector } from "../../../logic/storie/StorieSelectors";
import { Box } from "@material-ui/core";
import { StorieActions } from "../../../logic/storie/StorieRedux";
import { StoriesModal } from "../../../components/StoriesModal";
import { StoriesBlock } from "../../../components/StoriesBlock";
import cn from "classnames";
import useWindowWidth from "../../../hooks/useWindowWidth";

const Header = ({ scroll }: any) => {
  const s = useStyles();
  const { t } = i18n;
  const dispatch = useDispatch();
  const { theme } = useThemeContext();
  const widnowWidth = useWindowWidth();

  const navigation = useNavigate();
  const location = useLocation();
  const currentRoute = location.pathname;

  const {
    openedStorie,
    storiesModalVisible,
    getStorieList: { data: storieData },
  } = useSelector(storieSelector);

  const [Historyvisible, setHistoryVisible] = useState(false);
  const [selectLangVisible, setSelectLangVisible] = useState(false);
  const [MenugVisible, setmenugVisible] = useState(false);
  const [selectedLang, setSelectedLang] = useState("/images/flags/gb.svg");
  const [openModalLogout, setOpenModalLogout] = useState(false);
  const [sideBarMobVisible, setSideBarMobVisible] = useState(false);

  const onPressLogo = () => {
    if (currentRoute === "/main") {
      setSideBarMobVisible(true);
    } else {
      navigation("/main");
    }
  };

  const handleStoriesModalClose = (shouldShowNextStories: boolean) => {
    dispatch(StorieActions.setStoriesModalVisible(false));

    if (
      shouldShowNextStories &&
      openedStorie &&
      storieData &&
      openedStorie.index < storieData.length - 1
    ) {
      const nextIndex = openedStorie.index + 1;
      dispatch(
        StorieActions.setOpenedStorie({
          ...storieData[nextIndex],
          index: nextIndex,
        })
      );
      dispatch(StorieActions.setStoriesModalVisible(true));
    }
  };

  useEffect(() => {
    if (widnowWidth > 1300) setSideBarMobVisible(false);
  }, [widnowWidth]);

  return (
    <>
      <Wrapper>
        <Logo
          src="/images/Main_Logo.png"
          onClick={() => onPressLogo()}
          style={{ marginRight: "auto" }}
          alt="logo"
        />

        {isDesktop && <StoriesBlock />}

        <Box className={cn(s.rightContainer)}>
          <Tabs>
            {widnowWidth > 1300 && (
              <>
                <Tab onClick={() => scroll("MyCards")}>My Cards</Tab>
                <Tab onClick={() => scroll("MyWallets")}>My Wallets</Tab>
                <Tab onClick={() => scroll("MarketInfo")}>Market Info</Tab>
              </>
            )}

            <Tab onClick={() => navigation("/main/escrow")}>
              Escrow
            </Tab>

            <Tab onClick={() => setHistoryVisible(true)}>
              {t("header.history")}
            </Tab>

            {/* <Tab onClick={() => navigation("/nft")}>
              NFT
            </Tab> */}

            <div style={{ cursor: "pointer" }}>
              <Link to="support">
                {widnowWidth > 700 ? (
                  <ContentBox backColor="#0E1011" padding="4px">
                    <ContentBox backColor={theme.colors.white} padding="0">
                      <img
                        src="/images/Consultant.png"
                        alt="asd"
                        style={{ width: "40px", height: "40px" }}
                      />
                    </ContentBox>

                    <Text
                      color={theme.colors.white}
                      size="18px"
                      fontStyle={theme.fonts.f600}
                      margin="0 4px 0 10px"
                      hoverColor={theme.colors.yellowMain}
                    >
                      {t("header.support")}
                    </Text>
                  </ContentBox>
                ) : (
                  <Text
                    color={theme.colors.white}
                    size="18px"
                    fontStyle={theme.fonts.f600}
                    margin="0 4px 0 10px"
                    hoverColor={theme.colors.yellowMain}
                  >
                    {t("header.support")}
                  </Text>
                )}
              </Link>
            </div>
          </Tabs>
          <BoxWBorder onClick={() => setmenugVisible(true)}>
            <img
              src="/images/user.png"
              alt="asd"
              style={{ width: "33px", height: "33px" }}
            />
          </BoxWBorder>
        </Box>
      </Wrapper>
      {/* модалки */}

      {Historyvisible && <HistoryBar setVisible={setHistoryVisible} />}
      {sideBarMobVisible && <SideBarMobile setVisible={setSideBarMobVisible} />}
      {MenugVisible && (
        <DropDownMenu
          setVisible={setmenugVisible}
          setModalLogout={setOpenModalLogout}
          setHistory={setHistoryVisible}
        />
      )}
      {selectLangVisible && (
        <SelectLang
          setVisible={setSelectLangVisible}
          setLang={setSelectedLang}
        />
      )}
      {openModalLogout && (
        <LogoutModal
          setVisible={setOpenModalLogout}
          logout={() => dispatch(UserActions.logout.request({}))}
        />
      )}
      {openedStorie && (
        <StoriesModal
          storiesData={openedStorie.storieItems as any}
          visible={storiesModalVisible}
          onClose={handleStoriesModalClose}
        />
      )}
    </>
  );
};

export default Header;