import styled from "styled-components";
import { useThemeContext } from "../../../theme";
import i18next from "i18next";
import { theme } from "../../../theme/default/theme";
import { FC } from "react";
type Props = {
  placeholder: string;
};

const InputSearch: FC<Props> = ({ placeholder }) => {
  const { t } = i18next;
  return (
    <Wrapper>
      <Pic
        src="/images/markets/lupa.svg"
        onClick={() => {}}
      />
      <Input placeholder={placeholder as string} />
    </Wrapper>
  );
};
export default InputSearch;
const Wrapper = styled.div`
  background-color: ${theme.colors.lightGreyBg};
  padding: 12px;
  border-radius: 16px;
  border: 1px solid ${theme.colors.greySubText};
  display: flex;
  align-items: center;
  min-width: 300px;
`;
const Pic = styled.img`
  cursor: pointer;
`;
const Input = styled.input`
  all: unset;
  width: 100%;
  margin-top: 4px;
  text-transform: uppercase;
  padding-left: 10px;
  color: ${theme.colors.white};
  font-weight: 600;
  letter-spacing: 1px;
  font-family: "Gilroy-Regular";
  caret-color: ${theme.colors.white};
  ::placeholder {
    color: ${theme.colors.greySubTextDesc};
  }
`;
