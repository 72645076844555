import React from 'react';
import { useSelector } from 'react-redux';
import { useDependentValue } from './../helpers/customHooks';
import { userSelector } from './../logic/user/UserSelectors';

export const useVerified = (): boolean => {
  const {
    whoAmI: {
      data: profile
    },
    enabled2FA,
  } = useSelector(userSelector);

  const userFullyVerified = useDependentValue<boolean>(() => {
    return (profile?.status === "Verified" && enabled2FA);
  }, [profile, enabled2FA]);

  return userFullyVerified;
};