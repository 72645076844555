import i18next from "i18next";
import { Text } from "../../../components/Text/Text";
import { useThemeContext } from "../../../theme";
import { NftSocialMedia } from "../../../types/types";
import {
  ContainerColumn,
  InputsWrapper,
  Wrapper,
} from "../CreateNFT/CreateNFTStyles";
import Input from "../CreateNFT/components/Input";
import { useCreateNFTCollection } from "./hooks/useCreateNftCollection";
import ImagePicker from "../NFTProfilePage/components/ImagePicker";
import {
  BannerContainer,
  CoverContainer,
  LogoContainer,
} from "./CreateNFTCollectionStyle";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { Btn } from "../../../components/Button/Btn";
import {
  validateLink,
  validateNftCollectionDescription,
  validateNftCollectionTitle,
} from "../../../helpers/validationHelper";
import { useHideErrors } from "../../../hooks/useHideErrors";
import { prepareImageName } from "../../../helpers/formatHelpers";
import { NftActions } from "../../../logic/nft/NftRedux";
import { useDispatch, useSelector } from "react-redux";
import { nftSelector } from "../../../logic/nft/NftSelectors";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { CONFIG } from "../../../config";

const CreateNFTCollectionPage = () => {
  const { theme } = useThemeContext();
  const { t } = i18next;
  const windowWidth = useWindowWidth();
  const navigate = useNavigate();
  const location = useLocation();
  const { hideErrors, onInputErrorAnimationEnd, setHideErrors } =
    useHideErrors();
  const dispatch = useDispatch();
  const {
    createNftCollection: { fetching: fetchingCreateNftCollection },
    editNftCollection: { fetching: fetchingEditNftCollection },
  } = useSelector(nftSelector);

  const {
    categoryDropdownData,
    getLinksArray,
    inputsData,
    links,
    setLink,
    setInputsData,
    handleInput,
    category,
    handleSelect,
    nftProfile,
    validateLinks,
    images,
    setImages,
    setFilledLinks,
  } = useCreateNFTCollection();

  const onPressCreate = () => {
    if (!nftProfile) {
      return;
    }

    if (
      validateNftCollectionTitle(inputsData.title) &&
      validateNftCollectionDescription(inputsData.description) &&
      validateLinks()
    ) {
      const fData = new FormData();
      if (images.logo) {
        const logoBlob = new Blob([images.logo], {
          type: "image/jpeg",
        });
        fData.append(
          "logo",
          logoBlob,
          prepareImageName(images.logo?.name as string)
        );
      }
      if (images.cover) {
        const coverBlob = new Blob([images.cover], {
          type: "image/jpeg",
        });
        fData.append(
          "cover",
          coverBlob,
          prepareImageName(images.cover?.name as string)
        );
      }
      if (images.banner) {
        const bannerBlob = new Blob([images.banner], {
          type: "image/jpeg",
        });
        fData.append(
          "banner",
          bannerBlob,
          prepareImageName(images.banner?.name as string)
        );
      }

      dispatch(
        NftActions.createNftCollection.request({
          nft_user_id: nftProfile.id,
          category: inputsData.category.value as string,
          title: inputsData.title,
          description: inputsData.description,
          fData,
          links: getLinksArray(),
          successCallback: () => navigate(-1),
        })
      );
    } else {
      setHideErrors(false);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const onPressEdit = () => {
    const fData = new FormData();

    if (images.logo) {
      const logoBlob = new Blob([images.logo], {
        type: "image/jpeg",
      });
      fData.append(
        "logo",
        logoBlob,
        prepareImageName(images.logo?.name as string)
      );
    }
    if (images.cover) {
      const coverBlob = new Blob([images.cover], {
        type: "image/jpeg",
      });
      fData.append(
        "cover",
        coverBlob,
        prepareImageName(images.cover?.name as string)
      );
    }
    if (images.banner) {
      const bannerBlob = new Blob([images.banner], {
        type: "image/jpeg",
      });
      fData.append(
        "banner",
        bannerBlob,
        prepareImageName(images.banner?.name as string)
      );
    }

    dispatch(
      NftActions.editNftCollection.request({
        id: location.state.id,
        category: inputsData.category.value as string,
        title: inputsData.title,
        description: inputsData.description,
        fData,
        logoLink: images.logo ? undefined : location.state?.logo,
        coverLink: images.cover ? undefined : location.state.cover,
        bannerLink: images.banner ? undefined : location.state.banner,
        links: getLinksArray(),
        status: "Enabled",
      })
    );
    setTimeout(() => navigate(-1), 2000);
  };

  useEffect(() => {
    if (location.state) {
      setInputsData({
        category: {
          title: location.state.category,
          value: location.state.category,
        },
        description: location.state.description,
        title: location.state.title,
      });
      setFilledLinks(location.state.links);
    }
  }, [location.state]);

  return (
    <>
      <Wrapper>
        <InputsWrapper style={{ alignItems: "center", marginTop: "-30px" }}>
          <Text
            color={theme.colors.white}
            size="32px"
            fontStyle={theme.fonts.f600}
            margin={windowWidth > 800 ? "0" : "16px 0 0 0"}
          >
            {t("editNftProfileScreen.logo")}:
          </Text>
          <LogoContainer>
            <ImagePicker
              image={
                !location.state
                  ? images.logo
                  : CONFIG.ZED_BASE_URL + location.state?.logo
              }
              setImage={setImages}
              id={"fileLogo"}
              name={"logo"}
            />
          </LogoContainer>
          <Text
            color={theme.colors.white}
            size="32px"
            fontStyle={theme.fonts.f600}
            margin={windowWidth > 800 ? "0" : "16px 0 0 0"}
          >
            {t("createNftCollectionScreen.cover")}(300*200):
          </Text>
          <CoverContainer>
            <ImagePicker
              image={
                !location.state
                  ? images.cover
                  : CONFIG.ZED_BASE_URL + location.state?.cover
              }
              setImage={setImages}
              id={"fileCover"}
              name={"cover"}
            />
          </CoverContainer>
          <Text
            color={theme.colors.white}
            size="32px"
            fontStyle={theme.fonts.f600}
            margin={windowWidth > 800 ? "0" : "16px 0 0 0"}
          >
            {t("createNftCollectionScreen.banner")} (1920*300):
          </Text>
          <BannerContainer>
            <ImagePicker
              image={
                !location.state
                  ? images.banner
                  : CONFIG.ZED_BASE_URL + location.state?.banner
              }
              setImage={setImages}
              id={"fileBanner"}
              name={"banner"}
            />
          </BannerContainer>
          <Input
            title={t("createNftCollectionScreen.title")}
            type={"input"}
            name="title"
            value={inputsData.title}
            handleInput={handleInput}
            error={hideErrors || validateNftCollectionTitle(inputsData.title)}
          />
          <Input
            title={t("createNftCollectionScreen.description")}
            type={"input"}
            name="description"
            value={inputsData.description}
            handleInput={handleInput}
            error={
              hideErrors ||
              validateNftCollectionDescription(inputsData.description)
            }
          />
          <Input
            title={t("createNftCollectionScreen.category")}
            type={"select"}
            name="category"
            value={inputsData.category.title}
            handleInput={handleSelect}
            items={categoryDropdownData}
            error
          />
          {}
          {(Object.keys(links) as Array<NftSocialMedia>).map((media) => (
            <Input
              key={`${media}_link`}
              title={`${media} ${t("editNftProfileScreen.link")}`}
              value={links[media]}
              error={
                hideErrors ||
                links[media].length === 0 ||
                validateLink(links[media])
              }
              type={"input"}
              handleInput={(e: any) => setLink(media, e.target.value)}
            />
          ))}
          <Btn
            label={location.state ? t("buttons.update") : t("buttons.create")}
            type={"authPage"}
            buttonActive={true}
            spinner={
              location.state
                ? fetchingEditNftCollection
                : fetchingCreateNftCollection
            }
            func={() => (location.state ? onPressEdit() : onPressCreate())}
          />
        </InputsWrapper>
      </Wrapper>
    </>
  );
};
export default CreateNFTCollectionPage;
