import { FC } from "react";
import { Text } from "../../../../components/Text/Text";
import { Icon, Input, Wrapper } from "./InputItemStyle";
import { useThemeContext } from "../../../../theme";
import { Link } from "react-router-dom";

type Props = {
  label: string;
  value?: any;
  variant: "info" | "link" | "edit";
  name?: string;
  inputHandler?: any;
  hideError?: any;
};

const InputItem: FC<Props> = ({
  label,
  value,
  variant,
  name,
  inputHandler,
  hideError,
}) => {
  const { theme } = useThemeContext();

  const link = variant === "link";
  const edit = variant === "edit";
  return (
    <Wrapper hideError={edit ? !hideError : false}>
      {edit ? (
        <Input
          placeholder={label}
          value={value}
          name={name}
          onChange={inputHandler}
        />
      ) : (
        <>
          <Text
            color={link ? theme.colors.white : theme.colors.greySubText}
            size="24px"
            fontStyle={theme.fonts.f500}
          >
            {label}
          </Text>
          {link ? (
            <Link to={value!} target="_blank">
              <Icon src="/images/Select_arrow_Down.png" />
            </Link>
          ) : (
            <Text
              color={theme.colors.white}
              size="24px"
              fontStyle={theme.fonts.f500}
            >
              {value}
            </Text>
          )}
        </>
      )}
    </Wrapper>
  );
};
export default InputItem;
