import React, { FC, useState } from "react";
import { Eye, InputField, Wrapper } from "./InputStyles";

export type InputProps = {
  placeholderText: string;
  eye?: boolean;
  name?: string;
  value?: string;
  handler: (e: {
    target: {
      name: string;
      value: string;
    };
  }) => void;
  password?: boolean;
};

export const Input: FC<InputProps> = ({
  placeholderText,
  eye,
  name,
  value,
  handler,
  password,
}) => {
  const [visible, setVisible] = useState(password);

  const viewPassword = () => {
    setVisible(!visible);
  };
  return (
    <Wrapper>
      <InputField
        autoComplete="off"
        placeholder={placeholderText}
        name={name}
        value={value}
        onChange={(e) => handler(e)}
        type={visible ? "password" : "text"}
      />
      {eye && <Eye src="/images/eye.png" onClick={() => viewPassword()} />}
    </Wrapper>
  );
};
