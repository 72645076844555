import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: theme.metrics.x4,
    paddingRight: theme.metrics.x4,
    // height: "100%"
  },
});