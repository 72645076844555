import {
  ActionReducerMapBuilder,
  createAction,
  createReducer,
} from "@reduxjs/toolkit";
import {
  addAllRestReducers,
  createRestActions,
  getDefaultRestState,
} from '../../store/restHelper';
import {NodeRestStateType} from '../../store/restHelper.d';
import fp from 'lodash/fp';
import { ChangeRestActions, DeleteRestActions, EditRestActions, ListRestActions, NewRestActions } from './EscrowActions';
import { EscrowPartnerBasicInfo, EscrowTransaction } from '../../types/types';

/////Types/////

type PositiveResponse = {
  status: boolean
}

export type PartnerStatusType = {
  customer_id: number;
  status: 'Agreed' | string;
}

export type EscrowStatusType = {
  status: 'Cancelled' | string;
}

export type EscrowNewFileType = {
  title: string;
  file: string;
}

export type EscrowTransactionItemType = {
  account_id: number;
  debit: number;
  credit: number
}

export type ChangePayload = {
  id: number;
  type: 'Transaction' | 'Status' | string;
  new_status?: EscrowStatusType;
  new_partner_status?: PartnerStatusType;
  new_file?: EscrowNewFileType;
  new_transaction?: EscrowTransactionItemType;
  successCallback?: () => void;
}

type DeletePayload = {
  id: number;
}

export type EscrowLogsType = {
  date_time: string;
  text: string;
}

export type ListPayload = {
  customer_id: number;
  id?: number
}

export type ListResponse = EscrowTransaction[];

export type NewEscrowPayload = {
  title: string;
  description: string;
  partners: EscrowPartnerBasicInfo[];
  clearEscrowTxConstructorOnSuccess?: boolean;
  successCallback?: () => void;
}

export type EditPayload = NewEscrowPayload & {
  id: number;
};

const changeRestActions = createRestActions<
  PositiveResponse,
  ChangePayload
>(ChangeRestActions);

const deleteRestActions = createRestActions<
  void,
  DeletePayload
>(DeleteRestActions);

const editRestActions = createRestActions<
  PositiveResponse,
  EditPayload
>(EditRestActions);

const listRestActions = createRestActions<
  ListResponse,
  ListPayload
>(ListRestActions);

const newRestActions = createRestActions<
  PositiveResponse,
  NewEscrowPayload
>(NewRestActions);

const EscrowRestActions = {
  change: changeRestActions,
  delete: deleteRestActions,
  edit: editRestActions,
  list: listRestActions,
  new: newRestActions
};

const EscrowActions = {
  ...EscrowRestActions,
  setViewingTx: createAction<EscrowTransaction | null>('escrow/setViewingTx')
};

type EscrowRestNodes = keyof typeof EscrowRestActions;

type EscrowStore = {
  viewingTx: EscrowTransaction | null;
};

const initialEscrowStore: EscrowStore = {
  viewingTx: null
};

const initialRestState = {
  change: getDefaultRestState<PositiveResponse>(),
  delete: getDefaultRestState<PositiveResponse>(),
  edit: getDefaultRestState<PositiveResponse>(),
  list: getDefaultRestState<ListResponse>(),
  new: getDefaultRestState<PositiveResponse>()
};

type EscrowState = NodeRestStateType<
  EscrowRestNodes, 
  EscrowStore & typeof initialRestState
>;

type Builder = ActionReducerMapBuilder<EscrowState>;

const escrowReducer = createReducer(
  { ...initialEscrowStore, ...initialRestState }, 
  builder =>
    (fp.flow(addAllRestReducers<typeof EscrowRestActions>(EscrowRestActions))(builder) as Builder)
    .addCase(EscrowActions.setViewingTx, (state, action) => {
      state.viewingTx = action.payload;
    })
);

export {escrowReducer, EscrowActions};