import { ActionReducerMapBuilder, createAction, createReducer } from '@reduxjs/toolkit';
import { NodeRestStateType } from '../../store/restHelper.d';
import { storeCurrencyToLocalStorage, storeLanguageToLocalStorage } from '../../helpers/localStorage';
import { CurrencyT } from '../../types/types';
import { 
  // ExampleRestActions
} from './AppSettingsActions';
import fp from 'lodash/fp';

export type CurrentLangT = `en` | `ru`;

export const AppLangArr = ["en", "ru"];

// auto  использовать тему установленную в настройках устройства
export type AppMainTheme = 'light' | 'dark' | 'auto'; 

const AppSettingsActions = {
  // exampleOne: exampleOneRestActions,
  getAppSettingsFromAsyncStorage: createAction(`appSettings/getAppSettingsFromAsyncStorage`),
  currentLang: createAction<CurrentLangT>(`appSettings/currentLang`),
  setCurrency: createAction<CurrencyT>('appSettings/setCurrency'),
  setAppTheme: createAction<AppMainTheme>('appSettings/setAppTheme'),
  setColorTheme: createAction<'light' | 'dark'>('appSettings/setColorTheme'),
  appStartSettingsSetupDone: createAction<boolean>('appSettings/appStartSettingsSetupDone')
};

type AppSettingsRestNodes = 'example';
type AppSettingsStore = {
  currentLang: CurrentLangT;
  currency: CurrencyT;
  appTheme: AppMainTheme;
  colorTheme: 'light' | 'dark';
  initialSetupDone: boolean;
};

const initialAppSettingsStore: AppSettingsStore = {
  currentLang: `en`,
  currency: 'usd',
  // Настройка темы по приложению (light / dark / auto, где auto подразумевает light или dark в соответствии с настройками системы)
  appTheme: 'auto',
  // Фактическая тема в данный момент времени (может быть только light или dark). Нужно для рендера некоторых вещей
  colorTheme: 'light',
  // Завершена ли сага getAppSettingsFromAsyncStorage
  initialSetupDone: false
}

const initialRestState = {

};

type AppSettingsState = NodeRestStateType<
  AppSettingsRestNodes, 
  AppSettingsStore & typeof initialRestState
>;

type Builder = ActionReducerMapBuilder<AppSettingsState>;

const appSettingsReducer = createReducer(
  {...initialAppSettingsStore, ...initialRestState}, 
  builder =>
    (fp.flow([
      // addRestReducers(exampleOneRestActions, 'exampleOne'),    
    ])(builder) as Builder)
      .addCase(AppSettingsActions.currentLang, (state, action) => {
        state.currentLang = action.payload;
        storeLanguageToLocalStorage(action.payload);
      })
      .addCase(AppSettingsActions.setCurrency, (state, action) => {
        state.currency = action.payload;
        storeCurrencyToLocalStorage(action.payload);
      })
      .addCase(AppSettingsActions.appStartSettingsSetupDone, (state, action) => {
        state.initialSetupDone = action.payload;
      })
      .addCase(AppSettingsActions.setAppTheme, (state, action) => {
        // state.appTheme = action.payload;
        // storeThemeToAsyncStorage(action.payload);
        // const systemColorScheme = Appearance.getColorScheme();
        // if (action.payload === 'auto' && systemColorScheme) {
        //   // Use system color scheme
        //   setDefaultNavigationBackgroundColor(systemColorScheme);
        //   state.colorTheme = systemColorScheme;
        //   state.themeObj = systemColorScheme === 'dark'? darkTheme: theme;
        // } else {
        //   // Use user defined (or default) color scheme
        //   setDefaultNavigationBackgroundColor(action.payload);
        //   state.colorTheme = action.payload === 'dark'? 'dark': 'light';
        //   state.themeObj = action.payload === 'dark'? darkTheme: theme;
        // }
      })
      .addCase(AppSettingsActions.setColorTheme, (state, action) => {
        // if (state.appTheme === 'auto' && action.payload) {
        //   setDefaultNavigationBackgroundColor(action.payload);
        //   state.colorTheme = action.payload === 'dark'? 'dark': 'light';
        //   state.themeObj = action.payload === 'dark'? darkTheme: theme;
        // }
      })
);

export { appSettingsReducer, AppSettingsActions };