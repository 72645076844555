import React, { memo } from "react";
import { useStyles } from "./ActionModalStyles";
import { Box } from "@material-ui/core";
import { CustomButton } from "./../CustomButton";
import Modal from "react-modal";
import cn from "classnames";

Modal.setAppElement("#root");

// Global constants
const MODAL_CUSTOM_STYLE = {
  content: {
    display: "flex",
    position: "absolute",
    left: "30vw",
    top: "10vh",
  },
  overlay: {
    zIndex: 11,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};

export type ActionModalProps = {
  visible: boolean;
  title?: string;
  text?: string;
  yesBtnText?: string;
  noBtnText?: string;
  onClose: () => void;
  onPressYes: () => void;
  onPressNo?: () => void;
};

const ActionModal: React.FC<ActionModalProps> = ({
  visible,
  title,
  text,
  yesBtnText,
  noBtnText,
  onPressNo,
  onClose,
  onPressYes,
}) => {
  const s = useStyles();

  return (
    <Modal
      isOpen={visible}
      onRequestClose={() => onClose()}
      contentLabel="Action modal"
      className={cn(s.actionModal)}
      style={MODAL_CUSTOM_STYLE as any}
    >
      {title && <p className={cn(s.title)}>{title}</p>}
      {text && <p className={cn(s.text)}>{text}</p>}

      <Box
        className={cn(s.bottomRow)}
        style={{ justifyContent: onPressNo ? "space-between" : "center" }}
      >
        <Box className={cn(s.btn)}>
          <CustomButton
            bgColorActive={"#007AFF"}
            colorActive={"#ffffff"}
            title={yesBtnText}
            onPress={() => {
              onPressYes();
              onClose();
            }}
          />
        </Box>

        {onPressNo && (
          <Box className={cn(s.btn)}>
            <CustomButton
              bgColorActive={"#ff0000"}
              colorActive={"#ffffff"}
              title={noBtnText}
              onPress={() => {
                onPressNo();
                onClose();
              }}
            />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

ActionModal.defaultProps = {
  yesBtnText: "Yes",
  noBtnText: "No",
};

const MemorizedComponent = memo(ActionModal);
export { MemorizedComponent as ActionModal };
