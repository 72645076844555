import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
  >
    <path
      fill={props.color ?? "#5B5B5B"}
      d="M18 5a1 1 0 0 1 1 1v8a1 1 0 0 1-2 0V8.414l-9.95 9.95a1 1 0 0 1-1.414-1.414L15.586 7H10a1 1 0 1 1 0-2h8Z"
    />
  </svg>
)
export default SvgComponent
