import { Dispatch, FC, SetStateAction } from "react";
import SideBar from "../../Pages/Layout/SideBar";
import { ContentWrapper, Wrapper } from "../HistoryBar/HistoryBarStyles";

type Props = {
  setVisible: Dispatch<SetStateAction<boolean>>;
};

const SideBarMobile: FC<Props> = ({ setVisible }) => {
  return (
    <Wrapper onClick={() => setVisible(false)}>
      <ContentWrapper onClick={(e) => e.stopPropagation()}>
        <SideBar mobile={true} setVisible={setVisible} />
      </ContentWrapper>
    </Wrapper>
  );
};
export default SideBarMobile;
