import i18next from "i18next";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { prepareImageName } from "../../../helpers/formatHelpers";
import { SocialActions } from "../../../logic/social/SocialRedux";
import { socialSelector } from "../../../logic/social/SocialSelectors";
import { userIdSelector } from "../../../logic/user/UserSelectors";
import { TicketReplyRequest } from "../../../types/api";
import { showToast } from "../../../helpers/alertService";

const options = {
  mediaType: "photo",
  quality: 0.3,
};

export const useSupportChat = (id: number) => {
  const {
    ticketsList: { data: list, fetching: refresh },
    ticketReply: { data: ticketSended, fetching: sendingMessage },
    ticketChange: { data: ticketSuccess, fetching: closeFetch },
  } = useSelector(socialSelector);
  const customer_id = useSelector(userIdSelector);
  const [message, setMessage] = useState("");
  const [pickedImage, onImagePress] = useState<undefined | string>(undefined);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const dispatch = useDispatch();

  const onSend = () => {
    let sendData: TicketReplyRequest = {
      id: id as number,
      messages: [{ message: message, files: undefined }],
    };
    if (selectedFiles.length > 0) {
      let fdata = new FormData();
      selectedFiles.forEach((item: any, index: number) => {
        fdata.append("asd" + index, item, prepareImageName(item.name));
      });
      sendData.messages[0].files = fdata as any;
    }
    dispatch(SocialActions.ticketReply.request(sendData));
    setMessage("");
    setSelectedFiles([]);
  };

  const deleteImage = (index: number) => {
    let newArr = selectedFiles;
    _.pullAt(newArr, [index]);
    setSelectedFiles([...newArr]);
  };

  useEffect(() => {
    if (ticketSuccess) {
      dispatch(SocialActions.ticketChange.success(undefined as any));
      dispatch(
        SocialActions.ticketsList.request({
          items: 20,
          user_id: customer_id as number,
          id: customer_id as number,
        })
      );
      showToast({
        title: i18next.t("supportPage.success"),
        info: i18next.t("supportPage.ticketDeleted"),
        type: "success",
      });
    }
  }, [ticketSuccess]);

  const onClose = () => {
    dispatch(SocialActions.ticketChange.request({ id, status: "Closed" }));
  };

  const selectionLimit = useMemo(() => {
    return 6 - selectedFiles.length;
  }, [selectedFiles]);

  useEffect(() => {
    if (ticketSended) {
      onRefresh();
      dispatch(SocialActions.ticketReply.success(undefined as any));
    }
  }, [ticketSended]);

  const onRefresh = () => {
    dispatch(
      SocialActions.ticketsList.request({
        items: 20,
        user_id: customer_id as number,
        id: 1,
      })
    );
  };

  return {
    message,
    refresh,
    sendingMessage,
    setSelectedFiles,
    closeFetch,
    pickedImage,
    selectedFiles,
    selectionLimit,
    setMessage,
    onSend,
    onRefresh,
    onClose,
    onImagePress,
    ticketSuccess,
    deleteImage,
  };
};
