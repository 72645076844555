import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  border: 2px solid ${(p) => p.theme.colors.yellowMain};
  border-radius: 16px;
  padding: 20px 10px 20px 20px;
  background-color: ${(p) => p.theme.colors.subBackground};
`;
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 22px;
  max-width: 295px;
`;
export const Picture = styled.img`
  width: 170px;
  height: 170px;
  @media (max-width: 570px) {
    width: 120px;
    height: 120px;
  }
`;
