import styled, { css, keyframes } from "styled-components";
import { templates } from "../../../../theme/templates";
import MaskedInput from "react-text-mask";

const shakeAnimation = keyframes`
  0% { transform: translateX(0); }
  10%, 90% { transform: translateX(-5px); }
  20%, 80% { transform: translateX(5px); }
  30%, 50%, 70% { transform: translateX(-5px); }
  40%, 60% { transform: translateX(5px); }
  100% { transform: translateX(0); }
`;

export const ItemContainer = styled.div`
  ${templates.centerContent};
  justify-content: space-between;
  column-gap: 10px;
`;
export const Input = styled.input<{ error: boolean }>`
  all: unset;
  width: 60%;
  text-align: center;
  font-size: 21px;
  font-family: Gilroy-Medium;
  font-weight: 500;
  color: ${(p) => p.theme.colors.white};
  border-bottom: 1px solid ${(p) => p.theme.colors.yellowMain};
  @media (max-width: 700px) {
    font-size: 16px;
  }
  ${(p) =>
    p.error &&
    css`
      border-bottom-color: red;
      animation: ${shakeAnimation} 0.4s linear;
    `}
`;
export const MaskInput = styled(MaskedInput)`
  all: unset;
  width: 60%;
  text-align: center;
  font-size: 21px;
  font-family: Gilroy-Medium;
  font-weight: 500;
  color: ${(p) => p.theme.colors.white};
  border-bottom: 1px solid ${(p) => p.theme.colors.yellowMain};
  @media (max-width: 700px) {
    font-size: 16px;
  }
`;
export const Select = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 60%;
  text-align: center;
  font-size: 21px;
  font-family: Gilroy-Medium;
  font-weight: 500;
  color: ${(p) => p.theme.colors.white};
  border-bottom: 1px solid ${(p) => p.theme.colors.yellowMain};
  @media (max-width: 700px) {
    font-size: 16px;
  }
`;
export const SelectArea = styled.div`
  position: absolute;
  padding: 10px 0;
  background-color: ${(p) => p.theme.colors.subBackground};
  display: flex;
  flex-direction: column;
  top: 27px;
  row-gap: 15px;
  width: 100%;
  height: 200px;
  z-index: 2;
  border-radius: 0 0 12px 12px;
  border: 1px solid ${(p) => p.theme.colors.borderGrey};
  overflow-x: hidden;
  /* Стили скролла */
  scrollbar-width: thin;
  scrollbar-color: ${(p) => p.theme.colors.scrollbar};

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${(p) => p.theme.colors.greySubText};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.colors.white};
    border-radius: 3px;
  }
`;
export const SelectItem = styled.div`
  ${templates.centerContent};
  width: 100%;
  cursor: pointer;
  &:hover {
    color: ${(p) => p.theme.colors.yellowMain};
  }
`;
export const ButtonOpen = styled.div`
  ${templates.centerContent};
  position: absolute;
  right: 0;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  background-color: ${(p) => p.theme.colors.subBackground};
  margin-left: auto;
  margin-bottom: 5px;
  transition: 0.3s;
  flex-shrink: 0;
  @media (max-width: 700px) {
    width: 25px;
    height: 25px;
  }
  &:hover {
    border: 1px solid ${(p) => p.theme.colors.yellowMain};
  }
`;
export const Icon = styled.img<{ deg: boolean }>`
  transform: rotate(${(p) => (p.deg ? "180deg" : "0")});
`;
