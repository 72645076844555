import styled from "styled-components";
import { theme } from "../../../theme/default/theme";

import { templates } from "../../../theme/templates";

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 45%;
  padding: 0 10px;
  height: calc(100vh - 132px);
  height: 100%;
  border-right: 1px solid ${(p) => p.theme.colors.borderGrey};
  @media (max-width: 1030px) {
    width: 100%;
    border-right: none;
  }
  @media (max-width: 800px) {
    
  }
`;
export const TicketsArea = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-bottom: auto;
  padding-bottom: 100px;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const FixedContainer = styled.div`
  position: absolute;
  background-color: ${(p) => p.theme.colors.black};
  bottom: 0;
  box-shadow: 0 -10px 12px 10px black;
  margin: 0 auto;
  border-radius: 16px 16px 0 0;
  width: calc(100% - 20px);
`;
