import React, { FC } from "react";
import { Helmet } from "react-helmet-async";

const metaDecorator = require("./data.json");

type Props = {
  title?: string;
  desc?: string;
  img?: string;
};

const HelmTags: FC<Props> = ({ title, desc, img }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" key="description" content={desc} />

      {/* Open Graph */}
      <meta property="og:type" content="article" />
      <meta property="og:title" key="og:title" content={title} />
      <meta property="og:description" key="og:description" content={desc} />
      <meta property="og:image" key="og:image" content={img} />
      <meta property="og:url" content={window.location.href} />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:image" content={img} />

      <link rel="canonical" href={window.location.href} />
    </Helmet>
  );
};
export default HelmTags;
