import styled from "styled-components";
import { useThemeContext } from "../../../../../theme";
import { Text } from "../../../../../components/Text/Text";
import i18next from "i18next";

const { t } = i18next;

const SpaceBeetween = ({ name, value }: any) => {
  const { theme } = useThemeContext();
  return (
    <ContainerSB>
      <Text
        color={theme.colors.greySubText}
        size="20px"
        fontStyle={theme.fonts.f600}
      >
        {name}
      </Text>
      <Text
        color={theme.colors.greySubText}
        size="22px"
        fontStyle={theme.fonts.f600}
      >
        {value}
      </Text>
    </ContainerSB>
  );
};
const Description = ({ text }: any) => {
  const { theme } = useThemeContext();

  return (
    <>
      <Text
        color={theme.colors.greySubText}
        size="20px"
        fontStyle={theme.fonts.f600}
      >
        {t("nftDetailsScreen.description")}
      </Text>
      <Text
        color={theme.colors.greySubText}
        size="22px"
        fontStyle={theme.fonts.f600}
      >
        {text}
      </Text>
    </>
  );
};

const Tags = ({ collection, data }: any) => {
  const { theme } = useThemeContext();
  return (
    <>
      <Text
        color={theme.colors.greySubText}
        fontStyle={theme.fonts.f600}
        size="20px"
        margin="30px 0 20px 0"
      >
        {t("nftDetailsScreen.tags")}
      </Text>
      {data?.tags?.length && (
        <TagsList>
          {data.tags.map((el: any, index: any) => (
            <Text
              key={index}
              color={theme.colors.white}
              fontStyle={theme.fonts.f600}
              size="22px"
              margin="0px 0 20px 0"
            >
              #{el.tag}
            </Text>
          ))}
        </TagsList>
      )}
      {collection && (
        <Wrapper>
          <Text
            color={theme.colors.white}
            size="28px"
            fontStyle={theme.fonts.f600}
            margin="0 0 30px 0"
          >
            {t("nftDetailsScreen.collectionDetails")}
          </Text>
          <SpaceBeetween name="Title" value={data.title} />
          <Line />
          <Description text={data.description} />
          <Line />
          <SpaceBeetween name="Category" value={data.category} />
          <Line />
          <SpaceBeetween name="Items" value={collection.items} />
        </Wrapper>
      )}
    </>
  );
};
export default Tags;

const Wrapper = styled.div`
  padding: 32px 24px;
  background-color: ${(p) => p.theme.colors.subBackground};
  border-radius: 24px;
  border: 1px solid ${(p) => p.theme.colors.greySubText};
`;
const ContainerSB = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Line = styled.div`
  width: 100%;
  height: 1px;
  margin: 16px 0;
  background-color: ${(p) => p.theme.colors.greySubText};
`;
const TagsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;
`;
