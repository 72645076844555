import { useSelector } from "react-redux";
import { useDependentState, useDependentValue } from "../helpers/customHooks";
import { generalSelector } from "../logic/general/GeneralSelectors";

export const useCurrencyDropdownData = (allowedCurrencies?: string[]) => {
  const {
    currencyList: {
      data: allCurrencies
    }
  } = useSelector(generalSelector);

  const currencyDropdownData = useDependentValue(() => {
    if (!allCurrencies)
      return [];

    return Object.values(allCurrencies.currencies).map((currency) => ({
      ...currency,
      title: currency.code, 
      value: currency.id,
      id: currency.id
    })).filter((currency) => {
      if (allowedCurrencies === undefined)
        return true;

      return allowedCurrencies.includes(currency.code);
    });
  }, [allCurrencies]);

  const [currency, setCurrency] = useDependentState(() => {
    return currencyDropdownData[0] ?? {title: "Currency: ", value: 0};
  }, [currencyDropdownData]);
  
  const setCurrencyByValue = (value: number) => {
    const currencyDropdownItem = currencyDropdownData.find((c: any) => c.value === value);

    if (currencyDropdownItem)
      setTimeout(() => {
        setCurrency(currencyDropdownItem);
      }, 100);
  }
  return {
    currencyDropdownData,
    currency,
    setCurrency,
    setCurrencyByValue
  };
};