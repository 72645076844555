import styled from "styled-components";

import { templates } from "../../../theme/templates";

export const LeftWrapper = styled.div`
  min-width: 432px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  @media (max-width: 900px) {
    flex-direction: row;
    column-gap: 8px;
    min-width: auto;
    margin: 16px 0 32px;
  }
`;
export const LeftBox = styled.div<{ active: boolean }>`
  background-color: ${(p) =>
    p.active ? p.theme.colors.subBackground : "black"};
  ${templates.centerContent};
  align-items: start;
  padding: 32px 16px;
  width: 100%;
  border-radius: 24px;
  border: 1px solid #323232;
  cursor: pointer;
  @media (max-width: 900px) {
    padding: 10px 8px;
    border-radius: 11px;
    width: auto;
  }
`;
export const PaymentIcon = styled.div`
  width: 68px;
  min-width: 68px;
  height: 68px;
  background-color: ${(p) => p.theme.colors.yellowMain};
  ${templates.centerContent};
  border-radius: 16px;
  margin-top: 6px;
`;
export const WhiteButton = styled.div`
  ${templates.centerContent};
  padding: 7px 11px;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 6px;
  cursor: pointer;
  font-weight: 700;
  font-family: Gilroy-Bold;
  font-size: 14px;
  color: black;
`;
