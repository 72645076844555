import { FC } from "react";
import {
  Avatar,
  BottomLeftImg,
  ContainerSB,
  ContainerWBG,
  Icon,
  ImageHeader,
  PictureWrapper,
  Wrapper,
} from "./CardNFTStyle";
import { Text } from "../Text/Text";
import { useThemeContext } from "../../theme";
import { useNavigate } from "react-router-dom";
import { Timer } from "../Timer";
import { Box } from "@material-ui/core";
import { generalSelector } from "../../logic/general/GeneralSelectors";
import { useSelector } from "react-redux";
import { CONFIG } from "../../config";
import HeartBlackIcon from "./../../assets/icons/Heart_black.svg";
import HeartIcon from "./../../assets/icons/Heart.svg";
import { stringWithMaxLen } from "../../helpers/strings";

type Props = {
  img: string;
  name: string;
  title: string;
  verif: boolean;
  avatar?: string;
  bottomLeftImage?: string;
  timer?: number;
  price?: any;
  isCollection?: boolean;
  collection?: any;
  counterCollection?: any;
  href: string;
  likedByMe: any;
  currency_id?: any;
  currency_code?: string;
};

const CardNFT: FC<Props> = ({
  img,
  avatar,
  bottomLeftImage,
  timer,
  name,
  title,
  verif,
  price,
  isCollection,
  collection,
  counterCollection,
  href,
  likedByMe,
  currency_id,
  currency_code,
}) => {
  const { theme } = useThemeContext();
  const navigate = useNavigate();

  const {
    currencyList: { data: currencyList },
  } = useSelector(generalSelector);

  return (
    <Wrapper onClick={() => navigate(href)}>
      <PictureWrapper bg={img}>
        <ImageHeader>
          {timer === undefined ? <Box /> : <Timer initialTime={timer} />}
          {!isCollection && (
            <ContainerWBG likedByMe={likedByMe}>
              <Icon src={likedByMe ? HeartBlackIcon : HeartIcon}/>
            </ContainerWBG>
          )}
        </ImageHeader>
        <Avatar src={avatar} />
        {bottomLeftImage &&
          <BottomLeftImg src={bottomLeftImage} />
        }
      </PictureWrapper>
      <ContainerSB>
        <Text
          color={theme.colors.greySubTextDesc}
          size="18px"
          fontStyle={theme.fonts.f600}
          margin="12px 0 0 0"
        >
          {name}
        </Text>
        {verif && (
          <Icon
            src="/images/NFT_ItemPage/verif.svg"
            style={{ margin: "6px auto 0 5px" }}
          />
        )}
      </ContainerSB>

      <Text
        color={theme.colors.white}
        fontStyle={theme.fonts.f600}
        size="24px"
        margin="8px 0 15px 0"
      >
        {stringWithMaxLen(title, 16)}
      </Text>
      <ContainerSB>
        <Text
          color={theme.colors.greySubTextDesc}
          fontStyle={theme.fonts.f600}
          size="18px"
        >
          {isCollection ? collection : "Current Price"}
        </Text>
        {isCollection ? null : (
          <Icon
            src={`${CONFIG.ZED_BASE_URL}${
              currencyList ? currencyList?.currencies[currency_id]?.flag : "/"
            }`}
            style={{ marginLeft: "auto" }}
          />
        )}

        <Text
          color={theme.colors.yellowMain}
          size="18px"
          fontStyle={theme.fonts.f600}
          margin="0 0 0 5px"
        >
          {isCollection ? `Items: ${counterCollection}` : price}
        </Text>
        {isCollection ? null : (
          <Text
            color={theme.colors.greySubTextDesc}
            size="18px"
            fontStyle={theme.fonts.f600}
            margin="0 0 0 5px"
          >
            {currency_code}
          </Text>
        )}
      </ContainerSB>
    </Wrapper>
  );
};
export default CardNFT;
