import React, { FC } from "react";
import { Wrapper } from "./BtnStyles";
import { useThemeContext } from "../../theme";
import { ThemeProvider } from "styled-components";
import { CircularProgress } from "@material-ui/core";

export type BtnProps = {
  label: string;
  buttonActive?: boolean;
  func?: () => void;
  width?: string;
  type:
    | "authPage"
    | "sideBarWhite"
    | "sideBarGold"
    | "verification"
    | "support"
    | "NFT";
  spinner?: boolean;
};

export const Btn: FC<BtnProps> = ({
  label,
  buttonActive,
  func,
  type,
  spinner,
  width,
}) => {
  const onclickHandler = () => {
    if (buttonActive) {
      func && func();
    }
  };
  const { theme } = useThemeContext();
  return (
    <ThemeProvider theme={theme}>
      <Wrapper
        buttonActive={buttonActive && !spinner}
        onClick={() => onclickHandler()}
        type={type}
        width={width as string}
      >
        {spinner ? <CircularProgress size={20} /> : label}
      </Wrapper>
    </ThemeProvider>
  );
};

Btn.defaultProps = {
  spinner: false,
  buttonActive: true,
};
