export type BlockData = {
    icon: string;
    title: string;
    percents: string;
    value: string;
    secondValue: string;
    hoursVolume: string;
  };
export const blocksData: BlockData[] = [
{icon: '/images/markets/coin.svg', title: 'GOLD/USDZ', percents: '+1.12%', value: '1,941.853231', secondValue: '$ 1,937.917297498', hoursVolume: '24H Vol 406,856.94.1800'},
{icon: '/images/markets/coin.svg', title: 'GOLD/USDZ', percents: '+0.53%', value: '1,273.923929', secondValue: '$ 1,937.917297498', hoursVolume: '24H Vol 406,856.94.1800'},
{icon: '/images/markets/coin.svg', title: 'GOLD/USDZ', percents: '+1.03%', value: '3,911.585833', secondValue: '$ 1,937.917297498', hoursVolume: '24H Vol 406,856.94.1800'},
{icon: '/images/markets/coin.svg', title: 'GOLD/USDZ', percents: '+0.89%', value: '1,952.951253', secondValue: '$ 1,937.917297498', hoursVolume: '24H Vol 406,856.94.1800'},
{icon: '/images/markets/coin.svg', title: 'GOLD/USDZ', percents: '+0.01%', value: '2,411.822800', secondValue: '$ 1,937.917297498', hoursVolume: '24H Vol 406,856.94.1800'},
{icon: '/images/markets/coin.svg', title: 'GOLD/USDZ', percents: '+3.23%', value: '1,941.822800', secondValue: '$ 1,937.917297498', hoursVolume: '24H Vol 406,856.94.1800'},
{icon: '/images/markets/coin.svg', title: 'GOLD/USDZ', percents: '+0.23%', value: '1,941.822800', secondValue: '$ 1,937.917297498', hoursVolume: '24H Vol 406,856.94.1800'},


]
export const tabsData = [
    'Favorites',
    'Spot'
]

export const selectData = [
    'All Markets',
    'ETF',
    'ZEDXIONC',
    'USDT',
    'USDZ',
    'TOMAN',
    'BTC',
    'ETH',
    'USDC'
]

