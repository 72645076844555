import { restActionCreatorHelper } from "./../../helpers/restActionCreatorHelper";

const userRestAction = restActionCreatorHelper(`user`);

export const LogoutRestActions = userRestAction("logout");

export const RegisterEmailRestActions = userRestAction("registerEmail");

export const ConfirmNewEmailRestActions = userRestAction("confirmNewEmail");

export const RegisterRestActions = userRestAction("register");

export const LoginRestActions = userRestAction("login");

export const WhoAmIRestActions = userRestAction("whoAmI");

export const ChangePasswordRestActions = userRestAction("changePassword");

export const RequestResetPasswordRestActions = userRestAction(
  "requestResetPassword"
);

export const ResetPasswordRestActions = userRestAction("resetPassword");

export const GetAuthenticatorCodeRestActions = userRestAction(
  "getAuthenticatorCode"
);

export const VerifyAuthenticatorCodeRestActions = userRestAction(
  "verifyAuthenticatorCode"
);

export const DisableAuthenticatorCodeRestActions = userRestAction(
  "disableAuthenticatorCode"
);

export const SendDocumentsRestActions = userRestAction("sendDocuments");

export const VerifyPhoneRestActions = userRestAction("verifyPhone");

export const ConfirmPhoneRestActions = userRestAction("confirmPhone");

export const EditProfileRestActions = userRestAction("editProfile");

export const ChangeAvatarRestActions = userRestAction("changeAvatar");

export const SetNotificationsRestActions = userRestAction('setNotifications');

export const IntercomUserHashRestActions = userRestAction('intercomUserHash');