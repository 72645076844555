import React, { useState } from 'react';
import { useStyles } from './ExpandableBlockStyles';
import { Box } from '@material-ui/core';
import { useThemeContext } from "../../theme";
import ChevronUpIcon from './../../assets/icons/chevronUp';
import cn from 'classnames';
import 'animate.css';

export type ExpandableBlockProps = {
  label: string;
  children: React.ReactNode | React.ReactNode[];
  marginTop?: number;
  marginBottom?: number;
};

const ExpandableBlock: React.FC<ExpandableBlockProps> = ({
  label,
  children,
  marginTop,
  marginBottom,
}) => {
  const { theme } = useThemeContext();
  const s = useStyles();

  const [showChildren, setShowChildren] = useState(false);

  return (
    <Box className={cn(s.container)} style={{marginTop, marginBottom}}>
      <Box 
        className={s.previewCont}
        onClick={() => setShowChildren((v) => !v)}
      >
        <p 
          className={cn(s.label)} 
        >
          {label}
        </p>

        <Box className={cn(s.chevronCont, {[s.chevronRotated]: showChildren})}>
          <ChevronUpIcon />
        </Box>
      </Box>

      {showChildren &&
        <Box className={cn(s.childrenCont, "animate__animated animate__fadeIn")}>
          {children}
        </Box>
      }
    </Box>
  );
};

export { ExpandableBlock };