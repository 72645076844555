import React from 'react';
import { useStyles } from './MaintenanceBannerStyles';
import AlertTriangleIcon from './../../assets/icons/alertTriangle';
import { useThemeContext } from '../../theme';

export type MaintenanceBannerProps = {
  text: string;
};

const MaintenanceBanner: React.FC<MaintenanceBannerProps> = ({
  text
}) => {
  const { theme: { colors } } = useThemeContext();
  const s = useStyles();

  return (
    <div className={s.container}>
      <div className={s.iconCont}>
        <AlertTriangleIcon color={colors.white} />
      </div>
      <p className={s.text}>
        {text}
      </p>
    </div>
  );
};

export { MaintenanceBanner };