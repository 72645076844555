import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
  >
    <circle cx={8} cy={8.667} r={6} stroke="#000" />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8 6v2.667l1.667 1.666M2.333 3 5 1.333M13.667 3 11 1.333"
    />
  </svg>
)
export default SvgComponent
