import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// переводы
import translationEN from "./langs/en/translation.json";
import translationDE from "./langs/de/translation.json";
// Импортируйте другие языковые ресурсы по мере необходимости

// Инициализируйте i18next
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN,
    },
    de: {
      translation: translationDE,
    },
    // Добавьте другие языковые ресурсы здесь
  },
  lng: "en", // Установите язык по умолчанию
  fallbackLng: "en", // Язык, используемый в случае отсутствия перевода
  interpolation: {
    escapeValue: false, // Отключение экранирования строк
  },
});

export default i18n;
