import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
  >
    <g stroke="#92D37B" clipPath="url(#a)">
      <circle cx={8} cy={8} r={6.667} />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.667 8.333 7 9.667l3.333-3.334"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent