import { ChangeEvent, FC, useEffect, useState } from "react";
import { Text } from "../../../../components/Text/Text";
import { useThemeContext } from "../../../../theme";
import styled from "styled-components";
import { templates } from "../../../../theme/templates";
import ChoseImage from "../../../../components/Modals/Verification/components/ChoseImage/ChoseImage";
import { useVerification } from "../../../../components/Modals/Verification/useVerification";
import BlurWrapper from "../../../../components/BlurWrapper/BlurWrapper";
import CheckQuality from "../CheckQuality/CheckQuality";
import {
  FileContainer,
  Icon,
} from "../../../../components/Modals/CreateTicket/CreateTicketStyles";

type Props = {
  title: string;
  mandatory: boolean;
  images: any;
  openFilePicker: any;
  checkImage: any;
  setSelectedImage: any;
};

const DownloadDocument: FC<Props> = ({
  title,
  mandatory,
  images,
  openFilePicker,
  checkImage,
  setSelectedImage,
}) => {
  const { theme } = useThemeContext();
  const [openModal, setOpenModal] = useState(false);

  const handleRemoveFile = () => {
    setSelectedImage((prev: any) => {
      const updatedImage = { ...prev };
      delete updatedImage[title?.replace(/\s/g, "_")];
      return updatedImage;
    });
  };
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <Wrapper>
        {mandatory ? (
          <Text
            color={theme.colors.white}
            fontStyle={theme.fonts.f500}
            size="21px"
          >
            {title}
            <RedSpan>(required)</RedSpan>:
          </Text>
        ) : (
          <Text
            color={theme.colors.white}
            fontStyle={theme.fonts.f500}
            size="21px"
          >
            {title}:
          </Text>
        )}
        <ButtonContainer>
          {checkImage[title?.replace(/\s/g, "_")] ? (
            <>
              <input
                id="fileInput"
                type="file"
                style={{ display: "none" }}
                accept=".jpg, .jpeg, .png"
                multiple
                max="6"
                // onChange={handleFileChange}
              />

              <FileContainer
                style={{ minWidth: "250px", height: "250px" }}
                onClick={() => handleRemoveFile()}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                redBorder={true}
              >
                {isHovered && <Icon src="/images/support/delete.svg" />}

                <img
                  src={URL.createObjectURL(
                    checkImage[title?.replace(/\s/g, "_")]
                  )}
                  alt="Selected file"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "12px",
                  }}
                />
              </FileContainer>
            </>
          ) : (
            <FileContainer
              onClick={() => setOpenModal(true)}
              style={{ minWidth: "250px", height: "250px" }}
            >
              <input id="fileInput" type="file" style={{ display: "none" }} />
              <Icon src="/images/support/add.svg" />
            </FileContainer>
          )}
        </ButtonContainer>
      </Wrapper>
      {openModal && (
        <WrapperModal>
          <ContentWrapper>
            <CloseButton onClick={() => setOpenModal(false)}>
              <ClosePic src="/images/Close_Button.svg" alt="asd" />
            </CloseButton>
            {checkImage[title?.replace(/\s/g, "_")] ? (
              <CheckQuality
                setImage={setSelectedImage}
                image={checkImage[title?.replace(/\s/g, "_")]}
                id={title.replace(/\s/g, "_")}
                setModal={setOpenModal}
              />
            ) : (
              <ChoseImage
                func={openFilePicker}
                title={title}
                desc={title}
                id={title}
                images={images}
              />
            )}
          </ContentWrapper>
        </WrapperModal>
      )}
    </>
  );
};
export default DownloadDocument;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const WrapperModal = styled.div`
  position: fixed;
  ${templates.centerContent};
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: #000000c4;
  z-index: 7;
`;
const ContentWrapper = styled.div`
  position: absolute;
  padding: 24px 20px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 552px;
  height: auto;
  max-height: 90vh;
  background-color: ${(p) => p.theme.colors.subBackground};
  border-radius: 24px;
  @media (max-width: 810px) {
    width: calc(100% - 24px);
  }
`;
const CloseButton = styled.div`
  position: absolute;
  ${templates.centerContent};
  top: 24px;
  right: 24px;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  border: 1px solid ${(p) => p.theme.colors.white};
  cursor: pointer;
  @media (max-width: 700px) {
    width: 30px;
    height: 30px;
    border-radius: 7px;
  }
`;
const ClosePic = styled.img`
  @media (max-width: 700px) {
    width: 10px;
    height: 10px;
  }
`;
const RedSpan = styled.span`
  font-size: 21px;
  color: ${(p) => p.theme.colors.red};
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;
const Button = styled.div`
  ${templates.centerContent};

  cursor: pointer;
  width: 200px;
  height: 40px;
  background-color: ${(p) => p.theme.colors.yellowMain};
  border-radius: 8px;
  @media (max-width: 700px) {
    width: 150px;
    height: 30px;
  }
`;
