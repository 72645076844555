import { Instruction, InstructionGroup } from "@appklaar/codebud";
import { SocialActions } from "./../logic/social/SocialRedux";

export const getSocialInstructions = (store: any): (Instruction | InstructionGroup)[] => ([
  {
    groupId: "Social",
    groupDescription: "Social entity instructions",
    groupColor: "#FCFAED",
    groupInstructions: [
      {
        id: "getBlog",
        description: "request GET blog data",
        handler: () => {
          store.dispatch(SocialActions.blog.request());
        },
      },
      {
        id: "getNews",
        description: "request GET news",
        handler: () => {
          store.dispatch(SocialActions.news.request());
        },
      },
      {
        id: "getAd",
        description: "request GET ad",
        handler: () => {
          store.dispatch(SocialActions.ad.request());
        },
      },
    ]
  },
]);