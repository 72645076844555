import styled, { keyframes } from "styled-components";

import { templates } from "../../theme/templates";

const slideInAnimation = keyframes`
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  left: 336px;
  top: 0;
  width: calc(100% - 336px);
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 4;
  @media (max-width: 1300px) {
    left: 0;
    width: 100%;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;
  background-color: #1a1e20;
  padding-top: 20px;
  height: 100vh;
  animation: ${slideInAnimation} 0.4s ease-out;
  @media (max-width: 700px) {
    width: 375px;
  }
`;
export const Button = styled.div`
  width: 100%;
  padding: 0 24px;
  height: 60px;
  margin-top: 30px;
  ${templates.centerContent};
  background-color: ${(p) => p.theme.colors.yellowMain};
  border-radius: 16px;
  cursor: pointer;
`;
//контейнер (нет транзакций)
export const ContainerEmpty = styled.div`
  ${templates.centerContent}
  flex-direction: column;
  margin: auto 0;
  padding: 0 24px;
`;
//контейнер (есть транзакции)
export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 24px;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: ${(p) => p.theme.colors.scrollbar};

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${(p) => p.theme.colors.greySubText};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.colors.white};
    border-radius: 3px;
  }
`;
export const OneDayContainer = styled.div`
  margin: 16px 0;
`;
export const HeaderContainer = styled.div`
  width: 100%;
  padding-right: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const CloseButton = styled.div`
  ${templates.centerContent};
  min-width: 30px;
  height: 30px;
  font-size: 16px;
  cursor: pointer;
  color: ${(p) => p.theme.colors.white};
  background-color: ${(p) => p.theme.colors.subBackground};
  border: 1px solid ${(p) => p.theme.colors.borderGrey};
  border-radius: 8px;
  transition: 0.3s;
  &:hover {
    border: 1px solid ${(p) => p.theme.colors.yellowMain};
    color: ${(p) => p.theme.colors.yellowMain};
  }
`;
