import styled, { css, keyframes } from "styled-components";
import { templates } from "../../../../theme/templates";

const shakeAnimation = keyframes`
  0% { transform: translateX(0); }
  10%, 90% { transform: translateX(-5px); }
  20%, 80% { transform: translateX(5px); }
  30%, 50%, 70% { transform: translateX(-5px); }
  40%, 60% { transform: translateX(5px); }
  100% { transform: translateX(0); }
`;

export const Wrapper = styled.div`
  ${templates.centerContent};
  margin: 30px auto 0;
column-gap: 30px;
@media(max-width: 600px){
  flex-direction: column;
}
`;
export const Left = styled.div`
${templates.centerContent};
flex-direction: column;
width: 40%;
@media(max-width: 600px){
  width: 90%;
}
`;
export const Right =styled.div`

`;
export const KeyContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Copy = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;
export const Qr = styled.img`
  width: 200px;

  background-color: white;
  margin: 30px 0 20px 0;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
export const Input = styled.input<{ error: boolean }>`
  all: unset;
  width: 100%;
  text-align: left;
  font-size: 18px;
  padding-bottom: 5px;
  font-family: Gilroy-Medium;
  font-weight: 500;
  color: ${(p) => p.theme.colors.white};
  border-bottom: 1px solid ${(p) => p.theme.colors.yellowMain};
  @media (max-width: 700px) {
    font-size: 16px;
  }
  ${(p) =>
    p.error &&
    css`
      border-bottom-color: red;
      animation: ${shakeAnimation} 0.4s linear;
    `}
`;
