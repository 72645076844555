import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  bankInfoModal: {
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    height: '84vh',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colors.subBackground,
    borderRadius: 16,
    overflow: 'hidden',
  },
  "&__content": {outline: "none"},
  topCont: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '12%'
  },
  endRow: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    // backgroundColor: 'red',
    justifyContent: 'flex-end',
    paddingTop: theme.metrics.x2,
    paddingBottom: theme.metrics.x2,
    paddingRight: theme.metrics.x2,
    paddingLeft: theme.metrics.x2,
  },
  closeIcon: {
    display: 'flex',
    // backgroundColor: 'blue',
    width: 40,
    height: 40,
    // marginRight: 
  },
  contentContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingLeft: 20,
    paddingRight: 20,
    overflowY: 'scroll'
  }
});