import i18next from "i18next"
import { prettyPageUrl } from "../formatHelpers"

const {t} = i18next

export const metaTitle = (page: string) => {
const pageUrl = prettyPageUrl(page)
    

    switch (pageUrl) {
            case "/":
            return t('metaTags.title.login')
            
            case "/main":
            return t('metaTags.title.main')

            case "/main/support":
            return t('metaTags.title.support')

            case "/main/cardOperations":
            return t('metaTags.title.cardOperations')

            case "/main/verification":
            return t('metaTags.title.verification')

            case "/main/order-new-card":
            return t('metaTags.title.orderNewCard')

            case "/main/profile":
            return t('metaTags.title.profile')

            case "/main/faq":
            return t('metaTags.title.faq')

            case "/main/about":
            return t('metaTags.title.about')

            case "/main/markets":
            return t('metaTags.title.markets')

            case "/register":
            return t('metaTags.title.register')
            
        default:
            return t('metaTags.title.default')
            
    }
}
export const metaDescription = (page: string) => {
    const pageUrl = prettyPageUrl(page)
    

    switch (pageUrl) {
        case "/":
            return t('metaTags.description.login')
            
            case "/main":
            return t('metaTags.description.main')

            case "/main/support":
            return t('metaTags.description.support')

            case "/main/cardOperations":
            return t('metaTags.description.cardOperations')

            case "/main/verification":
            return t('metaTags.description.verification')

            case "/main/order-new-card":
            return t('metaTags.description.orderNewCard')

            case "/main/profile":
            return t('metaTags.description.profile')

            case "/main/faq":
            return t('metaTags.description.faq')

            case "/main/about":
            return t('metaTags.description.about')

            case "/main/markets":
            return t('metaTags.title.markets')

            case "/register":
            return t('metaTags.description.register')
            
        default:
            return t('metaTags.description.default')
    }
}
export const metaImage = '/images/Main_Logo.png'