import { useEffect, useState } from "react";
import { Btn } from "../../../../components/Button/Btn";
import { Text } from "../../../../components/Text/Text";
import { useThemeContext } from "../../../../theme";
import {
  Copy,
  Input,
  KeyContainer,
  Left,
  Qr,
  Right,
  Wrapper,
} from "./TwoSAuthStyles";
import { userSelector } from "../../../../logic/user/UserSelectors";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../../../../logic/user/UserRedux";
import i18next from "i18next";
import { validate2FACode } from "../../../../helpers/validationHelper";
import { showToast } from "../../../../helpers/alertService";
import { SkeletonQR, SkeletonQRCode } from "../../SkeletonLoaders";
import useWindowWidth from "../../../../hooks/useWindowWidth";

const TwoSAuth = () => {
  const { theme } = useThemeContext();
  const { t } = i18next;
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();

  const {
    whoAmI: { data: profile, fetching: fetchingProfile },
    getAuthenticatorCode: { data: getAuthData, fetching: fetchingGetAuth },
    verifyAuthenticatorCode: { fetching: fetchingVerify },
    disableAuthenticatorCode: { fetching: fetchingDisable },
  } = useSelector(userSelector);

  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const [copyIcon, setCopyIcon] = useState(false);

  useEffect(() => {
    if (profile?.otp_enabled === false)
      dispatch(UserActions.getAuthenticatorCode.request());
  }, [profile]);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(false);
      }, 1200);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [error]);

  const handleEnableOtp = () => {
    if (validate2FACode(code)) {
      dispatch(
        UserActions.verifyAuthenticatorCode.request({
          otp: code,
        })
      );
    } else {
      setError(true);
    }
  };

  const handleDisableOtp = () => {
    if (validate2FACode(code)) {
      dispatch(
        UserActions.disableAuthenticatorCode.request({
          otp: code,
        })
      );
    } else {
      setError(true);
    }
  };

  const copy = () => {
    navigator.clipboard.writeText(
      getAuthData?.value.AuthenticationManualCode as string
    );
    setCopyIcon(true);
    showToast({
      title: t("Success"),
      info: t("CopiedToClipboard"),
      type: "success",
    });
  };

  const enabled = profile?.otp_enabled;
  return (
    <Wrapper>
      <Left>
        <Text
          color={theme.colors.white}
          fontStyle={theme.fonts.f600}
          size="28px"
        >
          {t("twoStepAuth.title")}
        </Text>
        <Text
          color={enabled ? theme.colors.green : theme.colors.red}
          fontStyle={theme.fonts.f600}
          size="30px"
        >
          {enabled ? t("twoStepAuth.enabled") : t("twoStepAuth.disabled")}
        </Text>
        <Text
          color={theme.colors.descOpacityText}
          margin="10px 0 0 0"
          size="16px"
          textAlign="center"
          fontStyle={theme.fonts.f500}
        >
          {enabled
            ? t("twoStepAuth.activeDesc")
            : t("twoStepAuth.disabledDesc")}
        </Text>
        {!enabled && (
          <>
            <Text
              color={theme.colors.textDescription}
              margin="20px auto 0 0"
              size="16px"
              fontStyle={theme.fonts.f500}
            >
              {t("twoStepAuth.keyPlaceholder")}:
            </Text>

            <KeyContainer>
              <Text
                color={theme.colors.white}
                size="18px"
                fontStyle={theme.fonts.f600}
              >
                {getAuthData?.value.AuthenticationManualCode ? (
                  getAuthData?.value.AuthenticationManualCode
                ) : (
                  <SkeletonQRCode />
                )}
              </Text>
              <Copy
                src={
                  copyIcon
                    ? "/images/profile/copy-done.svg"
                    : "/images/profile/copy.svg"
                }
                alt="copy"
                onClick={copy}
              />
            </KeyContainer>
          </>
        )}

        <Text
          color={theme.colors.textDescription}
          fontStyle={theme.fonts.f500}
          margin="10px auto 0 0"
        >
          2FA Code
        </Text>
        <Input
          placeholder="000000"
          error={error}
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <Btn
          label={enabled ? t("disable") : t("twoStepAuth.verifySetup")}
          type="verification"
          func={() => (enabled ? handleDisableOtp() : handleEnableOtp())}
        />
      </Left>
      {!enabled && (
        <Right>
          {getAuthData?.value.AuthenticationBarCodeImage ? (
            <Qr src={getAuthData?.value.AuthenticationBarCodeImage} />
          ) : (
            <SkeletonQR desktop={windowWidth > 700} />
          )}
        </Right>
      )}
    </Wrapper>
  );
};
export default TwoSAuth;
