import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    height: 36,
    // backgroundColor: 'red',
    flexDirection: 'row',
    marginTop: theme.metrics.x4,
    marginBottom: theme.metrics.x4,
    justifyContent: 'space-between'
  },
  left: {
    display: 'flex',
    height: '100%',
    width: '42%',
    flexDirection: 'row',
    alignItems: 'center',
    // backgroundColor: 'blue'
  },
  center: {
    display: 'flex',
    height: '100%',
    width: '24%',
    // backgroundColor: 'green'
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '34%',
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  logoCont: {
    display: 'flex',
    width: 32,
    height: 32,
    borderRadius: 8,
    overflow: 'hidden',
    // backgroundColor: 'red',
    marginRight: theme.metrics.x2
  },
  logo: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  whiteBold: {
    color: theme.colors.white,
    fontFamily: theme.fonts.f800.fontFamily,
    fontSize: 16,
    marginLeft: 0,
    marginTop: 0,
    marginRight: 0,
    marginBottom: theme.metrics.x
  },
  greyText: {
    color: theme.colors.greySubText,
    fontFamily: theme.fonts.f600.fontFamily,
    fontSize: 14,
    margin: 0
  },
  changeText: {
    fontFamily: theme.fonts.f600.fontFamily,
    fontSize: 14,
    margin: 0
  },
  redText: {
    color: theme.colors.red,
    margin: 0
  },
  greenText: {
    color: theme.colors.sulu,
    margin: 0
  }
});