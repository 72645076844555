import { take, fork, join, ActionPattern } from "redux-saga/effects";

function takeLeading(
  patternOrChannel: ActionPattern,
  saga: any,
  ...args: any[]
) {
  return fork(function* () {
    while (true) {
      // @ts-ignore
      const action = yield take(patternOrChannel);
      // @ts-ignore
      const task = yield fork(saga, ...args.concat(action));
      yield join(task);
    }
  });
}

function takeEveryRegexp(regexp: RegExp, saga: any, ...args: any[]) {
  return fork(function* () {
    while (true) {
      // @ts-ignore
      const action = yield take("*");

      if (regexp.test(action.type)) {
        yield fork(saga, ...args.concat(action));
      }
    }
  });
}

export { takeLeading, takeEveryRegexp };
