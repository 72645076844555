import styled, { css } from "styled-components";
import { templates } from "../../../../theme/templates";

// Block Item
export const BlockItemWrapper = styled.div<{bgGradient: any, halfWidth?: boolean, block2?: boolean}>`
width: ${p => p.halfWidth ? '40%' : '100%'};
height: 279px;
${p => p.bgGradient};
border-radius: 24px;
padding: 40px 30px;
display: flex;

flex-direction: column;
position: relative;
@media(max-width: 700px){
    height: 160px;
    padding: 17px 16px;
}
@media(max-width: 500px){
    ${p => p.block2 && css`
    width: 100%;
    height: 130px;
    `}
    
}
`;
export const LayerContent = styled.div`
position: relative;
display: flex;
height: 100%;
flex-direction: column;
z-index: 2;
`;

export const ButtonsContainer = styled.div`
margin-top: auto;
display: flex;
width: 65%;
column-gap: 24px;
@media(max-width: 600px){
    width: 100%
}
`;

export const Button = styled.div<{bgColor?: boolean}>`
width: 100%;
background-color: ${p => p.bgColor ? p.theme.colors.white : p.theme.colors.black };
border-radius: 16px;
height: 68px;
${templates.centerContent};
margin-top: auto;
cursor: pointer;
@media(max-width: 700px){
    height: 48px;
 
}
`;

export const Picture = styled.img`
position: absolute;

top: 0;
right: 0;
z-index: 1;
@media(max-width: 700px){
    width: 130px;
height: 130px;
}

`;

export const ProfilePicture = styled.img`
position: absolute;
width: 200px;
height: 200px;
opacity: 0.5;
top: 0;
right: 0;
z-index: 1;
@media(max-width: 700px){
    width: 120px;
height: 120px;
}
`