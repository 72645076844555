import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  actionModal: {
    display: 'flex',
    flexDirection: 'column',
    width: '40vw',
    height: '30vh',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: "#fff",
    borderRadius: 16,
    overflow: 'hidden',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 16,
    paddingRight: 16
  },
  title: {
    color: "black",
    fontWeight: "bolder",
    fontSize: 26,
    margin: 0
  },
  text: {
    color: "black",
    fontSize: 20,
    margin: 0
  },
  bottomRow: {
    display: "flex",
    width: "100%",
  },
  btn: {
    display: "flex",
    height: 50,
    width: "47%"
  }
});