import React, { useMemo, useRef, useState, useLayoutEffect } from "react";
import { useStyles } from "./SimpleLineChartStyles";
import { CoinPriceTimestamp, NumberPair } from "../../types/types";
import {
  buildLineChart,
  buildLineChartNumberPair,
} from "../../helpers/lineChartHelpers";
import { CircularProgress } from "@material-ui/core";
import cn from "classnames";

export type SimpleLineChartProps = {
  loadingData: boolean;
  data: CoinPriceTimestamp[] | NumberPair[];
  dataType?: "object" | "array";
  lineColor: string;
  lineWidth?: number;
  bgColor?: string;
};

const SimpleLineChart: React.FC<SimpleLineChartProps> = ({
  loadingData,
  data,
  dataType,
  lineColor,
  lineWidth,
  bgColor,
}) => {
  const s = useStyles();

  const containerRef = useRef<null | HTMLDivElement>(null);

  const [gotL, setGotL] = useState(false);
  const [lw, setLw] = useState(0);
  const [lh, setLh] = useState(0);

  // Объект кривой
  const chart = useMemo(() => {
    if (loadingData || !data || !gotL) return null;

    return dataType === "object"
      ? buildLineChart(data as CoinPriceTimestamp[], lw, lh)
      : buildLineChartNumberPair(data as NumberPair[], lw, lh);
  }, [loadingData, data, dataType, gotL, lw, lh]);

  useLayoutEffect(() => {
    setLw(containerRef?.current?.offsetWidth ?? 0);
    setLh(containerRef?.current?.offsetHeight ?? 0);
    setGotL(true);
  }, []);

  return (
    <div
      ref={containerRef}
      className={cn(s.container)}
      style={{ backgroundColor: bgColor }}
    >
      {loadingData || !chart ? (
        <CircularProgress size={20} />
      ) : (
        <svg width={"100%"} height={"100%"}>
          <path
            d={chart.path}
            stroke={lineColor}
            strokeWidth={lineWidth}
            fill={"transparent"}
          />
        </svg>
      )}
    </div>
  );
};

SimpleLineChart.defaultProps = {
  bgColor: "transparent",
  lineWidth: 2,
  dataType: "object",
};

export { SimpleLineChart };
