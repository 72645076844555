import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: 'red'
  },
  greyText: {
    color: theme.colors.greySubText,
    fontFamily: theme.fonts.f400.fontFamily,
    margin: 0
  },
  changeContainer: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.metrics.x,
    // backgroundColor: 'red'
  },
  valueText: {
    color: theme.colors.textSub,
    fontFamily: theme.fonts.f800.fontFamily,
    fontSize: 24,
    marginTop: theme.metrics.x3,
    marginBottom: theme.metrics.x2,
    marginRight: 0,
    marginLeft: 0
  },
  changeText: {
    color: theme.colors.textSub,
    fontFamily: theme.fonts.f600.fontFamily,
    fontSize: 18,
    margin: 0
  },
  arrowIcon: {
    marginRight: theme.metrics.x3
  }
});