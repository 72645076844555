import { useEffect } from 'react';

type Props = {
  dep?: any;
};

const useSmoothScrollToTop = ({ dep }: Props) => {
  useEffect(() => {
    const scrollToTop = (): void => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

    if (dep) {
      scrollToTop();
     
    }
  }, [dep]);
};

export default useSmoothScrollToTop;