import { useEffect, useState } from "react";
import { Btn } from "../../../../components/Button/Btn";
import { ContentBox } from "../../../../components/ContentBox/ContentBox";
import { Text } from "../../../../components/Text/Text";
import { useThemeContext } from "../../../../theme";
import { Input } from "../../../../components/Input/Input";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import { userSelector } from "../../../../logic/user/UserSelectors";
import { useDispatch, useSelector } from "react-redux";
import { passwordValidations } from "../../../../helpers/validationHelper";
import { UserActions } from "../../../../logic/user/UserRedux";
import { ValidationInfo } from "../../../../components/ValidationInfo";
import i18next from "i18next";
import { showToast } from "../../../../helpers/alertService";

const ChangePassword = () => {
  const { theme } = useThemeContext();
  const windowWidth = useWindowWidth();
  const dispatch = useDispatch();
  const { t } = i18next;

  const {
    changePassword: {
      fetching: fetchingChangePassword,
      error: currentPasswordError,
    },
  } = useSelector(userSelector);

  // inputs data
  const [inputsData, setInputsData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    newEmail: "",
  });
  const { confirmPassword, newPassword, oldPassword } = inputsData;
  // buttons state
  const [buttonsState, setButtonsState] = useState({
    password: false,
  });

  const onPressSave = () => {
    if (
      passwordValidations.validatePassword(oldPassword) &&
      passwordValidations.validatePassword(newPassword) &&
      passwordValidations.validatePasswordsMatch(newPassword, confirmPassword)
    ) {
      dispatch(
        UserActions.changePassword.request({
          current_password: oldPassword,
          new_password: newPassword,
          delete_tokens: true,
        })
      );
    }
  };
  useEffect(() => {
    if (currentPasswordError) {
      showToast({
        title: "currentPassword not correct",
        info: "please type correct old password",
        type: "error",
      });
    }
  }, [currentPasswordError]);

  //input handler
  const inputHandlerPassword = (e: {
    target: { name: string; value: string };
  }) => {
    setInputsData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // button active/not active
  useEffect(() => {
    if (
      passwordValidations.validatePasswordsMatch(
        newPassword,
        confirmPassword
      ) &&
      passwordValidations.validateOneNumber(newPassword) &&
      passwordValidations.validatePasswordMinLength(newPassword) &&
      passwordValidations.validateOneUppercase(newPassword) &&
      oldPassword.length > 7
    ) {
      setButtonsState((prev) => ({ ...prev, password: true }));
    } else {
      setButtonsState((prev) => ({ ...prev, password: false }));
    }
  }, [inputsData, confirmPassword, newPassword, oldPassword]);

  return (
    <>
      <ContentBox
        direction="column"
        width="100%"
        backColor="none"
        margin="20px 0 0 0"
      >
        <Text
          color={theme.colors.white}
          size="44px"
          fontStyle={theme.fonts.f600}
          margin="0 auto 0 0"
        >
          {t("profile.changePassword")}
        </Text>
        <Input
          handler={inputHandlerPassword}
          eye={true}
          placeholderText="Old password"
          name="oldPassword"
          value={oldPassword}
          password={true}
        />
        <ContentBox
          backColor="none"
          width="100%"
          columnGap="23px"
          direction={windowWidth > 900 ? "row" : "column"}
        >
          <Input
            handler={inputHandlerPassword}
            eye={true}
            placeholderText="New password"
            name="newPassword"
            value={newPassword}
            password={true}
          />
          <Input
            handler={inputHandlerPassword}
            eye={true}
            placeholderText="Confirm password"
            name="confirmPassword"
            value={confirmPassword}
            password={true}
          />
        </ContentBox>

        <ValidationInfo
          validations={[
            {
              id: "v0",
              text: t("passwordsMustMatch"),
              ok: passwordValidations.validatePasswordsMatch(
                newPassword,
                confirmPassword
              ),
            },
            {
              id: "v1",
              text: t("passwordOneNumber"),
              ok: passwordValidations.validateOneNumber(newPassword),
            },
            {
              id: "v2",
              text: t("passwordOneUppercase"),
              ok: passwordValidations.validateOneUppercase(newPassword),
            },
            {
              id: "v3",
              text: t("passwordLengthMin"),
              ok: passwordValidations.validatePasswordMinLength(newPassword),
            },
          ]}
        />
        <div style={{ width: "50%" }}>
          <Btn
            type="verification"
            buttonActive={buttonsState.password}
            label={t("profile.changePassword")}
            spinner={fetchingChangePassword}
            func={onPressSave}
          />
        </div>
      </ContentBox>
    </>
  );
};
export default ChangePassword;
