import React from "react";
import ContentLoader from "react-content-loader";
import { theme } from "../../../theme/default/theme";

export const SkeletonWalletItem = (props: any) => (
  <ContentLoader
    speed={2}
    width={200}
    height={36}
    viewBox="0 0 200 36"
    backgroundColor="#8c8c8c"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="4" y="1" rx="7" ry="7" width="36" height="36" />
    <rect x="46" y="3" rx="0" ry="0" width="47" height="13" />
    <rect x="46" y="20" rx="0" ry="0" width="100" height="15" />
  </ContentLoader>
);
export const SkeletonMarketInfoItem = (props: any) => (
  <ContentLoader
    speed={2}
    width={121}
    height={95}
    viewBox="0 0 121 95"
    backgroundColor="#8c8c8c"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="25" y="3" rx="0" ry="0" width="70" height="14" />
    <rect x="2" y="35" rx="0" ry="0" width="129" height="20" />
    <rect x="27" y="75" rx="0" ry="0" width="67" height="16" />
  </ContentLoader>
);
export const SkeletonMarketChartItem = (props: any) => (
  <ContentLoader
    speed={2}
    width={288}
    height={36}
    viewBox="0 0 288 36"
    backgroundColor="#8c8c8c"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="18" cy="17" r="16" />
    <rect x="39" y="0" rx="0" ry="0" width="43" height="12" />
    <rect x="39" y="19" rx="0" ry="0" width="53" height="11" />
    <rect x="194" y="-1" rx="0" ry="0" width="90" height="14" />
    <rect x="213" y="18" rx="0" ry="0" width="71" height="11" />
  </ContentLoader>
);
