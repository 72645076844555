import { Action } from '@reduxjs/toolkit';
import _ from 'lodash';
import { call, put, delay } from 'redux-saga/effects';
// import { initI18n } from './../../i18n';
import { takeLeading } from '../../store/sagaHelper';
import { CurrencyT } from '../../types/types';
import { AppSettingsActions, CurrentLangT } from './AppSettingsRedux';
import { 
  getCurrencyFromLocalStorage, 
  getLanguageFromLocalStorage, 
  getUserPasscodeFromLocalStorage, 
  getUserTokenFromLocalStorage, 
  DefaultLocalStorageType, 
  getUserIdFromLocalStorage, 
  getUserCardsLabelsFromLocalStorage, 
} from './../../helpers/localStorage';
import { UserActions } from '../user/UserRedux';
import { CardsActions } from '../cards/CardsRedux';
import { updateAuthorizationHeader } from '../../api';

function* getAppSettingsFromAsyncStorage(action: Action) {
  if (AppSettingsActions.getAppSettingsFromAsyncStorage.match(action)) {
    try {
      // Theme
      // const userTheme: AppMainTheme | null | undefined = yield call(getThemeFromAsyncStorage);
      // yield put(AppSettingsActions.setAppTheme(userTheme ?? 'auto'));

      // User app passcode
      let passcode: DefaultLocalStorageType = yield call(getUserPasscodeFromLocalStorage);
      if (passcode) {
        yield put(UserActions.setUserPasscode(passcode));
      }

      // Language
      let userLanguage: CurrentLangT | null = yield call(getLanguageFromLocalStorage);
      if (!userLanguage) {
        // English would be default language
        const defaultLanguage = 'en';
        userLanguage = defaultLanguage as CurrentLangT;
      }

      yield put(AppSettingsActions.currentLang(userLanguage));
      // initI18n(userLanguage);

      // Currency
      const userCurrency: CurrencyT | null = yield call(getCurrencyFromLocalStorage);
      if (userCurrency) {
        // console.log('userCurrency:', userCurrency);
        yield put(AppSettingsActions.setCurrency(userCurrency));
      }

      // User token
      const userToken: DefaultLocalStorageType = yield call(getUserTokenFromLocalStorage);

      if (userToken) {
        yield put(UserActions.storeTokenToRedux(userToken));
        yield call(updateAuthorizationHeader, userToken);
        // yield put(UserActions.sendPushData.request());
      }

      // ZedCex userId
      const userId: DefaultLocalStorageType = yield call(getUserIdFromLocalStorage);
      if (userId !== undefined && userId !== null) {
        yield put(UserActions.storeUserIdToRedux(Number(userId)));
        // Cards labels
        const cardsLabels: DefaultLocalStorageType = yield call(getUserCardsLabelsFromLocalStorage, userId);
        if (cardsLabels)
          yield put(CardsActions.setCardsLabelsTable(JSON.parse(cardsLabels)));
      }

      yield delay(50);

      yield put(AppSettingsActions.appStartSettingsSetupDone(true));
    } catch (error) {
      console.log("getAppSettingsFromAsyncStorage saga error: ", error);
      yield put(AppSettingsActions.appStartSettingsSetupDone(true));
    }
  }
}

export function* AppSettingsSaga() {
  yield* [
    takeLeading(AppSettingsActions.getAppSettingsFromAsyncStorage.type, getAppSettingsFromAsyncStorage),
  ];
}