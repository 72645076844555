import styled from "styled-components";
import { templates } from "../../../theme/templates";

export const Wrapper = styled.div`
padding: 24px;
@media(max-width: 700px){
padding: 24px 16px;
}


`;
export const MainContainer = styled.div`
display: flex;
align-items: flex-start;
margin-top: 32px;
column-gap: 24px;
@media(max-width: 936px){
  flex-direction: column;
  row-gap: 24px;
}
`;
export const Left = styled.div`
display: flex;
flex-direction: column;
row-gap: 24px;

`
export const LeftContainer = styled.div`
background-color: ${p => p.theme.colors.subBackground};
padding: 32px 20px;
border-radius: 24px;
@media(max-width: 700px){
  padding: 24px 16px;
}

`;
export const FilterItemsContainer = styled.div`
display: flex;
flex-direction: column;
row-gap: 16px;
`;
export const Right = styled.div`
  display: grid;
  
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 24px;
  width: 100%;
  @media (max-width: 656px) {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }
    @media (max-width: 400px) {
    grid-template-columns: repeat(1, minmax(150px, 1fr));
  }
  
  & > div {
    width: 100%;
   
   
  }
`;
export const Button = styled.div`
width: 100%;
height: 48px;
border-radius: 16px;
margin-top: -8px;
background-color: ${p => p.theme.colors.white};
${templates.centerContent};
cursor: pointer;
`;

export const ToggleContainer = styled.div<{view: boolean}>`
display: flex;
flex-direction: column;
row-gap: 24px;
@media(max-width: 936px){
  display: ${p => p.view? 'flex': 'none'};
}
`;

export const LoaderContainer = styled.div`
width: 100%;
display: flex;
padding-top: 100px;
justify-content: center ;
`;