import styled, { css, keyframes } from "styled-components";
import { templates } from "../../../theme/templates";

const shakeAnimation = keyframes`
  0% { transform: translateX(0); }
  10%, 90% { transform: translateX(-5px); }
  20%, 80% { transform: translateX(5px); }
  30%, 50%, 70% { transform: translateX(-5px); }
  40%, 60% { transform: translateX(5px); }
  100% { transform: translateX(0); }
`;

export const Wrapper = styled.div`
padding: 24px;
@media(max-width: 700px){
  padding: 24px 16px;
}
`;
export const InputsWrapper = styled.div`
margin-top: 50px;
display: flex;
flex-direction: column;
row-gap: 24px;
width: 60%;
@media(max-width: 700px){
  width: 100%;
}
`;
// input styles

export const InputContainer = styled.div<{file?: boolean, error?: boolean}>`
display: flex;
justify-content: space-between;
align-items: ${p => p.file ? 'flex-start' : 'center'};
background-color: ${p => p.theme.colors.subBackground};
width: 100%;
height: ${p => p.file ?'auto' : '60px'};
border-radius: 16px;
padding: 10px 16px;
position: relative;
${p => p.file && css`
aspect-ratio: 2/1;
flex-direction: column;
width: 100%;
justify-content: start;
align-items: center;
row-gap: 20px;
`}
@media(max-width: 700px){
  height: ${p => p.file ?'auto' : '48px'};
}
${(p) =>
    p.error &&
    css`
      border:1px solid red;
      animation: ${shakeAnimation} 0.4s linear;
    `}
`;
export const InputField = styled.input`
all: unset;
width: 100%;
height: 60px;
color: ${p => p.theme.colors.white};

&::placeholder{
  font-family: "Gilroy-Regular";
  font-weight: 400;
  color: ${p => p.theme.colors.greySubText};
  font-size: 18px;
  @media(max-width: 700px){
    font-size: 12px;
  }
}
`;
export const Button = styled.div`
width: 125px;
height: 38px;
margin-left: 6px;
${templates.centerContent};
background-color: ${p => p.theme.colors.yellowMain};
border-radius: 10px;
cursor: pointer;
`;
export const OpenBtn = styled.div<{open: boolean}>`
width: 40px;
height: 40px;
border-radius: 11px;
border: 1px solid ${p => p.theme.colors.greySubText};
${templates.centerContent};
transform: ${p => p.open ? 'rotate(180deg)' : 'rotate(0)'};
user-select: none;
cursor: pointer;
@media(max-width: 700px){
    width: 30px;
    height: 30px;
  }
`; 
export const SelectArea = styled.div`
position: absolute;
display: flex;
flex-direction: column;
background-color: ${p => p.theme.colors.subBackground};
height: auto;
padding: 10px;
align-items: center;
row-gap: 10px;
width: 100%;
top: 50px;
border: 0.4px solid ${p => p.theme.colors.greySubText};
border-top: 0;
border-radius:0 0 11px 11px;
left: 0;
z-index: 1;

/* Стили скролла */
scrollbar-width: thin;
  scrollbar-color: ${(p) => p.theme.colors.scrollbar};

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${(p) => p.theme.colors.greySubText};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.colors.white};
    border-radius: 3px;
  }
`;
export const ContainerRow = styled.div`
display: flex;
justify-content: space-between;
column-gap: 24px;
@media(max-width: 700px){
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
}
`;
export const ContainerColumn = styled.div`
display: flex;
flex-direction: column;
width: 100%;
`;

// Cheker

export const CheckerContainer = styled.div`
display: flex;
column-gap: 5px;
cursor: pointer;
user-select: none;
`;
export const IconCheck = styled.img`
width: 8px;
height: 8px;
`;
export const CheckSquare = styled.div<{check: boolean}>`
${templates.centerContent};
width: 18px;
height: 18px;
border-radius: 4px;
background-color: ${p => p.check ? p.theme.colors.yellowMain : p.theme.colors.subBackground};
`;
export const CheckCircle = styled.div<{checkRadio: boolean}>`
${templates.centerContent};
width: 18px;
height: 18px;
border-radius: 50%;

background-color: ${p => p.checkRadio ? p.theme.colors.yellowMain : p.theme.colors.subBackground};
&::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: black;
    display: ${p => (p.checkRadio ? 'block' : 'none')};
  }
`;
export const TagsWrapper = styled.div`
display: flex;
margin-top: 8px;
gap: 10px;
flex-wrap: wrap;
`;
export const TagItem = styled.div`
display: flex;
background-color: ${p => p.theme.colors.subBackground};
padding: 6px;
border-radius: 10px;
column-gap: 5px;
`;
export const Cross = styled.img`
cursor: pointer;
width: 20px;
height: 20px;
`;
export const MainWrapperInput = styled.div`
display: flex;
flex-direction: column;
width: 100%;
`;
export const RowWrapper = styled.div`
display: flex;
align-items: center;
margin: 12px 0;
`;