export const text = {
  colors: {
    cream: "#FEF1EB",
    gray: "#6D6D7B",
    lightGray: "#8F8A89",
    yellow: "#FED337",
    green: "#56D46A",
    lightGreen: "#40FDC8",
    red: "#F75555",
    black: "#101010",
  },
};
